import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
import logoblack from "../../assets/fpsc-logo.png";
import httpClient from "../../services/httpClient.ts";
import loginGraphic from '../../assets/login-graphic.png';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../../constants/apiUrls.ts";
import styles from "./Login.module.css";
import { Modal, Button } from "react-bootstrap";
// import { useIntercom } from "react-use-intercom";
import { jwtDecode } from "jwt-decode";
import { useUser } from "../../context/UserContext.js"
import { Loader } from "../../components/Loader.tsx";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";


const Signup = () => {
    const { t, i18n } = useTranslation();
    const {
        register,
        clearErrors,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ mode: "all" });

    const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, watch, formState: { errors: errorsForm2 } } = useForm();
    const { updateUserSettings } = useUserSettings();
      const navigate = useNavigate();

        const [showSuccessModal, setShowSuccessModal] = useState(false)
        const [isLoading, setIsLoading] = useState(false)
        const [errorMessage, setErrorMessage] = useState(null);
        const [signupSuccessMessage, setSignupSuccessMessage] = useState(null);
        const [isSignup, setIsSignup] = useState(true);
        const storedLanguage = localStorage.getItem("loginLanguage") || "en";
        const [language, setLanguage] = useState(storedLanguage);


        const handleSignup = async (data: any) => {
            try {
              setIsLoading(true);
        
              const url = API_URLS.userSignUp; // Replace with your signup API endpoint
              const response = await httpClient({
                method: "post",
                url,
                data,
              });
        
              const { result, error } = response;
        
              if (result?.status) {
                setIsSignup(false); // Switch to login form after successful signup
                setShowSuccessModal(true);
                resetForm2();
              } else {
                toast.error(error?.message || "Signup failed. Please try again.");
              }
            } catch (err) {
              console.error("Error:", err);
              toast.error(err?.message || "Signup failed. Please try again.");
            } finally {
              setIsLoading(false);
            }
          };
        
          useEffect(() => {
            if (showSuccessModal) {
              const timer = setTimeout(() => {
                setShowSuccessModal(false);
              }, 5000); // 5 seconds
        
              return () => clearTimeout(timer); // Cleanup the timer on component unmount
            }
          }, [showSuccessModal, setShowSuccessModal]);
        
          const fetchTranslations = async (lang) => {
            let url = API_URLS.getTranslationFile.replace("{language}", lang)
            const response = httpClient({
              method: "get",
              url: `${url}&isLoggedIn=${false}`,
            })
              .then(({ result, error }) => {
                if (error) {
                  return // Call with error if the request fails
                }
                return result
              })
        
            return response;
          };
        
          const loadTranslations = async (lang) => {
            const translations = await fetchTranslations(lang);
            if (translations) {
              i18n.addResourceBundle(lang, 'translation', translations);
              i18n.changeLanguage(lang);
              localStorage.setItem("loginLanguage", lang);
            }
          }
        
          useEffect(() => {
            loadTranslations(language)
          }, [language]);


          return (
            <>
             <Loader show={isLoading} />
 <div className={styles.login_container}>
  <div className={styles.login_header}>
    <div className={`${styles.headerContainer} container-fluid`}>
      <img src={logoblack} alt="" style={{ width: "154px" }} />
      <div className={styles.languageSelector}>

        <select
          className={styles.languageDropdown}
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>
    </div>
    <div>
    </div>
  </div>
  <div className={`container`}>
    {/* <ToastContainer
      style={{ top: "20px", right: "20px" }}
      className="toast-error"
    >
      {errorMessage && (
        <Toast
          onClose={() => setErrorMessage(null)}
          show={true}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{errorMessage}</Toast.Body>
        </Toast>
      )}
    </ToastContainer> */}
    <div className="row no-gutters" style={{ padding: "0 20 !important" }}>
      <div
        className={`align-items-center bg-white col-md-7 d-flex`}
      >
        <div className={styles.login_form}>
          
            <div className="brand form-style">
              <h1 style={{ color: "#e61e28", marginBottom: "30px" }}>
                {t("signup.headerTitle")}
              </h1>

              <Form className="mb-5" onSubmit={handleSubmitForm2(handleSignup)}>
                <div className="row">
                  {/* First Name */}
                  <div className="col-md-6 custom-form-group">
                    <label> {t('signup.formLabel.firstName')} * </label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.firstName')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputFirstName"
                      aria-describedby="firstName"
                      {...registerForm2("firstName", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.firstName")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.firstName && (
                      <div className="invalid-feedback">{errorsForm2.firstName?.message?.toString()}</div>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="col-md-6 custom-form-group">
                    <label>{t('signup.formLabel.lastName')} *</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.lastName')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputLastName"
                      aria-describedby="lastName"
                      {...registerForm2("lastName", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.lastName")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.lastName && (
                      <div className="invalid-feedback">{errorsForm2.lastName?.message?.toString()}</div>
                    )}
                  </div>
                </div>

                {/* Email */}
                <div className="row">
                  <div className="col-12 custom-form-group">
                    <label> {t('signup.formLabel.email')}*</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.email')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputEmail"
                      aria-describedby="emailHelp"
                      {...registerForm2("email", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.email")} ${t('validation.requiredMessage')}`,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: `${t("validation.invalid")} ${t('signup.formLabel.email')}`,
                        },
                      })}
                    />
                    {errorsForm2.email && (
                      <div className="invalid-feedback">{errorsForm2.email?.message?.toString()}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 custom-form-group">
                    <label> {t('signup.formLabel.password')} *</label>
                    <input
                      type="password"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.password')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputPassword"
                      {...registerForm2("password", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.password")} ${t('validation.requiredMessage')}`,
                        },
                        minLength: {
                          value: 8,
                          message: `${t('validation.passwordLengthMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.password && (
                      <div className="invalid-feedback">{errorsForm2.password?.message?.toString()}</div>
                    )}
                  </div>
                </div>

                {/* Other Fields */}
                <div className="row">
                  {/* Job Title */}
                  <div className="col-md-6 custom-form-group">
                    <label> {t('signup.formLabel.jobTitle')}*</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.jobTitle')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputJobTitle"
                      {...registerForm2("jobTitle", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.jobTitle")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.jobTitle && (
                      <div className="invalid-feedback">{errorsForm2.jobTitle?.message?.toString()}</div>
                    )}
                  </div>

                  {/* Preferred Language */}
                  <div className="col-md-6 custom-form-group">
                    <label> {t('signup.formLabel.prefferedLanguage')} *</label>
                    <select
                      className="form-select c-form-control c-form-control-bg c-form-control-sm"
                      style={{ height: "48px" }}
                      id="InputPreferredLanguage"
                      {...registerForm2("preferredLanguage", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.prefferedLanguage")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    >
                      <option value="">{t('signup.formLabel.selectLanguage')}</option>
                      <option value="en">{t('signup.formLabel.english')}</option>
                      <option value="fr"> {t('signup.formLabel.french')}</option>
                    </select>
                    {errorsForm2.preferredLanguage && (
                      <div className="invalid-feedback">{errorsForm2.preferredLanguage?.message?.toString()}</div>
                    )}
                  </div>
                </div>

                {/* Organization/Business */}
                <div className="row">
                  <div className="col-12 custom-form-group">
                    <label>{t('signup.formLabel.organisation')}*</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.organisation')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputOrganization"
                      {...registerForm2("organization", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.organisation")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.organization && (
                      <div className="invalid-feedback">{errorsForm2.organization?.message?.toString()}</div>
                    )}
                  </div>
                </div>

                {/* Phone Number with Ext */}
                <div className="row">
                  <div className="col-md-6 custom-form-group">
                    <label> {t('signup.formLabel.phone')}*</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.phone')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputPhone"
                      {...registerForm2("phone", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.phone")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.phone && (
                      <div className="invalid-feedback">{errorsForm2.phone?.message?.toString()}</div>
                    )}
                  </div>

                  <div className="col-md-6 custom-form-group">
                    <label> {t('signup.formLabel.ext')}</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.formLabel.ext')}`}
                      maxLength="5"
                      className="c-form-control c-form-control-lg"
                      id="InputExt"
                      {...registerForm2("ext")}
                    />
                  </div>
                </div>

                {/* Business Mailing Address */}
                <div className="row">
                  <div className="col-12 custom-form-group">
                    <label> {t('signup.formLabel.mailingAddress')}*</label>
                    <input
                      type="text"
                      placeholder={`${t('signup.enter')} ${t('signup.formLabel.mailingAddress')}`}
                      className="c-form-control c-form-control-lg"
                      id="InputAddress"
                      {...registerForm2("address", {
                        required: {
                          value: true,
                          message: `${t("signup.formLabel.mailingAddress")} ${t('validation.requiredMessage')}`,
                        },
                      })}
                    />
                    {errorsForm2.address && (
                      <div className="invalid-feedback">{errorsForm2.address?.message?.toString()}</div>
                    )}
                  </div>
                </div>


                <button type="submit" className="button">
                  {t('signup.button.signup')}
                </button>
              </Form>
              <p>
                {" "}{t('signup.alreadyAnAccountText')}
                <span
                  className={styles.toggle_link}
                  style={{ color: "#e61e28", cursor: "pointer" }}
                  onClick={() => navigate('/login')}
                >
                  {" "}{t('signup.loginText')}
                </span>
              </p>
            </div>
          
        </div>
      </div>
      <div className="col-md-5">
        <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
      </div>
    </div>
  </div>
</div>
<Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title> {t('subscription.toast.success')}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>{t('signup.message.successMessage')}</p>
  </Modal.Body>

</Modal>
            
            </>
          )
}

export default Signup;
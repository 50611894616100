import React, { useEffect, useState } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import CommonHeader from "../../components/Header.tsx";
import TabEstablishment from "../../components/TabEstablishment";
import { useUser } from "../../context/UserContext.js";
import SubscriptionPlans from "../../components/Subscription.tsx";
import { useNavigate } from "react-router-dom";
import { getDefaultPlan, isLoggedIn } from "../utils/authUtils.js";

const EstablishmentData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [joyrideValue, setJoyrideValue] = useState(null);
  const { user, userPlan, isContextLoading, updateDefaultPlan, defaultPlan } = useUser()




  useEffect(() => {
    const checkAccess = async () => {
      const loggedIn = isLoggedIn();


      if (loggedIn) {
        // For logged-in users, check their plan
        // if (user?.role !== 'SUPER_ADMIN' && user?.role !== 'ADMIN') {
        //   if (!userPlan && !isContextLoading) {
        //     navigate('/subscription-plans');
        //   }
        // }
      } else {
 
        // For public users, fetch default plan
        try {
          const defaultPlan = await getDefaultPlan();
       
          if (!defaultPlan) {
            navigate(-1);
            return;
          }

          updateDefaultPlan(defaultPlan); // Store in context

          if (defaultPlan?.features?.establishmentInsights?.defaultEstablishmentAccessibility === 'inaccessible') {
            console.log('hhhhhy')
            navigate(-1);
          }
        } catch (error) {
          console.error("Error checking access:", error);
          navigate(-1);
        }
      }

    };

    checkAccess();
  }, [isContextLoading, userPlan, user, isLoggedIn]);


  useEffect(() => {
   
    if (isLoggedIn() && userPlan && userPlan?.subscriptionPlanId?.features?.establishmentInsights?.defaultEstablishmentAccessibility === 'inaccessible') {
      navigate(-1)
    }
  }, [userPlan])

  const updateSettings = (tabname, settingData) => {
    if (!isLoggedIn()) return;
    let userSetting = JSON.parse(localStorage.getItem('user'))
    let data

    switch (tabname) {
      case 'tabcomponent':
        data = {
          settings: {
            ...userSetting?.settings,
            establishment: {
              ...userSetting?.settings.establishment,
              tab: settingData
            },
          },
        };
        break;
      case 'comparison':

        data = {
          settings: {
            ...userSetting?.settings,
            establishment: {
              ...userSetting?.settings?.establishment,
              comparison: {
                ...userSetting?.settings?.comparison, field1: settingData
              }
            },
          },
        };

        break;
      case 'sectorYearly':
        data = {
          settings: {
            ...userSetting?.settings,
            employment: {
              ...userSetting?.settings.employment,
              sectorYearly: settingData
            },
          },
        };
        break;
      case 'years':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings?.employment,
              years: settingData
            },
          },
        };
        break;
      case 'ageRange':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings?.employment,
              ageRange: settingData
            },
          },
        };
        break;
      default:
        break;
    }

    let url = API_URLS.preloadedSettings;
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }

  const onStart = (value) => {
    setJoyrideValue(value);
  }

  return (
    <>
      {(!isLoggedIn() || userPlan || user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") &&
        <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
          <CommonHeader
            title={t("dashboardTitle")}
            subtitle={`Source: ${t("dataSourceEstablishment")}`}
            helpText={"dashboardHelpText"}
            onStart={onStart}
          />
          <div style={{ flex: 1 }}>
            <TabEstablishment getTabData={updateSettings} joyrideStatus={joyrideValue} setJoyrideStatus={setJoyrideValue} />

          </div>
        </div>
      }
    </>
  );
};

export default EstablishmentData;
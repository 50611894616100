import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { IconSetName } from "../../constants/commonData";
import { useTranslation } from "react-i18next";
import useWindowWidth from "../../hooks/useWindowWidth";
import { useUser } from "../../context/UserContext";
import { isLoggedIn } from "../../pages/utils/authUtils";

const BarChartHighChart = ({ dataset = {}, title, barClick }) => {
  const { t } = useTranslation()
  const { user, userPlan ,defaultPlan} = useUser()
  const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const windowWidth = useWindowWidth()
  let [options, setOptions] = useState({});
  let [noData, setNoData] = useState(false);

  useEffect(() => {
    // Define the chart configuration
    const categoriesData = [];
    const seriesData = [];
    if (dataset && Object.keys(dataset).length) {
      for (let index in dataset.colors) {
        let c = dataset.colors[index];
        let y = dataset.values[index];
        let d = dataset.labels2[index];
        let ec = dataset.labels[index]
        let n = dataset.industryNames[index]
        let obj = { y: y, color: c, name: n, code: ec };
        seriesData.push(obj);
        categoriesData.push(d);
      }
      setNoData(false);
    } else {
      setNoData(true);
    }
    const newOptions = {
      chart: {
        type: "column",
      },
      accessibility: {
        enabled: false
    },
      tooltip: {
        formatter: function () {
          return "<b>" + t(this.point.name) + "</b><br/>" +"<b>" + `${t("naics")} ${this.point.code}` + "</b><br/>" + `${t("establishments")}: ` + this.y.toLocaleString('en-CA');
        },
      },
      legend: {
        enabled: false, // Disable the legend
      },
      credits: {
        enabled: false,
      },
      title: {
        text: title,
        align: "left",
      },
      xAxis: {
        categories: categoriesData,
        labels: {
          formatter: function () {
            let iconName = this.value.replace(/ /g, "");
            let icons = `<img src="${IconSetName[iconName]}" alt="SI" height=${window.innerWidth <= 768 ?  "15px" : "34px"} width=${window.innerWidth <= 768 ?  "15px" : "34px"} />`;
            return icons;
        },
          useHTML: true, // Enable HTML for labels
        },
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: t("dashboard2SectorChartYAxisTitle"),
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
          borderRadius: {
            radius: 20,
          },
        },
        series: {
          point: {
            events: {
              click: function () {
                if(isLoggedIn() ?  userPlan?.subscriptionPlanId?.features?.establishmentInsights?.bySector?.barGraphClick == "inaccessible" : defaultPlan?.features?.establishmentInsights?.bySector?.barGraphClick == "inaccessible") {
                  return
                }
                // Your function to perform on click of a column bar
                barClick(this.index);
              },
            },
          },
        },
      },
      series: [
        {
          pointWidth: window.innerWidth <= 768 ? 15 : 40,
          data: seriesData,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 768, // For tablets and small devices
            },
            chartOptions: {
              chart: {
                height: 400, // Adjust chart height
              },
              xAxis: {
                labels: {
                  formatter: function () {
                    let iconName = this.value.replace(/ /g, "");
                    return `<img src="${IconSetName[iconName]}" alt="SI" height="20px" width="20px" />`;
                  },
                },
              },
              series: [
                {
                  pointWidth: 15, // Narrower bars for small devices
                },
              ],
            },
          },
          {
            condition: {
              maxWidth: 480, // For small screens (phones)
            },
            chartOptions: {
              chart: {
                height: 300, // Reduce chart height further
              },
              xAxis: {
                labels: {
                  formatter: function () {
                    let iconName = this.value.replace(/ /g, "");
                    return `<img src="${IconSetName[iconName]}" alt="SI" height="8px" width="8px" />`;
                  },
                },
              },
              series: [
                {
                  pointWidth: 10, // Smallest bar width for phones
                },
              ],
            },
          },
        ],
      },
    
    };
    setOptions(newOptions);
  }, [dataset, t]);

  return (
    <>
      {noData ? (
        <div className="text-danger">No Data , Please Select Different Filter</div>
      ) : (
        <div className="establishment-barchart">
        <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      )}
    </>
  );
};

export default BarChartHighChart;

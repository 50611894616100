import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useUser } from "../../context/UserContext.js";
import { getDefaultPlan, isLoggedIn } from "../../pages/utils/authUtils.js";
import Sidebar from "./components/Sidebar.tsx";
import { Loader } from "../../components/Loader.tsx";

const PrivateLayout = ({ component: Component, meta }: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { updateDefaultPlan, user ,fetchSteps,joyrideSteps } = useUser();
  const [initialized, setInitialized] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSteps()
  }, [pathname]);

    // Routes accessible to non-logged in users
    const publicAccessibleRoutes = [
      "/establishment-data",
      "/employment-data",
      "/educational-program",
    ];
  
    // Admin-only routes
    const adminRoutes = [
      "/settings",
      "/add-user",
      "/documents"
    ];



  useEffect(() => {

    const initialize = async () => {
      try {
        const storedUser = localStorage.getItem("user");

       
        if (storedUser) {
          // User is logged in
          if (adminRoutes.includes(pathname) && 
              !["SUPER_ADMIN", "ADMIN"].includes(user?.role)) {
            navigate(-1); // Stay on current page
            return;
          }
        } else {
          if(joyrideSteps.length < 0) fetchSteps()
          if (!publicAccessibleRoutes.includes(pathname)) {
        
            navigate(-1); // Stay on current page
            return;
          }
          // User is not logged in
          if (!localStorage.getItem('defaultPlan')) {
            const defaultPlan = await getDefaultPlan();
            updateDefaultPlan(defaultPlan);
          }
          
          // Redirect to establishment-data if trying to access private route


          
        }
      } catch (error) {
        console.error("Initialization error:", error);
      } finally {
        setInitialized(true);
      }
    };

    initialize();
  }, [pathname, navigate, updateDefaultPlan]);

  // if (!initialized) {
  //   return <div>Loading...</div>;
  // }

  return (
    <main role="main" className="main" style={{overflow: "hidden"}}>
          <Loader show={!initialized} />
      <Sidebar />
      <div className="mainBodyWrapper" id="mainBodyWrapper" style={{ height: '100vh'}}>
        <Component />
      </div>
    </main>
  );
};

export default PrivateLayout;
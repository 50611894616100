import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonHeader from "../../components/Header.tsx";
import TabAccounts from '../../components/TabAccounts.tsx';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation()
  return (
    <>
   
      <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
        <CommonHeader
          title={t("accounts.headerTitle")}
          component= {'accounts'}
        />
        <div style={{ flex: 1 }}>
          <TabAccounts />
        </div>
      </div>
    </>
  )
}

export default Settings
import axios, { AxiosRequestConfig } from "axios";
import ErrorHandlingService from "./errorHandlingService.ts";
import CryptoJS from "crypto-js";

// Extend AxiosRequestConfig to include isPublic
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  isPublic?: boolean;
}

const API_SECRET_KEY :any= process.env.REACT_APP_API_SECRET; // Never expose this

const BASE_URL :any= process.env.REACT_APP_BASE_URL;


function generateSignature(endpoint: string) {
  const message = `/api${endpoint}`;
 
  return CryptoJS.HmacSHA256(message, API_SECRET_KEY).toString(CryptoJS.enc.Hex);
}

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config :any) => {
  let token = localStorage.getItem("token");

    const signature = generateSignature(config.url || "");
    
    config.headers.Authorization = token ? `Bearer ${token}` : `Signature ${signature}`;
  

  // LoadingState.show();

  return config;
}, ErrorHandlingService.handleRequestError)
// async (error) => {
//   // If the error response has a status of 401, handle token expiration
//   if (error.response && error.response.status === 401) {
//     // You can log out the user or redirect them to the login page
//     localStorage.removeItem("token"); // Clear the invalid token
//     window.location.reload()
//     // const navigate = useNavigate(); // Create the navigate function to redirect

//     // Redirect to the login page
//     // navigate("/"); // Or your login path

//     // You can also show a toast message for the user
//     // toast.error("Session expired. Please log in again.");
//   }

//   // Pass on any other errors to the error handling service
//   return ErrorHandlingService.handleRequestError(error);
// });

axiosInstance.interceptors.response.use((response) => {
  // response.data = transformData(response.data);
  // LoadingState.hide();
  return response;
}, 
ErrorHandlingService.handleRequestError

);

export default axiosInstance;

import React, { useState } from 'react'
import SubscriptionDetails from '../pages/account/SubscriptionDetails.tsx';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51QfgosAC6MN0hm6W81Omk3Db1GX5X6M1SCJ8l9CKkqmADFjkiSJnSKlmFxNy6Qj9EtbZEfRX88pJvjm5ZfX8f1Rh00wuADDXmt');

const SubscriptionStripeWrapper = () => {
   
  return (
    <Elements stripe={stripePromise}>
        <SubscriptionDetails/>
    </Elements>
  )
}

export default SubscriptionStripeWrapper
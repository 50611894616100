// GlobalSettingsContext.js
import React, { createContext, useContext, useState } from "react";


const SAVED_TAB_KEY = "savedTabState";

export const GlobalSettingsContext = createContext();

export const GlobalSettingsProvider = ({ children }) => {
  const [defaultSettings, setDefaultSettings] = useState(() => {
    const storedSettings = localStorage.getItem("defaultSettings");
    if (storedSettings) {
      const userSettings = JSON.parse(storedSettings);
      return userSettings?.settings; // Extract only the settings part
    }
    return [
      {
        mainTab: "establishmentInsights",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              employmentSize: "",
              selectedPeriod: "1",
              selectedYear: "",
              selectedSector: {
                parents: [],
                children: [],
              },
            },
          },
          {
            subTab: "sector",
            settings: {
              employmentSize: "",
              selectedPeriod: "1",
              selectedYear: "",
              selectedGeography: "",
              selectedSector: [],
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                field: "",
                employmentSize: "",
                geography: "",
                period: '',
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "mens",
                range: "under30",
              },
              field2: {
                field: "",
                employmentSize: "",
                geography: "",
                period: '',
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              tab: 'tab5',
              compareField: "",
              subField: "",
            },
          },
        ],
      },
      {
        mainTab: "educationalPrograms",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              tableView: false,
              study: "all",
              selectedProvince: "",
              filters: {},
            },
          },
          {
            subTab: "areaByStudy",
            settings: {
              selectedProvince: "",
              selectedLevelOfInstruction: "",
              apprenticeOffered: "",
              filters: {},
            },
          },
          {
            subTab: "levelOfInstruction",
            settings: {
              selectedProvince: "",
              selectedCity: "",
              selectedLanguage: "",
            },
          },
          {
            subTab: "apprenticeship",
            settings: {
              selectedProvince: "",
              selectedCity: "",
              selectedLanguage: "",
              filters: {},
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              field2: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              compareField: "provinceTerritory",
              subField: "",
              field: ""
            },
          },
        ],
      },
      {
        mainTab: "workForce",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              tableView: false,
              selectedYear: "",
            },
          },
          {
            subTab: "totalEmploymentOverTime",
            settings: {
              selectedSector: [],
              selectedRange: [],
              selectedEstablishment: "all",
            },
          },
          {
            subTab: "demograpghicBreakDown",
            settings: {
              selectedSector: "",
              ageRange: "",
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
                sector: "",
                subField: "",
              },
              field2: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
                sector: "",
                subField: "",
              },
              compareField: "",
              subField: "",
              field: ""
            },
          },
        ],
      },
    ];
  });

  // State for current main tab and sub tab
  const [currentMainTab, setCurrentMainTab] = useState(null);
  const [currentSubTab, setCurrentSubTab] = useState(null);

  const [currentSavedMainTabSubTab, setCurrentSavedMainTabSubTab] = useState(() => {
    const savedTab = localStorage.getItem(SAVED_TAB_KEY);
    return savedTab ? JSON.parse(savedTab) : null;
  });

  // Separate function to update saved tab in both state and localStorage
  const updateSavedTabStorage = (tabState) => {

    if (tabState) {
      localStorage.setItem(SAVED_TAB_KEY, JSON.stringify(tabState));
    } else {
      localStorage.removeItem(SAVED_TAB_KEY);
    }
    setCurrentSavedMainTabSubTab(tabState);
  };

  // Modified updateCurrentSavedTab function
  const updateCurrentSavedTab = (mainTab, subTab) => {
    const newSavedTab = mainTab && subTab ? { mainTab, subTab } : null;
    updateSavedTabStorage(newSavedTab);
  };

  // Function to update the current main tab and sub tab
  const setCurrentTab = (mainTab, subTab) => {
    setCurrentMainTab(mainTab);
    setCurrentSubTab(subTab);
  };

  // Function to get the current main tab and sub tab
  const getCurrentTab = () => {
    return { mainTab: currentMainTab, subTab: currentSubTab };
  };

  // Function to update defaultSettings and localStorage
  const updateSettings = (mainTab, subTab, key, value) => {
    setDefaultSettings((prevSettings) => {
      const updatedSettings = prevSettings?.map((mainTabSetting) => {
        if (mainTabSetting.mainTab === mainTab) {
          return {
            ...mainTabSetting,
            subTabs: mainTabSetting?.subTabs?.map((subTabSetting) => {
              if (subTabSetting?.subTab === subTab) {
                if (subTab === "comparison") {
                  const keys = key.split(".");
                  if (keys.length === 1) {
                    return {
                      ...subTabSetting,
                      settings: {
                        ...subTabSetting.settings,
                        [key]: value,
                      },
                    };
                  } else if (keys.length === 2) {
                    const [parentKey, childKey] = keys;
                    return {
                      ...subTabSetting,
                      settings: {
                        ...subTabSetting.settings,
                        [parentKey]: {
                          ...subTabSetting.settings[parentKey],
                          [childKey]: value,
                        },
                      },
                    };
                  }
                } else {
                  return {
                    ...subTabSetting,
                    settings: {
                      ...subTabSetting.settings,
                      [key]: value,
                    },
                  };
                }
              }
              return subTabSetting;
            }),
          };
        }
        return mainTabSetting;
      });

      // Update localStorage with the full userSettings object
      const storedSettings = localStorage.getItem("defaultSettings");
      const userSettings = storedSettings ? JSON.parse(storedSettings) : {};
      userSettings.settings = updatedSettings; // Update only the settings part
      localStorage.setItem("defaultSettings", JSON.stringify(userSettings));

      return updatedSettings;
    });
  };

  const updateUserSettings = (newUserSettings) => {
   
    if (!newUserSettings || !newUserSettings.settings || newUserSettings.settings.length === 0) {
        console.error("Invalid or empty userSettings object:", newUserSettings);
        localStorage.removeItem("defaultSettings");
        setDefaultSettings([]);
        setCurrentSavedMainTabSubTab(null);
        updateSavedTabStorage(null);
        return;
    }

    setDefaultSettings(prevSettings => {
        // Get the updated main tab from backend response
        const updatedMainTab = newUserSettings.settings[0];
        
        // Merge with existing settings
        const mergedSettings = prevSettings.map(mainTab => {
            if (mainTab.mainTab === updatedMainTab.mainTab) {
                // Found the matching main tab - update its sub tabs
                return {
                    ...mainTab,
                    subTabs: mainTab.subTabs.map(subTab => {
                        // Find if this sub tab exists in the updated data
                        const updatedSubTab = updatedMainTab.subTabs.find(
                            s => s.subTab === subTab.subTab
                        );
                        // If found, return updated version, otherwise keep original
                        return updatedSubTab ? updatedSubTab : subTab;
                    })
                };
            }
            return mainTab; // Return unchanged for other main tabs
        });

        // Handle case where the main tab doesn't exist yet
        if (!mergedSettings.some(m => m.mainTab === updatedMainTab.mainTab)) {
            mergedSettings.push(updatedMainTab);
        }

        // Prepare complete user settings object for localStorage including metadata
        const fullUserSettings = {
            ...newUserSettings, // Keep all original properties (_id, name, isDefault, etc.)
            settings: mergedSettings // Update only the settings part
        };

        localStorage.setItem("defaultSettings", JSON.stringify(fullUserSettings));
        return mergedSettings; // Return only settings for context state
    });

    // Update current saved tab if provided
    if (newUserSettings.currentSavedMainTabSubTab) {
      
        updateSavedTabStorage(newUserSettings.currentSavedMainTabSubTab);
    }
};

  // Reset filters to initial state
  const resetFilters = (mainTab, subTab) => {
    // Check if current saved tab exists and matches the passed tab
  
    if (currentSavedMainTabSubTab && 
        currentSavedMainTabSubTab.mainTab === mainTab && 
        currentSavedMainTabSubTab.subTab === subTab) {
      return; // Skip reset if it matches the saved tab
    }

    setDefaultSettings((prevSettings) => {
      const updatedSettings = prevSettings.map((tab) => {
        if (tab?.mainTab === mainTab) {
  
          return {
            ...tab,
            subTabs: tab.subTabs.map((st) => {
              if (st?.subTab === subTab) {
               
                return {
                  ...st,
                  settings: getInitialDefaultSettings().find(t => t.mainTab === mainTab)
                    ?.subTabs.find(s => s.subTab === subTab)?.settings,
                };
              }
              return st;
            }),
          };
        }
        return tab;
      });
    // Update localStorage
      const storedSettings = localStorage.getItem("defaultSettings");
      const userSettings = storedSettings ? JSON.parse(storedSettings) : {};
      userSettings.settings = updatedSettings;
      localStorage.setItem("defaultSettings", JSON.stringify(userSettings));

      return updatedSettings;
    });
  };

  const getFilters = (mainTab, subTab, item, province) => {
    const settings = defaultSettings?.find(mainTabSetting => mainTabSetting.mainTab === mainTab)
      ?.subTabs?.find(subTabSetting => subTabSetting.subTab === subTab)?.settings;

    return settings?.filters?.[`${item}_${province}`] || {};
  };

  // Function to update filters based on item and province
  const updateFilters = (mainTab, subTab, item, province, filters) => {
    setDefaultSettings((prevSettings) => {
      const updatedSettings = prevSettings?.map((mainTabSetting) => {
        if (mainTabSetting.mainTab === mainTab) {
          return {
            ...mainTabSetting,
            subTabs: mainTabSetting?.subTabs?.map((subTabSetting) => {
              if (subTabSetting?.subTab === subTab) {
                const newFilters = {
                  ...subTabSetting.settings.filters,
                  [`${item}_${province}`]: filters, // Use combination of item and province as key
                };
                return {
                  ...subTabSetting,
                  settings: {
                    ...subTabSetting.settings,
                    filters: newFilters,
                  },
                };
              }
              return subTabSetting;
            }),
          };
        }
        return mainTabSetting;
      });

      // Save updated settings to localStorage
      const storedSettings = localStorage.getItem("defaultSettings");
      const userSettings = storedSettings ? JSON.parse(storedSettings) : {};
      userSettings.settings = updatedSettings; // Update only the settings part
      localStorage.setItem("defaultSettings", JSON.stringify(userSettings));

      return updatedSettings;
    });
  };

  const getInitialDefaultSettings = () => {
    return [
      {
        mainTab: "establishmentInsights",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              employmentSize: "",
              selectedPeriod: "1",
              selectedYear: "",
              selectedSector: {
                parents: [],
                children: [],
              },
            },
          },
          {
            subTab: "sector",
            settings: {
              employmentSize: "",
              selectedPeriod: "1",
              selectedYear: "",
              selectedGeography: "",
              selectedSector: [],
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                field: "",
                employmentSize: "",
                geography: "",
                period: '',
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "mens",
                range: "under30",
              },
              field2: {
                field: "",
                employmentSize: "",
                geography: "",
                period: '',
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              compareField: "",
              subField: "",
            },
          },
        ],
      },
      {
        mainTab: "educationalPrograms",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              tableView: false,
              study: "all",
              selectedProvince: "",
              filters: {},
            },
          },
          {
            subTab: "areaByStudy",
            settings: {
              selectedProvince: "",
              selectedLevelOfInstruction: "",
              apprenticeOffered: "",
              filters: {},
            },
          },
          {
            subTab: "levelOfInstruction",
            settings: {
              selectedProvince: "",
              selectedCity: "",
              selectedLanguage: "",
            },
          },
          {
            subTab: "apprenticeship",
            settings: {
              selectedProvince: "",
              selectedCity: "",
              selectedLanguage: "",
              filters: {},
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              field2: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
              },
              compareField: "provinceTerritory",
              subField: "",
              field: ""
            },
          },
        ],
      },
      {
        mainTab: "workForce",
        subTabs: [
          {
            subTab: "provinceAndTerritory",
            settings: {
              tableView: false,
              selectedYear: "",
            },
          },
          {
            subTab: "totalEmploymentOverTime",
            settings: {
              selectedSector: [],
              selectedRange: [],
              selectedEstablishment: "all",
            },
          },
          {
            subTab: "demograpghicBreakDown",
            settings: {
              selectedSector: "",
              ageRange: "",
            },
          },
          {
            subTab: "comparison",
            settings: {
              field1: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
                sector: "",
                subField: "",
              },
              field2: {
                employmentSize: "",
                geography: "",
                period: 1,
                year: null,
                studyField: "all",
                levelOfInstruction: "",
                gender: "men",
                range: "under30",
                sector: "",
                subField: "",
              },
              compareField: "",
              subField: "",
              field: ""
            },
          },
        ],
      },
    ];
  };

  // const resetSettings = () => {
  //   setDefaultSettings(prevSettings => {
  //     // Get initial settings
  //     const initialSettings = getInitialDefaultSettings();
      
  //     // Get current saved tab from state
  //     const savedTab = localStorage.getItem(SAVED_TAB_KEY) && JSON?.parse(localStorage.getItem(SAVED_TAB_KEY));
      
      
  //     // If there's no saved tab, do full reset
  //     if (!savedTab || !savedTab?.mainTab || !savedTab.subTab) {
  //       localStorage.setItem("defaultSettings", JSON.stringify({ 
  //         settings: initialSettings,
  //         currentSavedMainTabSubTab: null 
  //       }));
  //       setCurrentSavedMainTabSubTab(null);
  //       return initialSettings;
  //     }
  
  //     // Find the saved tab in current settings
  //     const currentSavedTabData = prevSettings.find(
  //       tab => tab.mainTab === savedTab.mainTab
  //     )?.subTabs.find(
  //       subTab => subTab.subTab === savedTab.subTab
  //     );
  

  //     console.log(currentSavedTabData,"currentSavedTabData")
  //     // If we can't find the saved tab, do full reset
  //     if (!currentSavedTabData) {
  //       localStorage.setItem("defaultSettings", JSON.stringify({ 
  //         settings: initialSettings,
  //         currentSavedMainTabSubTab: null 
  //       }));
  //       setCurrentSavedMainTabSubTab(null);
  //       return initialSettings;
  //     }
  
  //     // Merge - keep saved tab, reset others
  //     const mergedSettings = initialSettings.map(initialTab => {
  //       // If this is the saved main tab
  //       if (initialTab.mainTab === savedTab.mainTab) {
  //         return {
  //           ...initialTab,
  //           subTabs: initialTab.subTabs.map(initialSubTab => {
  //             // If this is the saved sub tab, keep current data
  //             if (initialSubTab.subTab === savedTab.subTab) {
  //               return currentSavedTabData;
  //             }
  //             // Otherwise reset to initial
  //             return initialSubTab;
  //           })
  //         };
  //       }
  //       // For other main tabs, reset completely
  //       return initialTab;
  //     });
  
  //     // Update localStorage
  //     localStorage.setItem("defaultSettings", JSON.stringify({ 
  //       settings: mergedSettings,
  //       currentSavedMainTabSubTab: savedTab 
  //     }));
  
  //     return mergedSettings;
  //   });
  // };
  const resetSettings = () => {
    setDefaultSettings(prevSettings => {
        // Get initial settings
        const initialSettings = getInitialDefaultSettings();
        
        // Get current saved tab from localStorage
        const savedTab = localStorage.getItem(SAVED_TAB_KEY) && JSON.parse(localStorage.getItem(SAVED_TAB_KEY));
        
        // Get current full settings from localStorage
        const currentStorage = JSON.parse(localStorage.getItem("defaultSettings")) || {};
        
        // If there's no saved tab, do full reset but preserve other data
        if (!savedTab || !savedTab?.mainTab || !savedTab.subTab) {
            const updatedStorage = {
                ...currentStorage,  // Keep all existing non-settings data
                settings: initialSettings,
                currentSavedMainTabSubTab: null 
            };
            localStorage.setItem("defaultSettings", JSON.stringify(updatedStorage));
            setCurrentSavedMainTabSubTab(null);
            return initialSettings;
        }

        // Find the saved tab in current settings
        const currentSavedTabData = prevSettings.find(
            tab => tab.mainTab === savedTab.mainTab
        )?.subTabs.find(
            subTab => subTab.subTab === savedTab.subTab
        );

        // If we can't find the saved tab, do full reset but preserve other data
        if (!currentSavedTabData) {
            const updatedStorage = {
                ...currentStorage,  // Keep all existing non-settings data
                settings: initialSettings,
                currentSavedMainTabSubTab: null 
            };
            localStorage.setItem("defaultSettings", JSON.stringify(updatedStorage));
            setCurrentSavedMainTabSubTab(null);
            return initialSettings;
        }

        // Merge - keep saved tab, reset others
        const mergedSettings = initialSettings.map(initialTab => {
            if (initialTab.mainTab === savedTab.mainTab) {
                return {
                    ...initialTab,
                    subTabs: initialTab.subTabs.map(initialSubTab => {
                        return initialSubTab.subTab === savedTab.subTab 
                            ? currentSavedTabData 
                            : initialSubTab;
                    })
                };
            }
            return initialTab;
        });

        // Update localStorage while preserving all other data
        const updatedStorage = {
            ...currentStorage,  // Keep _id, name, isDefault, etc.
            settings: mergedSettings,
            currentSavedMainTabSubTab: savedTab 
        };
        
        localStorage.setItem("defaultSettings", JSON.stringify(updatedStorage));
        return mergedSettings;
    });
};
  const resetSettingsCompletly = () => {
    const initialSettings = getInitialDefaultSettings();
    
    // Update localStorage
    localStorage.setItem("defaultSettings", JSON.stringify({ 
      settings: initialSettings,
      currentSavedMainTabSubTab: null 
    }));
    
    // Update context
    setDefaultSettings(initialSettings);
    setCurrentSavedMainTabSubTab(null);
  };

  return (
    <GlobalSettingsContext.Provider value={{
      resetFilters,
      resetSettingsCompletly,
      setCurrentTab,
      getCurrentTab,
      resetSettings,
      updateFilters,
      getFilters,
      defaultSettings,
      updateSettings,
      updateUserSettings,
      currentSavedMainTabSubTab,
      updateCurrentSavedTab
    }}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export const useUserSettings = () => useContext(GlobalSettingsContext);
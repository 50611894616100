// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
// import logoblack from "../../assets/fpsc-logo.png";
// import httpClient from "../../services/httpClient.ts";
// import loginGraphic from '../../assets/login-graphic.png';
// // import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import API_URLS from "./../../constants/apiUrls.ts";
// import styles from "./Login.module.css";
// import { Toast, ToastContainer } from "react-bootstrap";
// // import { useIntercom } from "react-use-intercom";
// import { jwtDecode } from "jwt-decode";
// import { useUser } from "../../context/UserContext.js"

// const Login = () => {
// const { login } = useUser()

//   const {
//     register,
//     clearErrors,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm({ mode: "all" });
//   const navigate = useNavigate();
//   // const { boot } = useIntercom()
//   const [errorMessage, setErrorMessage] = useState(null);

//   // Function to show dynamic error message
//   const showError = (message) => {
//     setErrorMessage(message);
//   };

// //   const fetchUserPermissions = (id) => {

// //     httpClient({
// //         method: "get",
// //         url: API_URLS.getPermissions.replace("{id}", id),
// //     })
// //         .then(({ result, error }) => {
// //             if (result) {
// //                 setPermissions(result?.role?.permissions);
// //             }
// //         })
// //         .catch((error) => {
// //             console.error("Error:", error);
// //         });
// // }

//   const handleLogin = (data) => {
//     let url = API_URLS.login;
//     httpClient({
//       method: "post",
//       url,
//       data,
//     }).then(({ result, error }) => {
//       if (result) {
//         localStorage.setItem("user", JSON.stringify(result?.user));
//         localStorage.setItem("token", result?.token);
//         const decodedToken = jwtDecode(result?.token);

//         login(result?.user, decodedToken)
//         const permissions = decodedToken?.role?.permissions
//         let route
//         if (permissions.includes("establishmentInsights") || permissions.includes("full")) {
//           route = "/establishment-data";
//         } else if (permissions.includes("educationalProgramInsights") || permissions.includes("full")) {
//           route = "/educational-program";
//         } else if (permissions.includes("workforceInsights") || permissions.includes("full")) {
//           route = "/employment-data";
//         } else if (decodedToken?.role?.role === 'ADMIN') {
//           route = "/settings";
//         }
//         navigate(route);
//         reset();
//         // boot({ name: result?.user?.name })
//       } else {
//         showError(error.message);
//       }
//     }).catch((error) => {
//    
//       showError(error.message);
//     }).finally(() => {
//     });
//   };
//   return (
//     <>
//       <div className={styles.login_container}>
//         <div className={styles.login_header}>
//           <div className="container-fluid">
//             <img src={logoblack} alt="" style={{ width: "154px" }} />
//           </div>
//         </div>
//         <div className={`container`}>
//           <ToastContainer
//             style={{ top: "20px", right: "20px" }}
//             className="toast-error"
//           >
//             {errorMessage && (
//               <Toast
//                 onClose={() => setErrorMessage(null)}
//                 show={true}
//                 delay={3000}
//                 autohide
//               >
//                 <Toast.Header>
//                   <strong className="me-auto">Error</strong>
//                 </Toast.Header>
//                 <Toast.Body>{errorMessage}</Toast.Body>
//               </Toast>
//             )}
//           </ToastContainer>
//           <div className="row no-gutters" style={{padding: "0 20 !important"}}>
//             <div
//               className={`align-items-center bg-white col-md-6 d-flex`}
//             >
//               <div className={styles.login_form}>
//                 <div className="brand  form-style">
//                   <h1>
//                     Effortlessly upload and oversee your documents all in one
//                     place.
//                   </h1>
//                   <p className={styles.login_form_info}>
//                     Welcome! Please enter your email and password
//                   </p>

//                   <Form className="mb-5" onSubmit={handleSubmit(handleLogin)}>
//                     <div className="custom-form-group">
//                       <label>Email *</label>
//                       <input
//                         type="text"
//                         placeholder="Enter your email address"
//                         className="c-form-control c-form-control-lg"
//                         id="InputEmail"
//                         aria-describedby="emailHelp"
//                         {...register("email", {
//                           required: {
//                             value: true,
//                             message: "Required",
//                           },
//                           pattern: {
//                             value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                             message: "Invalid email address",
//                           },
//                         })}
//                       />

//                       {errors.email && (
//                         <div className="invalid-feedback">
//                           {errors.email?.message}
//                         </div>
//                       )}
//                     </div>
//                     <div className="custom-form-group">
//                       <label>Password *</label>
//                       <input
//                         type="password"
//                         placeholder="Enter Password"
//                         className="c-form-control c-form-control-lg"
//                         id="InputPassword"
//                         {...register("password", {
//                           required: "Required",
//                           // pattern: {
//                           //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
//                           //     message: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long',
//                           // },
//                         })}
//                       />
//                       {errors.password && (
//                         <div className="invalid-feedback">
//                           {errors.password.message}
//                         </div>
//                       )}
//                     </div>
//                     <button type="submit" className="button">
//                       Login
//                     </button>
//                   </Form>
//                 </div></div>
//             </div>
//             <div className="col-md-6">
//               <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Login;


import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
import logoblack from "../../assets/fpsc-logo.png";
import httpClient from "../../services/httpClient.ts";
import loginGraphic from '../../assets/login-graphic.png';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../../constants/apiUrls.ts";
import styles from "./Login.module.css";
import { Modal, Button } from "react-bootstrap";
// import { useIntercom } from "react-use-intercom";
import { jwtDecode } from "jwt-decode";
import { useUser } from "../../context/UserContext.js"
import { Loader } from "../../components/Loader.tsx";
import { useTranslation } from "react-i18next";
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";

const Login = () => {
  const { login } = useUser()
  const { t, i18n } = useTranslation();
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, watch, formState: { errors: errorsForm2 } } = useForm();
  const { updateUserSettings } = useUserSettings();

  const navigate = useNavigate();
  // const { boot } = useIntercom()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const storedLanguage = localStorage.getItem("loginLanguage") || "en";
  const [language, setLanguage] = useState(storedLanguage);


  function navigateBasedOnPlan(plan) {
 
    if (!plan) {
        navigate('/subscription-plans');
        return;
    }

    const features = plan?.subscriptionPlanId?.features || {};

    const {
        establishmentInsights,
        educationalProgramInsights,
        workforceInsights,
    } = features;

    if (establishmentInsights?.defaultEstablishmentAccessibility === 'accessible') {
        navigate('/establishment-data');
        return;
    }

    if (educationalProgramInsights?.defaultEducationalAccessibility === 'accessible') {
        navigate('/educational-program');
        return;
    }

    if (workforceInsights?.defaultWorkforceAccessibility === 'accessible') {
        navigate('/employment-data');
        return;
    }

    navigate('/account-details');
}


  const handleLogin = async (data: any) => {
    try {
      const url = API_URLS.login;

      // API Call
      const { result, error } = await httpClient({
        method: "post",
        url,
        data,
      });

      if (!result?.status) {
        toast.error(error?.message || "Login failed.");
        return;
      }

      // Save user and token to localStorage
      const { user, token, plan, userSettings } = result;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      if (userSettings !== null && userSettings) {

        localStorage.setItem("defaultSettings", JSON.stringify(userSettings));
        updateUserSettings(userSettings); // Pass the full userSettings object
      }
      // Set preferred language
      const preferredLanguage = user?.preferredLanguage || "en";
      localStorage.setItem("selectedLanguage", preferredLanguage);

      // Decode token and login user
      login(user, plan);

      // Navigate based on user role and plan
      if (user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") {

        navigate("/establishment-data");
      } else {
    
      //  navigate(plan ? "/establishment-data" : "/subscription-plans");

        if(plan){
          navigateBasedOnPlan(plan);
        }else{
          navigate('/subscription-plans')
        }
      }

      // Reset the form
      reset();
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error.message || "An error occurred during login.");
    }
  };



  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [showSuccessModal, setShowSuccessModal]);

  const fetchTranslations = async (lang) => {
    let url = API_URLS.getTranslationFile.replace("{language}", lang)
    const response = httpClient({
      method: "get",
      url: `${url}&isLoggedIn=${false}`,
    })
      .then(({ result, error }) => {
        if (error) {
          return // Call with error if the request fails
        }
        return result
      })

    return response;
  };

  const loadTranslations = async (lang) => {
    const translations = await fetchTranslations(lang);
    if (translations) {
      i18n.addResourceBundle(lang, 'translation', translations);
      i18n.changeLanguage(lang);
      localStorage.setItem("loginLanguage", lang);
    }
  }

  useEffect(() => {
    loadTranslations(language)
  }, [language]);
  return (
    <>

      <Loader show={isLoading} />
      <div className={styles.login_container}>
        <div className={styles.login_header}>
          <div className={`${styles.headerContainer} container-fluid`}>
            <img src={logoblack} alt="" style={{ width: "154px" }} />
            <div className={styles.languageSelector}>

              <select
                className={styles.languageDropdown}
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="en">English</option>
                <option value="fr">Français</option>
              </select>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className={`container`}>
          {/* <ToastContainer
            style={{ top: "20px", right: "20px" }}
            className="toast-error"
          >
            {errorMessage && (
              <Toast
                onClose={() => setErrorMessage(null)}
                show={true}
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{errorMessage}</Toast.Body>
              </Toast>
            )}
          </ToastContainer> */}
          <div className="row no-gutters" style={{ padding: "0 20 !important" }}>
            <div
              className={`align-items-center bg-white col-md-7 d-flex`}
            >
              <div className={styles.login_form}>
            
                  <div className="brand form-style">
                    <h1>

                      {t("login.headerTitle")}
                    </h1>
                    <p className={styles.login_form_info}>

                      {t("login.subHeaderTitle")}
                    </p>

                    <Form className="mb-5" onSubmit={handleSubmit(handleLogin)}>
                      <div className="custom-form-group">
                        <label>{t("login.formLabel.email")} *</label>
                        <input
                          type="text"
                          placeholder={`${t('signup.enter')} ${t('login.formLabel.email')}`}
                          className="c-form-control c-form-control-lg"
                          id="InputEmail"
                          aria-describedby="emailHelp"
                          {...register("email", {
                            required: {
                              value: true,
                              message: `${t('login.formLabel.email')} ${t('validation.requiredMessage')}`,
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: `${t("validation.invalid")} ${t('login.formLabel.email')}`,
                            },
                          })}
                        />

                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email?.message?.toString()}
                          </div>
                        )}
                      </div>
                      <div className="custom-form-group">
                        <label>{t("login.formLabel.password")} *</label>
                        <input
                          type="password"
                          placeholder={`${t('signup.enter')} ${t('login.formLabel.password')}`}
                          className="c-form-control c-form-control-lg"
                          id="InputPassword"
                          {...register("password", {
                            required: `${t("login.formLabel.password")} ${t('validation.requiredMessage')}`,
                          })}
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password.message?.toString()}
                          </div>
                        )}
                      </div>
                      <button type="submit" className="button">
                        {t("login.button.login")}
                      </button>
                    </Form>
                    <p>
                      {t('forgotPassword.textLabel')}{" "}
                      <span
                        className={styles.toggle_link}
                        style={{ color: "#e61e28", cursor: "pointer" }}
                        onClick={() => navigate('/forgot-password')}
                      >
                        {t('forgotPassword.resetHere')}
                      </span>
                    </p>
                    <p>
                      {t("login.dontHaveAccountText")}{" "}
                      <span
                        className={styles.toggle_link}
                        style={{ color: "#e61e28", cursor: "pointer" }}
                        onClick={() => navigate('/signup')}
                      >
                        {t("login.newAccountText")}
                      </span>
                    </p>
                  </div>
                 
              </div>
            </div>
            <div className="col-md-5">
              <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title> {t('subscription.toast.success')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('signup.message.successMessage')}</p>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default Login;

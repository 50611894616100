import React, { useState, useRef, useEffect } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip, Overlay, Modal, Button } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Dashboard from "../pages/dashboard/Dashboard.tsx";
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from '../context/UserContext';
import { Loader } from "./Loader.tsx";
import { isLoggedIn } from "../pages/utils/authUtils.js";

const TabEstablishment = ({ getTabData, joyrideStatus, setJoyrideStatus }) => {
  const containerRef = useRef(null);
  const { joyrideSteps, user, userPlan ,defaultPlan} = useUser();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [restrictedTab, setRestrictedTab] = useState(null); // Track which tab is restricted
  const navigate = useNavigate();
  const location = useLocation();
  const initialTab = location.state?.tab ;

  const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState();
  const [overflowStyle, setOverflowStyle] = useState("initial");


  const tabImages = isLoggedIn() ? {
    tab1:  user?.preferredLanguage==='fr' ? "/tabImages/langFre/establishment/province.png" : "/tabImages/langEng/establishment/province.png",
    tab2: user?.preferredLanguage==='fr' ? "/tabImages/langFre/establishment/sector.png" :"/tabImages/langEng/establishment/sector.png",
    tab3: user?.preferredLanguage==='fr' ? "/tabImages/langFre/establishment/comparison.png" : "/tabImages/langEng/establishment/comparison.png"
  } : {
    tab1:  user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/establishment/province.png" : "/nonLoggedInTabImages/langEng/establishment/province.png",
    tab2: user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/establishment/sector.png" :"/nonLoggedInTabImages/langEng/establishment/sector.png",
    tab3: user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/establishment/comparison.png" : "/nonLoggedInTabImages/langEng/establishment/comparison.png"
  };

  const handleSectorData = (value) => {
    setState(value);
    setKey('tab2');
    getTabData('tabcomponent', 'tab2');
  };

  useEffect(() => {
    setKey(initialTab);
    setLoading(false)
  }, [initialTab]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key, state]);

  useEffect(() => {
    if(isSuperAdmin){
      setKey(initialTab || "tab1"); 
      return;
    }
   if(!isLoggedIn()){
    
    if ( defaultPlan?.features?.establishmentInsights?.defaultEstablishmentAccessibility === 'accessible') {

      const { establishmentInsights } = defaultPlan.features;
      const accessibleTabs = [
        establishmentInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
        establishmentInsights?.bySector?.defaultBySectorAccessibility === "accessible" && "tab2",
        establishmentInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab3",
      ].filter(Boolean);
      if(accessibleTabs?.length <= 0){
        setKey('tab1');
      }
      if (accessibleTabs?.length > 0) {
        setKey( initialTab || accessibleTabs[0]);
      }
    } else {

      // setKey(initialTab || "tab1");
      setRestrictedTab('tab1'); // Set which tab is restricted
      setShowModal(true);
    }
   }else{
 
    if ( userPlan?.subscriptionPlanId?.features?.establishmentInsights?.defaultEstablishmentAccessibility === 'accessible') {

      const { establishmentInsights } = userPlan.subscriptionPlanId.features;
      const accessibleTabs = [
        establishmentInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
        establishmentInsights?.bySector?.defaultBySectorAccessibility === "accessible" && "tab2",
        establishmentInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab3",
      ].filter(Boolean);
      if(accessibleTabs?.length <= 0){
        setKey('tab1');
      }
      if (accessibleTabs?.length > 0) {
        setKey( initialTab || accessibleTabs[0]);
      }
    } else {

      // setKey(initialTab || "tab1");
      setRestrictedTab('tab1'); // Set which tab is restricted
      setShowModal(true);
    }
   }
  }, [userPlan, initialTab ,isLoggedIn ,defaultPlan]);

  if (loading) {
    return <Loader show={loading} />;
  }

  const isTabAccessible = (subkey, featureKey) => {
  if(!isLoggedIn()){
   
    return (
     
      (defaultPlan &&
        defaultPlan?.features &&
        defaultPlan?.features?.establishmentInsights?.[subkey]?.[featureKey] === "accessible")
    );
  }else{

    return (
      user?.role === "SUPER_ADMIN" || user?.role === "ADMIN" ||
      (userPlan &&
        userPlan?.subscriptionPlanId &&
        userPlan?.subscriptionPlanId?.features &&
        userPlan?.subscriptionPlanId?.features.establishmentInsights?.[subkey]?.[featureKey] === "accessible")
    );
  }
  };

  const handleTab = (k, event) => {
    if (!isTabAccessible('comparison', 'defaultComparisonAccessibility') && k === "tab3" || 
        !isTabAccessible('provinceAndTerritory', 'defaultProvinceAndTerritoryAccessibility') && k === "tab1" || 
        !isTabAccessible('bySector', 'defaultBySectorAccessibility') && k === "tab2") {
      event.preventDefault();
     
      setRestrictedTab(k); // Set which tab is restricted
      setShowModal(true);
    } else {
      setKey(k);
      getTabData("tabcomponent", k);
    }
  };

  const handleUpgrade = () => {
    setShowModal(false);
    setRestrictedTab(null);
    !isLoggedIn() ? navigate("/login")  : navigate("/subscription-plans", { state: { upgradePlan: true } });
  };

  const handleCancel = () => {
    setShowModal(false);
    setRestrictedTab(null);
    
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
        <Tabs
          id="controlled-tab"
          activeKey={key}
          onSelect={(k, e) => handleTab(k, e)}
          className="custom_tab"
        >
          <Tab 
            eventKey="tab1"
            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('provinceAndTerritory', 'defaultProvinceAndTerritoryAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("workforceInsights.tabTitle.provinceAndTerritory")}</span>
              </div>
            }
            className="tab_container mt-2"
          />

          <Tab 
            eventKey="tab2"
            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('bySector', 'defaultBySectorAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("workforceInsights.tabTitle.sector")}</span>
              </div>
            }
            className="tab_container mt-2"
          />

          <Tab
            eventKey="tab3"
            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('comparison', 'defaultComparisonAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("commonTitleComparison")}</span>
              </div>
            }
            className="tab_container mt-2"
          />
        </Tabs>
      </div>

      <Modal
        show={showModal}
        onHide={handleCancel}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('plan.premiumUpgradeTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('plan.premiumUpgradeHeader')}
          </p>
          <p> {t('plan.premiumUpgradeBody')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleUpgrade}
            className="w-25 me-2"
          >
            {t('plan.upgrade')}
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancel}
            className="w-25"
          >
            {t('settings.tabUsers.modalConfirmation.buttonTitle.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Show preview image if tab is not accessible */}
       {restrictedTab &&  (
        <>

          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)',
            zIndex: 1000
          }}>
            <img 
              src={tabImages[restrictedTab]} 
              alt={`${restrictedTab} preview`} 
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </div>
          </>
        )}

      {/* Content Area */}
      <div ref={containerRef} className='tab_container' style={{ 
        flex: 1, 
        overflowY: "auto", 
        padding: "20px", 
        marginBottom: "10px", 
        maxHeight: "calc(100vh - 100px)",
        position: 'relative'
      }}>
        {/* Show tab content if accessible */}
        {key === "tab1"  && (
          <Dashboard handleSectorData={handleSectorData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabProvinceTerritory} />
        )}
        {key === "tab2" && (
          <Dashboard2 state={state} setState={setState} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabSector} />
        )}
        {key === "tab3" &&  (
          <Comparison field={'establishmentCount'} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} tabSettings={getTabData} />
        )}

       
      </div>
    </div>
  );
};

export default TabEstablishment;
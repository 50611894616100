// import React, { useState, useEffect } from "react";
// import { Form, Button, Table, Modal, Card } from "react-bootstrap";
// import httpClient from "../../services/httpClient.ts";
// import API_URLS from "../../constants/apiUrls.ts";
// import { toast, ToastContainer } from "react-toastify";
// import { FaEdit } from "react-icons/fa";
// import { RiDeleteBinLine } from "react-icons/ri";
// import { useTranslation } from "react-i18next";
// import { features, keyTextMapping } from "../../constants/commonData.js";
// import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
// import { capitalizeFirstLetter } from "../../services/commonFn.js";
// import { Loader } from "../../components/Loader.tsx";
// import { FaRegPaperPlane } from "react-icons/fa";

// const SubscriptionManagement = () => {
//   const { t } = useTranslation()
//   const navigate = useNavigate()
//   const {
//     register,
//     clearErrors,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm({ mode: "all" });

//   const {
//     register: registerForm2,
//     clearErrors: clearForm2Errors,
//     handleSubmit: handleSubmitForm2,
//     reset: resetForm2,
//     formState: { errors: errorsForm2 },
//     setValue
//   } = useForm({ mode: "all" });

//   const [showModal, setShowModal] = useState(false);
//   const [showUpdateModal, setShowUpdateModal] = useState(false)
//   const [showConfirmationModal, setShowConfirmationModal] = useState(false)
//   const [planFeatures, setPlanFeatures] = useState({})
//   const [subscriptionName, setSubscriptionName] = useState("");
//   const [subscriptionPlans, setSubscriptionPlans] = useState([])
//   const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState()
//   const [availableFeatures, setAvailableFeatures] = useState([
//     { id: 1, name: "Feature 1", description: "Description for Feature 1" },
//     { id: 2, name: "Feature 2", description: "Description for Feature 2" },
//     { id: 3, name: "Feature 3", description: "Description for Feature 3" },
//   ]);
//   const [selectedFeatures, setSelectedFeatures] = useState([]);

//   const [subscribedCount, setSubscribedCount] = useState<number>(0);

//   const handleModalClose = () => setShowModal(false);
//   const handleUpdateModalClose = () => setShowUpdateModal(false);
//   const handleModalShow = () => setShowModal(true);
//   const handleUpdateModalShow = () => setShowUpdateModal(true);
//   const getTextForKey = (key) => keyTextMapping[key] || key;

//   const updateFeature = (category, field, newValue) => {
//     setPlanFeatures((prevFeatures) => ({
//       ...prevFeatures,
//       [category]: {
//         ...prevFeatures[category],
//         [field]: newValue,
//       },
//     }));
//   };

//   const handleDeleteButtonClick = async (plan :any) => {
//     setSelectedSubscriptionPlan(plan);

//     // Fetch the subscription count
//     try {
//       const {result ,error} = await httpClient({
//         method: "get", // Assuming you use GET request to fetch subscription data
//         url: `${API_URLS.getActiveSubscribersCount}?subscriptionPlanId=${plan._id}`,
//       });

//       if (result.status) {
//         setSubscribedCount(result.count); // Set the count
//       } else {
//         toast.error(error.message);
//       }
//     } catch (error) {
//       console.error("Error fetching subscription data:", error);
//     }

//     // Open the modal
//     setShowConfirmationModal(true);
//   };

//   const handleCheckboxChange = (category, field, checked) => {

//     const newValue =
//       field === "businessSize" || field === "dataYears" || field === "demographicSectorDropdown" || (category === "workforceInsights" && field === "sectors")
//         ? checked
//           ? "full"
//           : "limited"
//         : checked
//           ? "accessible"
//           : "inaccessible";

//     updateFeature(category, field, newValue);
//   };

//   const handleUpdateFormData = (plan) => {
//     setSelectedSubscriptionPlan(plan)
//     setValue("planName", plan.plan)
//     setValue("recurrence", plan.recurrence)
//     setValue("price", plan.price)
//     setValue("isMostPopular", plan.isMostPopular)
//   }

//   const handleUpdateSubscription = (data) => {
//     const updatedData = { ...data, planId: selectedSubscriptionPlan?._id }
//     httpClient({
//       method: "post",
//       url: API_URLS.updateSubscriptionPlan,
//       data: updatedData
//     })
//       .then(({ result, error }) => {

//         if (result.status) {
//           toast.success("Subscription plan updated successfully");
//           reset()
//           getSubscriptionPlans()
//         } else {
//           toast.error(result.message)
//           setAvailableFeatures([])
//         }
//         if (error) {
//           toast.error(error.message);
//           return
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       })
//     setShowUpdateModal(false)
//   }


//   const handleSaveSubscription = async (data: any) => {
//     try {
//       // Merge data with plan features
//       const updatedData = { ...data, features: planFeatures };

//       // Make the API call
//       const response = await httpClient({
//         method: "post",
//         url: API_URLS.addSubscriptionPlan,
//         data: updatedData,
//       });

//       const { result, error } = response;

//       // Handle the result
//       if (result?.status) {
//         toast.success("Subscription plan added successfully");
//         reset();
//         getSubscriptionPlans(); // Fetch updated plans
//       } else if (error) {
//         toast.error(error?.message || "An error occurred while adding the subscription plan.");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(error?.message || "Something went wrong, please try again.");
//     } finally {
//       setShowModal(false); // Close modal
//       reset();
//     }
//   };

//   const handleDeletePlan = async (plan: any) => {
//     try {
//       const data = { planId: plan._id };

//       const { result, error } = await httpClient({
//         method: "post",
//         url: API_URLS.deleteSubscriptionPlan,
//         data,
//       });

//       if (result?.status) {
//         toast.success(result.message);
//         getSubscriptionPlans();
//         setShowConfirmationModal(false);
//       } else if (error) {
//         toast.error(error.message || "An error occurred");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("Failed to delete the plan. Please try again.");
//     }
//   };


//   const getSubscriptionPlans = () => {
//     httpClient({
//       method: "get",
//       url: API_URLS.getSubscriptionPlans,
//     })
//       .then(({ result, error }) => {

//         if (result.status) {
//           setSubscriptionPlans(result.subscriptions)

//         } else {
//           setSubscriptionPlans([])

//         }
//         if (error) {
//           toast.error(error);
//           return
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       })
//   }

//   useEffect(() => {
//     getSubscriptionPlans()
//     setPlanFeatures(features)
//   }, [])

//   return (
//     <>
//       <ToastContainer />

//       <div className="container mt-4">
//         <h2>Subscription Management</h2>
//         <Button
//           variant="primary"
//           onClick={handleModalShow}
//           className="mb-3"
//           style={{ float: "right" }}
//         >
//           Create Subscription Plan
//         </Button>
//         <Button
//           variant="primary"
//           onClick={() => navigate('/subscription-plans')}
//           className="mb-3 me-3"
//           style={{ float: "right" }}
//         >
//           Check Subscription Page
//         </Button>

//         <Table hover>
//           <thead>
//             <tr>
//               <th>Serial No</th>
//               <th>Plan Name</th>
//               <th>Recurrence</th>
//               <th>Price</th>
//               <th>Currency</th>
//               <th>Actions</th>
//               {/* <th>Accessibility Option</th> */}
//             </tr>
//           </thead>
//           <tbody>
//             {subscriptionPlans &&subscriptionPlans?.length >0 ? (subscriptionPlans?.map((plan :any, index :number) => (
//               <tr key={plan._id}>
//                 <td>{index + 1}</td>
//                 <td>{plan.plan}</td>
//                 <td>{plan.recurrence}</td>
//                 <td>{plan.price}</td>
//                 <td>{plan.currency}</td>
//                 <td>
//                   <FaEdit style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }} title='Edit' onClick={() => { handleUpdateFormData(plan); setShowUpdateModal(true) }} />
//                    {/* <RiDeleteBinLine style={{ fontSize: "20px", cursor: "pointer" }} title='Delete' onClick={() => {handleDeleteButtonClick(plan);setShowConfirmationModal(true)}} />  */}
//                 </td>

//                 {/* <td> <span dangerouslySetInnerHTML={{ __html: feature.accessibilityOption }} /></td> */}
//               </tr>
//             ))) :<tr>
//             <td colSpan={6} style={{ textAlign: "center", fontWeight: "-moz-initial" ,fontSize: "15px"}}>
//               No plan found
//             </td>
//           </tr>}
//           </tbody>
//         </Table>

//         {/* Modal */}
//         <Modal show={showModal} onHide={handleModalClose} centered>
//           <Modal.Header closeButton>
//             <Modal.Title>Create Subscription Plan</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleSubmit(handleSaveSubscription)}>
//               <Form.Group className="mb-3">
//                 <Form.Label>Subscription Plan Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter subscription plan name"
//                   {...register("planName", {
//                     required: "Subscription plan name is required",
//                     validate: (value) => {
//                       const allowedPlans = ["Public", "Essential", "Premium"];
//                       return allowedPlans.includes(value) || "Plan must be one of: Public, Essential, or Premium";
//                     },
//                   })}
//                   className={errors.planName ? "is-invalid" : ""}
//                 />
//                 {errors.planName && (
//                   <div className="invalid-feedback">{errors.planName.message?.toString()}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Recurrence</Form.Label>
//                 <select
//                   {...register("recurrence", { required: "Recurrence is required" })}
//                   className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
//                     }`}
//                   style={{ width: "100%" }}
//                 >
//                   <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
//                   <option value="monthly">Monthly</option>
//                   <option value="yearly">Yearly</option>
//                 </select>
//                 {errors.recurrence && (
//                   <div className="invalid-feedback">{errors.recurrence.message?.toString()}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Price</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter price"
//                   {...register("price", { required: "Price is required" })}
//                   className={errors.price ? "is-invalid" : ""}
//                 />
//                 {errors.price && (
//                   <div className="invalid-feedback">{errors.price.message?.toString()}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Most Popular</Form.Label>
//                 <select
//                   {...register("isMostPopular", { required: "Recurrence is required" })}
//                   className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
//                     }`}
//                   style={{ width: "100%" }}
//                 >
//                   <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
//                   <option value="true">Yes</option>
//                   <option value="false">No</option>
//                 </select>
//                 {errors.isMostPopular && (
//                   <div className="invalid-feedback">{errors.isMostPopular.message?.toString()}</div>
//                 )}
//               </Form.Group>

//               <div className="d-flex justify-content-end align-items-center">
//                 <Button type="submit" variant="primary" className="me-2">
//                   Save Plan
//                 </Button>
//                 <Button variant="secondary" onClick={handleModalClose}>
//                   Close
//                 </Button>
//               </div>

//             </Form>
//           </Modal.Body>

//         </Modal>


//         <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered>
//           <Modal.Header closeButton>
//             <Modal.Title>Update Subscription Plan</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleSubmitForm2(handleUpdateSubscription)}>
//               <Form.Group className="mb-3">
//                 <Form.Label>Subscription Plan Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter subscription plan name"
//                   {...registerForm2("planName", { required: "Subscription plan name is required" })}
//                   className={errorsForm2.planName ? "is-invalid" : ""}
//                 />
//                 {errorsForm2.planName && (
//                   <div className="invalid-feedback">{errorsForm2.planName.message}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Recurrence</Form.Label>
//                 <select
//                   {...registerForm2("recurrence", { required: "Recurrence is required" })}
//                   className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.recurrence ? "is-invalid" : ""
//                     }`}
//                   style={{ width: "100%" }}
//                 >
//                   <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
//                   <option value="monthly">Monthly</option>
//                   <option value="yearly">Yearly</option>
//                 </select>
//                 {errorsForm2.recurrence && (
//                   <div className="invalid-feedback">{errorsForm2.recurrence.message}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Price</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter price"
//                   {...registerForm2("price", { required: "Price is required" })}
//                   className={errorsForm2.price ? "is-invalid" : ""}
//                 />
//                 {errorsForm2.price && (
//                   <div className="invalid-feedback">{errorsForm2.price.message}</div>
//                 )}
//               </Form.Group>
//               <Form.Group className="mb-3">
//                 <Form.Label>Most Popular</Form.Label>
//                 <select
//                   {...registerForm2("isMostPopular")}
//                   className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.isMostPopular ? "is-invalid" : ""
//                     }`}
//                   style={{ width: "100%" }}
//                 >
//                   <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
//                   <option value="true">True</option>
//                   <option value="false">False</option>
//                 </select>
//                 {errorsForm2.isMostPopular && (
//                   <div className="invalid-feedback">{errorsForm2.isMostPopular.message}</div>
//                 )}
//               </Form.Group>

//               <div className="d-flex justify-content-end align-items-center">
//                 <Button type="submit" variant="primary" className="me-2">
//                   Save Plan
//                 </Button>
//                 <Button variant="secondary" onClick={handleUpdateModalClose}>
//                   Close
//                 </Button>
//               </div>

//             </Form>
//           </Modal.Body>
//         </Modal>
//         <Modal
//           show={showConfirmationModal}
//           onHide={() => setShowConfirmationModal(false)}
//           backdrop='static'
//           centered>
//           <Modal.Header closeButton>
//             <Modal.Title>Confirmation</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             Are you sure you want to cancel the subscription?
//             <div className="note-section" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
//      { subscribedCount  > 0 &&  <><FaRegPaperPlane style={{ fontSize: "20px", color: "orange", marginRight: "10px" }} />
//       <p style={{ fontSize: "14px", color: "#6c757d", marginBottom: "0" }}>
//         <strong>Note:</strong> This plan currently has <strong>{subscribedCount}</strong> user(s) subscribed. If you delete this plan, users will be unsubscribed from the plan .
//       </p>
//       </>}
//     </div>
//           </Modal.Body>
//           <Modal.Footer >

//             <Button type="submit" className="w-25 btn btn-primary me-2" onClick={() => handleDeletePlan(selectedSubscriptionPlan)}>
//               Yes
//             </Button>
//             <Button type="submit" className="w-25 btn btn-primary" onClick={() => setShowConfirmationModal(false)}>
//               Cancel
//             </Button>

//           </Modal.Footer>
//         </Modal>
//       </div>
//     </>
//   );
// };

// export default SubscriptionManagement;

import React, { useState, useEffect } from "react";
import { Form, Button, Table, Modal, Card } from "react-bootstrap";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "../../constants/apiUrls.ts";
import { toast, ToastContainer } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { features, keyTextMapping } from "../../constants/commonData.js";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../services/commonFn.js";
import { Loader } from "../../components/Loader.tsx";
import { FaRegPaperPlane } from "react-icons/fa";

const SubscriptionManagement = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const {
    register: registerForm2,
    clearErrors: clearForm2Errors,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    formState: { errors: errorsForm2 },
    setValue
  } = useForm({ mode: "all" });

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [planFeatures, setPlanFeatures] = useState({})
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<any>()
  const [availableFeatures, setAvailableFeatures] = useState([
    { id: 1, name: "Feature 1", description: "Description for Feature 1" },
    { id: 2, name: "Feature 2", description: "Description for Feature 2" },
    { id: 3, name: "Feature 3", description: "Description for Feature 3" },
  ]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [subscribedCount, setSubscribedCount] = useState<number>(0);

  const handleModalClose = () => { setShowModal(false); reset() };
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleModalShow = () => setShowModal(true);
  const handleUpdateModalShow = () => setShowUpdateModal(true);
  const getTextForKey = (key) => keyTextMapping[key] || key;

  const updateFeature = (category, field, newValue) => {
    setPlanFeatures((prevFeatures) => ({
      ...prevFeatures,
      [category]: {
        ...prevFeatures[category],
        [field]: newValue,
      },
    }));
  };

  const handleDeleteButtonClick = async (plan: any) => {
    setSelectedSubscriptionPlan(plan);
    setSubscribedCount(plan?.totalActiveUsers || 0)

    // Open the modal
    setShowConfirmationModal(true);
  };

  const handleCheckboxChange = (category, field, checked) => {

    const newValue =
      field === "businessSize" || field === "dataYears" || field === "demographicSectorDropdown" || (category === "workforceInsights" && field === "sectors")
        ? checked
          ? "full"
          : "limited"
        : checked
          ? "accessible"
          : "inaccessible";

    updateFeature(category, field, newValue);
  };

  const handleUpdateFormData = (plan) => {
    setSelectedSubscriptionPlan(plan)
    setValue("planName", plan.plan)
    setValue("recurrence", plan.recurrence)
    setValue("price", plan.price)
    setValue("isMostPopular", plan.isMostPopular)
  }

  const isDefaultPublicPlan = (planName) => {
    if (!planName) return false;

    // Normalize input: Trim spaces, convert to lowercase, and remove extra spaces
    const normalizedPlanName = planName.trim().toLowerCase().replace(/\s+/g, ' ');

    // List of possible variations of "Default Public Plan"
    const validDefaultPlans = [
        "default public plan",
        "default  public  plan", // Extra spaces
        " default public plan ", // Leading/trailing spaces
        "default-public-plan",  // Hyphenated form
        "defaultpublicplan",    // No spaces
        "defaultpublic plan",   // Partial spacing
        "default publicplan"    // Another spacing variation
    ];

    // Check if the normalized name matches any known variations
    return validDefaultPlans.includes(normalizedPlanName);
};

  const handleUpdateSubscription = async (data) => {



    try {

      if(isDefaultPublicPlan(data?.planName)){
        toast.error('plan with the name exists');
        return;
    }
      const updatedData = { ...data, planId: selectedSubscriptionPlan?._id }



      // Make the API call
      const response = await httpClient({
        method: "post",
        url: API_URLS.updateSubscriptionPlan,
        data: updatedData
      });

      const { result, error } = response;

      // Handle the result
      if (result?.status) {
        toast.success("Subscription plan updated successfully");
        reset();
        getSubscriptionPlans(); // Fetch updated plans
      } else if (error) {
        toast.error(error?.message || "An error occurred while adding the subscription plan.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.message || "Something went wrong, please try again.");
    } finally {
      setShowUpdateModal(false)
      reset();
    }


  }

  const handleSaveSubscription = async (data: any) => {

 
    try {
      if(isDefaultPublicPlan(data?.planName)){
          toast.error('plan with the name exists');
          return;
      }
      // Merge data with plan features
      const updatedData = { ...data, features: planFeatures };

      // Make the API call
      const response = await httpClient({
        method: "post",
        url: API_URLS.addSubscriptionPlan,
        data: updatedData,
      });

      const { result, error } = response;

      // Handle the result
      if (result?.status) {
        toast.success("Subscription plan added successfully");
        reset();
        getSubscriptionPlans(); // Fetch updated plans
      } else if (error) {
        toast.error(error?.message || "An error occurred while adding the subscription plan.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error?.message || "Something went wrong, please try again.");
    } finally {
      setShowModal(false); // Close modal
      reset();
    }
  };

  const handleDeletePlan = async (plan: any) => {
    try {
      const data = { planId: plan._id };

      const { result, error } = await httpClient({
        method: "post",
        url: API_URLS.deleteSubscriptionPlan,
        data,
      });

      if (result?.status) {
        toast.success(result.message);
        getSubscriptionPlans();
        setShowConfirmationModal(false);
        setSubscribedCount(0)

      } else if (error) {
        toast.error(error.message || "An error occurred");
        setSubscribedCount(0)

      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to delete the plan. Please try again.");
    }
  };


  const getSubscriptionPlans = () => {
    httpClient({
      method: "get",
      url: API_URLS.getSubscriptionPlans,
    })
      .then(({ result, error }) => {

        if (result.status) {
          setSubscriptionPlans(result.subscriptions)

        } else {
          setSubscriptionPlans([])

        }
        if (error) {
          toast.error(error);
          return
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  }

  const handleStatusToggle = async (plan) => {
    try {
      const updatedStatus = plan.status === 'active' ? 'inactive' : 'active';

      try {
        const data = { planId: plan._id, status: updatedStatus };

        const { result, error } = await httpClient({
          method: "post",
          url: API_URLS.deleteSubscriptionPlan,
          data,
        });

        if (result?.status) {
          toast.success(result.message);
          getSubscriptionPlans();
          setShowConfirmationModal(false);
          setSubscribedCount(0)

        } else if (error) {
          toast.error(error.message || "An error occurred");
          setSubscribedCount(0)

        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to delete the plan. Please try again.");
      }

    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    getSubscriptionPlans()
    setPlanFeatures(features)
  }, [])

  return (
    <>
      <ToastContainer />

      <div className="container mt-4">
        <h2>{t('settings.tabSubscription.subscriptionManagement')}</h2>
        <button
          type="button"
          onClick={handleModalShow}
          className="mb-3 button"
          style={{ float: "right" }}
        >
          {t('settings.tabSubscription.createSubscriptionPlan')}
        </button>
        <button
          type="button"
          onClick={() => navigate('/subscription-plans')}
          className="mb-3 me-3 button"
          style={{ float: "right" }}
        >
          {t('settings.tabSubscription.checkSubscriptionPage')}
        </button>

        <Table hover>
          <thead>
            <tr>
              <th>{t('settings.tabSubscription.tableHeaderName.serialNo')}</th>
              <th>{t('settings.tabSubscription.tableHeaderName.planName')}</th>
              <th>{t('settings.tabSubscription.tableHeaderName.price')}</th>
              <th>{t('settings.tabSubscription.tableHeaderName.currency')}</th>
              <th>{t('settings.tabSubscription.tableHeaderName.actions')}</th>
              <th>{t('settings.tabSubscription.tableHeaderName.status')}</th>

            </tr>
          </thead>
          <tbody>
            {subscriptionPlans && subscriptionPlans?.length > 0 ? (subscriptionPlans?.map((plan: any, index: number) => (
              <tr key={plan._id}>
                <td>{index + 1}</td>
                <td>{capitalizeFirstLetter(plan?.plan)}</td>
                <td>{plan?.price}</td>
                <td>{plan?.currency?.toUpperCase()}</td>
                {/* <td>
                  <FaEdit  style={{ fontSize: "20px", cursor: plan.plan === "Default Public Plan" ? "not-allowed" : "pointer", marginRight: "10px" }} title='Edit' disabled onClick={() => { handleUpdateFormData(plan); setShowUpdateModal(true) }} />
                  <RiDeleteBinLine style={{ fontSize: "20px", cursor: plan.plan === "Default Public Plan" ? "not-allowed" : "pointer" }} title='Delete' onClick={() => { handleDeleteButtonClick(plan); setShowConfirmationModal(true) }} />


  
                </td>

                <td>
                  <div
                    onClick={() => handleStatusToggle(plan)}
                    className={`subscription-toggle ${plan.status === 'active' ? 'active' : 'inactive'}`}
                  >
                    <div className="toggle-slider">
                     
                    </div>
                  </div>
                </td> */}
                <td>
  {plan.plan === "Default Public Plan" ? (
    <>
      <div className="tooltip-managment-container">
        <FaEdit 
          style={{ 
            fontSize: "20px", 
            cursor: "not-allowed",
            marginRight: "10px",
            opacity: 0.5 
          }} 
        />
        <span className="tooltip-managment-text">Cannot edit Default Public Plan</span>
      </div>
      <div className="tooltip-managment-container">
        <RiDeleteBinLine 
          style={{ 
            fontSize: "20px", 
            cursor: "not-allowed",
            opacity: 0.5 
          }} 
        />
        <span className="tooltip-managment-text">Cannot delete Default Public Plan</span>
      </div>
    </>
  ) : (
    <>
      <FaEdit 
        style={{ 
          fontSize: "20px", 
          cursor: "pointer", 
          marginRight: "10px" 
        }} 
        title='Edit' 
        onClick={() => { 
          handleUpdateFormData(plan); 
          setShowUpdateModal(true) 
        }} 
      />
      <RiDeleteBinLine 
        style={{ 
          fontSize: "20px", 
          cursor: "pointer" 
        }} 
        title='Delete' 
        onClick={() => { 
          handleDeleteButtonClick(plan); 
          setShowConfirmationModal(true) 
        }} 
      />
    </>
  )}
</td>

<td>
  {plan.plan === "Default Public Plan" ? (
    <div className="tooltip-managment-container">
      <div
        className={`subscription-toggle active`}
        style={{ opacity: 0.9, cursor: "not-allowed" }}
      >
        <div className="toggle-slider"></div>
        <span className="tooltip-managment-text">Cannot modify Default Public Plan status</span>
      </div>
    </div>
  ) : (
    <div
      onClick={() => handleStatusToggle(plan)}
      className={`subscription-toggle ${plan.status === 'active' ? 'active' : 'inactive'}`}
    >
      <div className="toggle-slider"></div>
    </div>
  )}
</td>
              </tr>
            ))) : <tr>
              <td colSpan={6} style={{ textAlign: "center", fontWeight: "-moz-initial", fontSize: "15px" }}>
                {t('settings.tabSubscription.notFound')}
              </td>
            </tr>}
          </tbody>
        </Table>

        {/* Modal */}
        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>  {t('settings.tabSubscription.createSubscriptionPlan')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(handleSaveSubscription)}>
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.planName')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('settings.tabSubscription.modalAddSubscription.planNamePlaceHolder')}
                  {...register("planName", {
                    required: "Subscription plan name is required",

                  })}
                  className={errors.planName ? "is-invalid" : ""}
                />
                {errors.planName && (
                  <div className="invalid-feedback">{errors.planName.message?.toString()}</div>
                )}
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>Recurrence</Form.Label>
                <select
                  {...register("recurrence", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {errors.recurrence && (
                  <div className="invalid-feedback">{errors.recurrence.message?.toString()}</div>
                )}
              </Form.Group> */}
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.price')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('settings.tabSubscription.modalAddSubscription.pricePlaceHolder')}
                  {...register("price", { required: "Price is required" })}
                  className={errors.price ? "is-invalid" : ""}
                />
                {errors.price && (
                  <div className="invalid-feedback">{errors.price.message?.toString()}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.mostPopular')}</Form.Label>
                <select
                  {...register("isMostPopular", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="true">{t("settings.tabSubscription.modalAddSubscription.options.yes")}</option>
                  <option value="false">{t("settings.tabSubscription.modalAddSubscription.options.no")}</option>
                </select>
                {errors.isMostPopular && (
                  <div className="invalid-feedback">{errors.isMostPopular.message?.toString()}</div>
                )}
              </Form.Group>

              <div className="d-flex justify-content-end align-items-center">
                <button type="submit" className="me-2 button">
                  {t("settings.tabSubscription.buttonTitle.save")}
                </button>
                <button type="button" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={handleModalClose}>
                  {t("settings.tabSubscription.buttonTitle.close")}
                </button>
              </div>

            </Form>
          </Modal.Body>

        </Modal>


        <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t('settings.tabSubscription.updateSubscriptionPlan')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmitForm2(handleUpdateSubscription)}>
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.planName')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('settings.tabSubscription.modalAddSubscription.planNamePlaceHolder')}
                  {...registerForm2("planName", { required: "Subscription plan name is required" })}
                  className={errorsForm2.planName ? "is-invalid" : ""}
                />
                {errorsForm2.planName && (
                  <div className="invalid-feedback">{errorsForm2.planName.message?.toString()}</div>
                )}
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>Recurrence</Form.Label>
                <select
                  {...registerForm2("recurrence", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {errorsForm2.recurrence && (
                  <div className="invalid-feedback">{errorsForm2.recurrence.message?.toString()}</div>
                )}
              </Form.Group> */}
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.price')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('settings.tabSubscription.modalAddSubscription.pricePlaceHolder')}
                  {...registerForm2("price", { required: "Price is required" })}
                  className={errorsForm2.price ? "is-invalid" : ""}
                />
                {errorsForm2.price && (
                  <div className="invalid-feedback">{errorsForm2.price.message?.toString()}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t('settings.tabSubscription.modalAddSubscription.mostPopular')}</Form.Label>
                <select
                  {...registerForm2("isMostPopular")}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.isMostPopular ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="true">{t("settings.tabSubscription.modalAddSubscription.options.yes")}</option>
                  <option value="false">{t("settings.tabSubscription.modalAddSubscription.options.no")}</option>
                </select>
                {errorsForm2.isMostPopular && (
                  <div className="invalid-feedback">{errorsForm2.isMostPopular.message?.toString()}</div>
                )}
              </Form.Group>

              <div className="d-flex justify-content-end align-items-center">
                <button type="submit" className="me-2 button">
                  {t("settings.tabSubscription.buttonTitle.update")}
                </button>
                <button type="button" className="button button-secondary" onClick={handleUpdateModalClose}>
                  {t("settings.tabSubscription.buttonTitle.close")}
                </button>
              </div>

            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          backdrop='static'
          centered>
          <Modal.Header closeButton>
            <Modal.Title> {t("settings.tabSubscription.modalDeleteSubscription.confirmation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {t("settings.tabSubscription.modalDeleteSubscription.cancelSubscription")}
            <div className="note-section" style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
              {subscribedCount > 0 && <><FaRegPaperPlane style={{ fontSize: "20px", color: "orange", marginRight: "10px" }} />
                <p style={{ fontSize: "14px", color: "#6c757d", marginBottom: "0" }}>
                  <strong>Note:</strong> {t("settings.tabSubscription.modalDeleteSubscription.confirmationNote1")} <strong>{subscribedCount}</strong> {t("settings.tabSubscription.modalDeleteSubscription.confirmationNote2")}
                </p>
              </>}
            </div>
          </Modal.Body>
          <Modal.Footer >

            <button type="button" className="w-25 btn btn-primary me-2" onClick={() => handleDeletePlan(selectedSubscriptionPlan)}>
              {t("settings.tabSubscription.modalAddSubscription.options.yes")}
            </button>
            <button type="button" className="w-25 button button-secondary" onClick={() => setShowConfirmationModal(false)}>
              {t("settings.tabSubscription.buttonTitle.cancel")}
            </button>

          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SubscriptionManagement;


import React, { useState, useEffect, useCallback, useRef } from "react";

import { jwtDecode } from "jwt-decode";
import {
  Button,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  FaQuestionCircle,
  FaSearch,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import httpClient from "../services/httpClient.ts";
import API_URLS from "../constants/apiUrls.ts";
import { toast } from "react-toastify";
import { useUser } from "../context/UserContext.js";
import { useUserSettings } from "../context/GlobalSettingsProvider.js";
import { FaPenToSquare, FaTrash } from "react-icons/fa6";
import { capitalizeFirstLetter } from "../services/commonFn.js";
import { keyTextMapping } from "../constants/commonData.js";
import { isLoggedIn } from "../pages/utils/authUtils.js";

interface CommonHeaderProps {
  title: string;
  subtitle?: string;
  buttonTitle?: string | "";
  ButtonIcon?: React.ReactNode;
  buttonEventTriggered?: () => void;
  helpText?: string | "";
  component?: string | "";
  onStart: () => void;
  isHeader?: boolean;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  title,
  subtitle,
  buttonTitle = "",
  ButtonIcon,
  buttonEventTriggered,
  helpText,
  component,
  onStart,
  isHeader = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const excludedComponents = [
    "documents",
    "addUser",
    "userRolePermission",
    "settings",
  ];
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [settingsName, setSettingsName] = useState("");

  const [userSettings, setUserSettings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSettingId, setSelectedSettingId] = useState<string | null>(
    null
  );
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [saveAction, setSaveAction] = useState(null); // 'override' or 'new'
  const [name, setName] = useState("");
  const { user, userPlan } = useUser();

  const {
    defaultSettings,
    resetSettings,
    resetSettingsCompletly,
    updateUserSettings,
    getCurrentTab,
    currentMainTab,
    currentSavedMainTabSubTab,
    updateCurrentSavedTab,
  } = useUserSettings();
  const { mainTab, subTab } = getCurrentTab();

  const modalSearchInputRef = useRef<HTMLInputElement>(null);

  const openSearchModal = () => {
    setShowSearch(true);
    setSearchResults([]);

    // Give a slight delay to ensure modal is fully opened before focusing
    setTimeout(() => {
      modalSearchInputRef.current?.focus();
    }, 100);
  };

  // Function to fetch search results
  const fetchSearchResults = useCallback(async (query: string) => {
    if (!query.trim()) return; // Do not call API for empty search term

    setLoading(true);
    setError(null);

    try {
      const { result, error } = await httpClient({
        method: "get",
        url: `${API_URLS.search}?userId=${"a146216968"}&term=${query}`,
      });
      const results: any = [];

      if (error) {
        toast.error(error.message || "error occured");
      }

      // Function to process insights
      const processInsights = (insights: any, mainTab: string) => {
        if (insights) {
          for (const subTab in insights) {
            if (Array.isArray(insights[subTab])) {
              insights[subTab].forEach((item: any) => {
                results.push({
                  mainTab,
                  subTab,
                  name: item.name,
                });
              });
            }
          }
        }
      };

      // Process EstablishmentInsights
      result?.EstablishmentInsights &&
        processInsights(result.EstablishmentInsights, "EstablishmentInsights");

      // Process EducationalProgramInsights
      result.EducationalProgramInsights &&
        processInsights(
          result.EducationalProgramInsights,
          "EducationalProgramInsights"
        );

      // Process WorkForceInsights (if present)
      if (result.WorkForceInsights) {
        processInsights(result.WorkForceInsights, "WorkForceInsights");
      }

      setSearchResults(results);
    } catch (err) {
      setError("Failed to fetch search results.");
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSave = async () => {
    const mainTabData = defaultSettings.find((tab) => tab.mainTab === mainTab);
    if (!mainTabData) {
      return;
    }
    const matchingSubTab = mainTabData.subTabs.find(
      (sub) => sub.subTab === subTab
    );
    console.log(mainTab, subTab, matchingSubTab)

    if (!matchingSubTab || mainTab === 'educationalPrograms' && subTab === 'apprenticeship') {
      setShowSaveConfirmation(false);
      return;
    }


    if (matchingSubTab === 'apprenticeship') {
      return;
    }

    const userId = user?._id;
    const settings = [
      {
        mainTab: mainTab,
        subTabs: [matchingSubTab], // Only include the matching sub tab
      },
    ];

    const defaultSetting = JSON.parse(localStorage.getItem("defaultSettings"));
    const name = defaultSetting?.name || "Custom Dashboard";

    try {
      const { result, error } = await httpClient({
        method: "post",
        url: API_URLS.saveUserSettings,
        data: {
          userId,
          settings,
          name,
        },
      });

      if (result.status && result?.defaultSettings) {
        toast.success("Settings saved successfuly !");
        getUserSettings();
        updateCurrentSavedTab(mainTab, subTab);
        resetSettings();
        updateUserSettings(result.defaultSettings);
      }
    } catch (error) {
      console.error("Error saving default settings:", error);
      toast.error("Failed to save settings");
    } finally {
      setShowSaveConfirmation(false);
    }
  };

  const handleSaveCheck = () => {
    if (currentSavedMainTabSubTab) {
      setShowSaveConfirmation(true);
      return;
    }
    handleSave();
  };

  const handleSaveAs = async () => {
    setShowSaveAsModal(true);
  };

  const getTextForKey = ((key: string) => {
    return keyTextMapping[key] || key;
  });

  const handleSaveAsSubmit = async () => {
    if (!settingsName.trim()) {
      setError("Settings name is required.");
      return;
    }

    const mainTabData = defaultSettings.find((tab) => tab.mainTab === mainTab);
    if (!mainTabData) {
      toast.error("Main tab not found in settings");
      return;
    }
    const matchingSubTab = mainTabData.subTabs.find(
      (sub) => sub.subTab === subTab
    );

    if (mainTab === 'educationalPrograms' && subTab === 'apprenticeship') {
      setShowSaveAsModal(false);
      return;
    }
    if (!matchingSubTab) {
      toast.error("Sub tab not found in current main tab");
      return;
    }

    setError("");
    const userId = user?._id;
    const settings = [
      {
        mainTab: mainTab,
        subTabs: [matchingSubTab], // Only include the matching sub tab
      },
    ];

    try {
      const { result, error } = await httpClient({
        method: "post",
        url: API_URLS.saveAsUserSettings,
        data: {
          userId,
          settings,
          name: settingsName,
        },
      });

      if (error) {
        toast.error(error?.message || "something went wrong !");
      }

      if (result.status && result?.newSettings) {
        setShowSaveAsModal(false);
        setSettingsName("");
        getUserSettings();
        toast.success("Settings saved successfuly !");
        updateCurrentSavedTab(mainTab, subTab);
        resetSettings();
        updateUserSettings(result.newSettings);
      }
    } catch (error) {
      console.error("Error saving default settings:", error);
    }
  };

  // const closeModal = () => {
  //     setShowSearch(false);
  //     setSearchQuery("");
  //     setSearchResults([]);
  //     setLoading(false)
  // };

  const getUserSettings = async () => {
    try {
      setLoading(true);
      const { result, error } = await httpClient({
        method: "get",
        url: `${API_URLS.fetchUserSettings}?userId=${user?._id}`,
      });

      if (error) {
        toast.error(error?.message || "something went wrong !");
      }

      if (result.status) {
        setUserSettings(result.userSettings);
      } else {
        toast.error("something went wrong , try agin");
      }
    } catch (error) {
      console.error("Error saving default settings:", error);
      toast.error(error?.message || "server error occured");
    } finally {
      setLoading(false);
    }
  };

  const deletetUserSettings = async (settings) => {
    try {
      const { result, error } = await httpClient({
        method: "delete",
        url: `${API_URLS.deleteUserSettings}?userId=${user._id}&settingsId=${settings._id}`,
      });

      if (error) {
        toast.error(error?.message || "something went wrong !");
      }

      if (result.status) {
        toast.success("Setting deleted successfuly !");
        getUserSettings();
        // Check if no filters are left

        if (result.isDefault) {
          resetSettingsCompletly();
          updateCurrentSavedTab(null);
          // Optionally, reload the page or reset the state
          window.location.reload();
          return;
        }
        if (userSettings.length === 1) {
          // Reset state to initial values
          resetSettingsCompletly();
          updateCurrentSavedTab(null);
          // Optionally, reload the page or reset the state
          window.location.reload();
        }
      } else {
        toast.error("something went wrong , try agin");
      }
    } catch (error) {
      console.error("Error saving default settings:", error);
      toast.error(error?.message || "server error occured");
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const openEditModal = (settingsId: string, currentName: string) => {
    setSelectedSettingId(settingsId);
    setName(currentName);
    setError(""); // Reset errors
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setName("");
    setError("");
    setSelectedSettingId(null);
  };

  const editUserSettings = async () => {
    try {
      if (!name.trim()) {
        setError("Name is required and cannot be empty");
        return;
      }
      const { result, error } = await httpClient({
        method: "post",
        url: `${API_URLS.editUserSettings}`,
        data: {
          userId: user?._id,
          settingsId: selectedSettingId,
          name,
        },
      });

      if (error) {
        toast.error(error?.message || "something went wrong !");
      }

      if (result.status) {
        toast.success("Settings saved successfuly !");
        setUserSettings(result.userSettings);
        getUserSettings();
        if (result.userSettings?.isDefault) {
          updateUserSettings(result.userSettings);
        }
        closeModal();
      } else {
        toast.error("something went wrong , try agin");
      }
    } catch (error) {
      console.error("Error saving default settings:", error);
      toast.error(error?.message || "server error occured");
    }
  };

  function getMainAndSubTabs(settings) {
    const result = [];

    settings.forEach((setting) => {
      const mainTab = setting?.mainTab || "Unknown";

      setting.subTabs.forEach((subTabObj) => {
        const subTab = subTabObj?.subTab || "Unknown";
        result.push({ mainTab, subTab });
      });
    });

    return result[0];
  }

  useEffect(() => {
    isLoggedIn() && getUserSettings();
  }, []);

  const setDefaultSettings = async (setting: any) => {
    const currentDefaults = userSettings.filter((s: any) => s.isDefault);

    try {
      const { result, error } = await httpClient({
        method: "put",
        url: `${API_URLS.setUserSettingsDefault}`,
        data: {
          userId: user?._id,
          settingsId: setting?._id,
          isDefault: !setting.isDefault,
        },
      });

      if (error) {
        toast.error(error?.message || "Something went wrong!");
        return;
      }

      if (result.status) {
        let results: any = getMainAndSubTabs(result?.userSettings?.settings);
        updateCurrentSavedTab(results?.mainTab, results?.subTab);
        updateUserSettings(result.userSettings);
        setUserSettings(result.userSettings);

        if (!results?.mainTab || !results?.subTab) {
          window.location.reload(); // Reload the page if either is null
          return;
        }
        // Map the main tab and sub tab to their respective routes and tab numbers
        let route = "";
        let tabNumber = "tab1";

        switch (results?.mainTab) {
          case "establishmentInsights":
            route = "/establishment-data";
            switch (results?.subTab) {
              case "provinceAndTerritory":
                tabNumber = "tab1";
                break;
              case "sector":
                tabNumber = "tab2";
                break;
              case "comparison":
                tabNumber = "tab3";
                break;
              default:
                break;
            }
            break;

          case "educationalPrograms":
            route = "/educational-program";
            switch (results?.subTab) {
              case "provinceAndTerritory":
                tabNumber = "tab1";
                break;
              case "areaByStudy":
                tabNumber = "tab2";
                break;
              case "levelOfInstruction":
                tabNumber = "tab3";
                break;
              case "apprenticeship":
                tabNumber = "tab4";
                break;
              case "comparison":
                tabNumber = "tab5";
                break;
              default:
                break;
            }
            break;

          case "workForce":
            route = "/employment-data";
            switch (results?.subTab) {
              case "provinceAndTerritory":
                tabNumber = "tab1";
                break;
              case "totalEmploymentOverTime":
                tabNumber = "tab2";
                break;
              case "demograpghicBreakDown":
                tabNumber = "tab3";
                break;
              case "comparison":
                tabNumber = "tab4";
                break;
              default:
                break;
            }
            break;

          default:
            break;
        }

        // Navigate to the appropriate route with the tab number as state
        if (route && tabNumber) {
          navigate(route, { state: { tab: tabNumber } });
          getUserSettings();
          window.location.reload(); // Fallback to reload if route or tab number is not found
        } else {
          window.location.reload(); // Fallback to reload if route or tab number is not found
        }

        setTimeout(() => {
          toast.success("Default status updated!");
        }, 1000); // Show toast after 1 second
      } else {
        toast.error("Something went wrong, try again.");
      }
    } catch (error) {
      console.error("Error toggling default:", error);
      toast.error(error?.message || "Server error occurred.");
    }
  };

  // Debounce search input
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (searchQuery) {
        fetchSearchResults(searchQuery);
      } else {
        setSearchResults([]);
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchQuery, fetchSearchResults]);

  const handleResultClick = (result: any) => {
    if (result.mainTab === "EstablishmentInsights") {
      navigate("/establishment-data", {
        state: {
          tab: result.subTab === "ProvinceAndTerritory" ? "tab1" : "tab2",
        },
      });
    } else if (result.mainTab === "EducationalProgramInsights") {
      const tabMapping: Record<string, string> = {
        ProvinceAndTerritory: "tab1",
        AreasByStudy: "tab2",
        LevelOfInstruction: "tab3",
        ProgramByApprenticeship: "tab4",
      };

      const tab = tabMapping[result?.subTab] || "tab1"; // Default to "tab1" if not found

      navigate("/educational-program", { state: { tab } });
    } else if (result.mainTab === "WorkForceInsights") {
      navigate("/workforce-data", {
        state: { tab: "tab1" },
      });
    }
    closeModal();
  };
  return (
    <>
      <header className="c_header">
        <div className="row align-items-center justify-content-between">
          {/* Left: Title */}
          <div className="col-xl-4 col-sm-12 col-12 text-md-start order-md-1">
            <h2>{title}</h2>
          </div>

          {/* Center: Search Box */}
          {/* <div className="col-md-4 col-sm-4 col-12 d-flex justify-content-center order-md-2">
                        <div className="input-group search-container">
                            {/* <span className="input-group-text search-icon">
                                <FaSearch />
                            </span>
                            <input
                                type="text"
                                placeholder="Search Here"
                                className="form-control search-box"
                                onClick={openSearchModal}
                                readOnly
                            /> */}
          {/*                            
                        </div>
                    </div> */}

          {/* Right: Button & Icons */}
          <div className="col-xl-8 col-sm-12 col-12 d-lg-flex 
+          justify-content-xl-end align-items-center order-md-3 mt-lg-0 mt-2">
            {/* Subtitle & Help Icon */}
            {buttonTitle && (
              <button className="button" onClick={buttonEventTriggered}>
                {typeof ButtonIcon === "string" ? (
                  <img
                    src={ButtonIcon}
                    className="inline-block align-middle me-2 icon-margin"
                    alt="icon"
                  />
                ) : (
                  <span style={{ marginRight: "8px", fontSize: "15px" }}>
                    {ButtonIcon}
                  </span>
                )}
                {buttonTitle}
              </button>
            )}
            <div className="source-header d-flex align-items-center">
              {subtitle && <h6 className="mb-0">{subtitle}</h6>}
              {!excludedComponents.includes(component as string) && (
                <>
                  <div className="vertical-line"></div>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} className="tooltip-justify">
                        {t("textClickHelp")}
                      </Tooltip>
                    )}
                    placement="bottom"
                  >

                    <div className="help-icon">
                      <FaQuestionCircle onClick={() => onStart(true)} />
                    </div>
                  </OverlayTrigger>
                </>

              )}
            </div>

            {/* Vertical Line & Save Changes Button */}
            {(isHeader && isLoggedIn()) && (
              <div className="d-md-flex d-setting-header align-items-center">
                <div className="vertical-line mx-3 d-lg-block d-none"></div>
                {/* Title */}
                <div
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: "700",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t('header.customHeader')}
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} className="tooltip-justify">
                        {t("customDashboardLMIHelpTextClick")}
                      </Tooltip>
                    )}
                    placement="bottom"
                  >
                    <div className="help-icon d-inline-block ms-2">
                      <FaQuestionCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                {/* Button Group */}
                <div
                  className="btn-group d-flex align-items-end ms-2"
                >
                  {/* Save Button */}
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={handleSaveCheck}
                    style={{
                      width: "max-content",
                      backgroundColor: "#e61e28",
                    }}
                  >
                    {t("header.buttonSave")}
                  </button>
                  <div
                    style={{
                      width: "1px",
                      backgroundColor: "#ccc", // Grey color
                      margin: "0 1px", // Add some spacing
                    }}
                  ></div>
                  {/* Save As Button */}

                  {/* Dropdown Toggle */}
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ backgroundColor: "#e61e28" }}
                      onClick={(e) =>
                        (e.currentTarget.style.backgroundColor = "#e61e28")
                      }
                    >
                      <span className="d-inline-block align-middle"
                        style={{
                          fontWeight: 700,
                          textOverflow: 'ellipsis', overflow: 'hidden'
                        }}
                        title={userSettings.length > 0 &&
                          capitalizeFirstLetter(
                            userSettings?.find((s: any) => s?.isDefault)?.name
                          )}>
                        {userSettings.length > 0 &&
                          capitalizeFirstLetter(
                            userSettings?.find((s: any) => s?.isDefault)?.name
                          )}
                      </span>
                      <span className="visually-hidden">Toggle Dropdown</span>
                    </button>

                    {/* Dropdown Menu (keep your existing menu) */}
                    <ul className="dropdown-menu dashboard-dropdown-menu">
                      <li>
                        <button
                          className="dropdown-item "
                          onClick={(e) => {
                            handleSaveAs();

                            (e.currentTarget.style.backgroundColor = 'white');
                            (e.currentTarget.style.color = 'black')
                          }}

                        >
                          {t("header.buttonSaveAs")}
                        </button>
                      </li>
                      {userSettings?.length > 0 && (
                        <li>
                          <hr className="dropdown-divider " />
                        </li>
                      )}
                      {userSettings?.length > 0 && (
                        <>
                          {userSettings?.map((setting: any) => (
                            <li key={setting._id}>
                              <button
                                onClick={() => setDefaultSettings(setting)}
                                type="button"
                                className={`dropdown-item d-flex flex-column p-0 overflow-hidden ${setting.isDefault ? "active" : ""
                                  }`}
                                style={{
                                  transition: "all 0.3s ease",
                                  backgroundColor: setting.isDefault ? "#e81039" : "transparent",
                                  color: setting.isDefault ? "white" : "black",
                                  border: "none",
                                  borderRadius: "0",
                                  textAlign: "left",
                                  width: "100%",
                                }}

                              >
                                {/* Top Row - Name and Actions */}
                                <div className="d-flex justify-content-between align-items-center w-100 px-3 pt-2 pb-1" title={capitalizeFirstLetter(setting?.name)}>
                                  <span
                                    className="text-truncate me-3"
                                    style={{
                                      fontWeight: 600, // Semi-bold for setting name
                                      fontSize: "0.95rem",
                                      flex: 1,
                                      minWidth: 0,
                                    }}

                                    title={capitalizeFirstLetter(setting?.name)}
                                  >
                                    {capitalizeFirstLetter(setting?.name)}
                                  </span>
                                  <div className="d-flex align-items-center gap-2">
                                    <FaPenToSquare
                                      title="edit"
                                      fontSize={14}
                                      className={`${setting.isDefault ? "text-white" : "text-primary"
                                        } cursor-pointer`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        openEditModal(setting._id, setting.name);
                                      }}
                                    />
                                    <FaTimesCircle
                                      fontSize={14}
                                      title="delete"
                                      className={`${setting.isDefault ? "text-white" : "text-danger"
                                        } cursor-pointer`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        deletetUserSettings(setting);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* Bottom Row - Metadata */}
                                {/* Bottom Row - Metadata */}
                                <div className="w-100 px-3 pb-2">
                                  <div
                                    className="d-flex flex-column"
                                    style={{
                                      borderTop: setting.isDefault
                                        ? "1px solid rgba(255,255,255,0.2)"
                                        : "1px solid rgba(0,0,0,0.1)",
                                      paddingTop: "0.25rem",
                                    }}
                                  >
                                    {/* Using grid for perfect alignment */}
                                    <div style={{
                                      display: 'grid',
                                      gridTemplateColumns: 'auto auto 1fr',
                                      alignItems: 'center',
                                      gap: '0 0.25rem',
                                      fontSize: "0.65rem",
                                      color: setting.isDefault ? "rgba(255,255,255,0.85)" : "#555"
                                    }}>
                                      {/* Data Type Row */}
                                      <span>{t('header.dashgboardSettings.dropDown.dataType')}</span>
                                      <span>:</span>
                                      <span title={t(getMainAndSubTabs(setting?.settings)?.mainTab) || "N/A"} className="text-truncate"> {t(getMainAndSubTabs(setting?.settings)?.mainTab) || "N/A"}</span>

                                      {/* Tab Type Row */}
                                      <span>{t('header.dashgboardSettings.dropDown.tabType')}</span>
                                      <span>:</span>
                                      <span title={t(getTextForKey(getMainAndSubTabs(setting?.settings)?.subTab)) || "N/A"} className="text-truncate"> {t(getTextForKey(getMainAndSubTabs(setting?.settings)?.subTab)) || "N/A"}</span>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </li>
                          ))}
                        </>
                      )}

                    </ul>
                  </div>
                </div>
              </div>
            )}
            {!isLoggedIn() && <div
              className="btn-group d-flex align-items-end ms-2"
            >
              {/* Save Button */}
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => { navigate('/login') }}
                style={{
                  width: "max-content",
                  backgroundColor: "#e61e28",
                }}
              >
                {'Login'}
              </button>
            </div>}
          </div>
        </div>
      </header>

      {/* Search Modal */}
      <Modal
        show={showSearch}
        onHide={closeModal}
        backdrop={true}
        keyboard={false}
        className="search-modal"
        style={{ maxWidth: "100vw", maxHeight: "90vh" }}
      >
        {/* <Modal.Header className="d-flex justify-content-between align-items-center">
          {/* <Modal.Title>Search</Modal.Title> */}
        {/* <FaTimes className="close-icon" onClick={closeModal} />
        </Modal.Header> */}
        <Modal.Body>
          <div className="search-input-container">
            <FaSearch className="search-icon-modal" />
            <input
              type="text"
              placeholder="Type to search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              ref={modalSearchInputRef}
            />
            <FaTimes className="close-icon-modal" onClick={closeModal} />
          </div>
          <hr />
          {loading ? (
            <p className="loading-text">Fetching...</p>
          ) : error ? (
            <p className="error-text">{error}</p>
          ) : searchResults?.length > 0 ? (
            <div className="search-results">
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  onClick={() => handleResultClick(result)}
                  className="search-item"
                >
                  <strong>{result.name}</strong>
                  <p>
                    {result?.mainTab === "EstablishmentInsights" &&
                      "Establishment Insights"}
                    {result?.mainTab === "EducationalProgramInsights" &&
                      "Educational Program Insights"}
                    {result?.mainTab === "WorkForceInsights" &&
                      "Workforce Insights"}
                    {" → " + result?.subTab}
                  </p>
                </div>
              ))}
            </div>
          ) : searchQuery.trim() ? (
            <p className="no-results">No results found</p>
          ) : null}
        </Modal.Body>
      </Modal>

      <Modal
        show={showSaveAsModal}
        onHide={() => setShowSaveAsModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("header.buttonSaveAs")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="settingsName">
            <Form.Label>{t("header.modal.messageSaveAs")}</Form.Label>
            <Form.Control
              type="text"
              value={settingsName}
              onChange={(e) => {
                setSettingsName(e.target.value);
                setError("");
              }}
              placeholder={t("header.modal.messageSaveAsPlaceHolder")}
              className="mb-2"
              isInvalid={!!error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveAsSubmit}>
            {t("uploadDocument.buttonTitle.save")}
          </Button>
          <Button variant="secondary" onClick={() => setShowSaveAsModal(false)}>
            {t("settings.tabUsers.modalConfirmation.buttonTitle.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("header.editUserSettings")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                {t("settings.tabUsers.tableColumnHeaderName")} *
              </Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={error ? "is-invalid" : ""}
              />
              {error && <div className="text-danger mt-1">{error}</div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={editUserSettings}>
            {t("uploadDocument.buttonTitle.save")}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            {t("uploadDocument.buttonTitle.close")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSaveConfirmation}
        onHide={() => setShowSaveConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('header.dashgboardSettings.saveModalHeader')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('header.dashgboardSettings.saveModalBody')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleSave}>
            {t('header.dashgboardSettings.saveModalSaveButton')}
          </Button>

          <Button
            variant="secondary"
            onClick={() => setShowSaveConfirmation(false)}
          >
            {t('header.dashgboardSettings.saveModalCancelButton')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonHeader;

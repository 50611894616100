// import React, { useState, useEffect, useRef } from "react";
// import { Tabs, Tab } from "react-bootstrap";
// import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
// import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
// import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
// import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
// import Comparison from "../pages/dashboard/Comparison.tsx";
// import Error403 from "../components/Error403.jsx"
// import { useTranslation } from "react-i18next";
// import { useUser } from "../context/UserContext"
// import { Loader } from "./Loader.tsx";
// import { useNavigate } from "react-router-dom";

// const TabComponent = ({
//   colData,
//   countByGeography,
//   areaOfStudy,
//   programCountByAreaOfStudy,
//   getTabData,
//   joyrideStatus,
//   setJoyrideStatus
// }) => {
//   const { t } = useTranslation();
//   const containerRef = useRef(null);
//   const navigate = useNavigate()
//   const { permissions, joyrideSteps, userPlan, user } = useUser()
//   const isSuperAdmin = user?.role === "SUPER_ADMIN"
//   const [overflowStyle, setOverflowStyle] = useState("initial");
//   const [loading, setLoading] = useState(true)
//   const [key, setKey] = useState(
//     JSON.parse(localStorage.getItem("user")).settings.educationalPrograms.tab ||
//     "tab1"
//   ); // State to manage active tab

//   const handleTab = (k) => {
//     setKey(k);
//     getTabData("tabcomponent", k);
//   };

//   // Monitor overflow in tab container
//   useEffect(() => {
//     const container = containerRef.current;
//     if (container && container.scrollHeight > container.clientHeight) {
//       setOverflowStyle("auto");
//     } else {
//       setOverflowStyle("initial");
//     }
//   }, [key]);  

//   // useEffect(() => {

//   //     setKey("tab1");
//   // }, [userPlan]);

//   useEffect(() => {
//     if (userPlan?.subscriptionPlanId?.features?.educationalProgramInsights) {
//       const { educationalProgramInsights } = userPlan.subscriptionPlanId.features;

//       const accessibleTabs = [
//         educationalProgramInsights.programByProvinceAndTerrirtory === "accessible" && "tab1",
//         educationalProgramInsights.programByAreaOfStudy === "accessible" && "tab2",
//         educationalProgramInsights.programByLevelOfInstruction === "accessible" && "tab3",
//         educationalProgramInsights.programByApprenticeship === "accessible" && "tab4",
//         educationalProgramInsights.dataComparison === "accessible" && "tab5"
//       ].filter(Boolean); // Remove false/null values

//       if (accessibleTabs.length > 0) {
//         setKey(accessibleTabs[0]); // Set first accessible tab
//       }
//     } else {
//       setKey("tab1"); // Default if no plan or no accessible tabs
//     }
//   }, [userPlan]);


//   return (
//     <div style={{ display: "flex", flexDirection: "column"}}>
//       {/* Fixed Tabs */}
//       <div className="tab-scroll-container">
//       <Tabs
//         id="controlled-tab"
//         activeKey={key}
//         onSelect={(k) => handleTab(k)}
//         className="custom_tab"
//       >
//              { (isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.programByProvinceAndTerrirtory == "accessible")) && (
//           <Tab
//             eventKey="tab1"
//             title={t("educationalProgram.tabTitle.provinceAndTerritory")}
//             className="tab_container mt-2"
//           />
//      )} 
//         { (isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.programByAreaOfStudy == "accessible")) && (
//           <Tab
//             eventKey="tab2"
//             title={t("educationalProgram.tabTitle.areaOfStudy")}
//             className="tab_container mt-2"
//           />
//         )} 
//         { (isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.programByLevelOfInstruction == "accessible")) && (
//           <Tab
//             eventKey="tab3"
//             title={t("educationalProgram.tabTitle.levelOfInstruction")}
//             className="tab_container mt-2"
//           />
//       )}
//        { (isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.programByApprenticeship == "accessible")) && (
//           <Tab
//             eventKey="tab4"
//             title={t("educationalProgram.tabTitle.apprenticeshipOffered")}
//             className="tab_container mt-2"
//           />
//         )}
//         {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison'))) && ( */}
//         {(isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.dataComparison == "accessible")) && (
//           <Tab
//             eventKey="tab5"
//             title={t("commonTitleComparison")}
//             className="tab_container mt-2"
//           />
//         )}
//       </Tabs>
//       </div>

// <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)"}}>
//         {key === "tab1" && (
//           <TableViewEducationalProgram
//             colData={colData}
//             countByGeography={countByGeography}
//             tabSettings={getTabData}
//             joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}
//             steps={joyrideSteps?.educationalProgramInsights?.tabProvinceTerritory}
//           />
//         )}
//         {key === "tab2" && (
//           <HorizontalChartEducationalProgram steps={joyrideSteps?.educationalProgramInsights?.tabAreaOfStudy} joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}/>
//         )}
//         {key === "tab3" && (
//           <TableByInstitution steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
//             setJoyrideStatus= {setJoyrideStatus}/>
//         )}
//         {key === "tab4" && (
//           <TableByApprenticeship steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
//           setJoyrideStatus= {setJoyrideStatus}/>
//         )}
//         {key === "tab5" && (isSuperAdmin || userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.dataComparison == "accessible") && (
//           <Comparison field={"educationalInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default TabComponent;

import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip, Overlay, Modal, Button } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";
import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Error403 from "../components/Error403.jsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext"
import { Loader } from "./Loader.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../pages/utils/authUtils.js";

const TabComponent = ({
  colData,
  countByGeography,
  areaOfStudy,
  programCountByAreaOfStudy,
  getTabData,
  joyrideStatus,
  setJoyrideStatus
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const navigate = useNavigate()
  const { permissions, joyrideSteps, userPlan, user ,defaultPlan } = useUser()
  const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const [restrictedTab, setRestrictedTab] = useState(null); // Track which tab is restricted
  const [overflowStyle, setOverflowStyle] = useState("initial");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const location = useLocation();
  const initialTab = location?.state?.tab ;
  const [key, setKey] = useState(
    localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user"))?.settings?.educationalPrograms?.tab :
    "tab1"
  ); // State to manage active tab


  const tabImages = isLoggedIn() ?  {
    tab1: user?.preferredLanguage==='fr' ? "/tabImages/langFre/educational/province.png" :  "/tabImages/langEng/educational/province.png",
    tab2: user?.preferredLanguage==='fr'  ? "/tabImages/langFre/educational/areaByStudy.png" :"/tabImages/langEng/educational/areaByStudy.png",
    tab3: user?.preferredLanguage==='fr' ? "/tabImages/langFre/educational/levelOfInstruction.png" :"/tabImages/langEng/educational/levelOfInstruction.png",
    tab4: user?.preferredLanguage==='fr'  ? "/tabImages/langFre/educational/apprenticeship.png" :"/tabImages/langEng/educational/apprenticeship.png",
    tab5: user?.preferredLanguage==='fr'  ? "/tabImages/langFre/educational/comparison.png" :  "/tabImages/langEng/educational/comparison.png"
  } : {
    tab1: user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/educational/province.png" :  "/nonLoggedInTabImages/langEng/educational/province.png",
    tab2: user?.preferredLanguage==='fr'  ? "/nonLoggedInTabImages/langFre/educational/areaByStudy.png" :"/nonLoggedInTabImages/langEng/educational/areaByStudy.png",
    tab3: user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/educational/levelOfInstruction.png" :"/nonLoggedInTabImages/langEng/educational/levelOfInstruction.png",
    tab4: user?.preferredLanguage==='fr'  ? "/nonLoggedInTabImages/langFre/educational/apprenticeship.png" :"/nonLoggedInTabImages/langEng/educational/apprenticeship.png",
    tab5: user?.preferredLanguage==='fr'  ? "/nonLoggedInTabImages/langFre/educational/comparison.png" :  "/nonLoggedInTabImages/langEng/educational/comparison.png"
  };

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);



  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     setKey(initialTab || "tab1");
  //     return;
  //   }

  //   if (userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.defaultEducationalAccessibility === 'accessible') {
  //     const { educationalProgramInsights } = userPlan?.subscriptionPlanId?.features;
  //     const accessibleTabs = [
  //       educationalProgramInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
  //       educationalProgramInsights?.areaOfStudy?.defaultAreaOfStudyAccessibility === "accessible" && "tab2",
  //       educationalProgramInsights?.levelOfInstruction?.defaultLevelOfInstructionAccessibility === "accessible" && "tab3",
  //       educationalProgramInsights?.apprenticeship?.defaultApprenticeshipAccessibility === "accessible" && "tab4",
  //       educationalProgramInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab5"
  //     ].filter(Boolean); // Remove false/null values

  //     if (accessibleTabs?.length <= 0) {
  //       setKey('tab1');
  //     }
  //     if (accessibleTabs?.length > 0) {

  //       setKey(initialTab || accessibleTabs[0]); // Set first accessible tab
  //     }
  //   } else {

  //     setRestrictedTab('tab1'); // Set which tab is restricted
  //     setShowModal(true);
  //   }
  // }, [userPlan, initialTab]);

    useEffect(() => {
      if(isSuperAdmin){
        setKey(initialTab || "tab1"); 
        return;
      }
     if(!isLoggedIn()){

      if ( defaultPlan?.features?.educationalProgramInsights?.defaultEducationalAccessibility === 'accessible') {
        const { educationalProgramInsights } = defaultPlan.features;
        const accessibleTabs = [
          educationalProgramInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
          educationalProgramInsights?.areaOfStudy?.defaultAreaOfStudyAccessibility === "accessible" && "tab2",
          educationalProgramInsights?.levelOfInstruction?.defaultLevelOfInstructionAccessibility === "accessible" && "tab3",
          educationalProgramInsights?.apprenticeship?.defaultApprenticeshipAccessibility === "accessible" && "tab4",
          educationalProgramInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab5"
        ].filter(Boolean);
        if(accessibleTabs?.length <= 0){
          setKey('tab1');
        }
        if (accessibleTabs?.length > 0) {
          setKey( initialTab || accessibleTabs[0]);
        }
      } else {
  
        // setKey(initialTab || "tab1");
        setRestrictedTab('tab1'); // Set which tab is restricted
        setShowModal(true);
      }
     }else{

      if ( userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.defaultEducationalAccessibility === 'accessible') {
  
        const { educationalProgramInsights } = userPlan.subscriptionPlanId.features;
        const accessibleTabs = [
          educationalProgramInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
          educationalProgramInsights?.areaOfStudy?.defaultAreaOfStudyAccessibility === "accessible" && "tab2",
          educationalProgramInsights?.levelOfInstruction?.defaultLevelOfInstructionAccessibility === "accessible" && "tab3",
          educationalProgramInsights?.apprenticeship?.defaultApprenticeshipAccessibility === "accessible" && "tab4",
          educationalProgramInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab5"
        ].filter(Boolean);
        if(accessibleTabs?.length <= 0){
          setKey('tab1');
        }
        if (accessibleTabs?.length > 0) {

          setKey( initialTab || accessibleTabs[0]);
        }
      } else {
  
        // setKey(initialTab || "tab1");
        setRestrictedTab('tab1'); // Set which tab is restricted
        setShowModal(true);
      }
     }
    }, [userPlan, initialTab ,isLoggedIn ,defaultPlan ,setKey]);


  const isTabAccessible = (subkey, featureKey) => {
    if(isLoggedIn()){
    return (
      user?.role === "SUPER_ADMIN" || user?.role === "ADMIN" ||
      (userPlan &&
        userPlan.subscriptionPlanId &&
        userPlan.subscriptionPlanId.features &&
        userPlan.subscriptionPlanId.features.educationalProgramInsights?.[subkey]?.[featureKey] === "accessible")
    );}else{
      return (
     
        (defaultPlan &&
          defaultPlan?.features &&
          defaultPlan?.features?.educationalProgramInsights?.[subkey]?.[featureKey] === "accessible")
      );
    }
  };



  const handleTab = (k, event) => {
    if ((!isTabAccessible("comparison", "defaultComparisonAccessibility") && k === "tab5" || !isTabAccessible("provinceAndTerritory", "defaultProvinceAndTerritoryAccessibility") && k === 'tab1' || !isTabAccessible("areaOfStudy", "defaultAreaOfStudyAccessibility") && k === 'tab2' || !isTabAccessible("levelOfInstruction", "defaultLevelOfInstructionAccessibility") && k === 'tab3' || !isTabAccessible("apprenticeship", "defaultApprenticeshipAccessibility") && k === 'tab4')) {
      console.log('not access')
      event.preventDefault(); // Block tab switch
      setRestrictedTab(k); // Set which tab is restricted
      setShowModal(true);
    } else {
      setKey(k);
      getTabData("tabcomponent", k);
    }
  };


  const handleUpgrade = () => {
    setShowModal(false); // Close the modal
    setRestrictedTab(null);


 !isLoggedIn() ? navigate("/login")  : navigate("/subscription-plans", { state: { upgradePlan: true } });

  };



  const handleCancel = () => {
    setShowModal(false); // Close the modal
    setRestrictedTab(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">

        <Tabs
          id="controlled-tab"
          activeKey={key}
          onSelect={(k, e) => handleTab(k, e)}
          className="custom_tab"
        >
          {/* Tab 1: Province and Territory */}
          <Tab
            eventKey="tab1"
            title={
              <div
                className="d-flex align-items-center"
              >
                {!isTabAccessible("provinceAndTerritory", "defaultProvinceAndTerritoryAccessibility") && (

                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>

                )}
                {t("educationalProgram.tabTitle.provinceAndTerritory")}
              </div>
            }
            className="tab_container mt-2"
          //disabled={!isTabAccessible("programByProvinceAndTerrirtory")} // Disable if not accessible
          />

          {/* Tab 2: Area of Study */}
          <Tab
            eventKey="tab2"
            title={
              <div
                className="d-flex align-items-center"
              >
                {!isTabAccessible("areaOfStudy", "defaultAreaOfStudyAccessibility") && (

                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>

                )}
                {t("educationalProgram.tabTitle.areaOfStudy")}
              </div>
            }
            className="tab_container mt-2"

          />

          {/* Tab 3: Level of Instruction */}
          <Tab
            eventKey="tab3"
            title={
              <div
                className="d-flex align-items-center"
              >
                {!isTabAccessible("levelOfInstruction", "defaultLevelOfInstructionAccessibility") && (

                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>

                )}
                {t("educationalProgram.tabTitle.levelOfInstruction")}
              </div>
            }
            className="tab_container mt-2"
          //disabled={!isTabAccessible("programByLevelOfInstruction")} // Disable if not accessible
          />

          {/* Tab 4: Apprenticeship Offered */}
          <Tab
            eventKey="tab4"
            title={
              <div
                className="d-flex align-items-center"
              >
                {!isTabAccessible("apprenticeship", "defaultApprenticeshipAccessibility") && (

                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>

                )}
                <span className="">{t("educationalProgram.tabTitle.apprenticeshipOffered")}</span>
              </div>
            }
            className="tab_container mt-2"

          />

          {/* Tab 5: Comparison */}
          <Tab
            eventKey="tab5"
            title={
              <div
                className="d-flex align-items-center"
              >
                {!isTabAccessible("comparison", "defaultComparisonAccessibility") && (

                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>

                )}
                {t("commonTitleComparison")}
              </div>
            }
            className="tab_container mt-2"

          />
        </Tabs>



      </div>

      <Modal
        show={showModal}
        onHide={handleCancel}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('plan.premiumUpgradeTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('plan.premiumUpgradeHeader')}
          </p>
          <p> {t('plan.premiumUpgradeBody')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleUpgrade}
            className="w-25 me-2"
          >
            {t('plan.upgrade')}
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancel}
            className="w-25"
          >
            {t('settings.tabUsers.modalConfirmation.buttonTitle.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show preview image if tab is not accessible */}
      {restrictedTab && (
        <>

          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)',
            zIndex: 1000
          }}>
            <img
              src={tabImages[restrictedTab]}
              alt={`${restrictedTab} preview`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </div>
        </>
      )}

      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>
        {key === "tab1" && (
          <TableViewEducationalProgram
            colData={colData}
            countByGeography={countByGeography}
            tabSettings={getTabData}
            joyrideStatus={joyrideStatus}
            setJoyrideStatus={setJoyrideStatus}
            steps={joyrideSteps?.educationalProgramInsights?.tabProvinceTerritory}
          />
        )}
        {key === "tab2" && (
          <HorizontalChartEducationalProgram steps={joyrideSteps?.educationalProgramInsights?.tabAreaOfStudy} joyrideStatus={joyrideStatus}
            setJoyrideStatus={setJoyrideStatus} />
        )}
        {key === "tab3" && (
          <TableByInstitution steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
            setJoyrideStatus={setJoyrideStatus} />
        )}
        {key === "tab4" && (
          <TableByApprenticeship steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
            setJoyrideStatus={setJoyrideStatus} />
        )}
        {key === "tab5" && (
          <Comparison field={"educationalInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
        )}
      </div>
    </div>
  );
};

export default TabComponent;

import React, { useEffect } from 'react';
import './App.css';
import Navigation from "./navigation/index.jsx";
// import { useIntercom } from 'react-use-intercom';
import { UserProvider } from './context/UserContext';
import { GlobalSettingsProvider } from './context/GlobalSettingsProvider';
import { ToastContainer } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css";


function App() {
  const router = Navigation();
  document.title = 'Food Processing';
  // const { boot } = useIntercom()

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   boot({ name: user?.name })
  // }, [boot])

  return (
    <>
        <UserProvider>
          <GlobalSettingsProvider>
    
          <Navigation />
      
          <ToastContainer />
          </GlobalSettingsProvider>
        </UserProvider>
    </>
  );
}

export default App;
import React, { useEffect, useState, useRef } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { Card, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Loader } from "../../components/Loader.tsx";
import PieChart from "../../components/charts/PieChart.tsx";
import BarChartHighChart from "../../components/charts/BarChartHighChart";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter, capitalizeString } from "../../services/commonFn.js";
import { FaQuestionCircle } from "react-icons/fa";
import HelpTour from "../../components/HelpTour.tsx";
import PieChartHelpImage from "../../assets/EstablishmentSectorPieChart.png"
// import dummyVideo from '../../assets/Dummy Video For YouTube API Test.mp4'
import ReactPlayer from 'react-player';
import { BiSolidVideos } from "react-icons/bi";
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";
import { useUser } from "../../context/UserContext.js";
import { isLoggedIn } from "../utils/authUtils.js";


const Dashboard2 = ({ state, setState, steps, joyrideStatus, setJoyrideStatus }) => {

  const imageMapping = {
    "PieChartHelpImage": PieChartHelpImage,
  };

  interface Period {
    month: string;
    value: number;
  }

  interface SelectedYear {
    year: number;
    period: Period[];
  }
  const { t } = useTranslation();
  const totalSteps = [
    ...(Array.isArray(steps?.parentSteps) ? steps.parentSteps : []),
    ...(Array.isArray(steps?.pieChartSteps) ? steps.pieChartSteps : [])
  ];
  
  const { user, userPlan ,defaultPlan } = useUser();
   const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const [stepStates, setStepStates] = useState(
    totalSteps?.map(() => ({ showVideo: false })) || []
  )
  const [businessTypeList, setBusinessTypeList] = useState<any>([]);
  const [establishmentList, setEstablishmentList] = useState<any>([]);
  const [years, setYears] = useState<any>([]);
  const [geographyList, setGeographyList] = useState<any>([]);
  const [establishmentBusinessCount, setEstablishmentBusinessCount] =
    useState<any>([]);
  const [subEstablishmentBusinessCount, setSubEstablishmentBusinessCount] =
    useState<any>([]);
  const [response, setResponse] = useState<any>([]);
  const [employmentSize, setEmploymentSize] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | undefined>(undefined);
  const [selectedGeography, setSelectedGeography] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState(2);
  const [selectedYear, setSelectedYear] = useState<SelectedYear | null>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [helpRun, setHelpRun] = useState(false)
  const [userSetting, setUserSetting] = useState(
    localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : null
  );
  const { defaultSettings, updateSettings ,setCurrentTab ,resetSettings} = useUserSettings()

  const [joyrideSteps, setSteps] = useState<any[]>([])

  const [colors, setColors] = useState<any>([
    "#de9b61", //  3111:
    "#fbb518", // 3112:
    "#d460a2", // 3113:
    "#ef4b32", // 3114:
    "#27ade3", // 3115:
    "#e91c4d", // 3116:
    "#113f70", // 3117:
    "#663300", // 3118:
    "#119448", // 3119:
    "#804795", // 3121:
    "#0f5b2e", // 3123:
  ]);

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      setStepStates(stepStates?.map(() => ({ showVideo: false })) || [])
    }

  }


    useEffect(()=>{
      resetSettings()
    },[])

    useEffect(() =>{
      setCurrentTab('establishmentInsights' ,'sector')
    },[])


  const handleEmploymentSizeChange = (event) => {
    const empSize = event.target.value;
    setEmploymentSize(empSize);
    setState(null);
    updateSettings("establishmentInsights", "sector", "employmentSize", empSize);
  };
  const handleSectorChange = (event) => {

    state = null;
    setSelectedSector(event.target.value);
  };
  const handleGeograpgyChange = (event) => {
    const geography = event.target.value;
    setSelectedGeography(geography);
    state = null;
    updateSettings("establishmentInsights", "sector", "selectedGeography", geography);
  };
  const handlePeriodChange = (event) => {
    const period = event.target.value;
    setSelectedPeriod(period);
    state = null;
    updateSettings("establishmentInsights", "sector", "selectedPeriod", period);
  };
  const handleYearChange = (event) => {
    setState(null);
    //state=null;

    const year = event.target.value;
    setSelectedYear(JSON.parse(year));
    setSelectedPeriod(JSON.parse(year).period.some((i) => i.value === selectedPeriod) ? selectedPeriod : 1);
    updateSettings("establishmentInsights", "sector", "selectedYear", JSON.parse(year));
  };

  // const clearAll = () => {
  //   setEmploymentSize("");
  //   setSelectedSector("");
  //   setSelectedYear(years[0]);
  //   setSelectedPeriod(years?.[0].period.length > 1 ? 2 : 1);
  //   setSelectedGeography(
  //     geographyList.filter((geo) => geo.name == "canada")[0]?._id
  //   );
  //   setSubEstablishmentBusinessCount([])
  // };

  const clearAll = () => {
    setState(null);
    // Reset state to default values
    const defaultEmpSize = "";
    const defaultPeriod = years?.[0]?.period?.length > 1 ? 2 : 1;
    const defaultYear = years[0];
    const defaultGeography = geographyList.filter((geo) => geo.name === "canada")[0]?._id;

    setEmploymentSize(defaultEmpSize);
    setSelectedPeriod(defaultPeriod);
    setSelectedYear(defaultYear);
    setSelectedGeography(defaultGeography);
    setSubEstablishmentBusinessCount([]);


    // Update global context with default values
    // updateSettings("establishmentInsights", "sector", {
    //   employmentSize: defaultEmpSize,
    //   selectedPeriod: defaultPeriod,
    //   selectedYear: defaultYear,
    //   selectedGeography: defaultGeography,
    // });

    updateSettings("establishmentInsights", "sector", "selectedYear", defaultYear);
    updateSettings("establishmentInsights", "sector", "selectedPeriod", defaultPeriod);
    updateSettings("establishmentInsights", "sector", "employmentSize", defaultEmpSize);
    updateSettings("establishmentInsights", "sector", "selectedGeography", defaultGeography);
    // Apply filters with default values
    filterUpdated(defaultEmpSize, defaultPeriod, defaultYear, defaultGeography);
  };

  const getFilterOptions = async () => {
    setShowLoader(true);
    try {
      const [
        businessTypesResult,
        establishmentsResult,
        geographiesResult,
        yearsResult,
      ] = await Promise.all(
        [
          httpClient({
            method: "get",
            url: API_URLS.listBusinessTypes,
          }),
          httpClient({
            method: "get",
            url: API_URLS.listEstablishments,
          }),
          httpClient({
            method: "get",
            url: API_URLS.listGeographies,
          }),
          httpClient({
            method: "get",
            url: API_URLS.businessCountYearsList,
          }),
        ].map((promise) => promise.then(({ result }) => result))
      );

      const processedResults = {
        businessTypes: businessTypesResult?.businessTypes || [],
        establishments: establishmentsResult?.establishments || [],
        geographies: geographiesResult?.geographies || [],
        years: yearsResult?.years || [],
      };

      if (processedResults.businessTypes.length > 0) {
        setBusinessTypeList(processedResults.businessTypes);
      }

      if (processedResults.establishments.length > 0) {
        setEstablishmentList(processedResults.establishments);
      }

      if (processedResults.geographies.length > 0) {
        const sortedGeographies = processedResults.geographies
          .filter((geo) => geo.name.toLowerCase() !== "geography")
          .sort((a, b) => a["name"].localeCompare(b["name"]));
        const canada = sortedGeographies.filter(
          (geo) => geo.name === "canada"
        );
        const otherProvinces = sortedGeographies.filter(
          (geo) => geo.name !== "canada"
        );
        const finalSortedGeographies = [...canada, ...otherProvinces];
        setGeographyList(finalSortedGeographies);
        setSelectedGeography(canada?.[0]?._id)
      }

      if (processedResults.years.length > 0) {
        setYears(processedResults.years);
        setSelectedYear(processedResults.years[0])
      }

      return processedResults; // Return the processed results
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoader(false);
    }
  };

  const filterUpdated = (
    employmentSizeData,
    selectedPeriodData,
    selectedYearData,
    selectedGeographyData
  ) => {
    let filterString: string = "?";
    if (employmentSizeData) {
      filterString += "businessType=" + employmentSizeData + "&";
    }
    if (selectedSector) {
      filterString += "establishment=" + selectedSector + "&";
    }
    if (selectedPeriodData) {
      filterString += "period=" + selectedPeriodData + "&";
    }
    if (selectedYearData) {
      filterString += "year=" + selectedYearData.year + "&";
    }
    if (selectedGeographyData) {
      filterString += "geography=" + selectedGeographyData + "&";
    }
    filterString = filterString.slice(0, -1);

    getBusinessCountByGeography(filterString);
    // getSubEstablishmentData();
    // updateSettings("establishmentInsights", "sector", {
    //   employmentSizeData,
    //   selectedPeriodData,
    //   selectedYearData,
    //   selectedGeographyData,
    // });

    updateSettings("establishmentInsights", "sector", "selectedYear", selectedYearData);
    updateSettings("establishmentInsights", "sector", "selectedPeriod", selectedPeriodData);
    updateSettings("establishmentInsights", "sector", "employmentSize", employmentSizeData);
    updateSettings("establishmentInsights", "sector", "selectedGeography", selectedGeographyData);
  };

  useEffect(() => {
    if (joyrideStatus) {
      if (subEstablishmentBusinessCount.length > 0) {

        const parentArrayFirstPart = steps?.parentSteps?.slice(0, 2);
        const parentArraySecondPart = steps?.parentSteps?.slice(2);
        const combineArray = [...parentArrayFirstPart, ...steps?.pieChartSteps, ...parentArraySecondPart]

        const joyrideSteps = combineArray?.map((step, index) => {

          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {!stepStates[index]?.showVideo && (
                <button
                  onClick={() =>
                    setStepStates((prev) =>
                      prev.map((state, i) =>
                        i === index ? { ...state, showVideo: !state.showVideo } : state
                      )
                    )
                  }
                  style={{ outline: 'none', border: 'none' }}
                >
                  {<BiSolidVideos fontSize={20} />}
                </button>
              )}
              {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );

          const translatedContent = (<p>
            {index === 2 ? t(step.text)
              .split("\\n")
              .map((line, idx) => (
                <p key={idx}>{line}</p>
              )) : t(step.content).split('\\n').map((line, index, array) => (
                <p key={index}>{line}</p>
              ))}
          </p>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            placement: window.innerWidth <= 768 ? 'top' : step.placement,
            waitForSelector: true
          };
        })

        setSteps(joyrideSteps)
      } else {
        const joyrideSteps = steps?.parentSteps?.map((step, index) => {

          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              {!stepStates[index]?.showVideo && (
                <button
                  onClick={() =>
                    setStepStates((prev) =>
                      prev.map((state, i) =>
                        i === index ? { ...state, showVideo: !state.showVideo } : state
                      )
                    )
                  }
                  style={{ outline: 'none', border: 'none' }}
                >
                  {<BiSolidVideos fontSize={20} />}
                </button>
              )}
              {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          const translatedContent = (<>
            {t(step.content).split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            waitForSelector: true
          };
        })
        // setStepStates(new Array(joyrideSteps.length).fill({ showVideo: false }))
        setSteps(joyrideSteps)
      }

      setHelpRun(true)
    }
  }, [joyrideStatus, stepStates])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await getFilterOptions(); // Wait for getFilterOptions to complete
  //     if (state) {
  //       const { id, empSize, period, year } = state || {};
  //       const selectedEmpSize = empSize !== undefined ? empSize : "null";
  //       const selectedPeriod = period !== undefined ? period : result?.years[0].period.length > 1 ? 2 : 1;
  //       const selectedYear = year !== undefined ? year : result?.years[0];
  //       const selectedGeographyId = result?.geographies.filter(
  //         (geo) => geo?.name.toLowerCase() === id.toLowerCase()
  //       )[0]?._id;

  //       setEmploymentSize(selectedEmpSize);
  //       setSelectedPeriod(selectedPeriod);
  //       setSelectedYear(selectedYear);
  //       setSelectedGeography(selectedGeographyId);

  //       filterUpdated(
  //         selectedEmpSize,
  //         selectedPeriod,
  //         selectedYear,
  //         selectedGeographyId
  //       );
  //     } else {
  //       if (userSetting?.settings?.establishment?.sectorInsights) {
  //         const empSize = userSetting?.settings?.sectorInsights?.establishment?.employmentSize;
  //         const period = userSetting?.settings?.establishment?.sectorInsights?.year ? JSON.parse(userSetting?.settings?.establishment?.sectorInsights?.year).period.some(
  //           (i) => i.value === Number(userSetting?.settings?.establishment?.sectorInsights?.period) // Check if period value matches
  //         )
  //           ? userSetting?.settings?.establishment?.sectorInsights?.period // If match found, use the period
  //           : 1
  //           : null;
  //         const year = userSetting?.settings?.establishment?.sectorInsights?.year ? JSON.parse(userSetting?.settings?.establishment?.sectorInsights?.year) : result?.years[0];
  //         const geography = userSetting?.settings?.establishment?.sectorInsights?.geography ? userSetting?.settings?.establishment?.sectorInsights?.geography : result?.geographies.filter(
  //           (geo) => geo?.name.toLowerCase() === "canada"
  //         )[0]?._id;

  //         setEmploymentSize(empSize);
  //         setSelectedPeriod(period);
  //         setSelectedYear(year);
  //         setSelectedGeography(geography);
  //         filterUpdated(empSize, period, year, geography);
  //       }
  //       else {
  //         filterUpdated(
  //           employmentSize,
  //           selectedPeriod,
  //           selectedYear,
  //           result?.geographies.filter(
  //             (geo) => geo?.name.toLowerCase() === "canada"
  //           )[0]?._id
  //         );
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [state]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getFilterOptions(); // Wait for getFilterOptions to complete

      if (state) {

        const { id, empSize, period, year } = state || {};
        const selectedEmpSize = empSize !== undefined ? empSize : "null";
        const selectedPeriod = period !== undefined ? period : result?.years[0].period.length > 1 ? 2 : 1;
        const selectedYear = year !== undefined ? year : result?.years[0];
        const selectedGeographyId = result?.geographies.filter(
          (geo) => geo?.name.toLowerCase() === id.toLowerCase()
        )[0]?._id;

        setEmploymentSize(selectedEmpSize);
        setSelectedPeriod(selectedPeriod);
        setSelectedYear(selectedYear);
        setSelectedGeography(selectedGeographyId);


        // updateSettings("establishmentInsights", "sector", {
        //   employmentSize: selectedEmpSize,
        //   selectedPeriod: selectedPeriod,
        //   selectedYear: selectedYear,
        //   selectedGeography: selectedGeographyId,
        // });


        updateSettings("establishmentInsights", "sector", "selectedYear", selectedYear);
        updateSettings("establishmentInsights", "sector", "selectedPeriod", selectedPeriod);
        updateSettings("establishmentInsights", "sector", "employmentSize", selectedEmpSize);
        updateSettings("establishmentInsights", "sector", "selectedGeography", selectedGeographyId);


        filterUpdated(
          selectedEmpSize,
          selectedPeriod,
          selectedYear,
          selectedGeographyId
        );
      } else {

        // Retrieve default settings for the "sector" sub-tab
        const sectorSettings = defaultSettings?.find(mainTab => mainTab?.mainTab === "establishmentInsights")?.subTabs.find(subTab => subTab?.subTab === "sector")?.settings;
        if (sectorSettings) {

          // Set default values from context or fallback to API results
          const empSize = sectorSettings?.employmentSize || "";
          const period = sectorSettings?.selectedPeriod || (result?.years[0]?.period?.length > 1 ? 2 : 1);
          const year = sectorSettings?.selectedYear || result?.years[0];
          const geography = sectorSettings?.selectedGeography || result?.geographies.filter(
            geo => geo?.name.toLowerCase() === "canada"
          )[0]?._id;

          // Update state with default values
          setEmploymentSize(empSize);
          setSelectedPeriod(period);
          setSelectedYear(year);
          setSelectedGeography(geography);

          // Apply filters with default values from context
          filterUpdated(empSize, period, year, geography);
        } else {
          // // Apply filters with default values
          const defaultEmpSize = "";
          const defaultPeriod = result?.years[0]?.period?.length > 1 ? 2 : 1;
          const defaultYear = result?.years[0];
          const defaultGeography = result?.geographies.filter(
            geo => geo?.name.toLowerCase() === "canada"
          )[0]?._id;

          // Update state with default values
          setEmploymentSize(defaultEmpSize);
          setSelectedPeriod(defaultPeriod);
          setSelectedYear(defaultYear);
          setSelectedGeography(defaultGeography);

          updateSettings("establishmentInsights", "sector", {
            employmentSize: defaultEmpSize,
            selectedPeriod: selectedPeriod,
            selectedYear: selectedYear,
            selectedGeography: defaultGeography,
          });
          // Apply filters with default values from API results
          filterUpdated(defaultEmpSize, defaultPeriod, defaultYear, defaultGeography);
        }

        // filterUpdated(empSize, period, year, geography);
      }

    };

    fetchData();
 
  }, []);

  const [imageSrc, setImageSrc] = useState(null);
  const getSubEstablishmentData = (index: number) => {
    setShowLoader(true);
    setImageSrc(null);
    setSubEstablishmentBusinessCount([])
    setSelectedBarIndex(index);

    let filterString: string = "?";
    if (establishmentBusinessCount[index]?._id) {
      filterString +=
        "establishment=" + establishmentBusinessCount[index]?._id + "&";
      import(
        `./../../assets/sector-icons/New folder/NAICS ${establishmentBusinessCount[index]?.externalId}.png`
      )
        .then((module) => {
          setImageSrc(module.default);
        })
        .catch((error) => {
          console.error("Error loading image:", error);
        });
    } else {
      setShowLoader(false);
      setImageSrc(null);
      setSubEstablishmentBusinessCount([]);
      return;
    }
    if (employmentSize) {
      filterString += "businessType=" + employmentSize + "&";
    }
    if (selectedPeriod) {
      filterString += "period=" + selectedPeriod + "&";
    }
    if (selectedYear) {
      filterString += "year=" + selectedYear?.year + "&";
    }
    if (selectedGeography) {
      filterString += "geography=" + selectedGeography + "&";
    }
    filterString = filterString.slice(0, -1);
    let url = API_URLS.businessCountByChildEstablishment + filterString;
    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts && result.counts.length) {
            setSubEstablishmentBusinessCount(result.counts);

          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getBusinessCountByGeography = (filters: string = "") => {
    setShowLoader(true);
    let url = API_URLS.businessCountByParentEstablishment + filters;

    httpClient({
      method: "get",
      url,
    })
      .then(({ result, error }) => {
        if (result) {
          if (result.counts && result.counts.length) {
            setEstablishmentBusinessCount(result.counts);
            const labels = result.counts.map(
              (item) => `${item.externalId}`
            );
            const labels2 = result.counts.map(
              (item) => `NAICS ${item.externalId}`
            );
            const titles = result.counts.map((item) => item.name);
            const values = result.counts.map((item) => item.totalCount);
            const industryNames = result.counts.map((item) => item.languageKey);

            setResponse({ labels, labels2, values, titles, colors, industryNames });
          } else {
            setResponse({});
          }
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      filterUpdated(
        employmentSize,
        selectedPeriod,
        selectedYear,
        selectedGeography
      );

      updateSettings("establishmentInsights", "sector", {
        employmentSize,
        selectedPeriod,
        selectedYear,
        selectedGeography,
      });
    }
    let url = API_URLS.preloadedSettings;
    let data = {
      settings: {
        ...userSetting?.settings,
        sectorInsights: {
          geography: selectedGeography,
          year: JSON.stringify(selectedYear),
          period: selectedPeriod,
          employmentSize: employmentSize,
        },
      },
    };
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }, [selectedPeriod, selectedYear, selectedGeography, employmentSize]);



  return (
    <>
      <Loader show={showLoader} />
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      />
      <div className="row gx-2">
        <div className="col-xl-9 col-md-12">
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="flex-space-between">
                  <h3>{t("commonHeadingFilters")}</h3>
                  <div className="d-flex align-items-center">
                    <button className="clear_btn" onClick={clearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
                      {t("commonSubHeadingClearAll")}
                    </button>

                  </div>
                </div>
              </Card.Title>
              <div className="sector_filter_container">
                <div className="row gx-3">
                  <div className="col-xl-3 col-md-6">
                  { (isLoggedIn() ?  userPlan?.subscriptionPlanId?.features?.establishmentInsights?.bySector?.businessSize == 'limited' : defaultPlan?.features?.establishmentInsights?.bySector?.businessSize == 'limited') &&
                    <div className="custom-form-group">
                    <label
                      htmlFor="employmentSizeSelect"
                      className="text-black"
                      style={{ fontSize: "13px" }}
                    >
                      {t("commonInputTitleEmploymentSize")}:
                    </label>
                    <select
                                             className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"

                      value={employmentSize}
                      onChange={handleEmploymentSizeChange}
                    >
                      <option value={""} selected>
                        {t("businessTypeTotalWithAndWithoutEmployees")}
                      </option>
                      <option value={"null"}>
                        {t("businessTypeTotalWithoutEmployees")}
                      </option>
                      {businessTypeList.filter(type => type.name == "total, with employees").map((type) => (
                        <option value={type._id}>
                          {capitalizeFirstLetter(t(type.languageKey))}
                        </option>
                      ))}
                    </select>
                  </div>
                    }
                    {(isSuperAdmin ||( isLoggedIn() ?  userPlan?.subscriptionPlanId?.features?.establishmentInsights?.bySector?.businessSize == 'full' :  defaultPlan?.features?.establishmentInsights?.bySector?.businessSize == 'full')) &&
                      <div className="custom-form-group">
                      <label
                        htmlFor="employmentSizeSelect"
                        className="text-black"
                        style={{ fontSize: "13px" }}
                      >
                        {t("commonInputTitleEmploymentSize")}:
                      </label>
                      <select
                                               className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"

                        value={employmentSize}
                        onChange={handleEmploymentSizeChange}
                      >
                        <option value={""} selected>
                          {t("businessTypeTotalWithAndWithoutEmployees")}
                        </option>
                        <option value={"null"}>
                          {t("businessTypeTotalWithoutEmployees")}
                        </option>
                        {businessTypeList.map((type) => (
                          <option value={type._id}>
                            {capitalizeFirstLetter(t(type.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                    }
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="GeographySelect" className="text-black" style={{ fontSize: '13px' }}>
                        {t("commonInputTitleGeography")}
                      </label>
                      <select
                        className="form-select c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={selectedGeography}
                        onChange={handleGeograpgyChange}
                      >
                        <option disabled>{t("commonTextSelect")}</option>
                        {geographyList.map((geography) => (
                          <option value={geography._id}>
                            {capitalizeString(t(geography.languageKey))}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="periodSelect" className="text-black" style={{ fontSize: '13px' }}>
                        {t("commonInputTitlePeriod")}
                      </label>
                      <select
                        className="form-select  c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelect")}{" "}
                        </option>
                        {selectedYear && selectedYear?.period?.map((item, index) => (
                          <option key={index} value={item.value}>
                            {(() => {
                              switch (item.value) {
                                case 1:
                                  return t("commonPeriodInputValueJune"); // June
                                case 2:
                                  return t("commonPeriodInputValueDecember"); // December
                                // You can add more cases for other month values if needed
                                default:
                                  return t("commonPeriodInputValueUnknown"); // Fallback or unknown value
                              }
                            })()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="custom-form-group">
                      <label htmlFor="yearSelect" className="text-black" style={{ fontSize: '13px' }}>
                        {t("commonInputTitleYear")}
                      </label>
                      <select
                        className="form-select  c-form-control fw-semibold c-form-control-sm  c-form-control-bg"
                        value={JSON.stringify(selectedYear)}
                        onChange={handleYearChange}
                      >
                        <option value={""} disabled>
                          {t("commonTextSelectYear")}
                        </option>
                        {
                          years.map((year, index) => (
                            <option key={index} value={JSON.stringify(year)}>
                              {year.year}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <BarChartHighChart
                dataset={response}
                title={t("commonHeadingSectors")}
                barClick={getSubEstablishmentData}
              />
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-3 col-md-12 col-sm-12 col-lg-4">
          <Card className="insight_card">
            <Card.Body>
              <Card.Title className="w-auto d-inline-block">
                <h3 style={{ fontSize: "15px" }}>{t("commonTitleInsights")}</h3>
              </Card.Title>

              {!!subEstablishmentBusinessCount?.length && (
                <PieChart
                  dataset={subEstablishmentBusinessCount}
                  // subEstablishmentBusinessCount={subEstablishmentBusinessCount}
                  imageSrc={imageSrc}
                  selectedBarIndex={selectedBarIndex}
                  color={
                    selectedBarIndex !== undefined
                      ? colors[selectedBarIndex]
                      : "#E61E28"
                  }
                // onMount={handleChildMount}
                />
              )}
              {!subEstablishmentBusinessCount.length && (
                <ListGroup>
                  <ListGroup.Item>
                    {t("dashboard2InsightsMessage")}
                  </ListGroup.Item>
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
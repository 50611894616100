const API_URLS = {
  login: `/auth/login-with-password`,
  saveUserSettings :'/user/save-user-settings',
  fetchUserSettings :'/user/get-user-settings',
  editUserSettings : '/user/edit-user-settings',
  deleteUserSettings : '/user/delete-user-settings',
  setUserSettingsDefault :'/user/set-default-settings',
  saveAsUserSettings :'/user/saveAs-user-settings',
  forgotPassword:'/user/forgot-password',
  checkTokenExpired :'/user/checkTokenExpired',
  businessCountByGeography: `/business-count/by-geography`,
  businessCountYearsList: `/business-count/years/list`,
  businessCountByParentEstablishment: `/business-count/by-parent-establishment`,
  businessCountByChildEstablishment: `/business-count/establishments-by-parent-establishment`,
  listBusinessTypes: `/business-type/list`,
  listEstablishments: `/establishment/list?isParent=false`,
  listParentAndChildren: `/establishment/listParentAndChildren`,
  listAreaOfStudy: `/educational-program/list-areaOfStudy`,
  countAreaOfStudy: `/educational-program/count-area-of-study?province={province}&levelOfInstruction={levelOfInstruction}&apprentice={apprentice}`,
  listLevelOfInstruction: `/educational-program/list-levelOfInstruction`,
  listStudyProgramsLanguage: `/educational-program/list-language`,
  listGeographies: `/geography/list`,
  listDocuments: `/document/list`,
  verifyDocument: `/document/verify`,
  uploadFile: `/file/upload/:type`,
  uploadStepHelpVideo: `/file/uploadHelpVideo`,
  updateStepHelpVideo: `/file/updateHelpVideo`,
  deleteStepHelpVideo: `/file/deleteHelpVideo`,
  downloadFile: `file/downloadFile/:fileId?type={type}`,
  businessWithEmployees: "/file/canadian-business-count-with-employee-sizes",
  businessWithoutEmployees:
    "/file/canadian-business-count-without-employee-sizes",
  fpscEstablishments: "/file/canada-fpsc-establishments",
  // educationalProgramUpload: "/file/educational-program",
  // employmentDataUpload: "/file/employment-data",
  employmentDataYears: "/employment-count/years",
  employmentDataByProvince: "/employment-count/by-geography?year={year}",
  employmentDataByEstablishment:
    "/employment-count/by-parent-establishment?year={year}",
  getUsers: "/user/getUsers",
  getUserTypes: "/user/getUserTypes",
  addNewUserType: "/user/addUserType",
  updateUserPermissions: "/user/updateUserPermissions",
  getPermissions: "/user/getPermissions?role={role}",
  userSignUp: "/user/signUp",
  userUpdateProfile: "/user/update-profile",
  addUser: "/user/addUser",
  setPassword: "/user/setNewUserPassword",
  updateUser: "/user/updateUser",
  deleteUser: "/user/deleteUser",
  changePassword: "/user/change-password",
  resetPassword: "/auth/reset-password",
  verifyNewUserToken: "/user/verifyUserToken",
  resendTokenAdmin: "/user/resendInvitationToken",
  resendToken: "/user/resendToken",
  educationalProgramUpload: "/file/canada-educational-programs",
  employmentDataUpload: "/file/canada-employment-data",
  genderAgeBreakdown: "/file/canada-gender-age-breakdown",
  immigrantBreakdown: "/file/canada-immigration-breakdown",
  compareCharts: "/business-count/comparison-by-parent-establishment",
  educationComparison: "/comparison/education-programs",
  employmentComparison: "/comparison/employment",
  educationalProgram: "/educational-program/list-by-geography",
  // educationalProgram: "/educational-program/list-by-geography?fieldOfStudy={fieldOfStudy}",
  eductaionalProgramByInstitution:
    "/educational-program/list-by-level-of-instruction?province={province}&city={city}&language={language}",
  eductaionalProgramByApprenticeship:
    "/educational-program/list-by-geography-with-apprenticeship-offer",
  preloadedSettings: "/user/settings",
  employmentCountPerYear:
    "/employment-count/by-parent-establishment-all-years?establishment={establishment}&from={yearFrom}&to={yearTo}",
  educationalListPagination:
    "/educational-program/list?fieldOfStudy={fieldOfStudy}&province={province}&city={city}&program={program}&duration={duration}&apprentice={apprentice}&language={language}&page={page}&limit={limit}",
  employmentRaceByYears: "/employment-count/by-years?year={year}",
  employmentByGender: "/age-gender-breakdown/count-by-establishment?establishment={establishment}&range={range}",
  employmentByGenderRange:
    "/age-gender-breakdown/count-by-age-range?establishment={establishment}",
  employmentByImmigrants: "/immigrants-breakdown/count-by-immigrants?establishment={establishment}",
  addTableSettings: "/addTableSettings",
  getTableSettings: "/getTableSettings",
  uploadTranslationFile: "/file/uploadTranslationFile",
  getTranslationFile: "/file/getTranslation?language={language}",
  getTranslationFileInfo: "/file/getTranslationFileInfo",
  getJoyrideSteps: "/file/helpSteps",

  addCardToStripe: "/stripe/attach-card",
  fetchCustomerCards: "/stripe/all-cards",
  detachPaymentMethod: "/stripe/detach-card",
  setDefaultCard: "/stripe/make-card-default",
  createPaymentIntent: "/stripe/create-payment-intent",

  getUserPlan: "/subscription/get-user-subscription",
  addUserSubscriptionPlan: "/subscription/add-user-subscription",
  cancelUserSubscription: "/subscription/cancel-user-subscription",
  upgradePlan : "/subscription/upgradePlan",
  getUpcomingPlans : "/subscription/getUpcomingPlans",
  getPaymentHistory:'/subscription/getPaymentHistory',
  invoice:'/subscription/invoice',

  addSubscriptionPlan: "/subscription/add-subscription-plan",
  updateSubscriptionPlan: "/subscription/update-subscription-plan",
  deleteSubscriptionPlan: "/subscription/delete-subscription-plan",
  getSubscriptionPlans: "/subscription/get-subscription-plans",
  updateSubscriptionFeatures: "/subscription/update-subscription-feature",
  getActiveSubscribersCount:"/subscription/getActiveSubscribersCount",
  getSubscriptionPlanDetail: '/subscription/getSubscriptionPlanDetail',
  search: '/subscription/search',

  addFeature: "/feature/add-feature",
  getAllFeatures: "/feature/features",

   //default plan 
  defaultSubscriptionPlan  :'/subscription/fetchDefaultPlan'
};
export default API_URLS;
import React, { useEffect, useState } from "react";
// import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { isLoggedIn } from "../../pages/utils/authUtils";


const PublicLayout = ({ component: Component }: any) => {


  const { pathname } = useLocation();

  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
    // Block access if logged in user tries to access public auth routes
    if (isLoggedIn() && 
        ["/login", "/signup", "/forgot-password", "/set-password"].includes(pathname)) {
        
         navigate(-1); // Stay on current page
          return;
    }
  }, [pathname ,navigate]);

  useEffect(() => {

    let user = localStorage.getItem("user");
    if (user) {
      let parsedUser = JSON.parse(user);
      setUser(parsedUser);
      //navigate("/employment-data");
    }
  }, []);



  // if (user) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         width: "100%",
  //         height: "70vh",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <RotatingLines
  //         strokeColor="#279AF1"
  //         strokeWidth="3"
  //         animationDuration="0.75"
  //         width="52"
  //         visible={true}
  //       />
  //     </div>
  //   );
  // }
  return (
    <>
      <main role="main">
        <div>
          <Component />
        </div>
      </main>
    </>
  );
};

export default PublicLayout;

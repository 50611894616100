import React, { useEffect, useState } from "react";
import FooterLayout from "../footer";
import { useLocation, useNavigate, Navigate } from "react-router";
import { RotatingLines } from "react-loader-spinner";
import Header from "../../pages/dashboard/components/Header.tsx";
import Sidebar from "./components/Sidebar.tsx";
import { useTranslation } from "react-i18next";
import { getDefaultPlan, isLoggedIn } from "../../pages/utils/authUtils.js";
import { useUser } from "../../context/UserContext.js";

// import Header from "../header/header";
interface User {
  role: string;
  // Add other user properties as needed
}
const roleBasedGuard = (allowedRoles: any, userRole: any) => {
  if (allowedRoles.includes(userRole)) {
    return true;
  }
  return false;
};
const DefaultLayout = ({ component: Component, meta }: any) => {
  const { t } = useTranslation()
  const { pathname } = useLocation();
  const navigate = useNavigate();

    const {  updateDefaultPlan } = useUser()
  
    const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [pathname]);
  const [user, setUser] = useState<User | null>(null);

  // useEffect(() => {
  //   const fetchDefaultPlan = async () => {
  //     let user = localStorage.getItem("user");
  
  //     if (user) {
  //       try {
  //         let parsedUser = JSON.parse(user);
  //         setUser(parsedUser);
  //       } catch (error) {
  //         console.error("Error parsing user data:", error);
  //       }
  //     } else {
  //       try {
  //         const defaultPlan = await getDefaultPlan(); // No need in dependency array
  //         localStorage.setItem("returnUrl", pathname);
  //         updateDefaultPlan(defaultPlan);
  //       } catch (error) {
  //         console.error("Error fetching default plan:", error);
  //       }
  //       navigate("/establishment-data",{ replace: true });
  //     }
  
  //     console.log("useEffect executed");
  //   };
  
  //   fetchDefaultPlan();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);
  

  
  // if (!user) {
  //   return null;
  // }

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!isLoggedIn()) {
          // Only fetch default plan if we don't have it
          if (!localStorage.getItem('defaultPlan')) {
            const defaultPlan = await getDefaultPlan();
            updateDefaultPlan(defaultPlan);
          }
          
          // Don't navigate if we're already on establishment-data
          if (pathname !== "/establishment-data") {
            navigate("/establishment-data");
          }
        }
      } catch (error) {
        console.error("Error initializing default layout:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [t, pathname]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  
  return (
    <>
    {/* {roleBasedGuard(meta.allowedRoles, userRole) ? ( */}
      <main role="main" className="main" style={{overflow: "hidden"}}>
       <Sidebar />
        <div className="mainBodyWrapper" id="mainBodyWrapper" style={{ height: '100vh'}}>
          <Component></Component>
        </div>
        {/* <FooterLayout></FooterLayout> */} 
      </main>
    {/* ) : (
      <Navigate to="/" />
    )} */}
  </>
  );
};

export default DefaultLayout;

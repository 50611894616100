import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext";
import UserAccountDetails from "../pages/account/UserAccountDetails.tsx"
import SubscriptionDetails from "../components/SubscriptionStripeWrapper.tsx";
import ChangePassword from "../pages/settings/ChangePassword.tsx";
import PaymentHistory from "../pages/account/PaymentHistory.tsx";
import UserSettings from "../pages/settings/UserSettings.tsx";

const TabComponent = () => {
  const { t } = useTranslation()
  const { user, userPlan } = useUser()

  const [key, setKey] = useState<string | null>('tab1'); // State to manage active tab

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
        <Tabs className="custom_tab" id="controlled-tab" activeKey={key ?? 'tab1'} onSelect={(k) => setKey(k)}>
          {/* {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( */}
            <Tab eventKey="tab1" title={t('accounts.tabAccountDetails.title')} className="tab_container mt-2" />
            <Tab eventKey="tab2" title={t('accounts.tabSubscriptionDetails.title')} className="tab_container mt-2"/>
            <Tab eventKey="tab3" title={t('accounts.tabChangePassword.title')} className="tab_container mt-2"/>
            <Tab eventKey="tab4" title={t('accounts.tabPaymentHistory.title')} className="tab_container mt-2"/>
            {/* //<Tab eventKey="tab5" title='Dashboard Settings' className="tab_container mt-2"/> */}
          {/* )} */}
          {/* {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab2" title={t('settings.tabTitle.userRolePermission')} className="tab_container mt-2"/>
             
          )}
          <Tab eventKey="tab3" title={t('settings.tabTitle.changePassword')} className="tab_container mt-2"/>
           
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab4" title={t("settings.tabTitle.tableSettings")} className="tab_container mt-2"/>
              
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab5" title={t("settings.tabTitle.translation")} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab6" title={t("settings.tabTitle.helpVideoUpload")} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab7" title={"Feature"} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab8" title={"Subscription"} className="tab_container mt-2"/>
          )} */}
        </Tabs>
      </div>
      <div className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>
        {key === "tab1" && (
            <UserAccountDetails />
        )}
         {key === "tab2" && (
            <SubscriptionDetails />
        )}
        {key === "tab3" && (
            <ChangePassword />
        )}
         {key === "tab4" && (
            <PaymentHistory />
        )}
        {/* {key === "tab2" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <UserRole />
        )}
        {key === "tab3" && (<ChangePassword />)}
        
        {key === "tab4" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
          <TableSetting />
        )}
        {key === "tab5" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <TranslationManager />
        )}
        {key === "tab6" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <StepHelpUpload />
        )}
        {key === "tab7" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <FeaturePage />
        )}
         {key === "tab8" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <SubscriptionManagement />
        )} */}
      </div>
    </div>
  );
};

export default TabComponent;

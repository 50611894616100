import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
import logoblack from "../../assets/fpsc-logo.png";
import httpClient from "../../services/httpClient.ts";
import loginGraphic from '../../assets/login-graphic.png';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../../constants/apiUrls.ts";
import styles from "./Login.module.css";
import { Modal, Button } from "react-bootstrap";
import { Loader } from "../../components/Loader.tsx";
import { useTranslation } from "react-i18next";


const ForgotPassword = () => {
  const { t ,i18n} = useTranslation();
     const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });


 const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, watch, formState: { errors: errorsForm2 } } = useForm();

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
    const storedLanguage = localStorage.getItem("loginLanguage") || "en";
    const [language, setLanguage] = useState(storedLanguage);

      useEffect(() => {
        if (showSuccessModal) {
          const timer = setTimeout(() => {
            setShowSuccessModal(false);
            
          }, 5000); // 5 seconds
    
          return () => clearTimeout(timer); // Cleanup the timer on component unmount
        }
      }, [showSuccessModal, setShowSuccessModal]);

      const fetchTranslations = async (lang) => {
        let url = API_URLS.getTranslationFile.replace("{language}", lang)
        const response = httpClient({
          method: "get",
          url:`${url}&isLoggedIn=${false}`,
        })
        .then(({ result, error }) => {
          if (error) {
            return // Call with error if the request fails
          }
          return result
        })
        
        return response;
      };
    
        const loadTranslations = async (lang) => {
          const translations = await fetchTranslations(lang);
          if(translations) {
            i18n.addResourceBundle(lang, 'translation', translations);
            i18n.changeLanguage(lang);
            localStorage.setItem("loginLanguage", lang);
          } 
      }
      
        useEffect(() => {
          loadTranslations(language)
        }, [language]);

        const handleForgotPassword = async ( data) => {
      

            try{
              setIsLoading(true);
                const url = API_URLS.forgotPassword;
                const { result, error } = await httpClient({
                    method: "post",
                    url,
                    data,
                  });
              
                  if (!result?.status) {
                    toast.error(error?.message || "Action failed.");
                    return;
                  }
                  if(result?.status){
                    setShowSuccessModal(true);
                    reset()
                  }
            }catch(err:any){
                toast.error(err.message || "An error occurred ,please try again");

          } finally {
            setIsLoading(false);
          }
        }


    return (
        <>

        <Loader show={isLoading} />
        <div className={styles.login_container}>
        <div className={styles.login_header}>
            <div className={`${styles.headerContainer} container-fluid`}>
              <img src={logoblack} alt="" style={{ width: "154px" }} />
              <div className={styles.languageSelector}>
        {/* //<label className={styles.languageLabel}>Lang:</label> */}
        <select
          className={styles.languageDropdown}
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>
            </div>
            <div>
            </div>
          </div>
          <div className={`container`}>
       
            <div className="row no-gutters" style={{ padding: "0 20 !important" }}>
              <div
                className={`align-items-center bg-white col-md-7 d-flex`}
              >
                <div className={styles.login_form}>
               
                    <div className="brand form-style">
                       <h1>
                  
                  {t("login.headerTitle")}
                </h1>
                <p className={styles.login_form_info}>
                
                  {t("forgotPassword.subHeaderTitle")}
                </p>
  
                <Form className="mb-5" onSubmit={handleSubmit(handleForgotPassword)}>
                        <div className="custom-form-group">
                          <label>{t("login.formLabel.email")} *</label> 
                          <input
                            type="text"
                            placeholder={`${t('signup.enter')} ${t('login.formLabel.email')}`}
                            className="c-form-control c-form-control-lg"
                            id="InputEmail"
                            aria-describedby="emailHelp"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
  
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email?.message?.toString()}
                            </div>
                          )}
                        </div>
               
                        <button type="submit" className="button">
                         {t("setPassword.formLabel.submit")} 
                        </button>
                      </Form>
                      <p>
                    {t("forgotPassword.rememberYourPassword")}{" "} 
                      <span
                        className={styles.toggle_link}
                        style={{ color: "#e61e28", cursor: "pointer" }}
                        onClick={() => navigate('/')}
                      >
                             {t("forgotPassword.LoginHere")}
                      </span>
                    </p>
                      
                    </div>
                  
                </div>
              </div>
              <div className="col-md-5">
                <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
              </div>
            </div>
          </div>
        </div>
        <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
          <Modal.Header>
            <Modal.Title> {t('subscription.toast.success')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('forgotPassword.successMessage')}</p>
          </Modal.Body>
          
        </Modal>
      </>
    )

}

export default ForgotPassword;
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useUser } from '../../context/UserContext'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import httpClient from '../../services/httpClient.ts'
import API_URLS from '../../constants/apiUrls.ts'
import { useTranslation } from 'react-i18next'

const UserAccountDetails: React.FC = () => {
    const { user, updateUser } = useUser()
    const { t } = useTranslation()
    const [showUpdateProfileModal, setShowUpdateProfileModal] = useState<boolean>(false)
    const {
        register,
        clearErrors,
        setValue,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({ mode: "all" });

    const handleUpdateProfileModal = () => {
        if (!user) {
            throw new Error("user not found");
            return
        }
        setValue('firstName', user?.firstName ?? "")
        setValue('lastName', user?.lastName ?? "")
        setValue('organization', user?.organization ?? "")
        setValue('jobTitle', user?.jobTitle ?? "")
        setValue('phone', user?.phone ?? "")
        setValue('ext', user?.ext ?? "")
        setValue('mailingAddress', user?.mailingAddress ?? "")
        setValue('preferredLanguage', user?.preferredLanguage ?? "")
        setShowUpdateProfileModal(true)
    }

    const handleUpdateProfile = async (data) => {
        if (!user) {
            throw new Error("user not found");
            return
        }
        const updatedData = {
            ...data,
            userId: user?._id
        }
        httpClient({
            method: "post",
            url: API_URLS.userUpdateProfile,
            data: updatedData,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error.message);
                    return
                }
                if (result.status) {
                    updateUser(result.user)
                    reset()
                    setShowUpdateProfileModal(false)
                    toast.success(result.message);
                } else {
                    toast.error(result.message)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error(error.message)
            })
    }

    return (
        <>

            <div className="row gap-2 w-100">
                {/* Profile Details Section */}
                <div className="col-12 col-md-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="font-semibold mb-4">{t('accounts.tabAccountDetails.heading')}</h5>

                            {/* Row for First Name and Last Name */}
                            <div className="row">
                                {/* First Name */}
                                <div className="col-12 col-md-3 custom-form-group" style={{ marginBottom: "5px" }}>
                                    <label>{t('accounts.tabAccountDetails.inputTitle.name')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputName"
                                        value={user?.lastName ? (user?.firstName + ' ' + user?.lastName || '') : (user?.firstName || '')}
                                        disabled
                                    />
                                </div>

                                <div className="mb-4 col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.email')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputEmail"
                                        value={user?.email || ''}
                                        disabled
                                    />
                                </div>
                                <div className="mb-4 col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.organization')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputOrganization"
                                        value={user?.organization || ''}
                                        disabled
                                    />
                                </div>
                                <div className="mb-4 col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.jobTitle')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputJobTitle"
                                        value={user?.jobTitle || ''}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.phone')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputPhone"
                                        value={user?.phone || ''}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.ext')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputExt"
                                        value={user?.ext || ''}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.mailingAddress')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputMailingAddress"
                                        value={user?.mailingAddress || ''}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 col-md-3 custom-form-group">
                                    <label>{t('accounts.tabAccountDetails.inputTitle.preferredLanguage')}</label>
                                    <input
                                        type="text"
                                        className="c-form-control c-form-control-lg"
                                        id="InputPreferredLanguage"
                                        value={(user?.preferredLanguage == 'en' ? "English" : user?.preferredLanguage == 'fr' ? "French" : "") || ''}
                                        disabled
                                    />
                                </div>

                                {/* <div className="row justify-content-end">
                                <div className="col-md-4">
                                    <div className="d-flex">
                                        <button className="btn btn-primary w-100 me-2">Edit Profile</button>
                                        <button className="btn btn-primary w-100">Change Password</button>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                            <div className='row justify-content-start'>
                                <div className="col-md-3">
                                    <button type="button" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={handleUpdateProfileModal}>{t('accounts.tabAccountDetails.button.editProfile')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal
                show={showUpdateProfileModal}
                onHide={() => {setShowUpdateProfileModal(false);reset()}}
                backdrop="static"
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('accounts.tabAccountDetails.modalEditProfile.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => {
                        e.preventDefault(); // Prevent default submission
                        handleSubmit(handleUpdateProfile)(e); // Call handleSubmit and pass the event
                    }}>
                        <div className='row'>
                            <div className="col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.firstName')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className={`form-control ${errors.firstName ? "is-invalid" : "is-valid"}`}
                                    id="InputFirstName"
                                    placeholder='Enter First Name'
                                    // value={user?.lastName ? (user?.firstName + ' ' + user?.lastName || '') : (user?.firstName || '')}
                                    {...register("firstName", {
                                        required: "First Name is required",
                                    })}
                                />
                                {errors.firstName && (
                                    <div className="invalid-feedback">{errors.firstName.message?.toString()}</div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.lastName')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    className={`form-control ${errors.lastName ? 'is-invalid' : 'is-valid'}`}
                                    placeholder='Enter Last Name'
                                    id="InputLastName"
                                    // value={user?.lastName ? (user?.firstName + ' ' + user?.lastName || '') : (user?.firstName || '')}
                                    {...register("lastName")}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-4 col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.organization')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    placeholder="Enter Organization"
                                    className={`form-control ${errors.organization ? "is-invalid" : watch('organization') ? "is-valid" : "is-invalid"}`}
                                    id="InputOrganization"
                                    {...register("organization", {
                                        required: "Organization is required",
                                    })}
                                />
                                {errors.organization && (
                                    <div className="invalid-feedback">{errors.organization.message?.toString()}</div>
                                )}
                            </div>
                            <div className="mb-4 col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.jobTitle')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    placeholder="Enter Job Title"
                                    className={`form-control ${errors.jobTitle ? "is-invalid" : watch('jobTitle') ? "is-valid" : "is-invalid"}`}
                                    id="InputJobTitle"
                                    {...register("jobTitle", {
                                        required: "Job Title is required",
                                    })}
                                />
                                {errors.jobTitle && (
                                    <div className="invalid-feedback">{errors.jobTitle.message?.toString()}</div>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.phone')}</label>
                                <input
                                    type="text"
                                    placeholder="Enter Phone No"
                                    autoComplete="off"
                                    className={`form-control ${
                                        errors.phone ? "is-invalid" : watch("phone") && watch("phone").trim() ? "is-valid" : "is-invalid"
                                      }`}
                                    id="InputPhone"
                                    {...register("phone", {
                                        required: "Phone is required",
                                    })}
                                />
                                {errors.phone && (
                                    <div className="invalid-feedback">{errors.phone.message?.toString()}</div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.ext')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    placeholder="Enter Ext"
                                    maxLength={5}
                                    className={`form-control ${errors.ext ? "is-invalid" : "is-valid"}`}
                                    id="InputExt"
                                    {...register("ext")}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12 col-md-12 custom-form-group">
                                <label>{t('accounts.tabAccountDetails.inputTitle.mailingAddress')}</label>
                                <input
                                    type="text"
                                    autoComplete='off'
                                    placeholder="Enter Mailing Address"
                                    className={`form-control ${errors.mailingAddress ? "is-invalid" : watch('mailingAddress') ? "is-valid" : "is-invalid"}`}
                                    id="InputMailingAddress"
                                    {...register("mailingAddress", {
                                        required: "Mailing Address is required",
                                    })}
                                />
                                {errors.mailingAddress && (
                                    <div className="invalid-feedback">{errors.mailingAddress.message?.toString()}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 custom-form-group">
                            <label>{t('accounts.tabAccountDetails.inputTitle.preferredLanguage')}</label>
                            <select
                                className={`form-control ${errors.preferredLanguage ? "is-invalid" : "is-valid"}`}
                                style={{ height: "48px" }}
                                id="InputPreferredLanguage"
                                {...register("preferredLanguage", {
                                    required: {
                                        value: true,
                                        message: "Required",
                                    },
                                })}
                            >
                                <option value="">{t('accounts.tabAccountDetails.inputValue.selectLanguage')}</option>
                                <option value="en">{t('accounts.tabAccountDetails.inputValue.english')}</option>
                                <option value="fr">{t('accounts.tabAccountDetails.inputValue.french')}</option>
                            </select>
                            {errors.preferredLanguage && (
                                <div className="invalid-feedback">{errors.preferredLanguage?.message?.toString()}</div>
                            )}
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-md-4">
                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }}>{t('accounts.tabAccountDetails.modalEditProfile.button.saveProfile')}</button>
                                    {/* <button className="btn btn-primary w-100">Change Password</button> */}
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* </div> */}
                    {/* </div>
                </div> */}
                    {/* </div> */}

                    {/* </div> */}
                </Modal.Body>
            </Modal>
            
        </>
    )
}

export default UserAccountDetails
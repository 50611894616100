import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaTrash, FaPenToSquare } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonHeader from "../../components/Header.tsx";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "./../../constants/apiUrls.ts";
import { FaPlus } from "react-icons/fa6";
import { RiLockPasswordFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as XLSX from "xlsx";
import { capitalizeFirstLetter } from "../../services/commonFn.js";
import { MdOutgoingMail } from "react-icons/md";

const AddUser = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  // const { register: register, handleSubmit: handleSubmitForm1, formState: { errors: errorsForm1 } } = useForm();
  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    watch,
    formState: { errors: errorsForm2 },
  } = useForm();

  const [usersData, setUsersData] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // This function is for opening user form modal
  const openModal = () => {
    setShowModal(true);
  };

  // This function is for closing user form modal
  const closeModal = () => {
    setShowModal(false);
  };

  const downloadTableData = () => {
    // Convert usersData into a format suitable for XLSX
    const formattedData = usersData.map((user, index) => ({
      "#": index + 1,
      "First Name": user?.firstName,
      "Last Name": user?.lastName,
      Email: user?.email || "",
      Role: removeUnderscores(user?.role?.role) || "",
      "Subscription Plan": user?.usersubscription?.plan || "",
      "Start From": user?.usersubscription?.startFrom
        ? moment(user.usersubscription.startFrom).format("DD-MM-YYYY")
        : "",
      "Valid To": user?.usersubscription?.validUpTo
        ? moment(user.usersubscription.validUpTo).format("DD-MM-YYYY")
        : "",
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "UsersData");

    // Export the file
    XLSX.writeFile(workbook, "Users Data.xlsx");
  };

  function removeUnderscores(str) {
    if (!str || str.length === 0) {
      return ""; // Return empty string if input is empty or invalid
    }

    if (str && str?.includes("_")) {
      // Replace underscores with spaces
      str = str.replace(/_/g, " ");
    }

    // Capitalize the first letter of each word and make other letters lowercase
    return str
      .split(" ") // Split string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join words back together with spaces
  }

  // This function add data in form hook
  const handleUserData = (user) => {

    setValue("_id", user?._id);
    setValue("firstName", user?.firstName);
    setValue("lastName", user?.lastName);
    setValue("email", user?.email);
    setValue("role", user?.role?._id);
    setSelectedUser(user);
  };

  // This function add new user and update existing user in db
  const handleAddAndUpdateUser = (data) => {
    data?._id
      ? httpClient({
          method: "post",
          url: API_URLS.updateUser,
          data,
        })
          .then(({ result, error }) => {
            if (result) {
              closeModal();
              reset();
              fetchAllUsersData();
              setSelectedUser(null);
              toast.success("User updated successfully");
            } else {
              toast.error(error);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          })
      : httpClient({
          method: "post",
          url: API_URLS.addUser,
          data,
        })
          .then(({ result, error }) => {
            if (result) {
              closeModal();
              reset();
              fetchAllUsersData();
              toast.success("User added successfully");
            } else {
              toast.error(error.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
  };

  const handleResendInvitationToken = (id: string) => {
    if (!id) {
      toast.error("User Id not found");
    }

    const data = {
      userId: id,
    };

    httpClient({
      method: "post",
      url: API_URLS.resendTokenAdmin,
      data,
    })
      .then(({ result, error }) => {
        if (result) {
          setShowModal(false);
          setSelectedUser(null);
          reset();
          toast.success("Resend Token sent successfully");
        } else {
          toast.error(error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleResetPassword = (data) => {
    if (!selectedUser) {
      console.error("No user selected");
      return;
    }

    const passwordData = {
      id: selectedUser._id,
      password: data?.newPassword,
    };
    httpClient({
      method: "post",
      url: API_URLS.resetPassword,
      data: passwordData,
    })
      .then(({ result, error }) => {
        if (result) {
          setShowResetPasswordModal(false);
          setSelectedUser(null);
          reset();
          toast.success("Password updated successfully");
        } else {
          toast.error(error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // This function delete user in db
  const handleDelete = async (id: string) => {
    try {
      const { result, error } = await httpClient({
        method: "delete",
        url: API_URLS.deleteUser,
        data: { id },
      });

      if (result) {
        setShowConfirmationModal(false);
        fetchAllUsersData();
        setSelectedUser(null);
        toast.success("User removed successfully");
      } else {
        toast.error(error?.message || "Failed to delete user.");
      }
    } catch (error) {
      console.error("Error during delete:", error);
      toast.error(
        error?.message || "An error occurred while deleting the user."
      );
    }
  };

  // This function fetch all user data from db
  const fetchAllUsersData = async () => {
    httpClient({
      method: "get",
      url: API_URLS.getUsers,
    })
      .then(({ result, error }) => {
        if (result) {
          setUsersData(result?.users);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchUserTypes = async () => {
    httpClient({
      method: "get",
      url: API_URLS.getUserTypes,
    })
      .then(({ result, error }) => {
        if (result) {
          setUserTypes(result?.userTypes);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchAllUsersData();
    fetchUserTypes();
  }, []);

  return (
    <>


      <div className="pt-2 ps-2 pe-2 d-flex justify-content-between align-items-center gap-2">
        <div style={{ flex: 1 }}>
          <CommonHeader
            title={t("settings.tabUsers.title")}
            component={"addUser"}
            ButtonIcon={<FaPlus />}
            buttonTitle={t("settings.tabUsers.buttonTitle")}
            buttonEventTriggered={openModal}
            isHeader={false}
          />
        </div>
        <div>
          <button
            type="button"
            className="button d-flex align-items-center mb-2"
            onClick={downloadTableData}
          >
            Download Data
          </button>
        </div>
      </div>

      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col" style={{ width: "50px" }}>
              #
            </th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderName")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderEmail")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderRole")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderSubscriptionPlan")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderStartFrom")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderValidTo")}</th>
            <th scope="col">{t("settings.tabUsers.tableColumnHeaderEmailStatus")}</th>
            {/* <th scope="col">{"Password"}</th> */}
            <th scope="col">
              {t("settings.tabUsers.tableColumnHeaderActions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(usersData) &&
            usersData.length &&
            usersData?.map((user: any, index: number) => (
              <tr key={index}>
                <td style={{ width: "50px" }}>{index + 1}</td>
                <td>{user?.firstName + " " + (user?.lastName || "")}</td>
                <td>{user?.email}</td>
                <td>{removeUnderscores(user?.role?.role)}</td>
                <td>{capitalizeFirstLetter(user?.usersubscription?.plan)}</td>
                <td>
                  {" "}
                  {user?.usersubscription?.startFrom
                    ? moment(user.usersubscription.startFrom).format(
                        "DD-MM-YYYY"
                      )
                    : ""}
                </td>
                <td>
                  {user?.usersubscription?.validUpTo
                    ? moment(user.usersubscription.validUpTo).format(
                        "DD-MM-YYYY"
                      )
                    : ""}
                </td>
                <td>{user?.isEmailVerified ? "Verified" : "Not Verified"}</td>
                {/* <td>
                  {user?.hasPassword ? "Available" : "Not Available"}
                </td> */}
                <td style={{ width: "120px" }}>
                  <div className="icon-container">
                    <FaPenToSquare
                      fontSize={18}
                      className="text-primary cursor-pointer me-2"
                      onClick={() => {
                        handleUserData(user);
                        setShowModal(true);
                      }}
                    />
                    <span className="icon-tooltip">
                      {t("settings.tabUsers.tooltip.editUser")}
                    </span>{" "}
                    {/* Span that will show on hover */}
                  </div>
                  <div className="icon-container">
                    <RiLockPasswordFill
                      fontSize={18}
                      className="text-grey cursor-pointer"
                      onClick={() => {
                        setSelectedUser(user);
                        setShowResetPasswordModal(true);
                      }}
                      style={{ color: "grey" }}
                    />
                    <span className="icon-tooltip">
                      {t("settings.tabUsers.tooltip.resetPassword")}
                    </span>{" "}
                    {/* Span that will show on hover */}
                  </div>
                  {!user?.isEmailVerified && (
                    <div className="icon-container">
                      <MdOutgoingMail
                        fontSize={20}
                        className="text-danger cursor-pointer"
                        onClick={() => {
                          handleResendInvitationToken(user?._id);
                        }}
                      />
                      <span className="icon-tooltip">
                        {"Resend Invitation Link"}
                      </span>{" "}
                      {/* Span that will show on hover */}
                    </div>
                  )}
                  <div className="icon-container">
                    <FaTrash
                      fontSize={16}
                      className="text-danger cursor-pointer"
                      onClick={() => {
                        setShowConfirmationModal(true);
                        setSelectedUser(user);
                      }}
                    />
                    <span className="icon-tooltip">
                      {t("settings.tabUsers.tooltip.deleteUser")}
                    </span>{" "}
                    {/* Span that will show on hover */}
                  </div>

                  {/* <div className="icon-container"> */}

                  {/* <FaTrash
                      className="table-action-icon text-danger cursor-pointer"
                      onClick={() => { setShowConfirmationModal(true); setSelectedUser(user) }}
                    />
                    <span className="icon-tooltip">{t('settings.tabUsers.tooltip.deleteUser')}</span> */}
                  {/* </div> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        show={showModal}
        onHide={() => {
          closeModal();
          reset();
          setSelectedUser(null);
        }}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedUser
              ? t("settings.tabUsers.modalAddNewUser.titleUpdate")
              : t("settings.tabUsers.modalAddNewUser.titleAdd")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form className="w-100">
              <div className="custom-form-group">
                <label>
                  {t("settings.tabUsers.modalAddNewUser.inputTitle.firstName")}{" "}
                  *
                </label>
                <input
                  type="text"
                  placeholder={t(
                    "settings.tabUsers.modalAddNewUser.inputPlaceholder.userFirstName"
                  )}
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputFirstName"
                  aria-describedby="firstNameHelp"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message:  `${t("signup.formLabel.firstName")} ${t('validation.requiredMessage')}`,
                    },
                  })}
                />
                {errors.firstName && (
                  <div className="invalid-feedback">
                    {errors.firstName?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="custom-form-group">
                <label>
                  {t("settings.tabUsers.modalAddNewUser.inputTitle.lastName")} *
                </label>
                <input
                  type="text"
                  placeholder={t(
                    "settings.tabUsers.modalAddNewUser.inputPlaceholder.userLastName"
                  )}
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputLastName"
                  aria-describedby="lastNameHelp"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: `${t("signup.formLabel.lastName")} ${t('validation.requiredMessage')}`,
                    },
                  })}
                />
                {errors.lastName && (
                  <div className="invalid-feedback">
                    {errors.lastName?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="custom-form-group">
                <label>
                  {t("settings.tabUsers.modalAddNewUser.inputTitle.email")} *
                </label>
                <input
                  type="text"
                  placeholder={t(
                    "settings.tabUsers.modalAddNewUser.inputPlaceholder.userEmail"
                  )}
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputEmail"
                  aria-describedby="emailHelp"
                  {...register("email", {
                    required: {
                      value: true,
                      message: `${t("signup.formLabel.email")} ${t('validation.requiredMessage')}`,
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: `${t("validation.invalid")} ${t('signup.formLabel.email')}`,
                    },
                  })}
                />

                {errors.email && (
                  <div className="invalid-feedback">
                    {errors.email?.message?.toString()}
                  </div>
                )}
              </div>
              <div className="custom-form-group">
                <label>
                  {t("settings.tabUsers.modalAddNewUser.inputTitle.role")} *
                </label>
                <select
                  {...register("role", {
                    required: true,
                  })} // Registering "role" field
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                >
                  <option value="">
                    {t("settings.tabUsers.inputValue.selectRole")}
                  </option>
                  {userTypes?.map((userType: any) => (
                    <option key={userType?._id} value={userType?._id}>
                      {removeUnderscores(userType?.role)}
                    </option>
                  ))}
                </select>
                {errors.role && (
                  <div className="invalid-feedback">
                    
                    {t("settings.tabUsers.modalAddNewUser.inputTitle.role")} {t('validation.requiredMessage')} {/* Use errors.role.message here */}
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button"
            variant={null}
            onClick={handleSubmit(handleAddAndUpdateUser)}
          >
            {t("settings.tabUsers.modalAddNewUser.buttonTitle.save")}
          </Button>
          <Button
            className="button button-secondary"
            variant={null}
            onClick={() => {
              closeModal();
              reset();
            }}
          >
            {t("settings.tabUsers.modalAddNewUser.buttonTitle.close")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("settings.tabUsers.modalConfirmation.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>{t("settings.tabUsers.modalConfirmation.message")}</h5>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button"
            variant={null}
            onClick={() => handleDelete(selectedUser?._id)}
          >
            {t("settings.tabUsers.modalConfirmation.buttonTitle.yes")}
          </Button>
          <Button
            className="button button-secondary"
            variant={null}
            onClick={() => setShowConfirmationModal(false)}
          >
            {t("settings.tabUsers.modalConfirmation.buttonTitle.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showResetPasswordModal}
        onHide={() => {
          setShowResetPasswordModal(false);
          resetForm2();
          setSelectedUser(null);
        }}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("settings.tabUsers.modalResetPassword.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form className="w-100">
              <div className="custom-form-group">
                <label>
                  {t(
                    "settings.tabUsers.modalResetPassword.inputTitle.newPassword"
                  )}{" "}
                  *
                </label>
                <input
                  type="text"
                  placeholder={t(
                    "settings.tabUsers.modalResetPassword.inputPlaceholder.newPassword"
                  )}
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputNewPassword"
                  aria-describedby="newPassword"
                  {...registerForm2("newPassword", {
                    required: `${t("login.formLabel.password")} ${t('validation.requiredMessage')}`,
                    minLength: { value: 8, message:  `${t('validation.passwordLengthMessage')}` }
                  })}
                />
                {errorsForm2.newPassword && (
                  <div className="invalid-feedback">
                    {errorsForm2.newPassword?.message}
                  </div>
                )}
              </div>
              <div className="custom-form-group">
                <label>
                  {t(
                    "settings.tabUsers.modalResetPassword.inputTitle.confirmNewPassword"
                  )}{" "}
                  *
                </label>
                <input
                  type="text"
                  placeholder={t(
                    "settings.tabUsers.modalResetPassword.inputPlaceholder.confirmNewPassword"
                  )}
                  className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                  id="InputConfirmPassword"
                  aria-describedby="confirmPassword"
                  {...registerForm2("confirmPassword", {
                    required: {
                      value: true,
                      message: `${t("login.formLabel.password")} ${t('validation.requiredMessage')}`,
                    },
                    validate: (value) =>
                      value === watch("newPassword") ||
                      `${t('validation.passwordMatchMessage')}`
                  })}
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: "Invalid email address",
                  // },
                />

                {errorsForm2.confirmPassword && (
                  <div className="invalid-feedback">
                    {errorsForm2.confirmPassword?.message}
                  </div>
                )}
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button"
            variant={null}
            onClick={handleSubmitForm2(handleResetPassword)}
          >
            {t("settings.tabUsers.modalAddNewUser.buttonTitle.save")}
          </Button>
          <Button
            className="button button-secondary"
            variant={null}
            onClick={() => {
              setShowResetPasswordModal(false);
              resetForm2();
            }}
          >
            {t("settings.tabUsers.modalAddNewUser.buttonTitle.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUser;

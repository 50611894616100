import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../context/UserContext.js";
import { capitalizeFirstLetter } from "../services/commonFn.js";
import { keyTextMapping } from "../constants/commonData.js";
import { Modal, Button } from "react-bootstrap";
import { Loader } from "./Loader.tsx";
import { FaChevronUp, FaArrowCircleLeft, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const SubscriptionPlans: React.FC = () => {

  const { t } = useTranslation();

  const { user, userPlan, updateUserPlan } = useUser();
  const location = useLocation();
  const isUpgrading = location?.state?.upgradePlan || false;
  const navigate = useNavigate();
  const tableRef = useRef<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [showFeatureList, setShowFeatureList] = useState(false)
  const [plans, setPlans] = useState(null)
  const [combinedfeatureList, setCombinedfeatureList] = useState({})
  const [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal] = useState(false)

  const getTextForKey = (key) => keyTextMapping[key] || key;

  const combineSpecificFeaturesToArray = (subscriptionPlans) => {
    const combinedFeatures = {};

    subscriptionPlans.forEach((plan) => {
      const planFeatures = plan?.features;
      const targetedCategories = Object?.keys(planFeatures); // Extract categories dynamically

      // Track uniqueness per plan
      const seenValuesPerPlan = {};

      targetedCategories?.forEach((category) => {
        if (planFeatures[category]) {
          Object.entries(planFeatures[category]).forEach(([field, value]) => {
            if (!combinedFeatures[category]) {
              combinedFeatures[category] = {};
            }

            if (category === 'educationalProgramInsights') {
              if (field.startsWith('default')) {
                return;
              }
              // For educationalProgramInsights, store the subcategory name and its inner value
              if (!combinedFeatures[category][field]) {
                combinedFeatures[category][field] = [];
              }

              if (typeof value === 'object' && value !== null) {

                Object.entries(value).forEach(([innerField, innerValue]) => {

                  // if (innerField.startsWith('default')) {
                  //     return;
                  // }
                  // Always push the subcategory and its value
                  combinedFeatures[category][field].push({
                    innerValue
                  });
                });
              } else {
                // Handle direct values
                combinedFeatures[category][field].push({
                  value
                });
              }
            } else {
              // For other categories, skip fields starting with 'default' or named 'dataYearRange'
              if (field.startsWith('default') || field === 'dataYearRange') {
                return; // Skip this field
              }

              if (typeof value === 'object' && value !== null) {
                // If value is an object, go deeper
                Object.entries(value).forEach(([subField, subValue]) => {
                  if (subField.startsWith('default') || subField === 'dataYearRange') {
                    return; // Skip this field
                  }

                  // Track uniqueness per plan for this category and subField
                  if (!seenValuesPerPlan[category]) {
                    seenValuesPerPlan[category] = {};
                  }
                  if (!seenValuesPerPlan[category][subField]) {
                    seenValuesPerPlan[category][subField] = new Set();
                  }

                  // Skip if the value has already been seen for this plan
                  if (seenValuesPerPlan[category][subField].has(subValue)) {
                    return;
                  }

                  // Mark the value as seen for this plan
                  seenValuesPerPlan[category][subField].add(subValue);

                  // Add the value to the combined features
                  if (!combinedFeatures[category][subField]) {
                    combinedFeatures[category][subField] = [];
                  }
                  combinedFeatures[category][subField].push(subValue);
                });
              } else {
                // Track uniqueness per plan for this category and field
                if (!seenValuesPerPlan[category]) {
                  seenValuesPerPlan[category] = {};
                }
                if (!seenValuesPerPlan[category][field]) {
                  seenValuesPerPlan[category][field] = new Set();
                }

                // Skip if the value has already been seen for this plan
                if (seenValuesPerPlan[category][field].has(value)) {
                  return;
                }

                // Mark the value as seen for this plan
                seenValuesPerPlan[category][field].add(value);

                // Add the value to the combined features
                if (!combinedFeatures[category][field]) {
                  combinedFeatures[category][field] = [];
                }
                combinedFeatures[category][field].push(value);
              }
            }
          });
        }
      });
    });

    return combinedFeatures;
  };



  const handleAddUserSubscriptionPlan = (plan: any) => {


    if (!plan?._id) {
      toast.error("Invalid subscription plan.");
      return;
    }

    // If plan price is 0, navigate to checkout with plan data
    if (plan.price !== 0) {
      navigate("/checkout", { state: { plan, isUpgrading } });
      return;
    }

    const apiUrl = isUpgrading ? API_URLS.upgradePlan : API_URLS.addUserSubscriptionPlan;
    const data = {
      userId: user?._id,
      planId: plan._id
    }



    httpClient({
      method: "post",
      url: apiUrl,
      data
    })
      .then(({ result, error }) => {
        if (error) {
          toast.error(error.message);
          return;
        }
        if (result.status) {
          // toast.success(result.message)
          setShowSuccessSubscriptionModal(true)
          !isUpgrading && updateUserPlan(result.plan)

          // setPlans(result?.subscriptions)
          // const structuredFeatures = combineSpecificFeaturesToArray(result?.subscriptions)
          // setCombinedfeatureList(structuredFeatures)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
      });
  }

  function navigateBasedOnPlan(plan) {


    if (!plan) {
        navigate('/subscription-plans');
        return;
    }

    const features = plan?.subscriptionPlanId?.features || {};

    const {
        establishmentInsights,
        educationalProgramInsights,
        workforceInsights,
    } = features;

    if (establishmentInsights?.defaultEstablishmentAccessibility === 'accessible') {
        navigate('/establishment-data');
        return;
    }

    if (educationalProgramInsights?.defaultEducationalAccessibility === 'accessible') {
        navigate('/educational-program');
        return;
    }

    if (workforceInsights?.defaultWorkforceAccessibility === 'accessible') {
        navigate('/employment-data');
        return;
    }

    navigate('/account-details');
}

  const handleToggleFeatureList = () => {
    setShowFeatureList(!showFeatureList)

    setTimeout(() => {
      tableRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 300); // Minimal delay to allow layout updates
  };

  const getSubscriptionPlans = () => {
    setIsLoading(true)
    httpClient({
      method: "get",
      url: API_URLS.getSubscriptionPlans,
    })
      .then(({ result }) => {
        if (result.status) {
          setPlans(result?.subscriptions)
          const structuredFeatures = combineSpecificFeaturesToArray(result?.subscriptions?.filter((plan: any) => plan.status !== 'inactive' && plan?.plan !== "Default Public Plan"))
          setCombinedfeatureList(structuredFeatures)
          setIsLoading(false)
        } else {
          toast.error(result.message)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
      });
  }

  useEffect(() => {
    getSubscriptionPlans()
  }, [])

  useEffect(() => {
    if (showSuccessSubcriptionModal) {
      const timer = setTimeout(() => {
        setShowSuccessSubscriptionModal(false);
        navigateBasedOnPlan(userPlan);
      }, 2000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal]);

  return (
    <>

      <Loader show={isLoading} />


      <section
        className="plans__container"
        style={{
          overflowY: "scroll",
          // height: "calc(100% - 50px)",
          height: "100%",
          maxHeight: "100%",
        }}
      >
        <div className="row d-flex justify-content-start mt-3" style={{ position: 'fixed' }}>
          <div onClick={() => navigate(-1)}>
            <FaArrowCircleLeft className="ms-3" fontSize={25} />
          </div>
        </div>
        {plans && plans?.length > 0 &&
          <div className="plans">
            <div className="plansHero">
              <h1 className="plansHero__title">{t('subscription.chooseYourPlan')}</h1>
            </div>
            <div className={`planItem__container d-flex justify-content-center`}>
              {plans && plans.length > 0 && plans?.filter((plan) => plan.status !== 'inactive' && plan?.plan !== "Default Public Plan")?.map((plan, index) => (
                <>
                  <div key={plan?._i} className={`planItem planItem--free }`} style={{ width: '400px', height: '450px', position: 'relative' }}>
                    {plan?.isMostPopular && <div className="most-popular" style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
                      <span className="most-popular-span">{t('settings.tabSubscription.modalAddSubscription.mostPopular')}</span>
                    </div>
                    }
                    <div className="planItem--inner" style={{ marginTop: "30px" }}>
                      <div className="planItem_card">
                        <div className="planItem_card__header">
                          <h2>{capitalizeFirstLetter(plan?.plan)}</h2>
                        </div>
                        <div className="price">
                          {(plan.price && typeof plan.price == "number") ? `$${plan.price}` : `$${plan.price}`}<span>{(plan.price && typeof plan.price == "number") ? `/ ${t('subscription.monthly')}` : `/ ${t('subscription.monthly')}`}</span>
                        </div>

                        <button type='submit' className="button button-rounded-full  button-full" onClick={() => handleAddUserSubscriptionPlan(plan)} disabled={(user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") || (isUpgrading && userPlan?.subscriptionPlanId?._id.toString() == plan?._id.toString())}>
                          {(userPlan?.subscriptionPlanId?._id === plan?._id && !userPlan.isCancel) ? `${t('subscription.subscribed')}` : `${t('subscription.getStarted')}`}
                        </button>


                      </div>
                      <hr />
                      <ul className="featureList">
                        <li>
                          Limited Filter Accessibility
                          <span className="icon"></span>
                        </li>
                        <li>
                          Limited Sector Accessibility <span className="icon"></span>
                        </li>
                        <li>
                          No Interactive Charts <span className="icon"></span>
                        </li>
                        <li>
                          No Sub Sector Filters <span className="icon"></span>
                        </li>
                        <li>
                          No Data Comparison <span className="icon"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="plan-feature-detail" ref={tableRef}>
              <div className="d-flex justify-content-center align-items-center gap-3 mb-4 mt-4" onClick={handleToggleFeatureList}>
                <h3 className="text-center mb-0">{t('subscription.completeFeaturesList')}</h3>
                <div className="toggle-icon">
                  <span
                    className={`feature-list-icon ${showFeatureList ? "rotate-up" : "rotate-down"}`}
                  >
                    <FaChevronUp fontSize={20} />
                  </span>
                </div>
              </div>
              <div className={`table-responsive feature ${showFeatureList ? "show" : "hide"}`} >
                <table className="table align-middle" style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th style={{ width: "25%" }}></th>
                      {plans?.filter((plan) => plan?.status !== 'inactive'&& plan?.plan !== "Default Public Plan")?.map((plan, index) => (
                        <th key={index} style={{ width: "25%" }}>{capitalizeFirstLetter(plan.plan)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody >
                    <tr id="feature__list">
                      <td></td>
                      {plans?.filter((plan) => plan?.status !== 'inactive' && plan?.plan !== "Default Public Plan")?.map((plan, index) => (
                        <td className="text-center">
                          <button type='submit' className="button button-rounded-full button-border-primary" onClick={() => handleAddUserSubscriptionPlan(plan)} disabled={(user?.role === "SUPER_ADMIN" || user?.role === 'ADMIN') || userPlan?.subscriptionPlanId?._id === plan?._id}>
                            {userPlan?.subscriptionPlanId?._id === plan?._id ? `${t('subscription.subscribed')}` : `${t('subscription.getStarted')}`}
                          </button>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">{t('establishmentInsights')}</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.establishmentInsights && Object.entries(combinedfeatureList.establishmentInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{t(getTextForKey(feature))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? `${t('subscription.limitedAccess')}` :
                              status === 'full' ? `${t('subscription.fullAccess')}` :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">{t('educationalProgramInsights')}</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.educationalProgramInsights && Object.entries(combinedfeatureList.educationalProgramInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{t(getTextForKey(feature))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? `${t('subscription.limitedAccess')}` :
                              status === 'full' ? `${t('subscription.fullAccess')}` :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">{t('sidebarWorkforceInsights')}</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.workforceInsights && Object.entries(combinedfeatureList.workforceInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{t(getTextForKey(feature))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? `${t('subscription.limitedAccess')}` :
                              status === 'full' ? `${t('subscription.fullAccess')}` :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>

                        ))}
                      </tr>
                    ))}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        }
      </section>


      <br />

      <Modal
        show={showSuccessSubcriptionModal}
        onHide={() => { setShowSuccessSubscriptionModal(false); navigate('/establishment-data') }}
        backdrop="static" // Prevent closing on outside click
        keyboard={false} // Prevent closing with "Escape" key
        centered>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

          {/* Icon and Success Text */}
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px" }}>
            <FaCheckCircle style={{ fontSize: "50px", color: "green" }} />
            <p style={{ fontSize: "18px", fontWeight: "bold", margin: "10px 0 0 0" }}>{t('subscription.toast.success')}</p>
          </div>

          <p style={{ fontSize: "16px", fontWeight: "500" }}>{`${t('subscription.toast.message')}`} {userPlan?.subscriptionPlanId?.plan} {`${t('subscription.toast.plan')}`}.</p>
          {/* <p>With this subscription, you now have access to all the exclusive features and benefits tailored to your needs.</p> */}
        </Modal.Body>

      </Modal>

    </>
  );
};

export default SubscriptionPlans;

import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";

// utils/authUtils.js
export const isLoggedIn = () => {
    const user = localStorage.getItem('token');
    return !!user; // returns true if user exists, false otherwise
  };
  
  export const getDefaultPlan = async () => {
    try {
      const { result, error } =await httpClient({
        method: "get",
        url :API_URLS.defaultSubscriptionPlan
      });

   
      if(result.status){
   
        return result?.defaultPlan
      }
      return null;
    } catch (error) {
      console.error("Error fetching default plan:", error);
      return null;
    }
  };
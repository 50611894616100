import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext'
import { Loader } from '../../components/Loader.tsx';
import httpClient from '../../services/httpClient.ts'
import API_URLS from '../../constants/apiUrls.ts'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer } from 'react-toastify'
import { capitalizeFirstLetter } from '../../services/commonFn.js';
import axios from 'axios';

const PaymentHistory: React.FC = () => {
    const { user } = useUser()
    const { t } = useTranslation()

    const [transactions, setTransactions] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const getPaymentHistory = async () => {
        setLoading(true); // Start loading
        try {
            const data = {
                userId: user._id,
            };

            const response = await httpClient({
                method: 'post',
                url: API_URLS.getPaymentHistory,
                data,
            });

            const { result, error } = response;
            if (error) {
                toast.error(error?.message || 'Something went wrong, please try again');
                return;
            }

            if (result.status) {
                setTransactions(result.data);
            }
        } catch (error) {
            console.error('Error fetching payment history:', error);
            toast.error('Failed to fetch payment history. Please try again.');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        getPaymentHistory()
    }, []);

    const downloadInvoice = async (transactionId: string) => {

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/subscription/invoice?transactionId=${transactionId}`,
                {
                    responseType: 'blob', // Important to handle binary data
                }
            );

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `invoice_${transactionId}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading invoice:', error);
            toast.error('Failed to download invoice.');
        }
    };

    return (
        <>

            <Loader show={loading} />
            <div className="row gap-2 w-100">
                {/* Profile Details Section */}
                <div className="col-12 col-md-12">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="font-semibold mb-4">{t('accounts.tabPaymentHistory.heading')}</h5>

                            {transactions && transactions?.length > 0 ? (
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>

                                                <th>{t('accounts.tabPaymentHistory.tableHeading.date')}</th>
                                                <th>{t('accounts.tabPaymentHistory.tableHeading.amount')}</th>
                                                <th style={{ width: '150px' }}>{t('accounts.tabPaymentHistory.tableHeading.message')}</th>
                                                <th>{t('accounts.tabPaymentHistory.tableHeading.cardNumber')}</th>
                                                <th>{t('accounts.tabPaymentHistory.tableHeading.status')}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions?.map((transaction, index) => (
                                                <tr key={index}>

                                                    <td>{new Date(transaction.date).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: '2-digit',
                                                    })}</td>
                                                    <td>$ {transaction.amount} {transaction.currency.toUpperCase()}</td>
                                                    <td>
                            {transaction.status === 'success' ? (
                                <>
                                    {t('accounts.tabPaymentHistory.text.payment')} {t('accounts.tabPaymentHistory.text.for')} <strong>{capitalizeFirstLetter(transaction.planName)}</strong> {t('accounts.tabPaymentHistory.text.memberShip')} |
                                    <br />
                                    {t('accounts.tabPaymentHistory.text.valid')}: {new Date(transaction.startFrom).toLocaleDateString()} - {new Date(transaction.validUpTo).toLocaleDateString()}
                                </>
                            ) : (
                                <>
                                    {t('accounts.tabPaymentHistory.text.memberShip')} {t('accounts.tabPaymentHistory.text.for')} <strong>{capitalizeFirstLetter(transaction.planName)}</strong> - {t('accounts.tabPaymentHistory.text.failed')}
                                </>
                            )}
                        </td>
                                                    <td>{transaction.cardNumber !== 'N/A' ? `**** **** **** ${transaction.cardNumber}` : 'N/A'}</td>
                                                    <td>
                                                        <span
                                                            className={`badge ${transaction.status === 'success'
                                                                ? 'bg-success'
                                                                : transaction.status === 'failed'
                                                                    ? 'bg-danger'
                                                                    : 'bg-warning'
                                                                }`}
                                                        >
                                                            {capitalizeFirstLetter(transaction.status)}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button
                                                            onClick={() => downloadInvoice(transaction._id)}
                                                            className="invoice-btn"
                                                        >
                                                            {t('accounts.tabPaymentHistory.text.downloadInvoice')}
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>{t('accounts.tabPaymentHistory.text.messageNoTransaction')}</p>
                            )}

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentHistory
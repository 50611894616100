import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import httpClient from "../services/httpClient.ts";
import API_URLS from "../constants/apiUrls.ts";
import { ToastContainer, toast } from "react-toastify";
import { FaDownload } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
const fileTypes = [
  {
    name: "MainTemplate",
    value: "MainTemplate",
    translationKey: "settings.tabTranslation.inputTitle.mainFile",
  },
  {
    name: "HelpTemplate",
    value: "HelpTemplate",
    translationKey: "settings.tabTranslation.inputTitle.helpAndInformation",
  },
  { name: "ProvinceTemplate", value: "Province", translationKey: "province" },
];
const TranslationManager = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const handleDowloadType = (e) => {
    const { value } = e.target;
    const file = files.find((item) => item.name === value);
    setFileType(value);
  };
  const handleDownload = async (fileId, fileType) => {
    let url = API_URLS.downloadFile
      .replace(":fileId", fileId || null)
      .replace("{type}", fileType);
    httpClient({
      method: "get",
      url,
      responseType: "blob",
    }).then(({ result }) => {
      if (result) {
        const blob = new Blob([result], { type: result.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        let fileName;
        
        switch (fileType) {
          case "MainTemplate":
            fileName = "Main Translation Template.csv";
            break;
          case "HelpTemplate":
            fileName = "Help Information Translation Template.csv";
            break;
          case "Province":
            fileName = "Province Translation Template.csv";
            break;
          default:
            fileName = "Default Template.csv";
          
        }
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    });
  };
  const uploadFile = () => {
    const url = API_URLS.uploadTranslationFile;
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", fileType);
    httpClient({
      method: "post",
      url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(({ result }) => {
      if (result) {
        const updatedDataArray = fileTypes.map((item) => {
          const getItem = result.files.find((file) => file.name === item.name);
          if (getItem) {
            const updatedItem = {
              ...item,
              fileId: getItem?._id,
              updatedAt: getItem.updatedAt,
            };
            return updatedItem;
          } else {
            return item;
          }
        });
        setFiles(updatedDataArray);
        setFile(null);
        toast.success(result.message);
        window.location.reload();
      }
    });
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleReset = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  useEffect(() => {
    httpClient({ method: "get", url: API_URLS.getTranslationFileInfo }).then(
      ({ result }) => {
        if (result) {
          const updatedDataArray = fileTypes.map((item) => {
            const getItem = result.files.find(
              (file) => file.name === item.name
            );
            if (getItem) {
              const updatedItem = {
                ...item,
                fileId: getItem?._id,
                uploadedBy: getItem?.uploadedBy?.firstName,
                updatedAt: getItem?.updatedAt,
              };
              return updatedItem;
            } else {
              return item;
            }
          });
          setFiles(updatedDataArray);
        }
      }
    );
  }, []);
  return (
    <>
  
      <Card style={{ width: "100%", marginTop: "10px" }}>
        <Card.Body>
          <Card.Title>
            {t("settings.tabTranslation.cardHeading.translationFile")}
          </Card.Title>
          <table className="table table-hover document-table">
            <thead>
              <tr>
                <th scope="col" style={{ borderRadius: "5px 0 0 0" }}>
                  {t("settings.tabTranslation.tableHeading.fileType")}
                </th>
                <th style={{ textAlign: "center" }}>{t("settings.tabTranslation.tableHeading.lastUploadedBy")}</th>
                <th>{t("settings.tabTranslation.tableHeading.lastUpdated")}</th>
                <th style={{ borderRadius: "0 5px 0 0", textAlign: "center" }}>
                  {t("settings.tabTranslation.tableHeading.actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {files.length > 0 ? (
                files.map((item, index) => (
                  <tr className="align-middle">
                    <td>{t(item.translationKey)}</td>
                    <td style={{ textAlign: "center" }}>{item.uploadedBy}</td>
                    <td>
                      {item?.updatedAt
                        ? moment(item?.updatedAt)
                            .tz(
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            )
                            .format("YYYY-MM-DD HH:mm:ss A") // ✅ Use 'mm' for minutes
                        : ""}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.fileId && (
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <FaDownload
                            className="fs-5 ms-2"
                            onClick={() =>
                              handleDownload(item.fileId, item.value)
                            }
                            style={{ cursor: "pointer", color: "#e61e28" }}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>
            {t("settings.tabTranslation.cardHeading.uploadFile")}
          </Card.Title>
          <Row className="d-flex align-items-center mt-2">
            <Col className="d-flex align-items-center" md={4}>
              <select
                className="form-select fw-semibold c-form-control-sm"
                style={{ width: "100%" }}
                value={fileType}
                onChange={handleDowloadType}
              >
                <option value="">
                  {t("settings.tabTranslation.inputTitle.selectFileType")}
                </option>
                <option value="MainTemplate">
                  {t("settings.tabTranslation.inputTitle.mainFile")}
                </option>
                <option value="HelpTemplate">
                  {t("settings.tabTranslation.inputTitle.helpAndInformation")}
                </option>
                <option value="Province">{t("province")}</option>
              </select>
            </Col>
            <Col md={6}>
              <Form.Control
                ref={fileInputRef}
                type="file"
                disabled={!fileType}
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </Col>
            <Col md={2}>
              <div className="d-flex justify-content-end gap-1">
                <Button
                  variant="secondary"
                  disabled={!fileType}
                  onClick={() => handleReset()}
                >
                  {t("settings.tabTranslation.button.reset")}
                </Button>
                <button
                  type="button"
                  className="btn"
                  disabled={!fileType || !file}
                  style={{
                    backgroundColor: "#e61e28",
                    outline: "none",
                    color: "#fff",
                  }}
                  onClick={() => uploadFile()}
                >
                  {t("settings.tabTranslation.button.upload")}
                </button>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default TranslationManager;
// import Dashboard from "../pages/dashboard/Dashboard.tsx";
// import Login from "../pages/auth/Login.tsx";
// import Documents from "../pages/documents/Documents.tsx";
// import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
// import Comparison from "../pages/dashboard/Comparison.tsx";
// import ChangePassword from "../pages/settings/ChangePassword.tsx";
// import AddUser from '../pages/settings/AddUser.tsx'
// import EducationalProgram from "../pages/dashboard/EducationalProgram";
// import EmploymentData from "../pages/dashboard/EmploymentData";
// import HorizontalChartEducationalProgram from "../components/EducationalProgramTabComponents/HorizontalChartEducationalProgram.js";
// import EstablishmentData from "../pages/dashboard/EstablishmentData.js";
// import Settings from "../pages/settings/Settings.tsx"
// import AccountInfo from "../components/Account.jsx";
// import SubscriptionPlans from "../components/Subscription.tsx";
// import SetPassword from "../pages/SetPassword.tsx";
// import Checkout from "../components/Checkout.jsx";
// import VerifyEmail from "../components/VerifyEmail.tsx";

// const Routes: any = [
//   {
//     component: Login,
//     path: "/",
//     title: "User Login",
//     to: "/",
//     type: "public",
//     children: [],
//   },
//   {
//     component: SetPassword,
//     path: "/set-password",
//     title: "Set Password",
//     to: "/set-password",
//     type: "public",
//     children: [],
//   },
//   {
//     component: Dashboard,
//     path: "/geography-insights",
//     title: "Geography Insights",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: Dashboard2,
//     path: "/sector-insights",
//     title: "Sector Insights",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: Comparison,
//     path: "/comparison",
//     title: "Comparison",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },

//   {
//     component: Documents,
//     path: "/documents",
//     title: "Documents",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: AddUser,
//     path: "/add-user",
//     title: "Users",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: Settings,
//     path: "/settings",
//     title: "Settings",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: AccountInfo,
//     path: "/account-info",
//     title: "Account",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: SubscriptionPlans,
//     // component: Checkout,
//     path: "/subscription-plans",
//     title: "Subscription",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: VerifyEmail,
//     // component: Checkout,
//     path: "/verifyEmail",
//     title: "Verify Email",
//     to: "/",
//     type: "public",
//     children: [],
//     meta: [],
//   },
//   {
//     component: Checkout,
//     path: "/checkout",
//     title: "Checkout",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },

//   {
//     component: ChangePassword,
//     path: "/change-password",
//     title: "Change Password",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: EducationalProgram,
//     path: "/educational-program",
//     title: "Educational Program",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: EmploymentData,
//     path: "/employment-data",
//     title: "Employment Data",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
//   {
//     component: EstablishmentData,
//     path: "/establishment-data",
//     title: "establishment Data",
//     to: "/",
//     type: "private",
//     children: [],
//     meta: [],
//   },
// ];
// export default Routes;


import Dashboard from "../pages/dashboard/Dashboard.tsx";
import Login from "../pages/auth/Login.tsx";
import Documents from "../pages/documents/Documents.tsx";
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
import Comparison from "../pages/dashboard/Comparison.tsx";
import ChangePassword from "../pages/settings/ChangePassword.tsx";
import AddUser from '../pages/settings/AddUser.tsx'
import EducationalProgram from "../pages/dashboard/EducationalProgram";
import EmploymentData from "../pages/dashboard/EmploymentData";
import HorizontalChartEducationalProgram from "../components/EducationalProgramTabComponents/HorizontalChartEducationalProgram.js";
import EstablishmentData from "../pages/dashboard/EstablishmentData.js";
import Settings from "../pages/settings/Settings.tsx"
import AccountInfo from "../pages/account/Account.tsx";
import SubscriptionPlans from "../components/Subscription.tsx";
import SetPassword from "../pages/SetPassword.tsx";
import Checkout from "../components/Checkout.jsx";
import VerifyEmail from "../components/VerifyEmail.tsx";
import ForgotPassword from "../pages/auth/ForgetPassword.tsx";
import Signup from "../pages/auth/Signup.tsx";

const Routes: any = [
  {
    component: Login,
    path: "/login",
    title: "User Login",
    to: "/",
    type: "public",
    children: [],
  },

  {
    component: Signup,
    path: "/signup",
    title: "User Signup",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: ForgotPassword,
    path: "/forgot-password",
    title: "Forgot Password",
    to: "/",
    type: "public",
    children: [],
  },
  {
    component: SetPassword,
    path: "/set-password",
    title: "Set Password",
    to: "/set-password",
    type: "public",
    children: [],
  },
  {
    component: Dashboard,
    path: "/geography-insights",
    title: "Geography Insights",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Dashboard2,
    path: "/sector-insights",
    title: "Sector Insights",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Comparison,
    path: "/comparison",
    title: "Comparison",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },

  {
    component: Documents,
    path: "/documents",
    title: "Documents",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: AddUser,
    path: "/add-user",
    title: "Users",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: Settings,
    path: "/settings",
    title: "Settings",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: AccountInfo,
    path: "/account-details",
    title: "Account",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: SubscriptionPlans,
    // component: Checkout,
    path: "/subscription-plans",
    title: "Subscription",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: VerifyEmail,
    // component: Checkout,
    path: "/verifyEmail",
    title: "Verify Email",
    to: "/",
    type: "public",
    children: [],
    meta: [],
  },
  {
    component: Checkout,
    path: "/checkout",
    title: "Checkout",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: ChangePassword,
    path: "/change-password",
    title: "Change Password",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EducationalProgram,
    path: "/educational-program",
    title: "Educational Program",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EmploymentData,
    path: "/employment-data",
    title: "Employment Data",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
  {
    component: EstablishmentData,
    path: "/establishment-data",
    title: "establishment Data",
    to: "/",
    type: "private",
    children: [],
    meta: [],
  },
];
export default Routes;

import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import PaginationComponent from "./PaginationComponent.js";
import { useTranslation } from "react-i18next";
import { capitalizeString } from "../../services/commonFn";
import { Loader } from "../Loader.tsx";
import { FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import { Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpTour from "../../components/HelpTour.tsx";
import ReactPlayer from "react-player";
import { BiSolidVideos } from 'react-icons/bi'
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";

const HorizontalChartEducationalProgram = ({ steps, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation();
  const { defaultSettings, updateSettings,setCurrentTab ,resetSettings} = useUserSettings();
  const mainTab = "educationalPrograms";
  const subTab = "areaByStudy";

    // Initialize filter values from global context or use defaults
    const userSettings = defaultSettings?.find(setting => setting?.mainTab === mainTab)?.subTabs?.find(sub => sub?.subTab === subTab)?.settings;
 
    const chartRef = useRef(null)
  const clearAllRef = useRef(null)
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const [showLoader, setShowLoader] = useState(false)
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [levelOfInstructions, setLevelOfInstructions] = useState([])
  const [selectedProvince, setProvince] = useState(userSettings?.selectedProvince ?? "")
  const [selectedLevelOfInstruction, setLevelOfInstruction] = useState(userSettings?.selectedLevelOfInstruction ?? "")
  const [apprenticeOffered, setApprenticeOffered] = useState(userSettings?.apprenticeOffered ?? "")
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)
  const [studyField, setStudyField] = useState("")
  const [helpRun, setHelpRun] = useState(false)
  const [joyrideSteps, setSteps] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [key, setKey] = useState(0);

  useEffect(()=>{
    resetSettings()
    
  },[])
  useEffect(() =>{
    setCurrentTab('educationalPrograms' ,'areaByStudy')
  },[]);

  
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    title: {
      text: '',
      align: "left",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        if (this.point.item.studyField && this.point.y) {
          return `<b>${capitalizeString(t(this.point.item.studyField))}<br/>${t("programs")}: ${this.point.y} </b><br/><b style='font-weight:bold'>${t("clickForProgramDetails")}</b>`;
        }
        return '';
      },
    },
    series: [
      {
        name: "Program(s)",
        data: [],
        pointWidth: 20,
        borderRadius: 10,
        color: "#E61E28",
        dataLabels: {
          enabled: true,
          inside: true,
          format: "{y}",
          style: {
            color: "white",
            textOutline: "0px",
          },
        },
        tooltip: {
          pointFormat:
            "<b>{point.name}<br/>" +
            `${t("programs")}: ` +
            `{point.y}` +
            "</b><br/>" +
            `<h4 style='font-weight:bold'>${t("clickForProgramDetails")}</h4>`
        }
      },
    ],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              setShowPaginationComponent(!showPaginationComponent);
              setStudyField(this.item);
            },
          },
        },
        cursor: "pointer"
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 768, // Medium screens (e.g., tablets)
          },
          chartOptions: {
            chart: {
              height: 400, // Adjust chart height for medium screens
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: "12px", // Reduce font size for xAxis labels
                },
              },
            },
            series: [
              {
                pointWidth: 15, // Adjust point width
              },
            ],
          },
        },
        {
          condition: {
            maxWidth: 480, // Small screens (e.g., phones)
          },
          chartOptions: {
            chart: {
              height: 300, // Further reduce chart height
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: "10px", // Smallest font size for xAxis labels
                },
              },
            },
            series: [
              {
                pointWidth: 10, // Further adjust point width
              },
            ],
          },
        },
      ],
    },
  })

  const resetStepStates = () => {
    setStepStates(steps?.map(() => ({ showVideo: false })) || [])
  }

  const handleClearAll = () => {
    setProvince("")
    setLevelOfInstruction("")
    setApprenticeOffered("")
    updateSettings(mainTab, subTab, "selectedProvince", "");
    updateSettings(mainTab, subTab, "selectedLevelOfInstruction", "");
    updateSettings(mainTab, subTab, "apprenticeOffered", "");
    clearAllRef.current?.();
  }

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      resetStepStates()
    }
  }

  useEffect(() => {
    if (joyrideStatus) {
      let joyrideSteps; // Initialize a variable to hold steps
      if (showPaginationComponent) {
        // Use slice to avoid mutating joyrideSteps
        joyrideSteps = steps?.slice(-3)?.reverse() // Get steps from index 2 onward
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content)?.split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true, // Disable close on Esc if needed
            disableBeacon: true,
            showSkipButton: false, // Optionally hide the skip button
            showNextButton: true, // Show next button
            closeButtonText: "Close", // Change button text if needed
          }
        });
      } else {
        joyrideSteps = steps?.slice(0, 2); // Get the first 3 steps
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content).split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            // placement: window.innerWidth <= 768 ? 'top' : step.placement
          };
        })
      }
      setSteps(joyrideSteps); // Set the steps state
      setHelpRun(true); // Set help run state
    }
  }, [joyrideStatus, stepStates]);

  useEffect(() => {
    try {
      const fetch = async () => {
        // Define all the API URLs
        setShowLoader(true)
        try {
          const urls = {
            geographies: API_URLS.listGeographies,
            countAreaOfStudy: API_URLS.countAreaOfStudy.replace('{province}', '').replace('{levelOfInstruction}', '').replace('{apprentice}', ''),
            levelOfInstruction: API_URLS.listLevelOfInstruction
          };


          // Execute all API calls concurrently
          const [geographiesResponse, levelOfInstructionResponse, countAreaOfStudyResponse] = await Promise.all([
            httpClient({ method: "get", url: urls.geographies }),
            httpClient({ method: "get", url: urls.levelOfInstruction }),
          ]);

          // Handle geographies response
          if (geographiesResponse?.result) {
            setProvinces(geographiesResponse?.result?.geographies.filter(item => item.name !== 'canada' && item.name !== 'geography').sort((a, b) => a.name.localeCompare(b.name)))
          }
          if (levelOfInstructionResponse.result) {
            setLevelOfInstructions(levelOfInstructionResponse.result.results)
          }
        } catch (error) {
          console.log(error)
        } finally {
          setShowLoader(false)
        }
      }
      fetch()
    } catch (error) {
      console.log(error)
    } finally {
      setInitialLoading(false)
    }

  }, [])

  useEffect(() => {
    // if (!initialLoading) {
    try {
      setShowLoader(true)
      const url = API_URLS.countAreaOfStudy.replace('{province}', selectedProvince).replace('{levelOfInstruction}', selectedLevelOfInstruction).replace('{apprentice}', apprenticeOffered)

      httpClient({
        method: "get",
        url,
      }).then(({ result, error }) => {
        if (result) {
          const res = result.counts.sort((a, b) => a.count < b.count ? 1 : -1)
          const categories = res.map(item => capitalizeString(t(item.studyField)))
          const data = res.map(item => { return { y: item.count, item } })

          setOptions(prevOptions => ({
            ...prevOptions,
            xAxis: {
              categories: categories
            },
            tooltip: {
              formatter: function () {
                if (this.point) {
                  return `<b>${capitalizeString(t(this?.point?.item?.studyField))}<br/>${t("programs")}: ${this?.point?.y} </b><br/><p style='font-weight:bold'>${t("clickForProgramDetails")}</p>`
                }
              },
            },
            series: [
              {
                ...prevOptions.series[0],
                data: data,
              },
            ],
          }))
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoader(false)
    }

  }, [selectedProvince, selectedLevelOfInstruction, apprenticeOffered, window.location.href, t])

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.reflow();
    }
  }, [window.location.href, options]);

    // Update global context when filter values change
    const handleProvinceChange = (e) => {
      const value = e.target.value;
      setProvince(value);
   
      updateSettings('educationalPrograms', 'areaByStudy', "selectedProvince", value);
    };
  
    const handleLevelOfInstructionChange = (e) => {
      const value = e.target.value;
      setLevelOfInstruction(value);
      updateSettings(mainTab, subTab, "selectedLevelOfInstruction", value);
    };
  
    const handleApprenticeOfferedChange = (e) => {
      const value = e.target.value;
      setApprenticeOffered(value);
      updateSettings(mainTab, subTab, "apprenticeOffered", value);
    };



  return (
    <>
      <Loader show={showLoader} />
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      // setStepIndex={handlePaginationHelpTour}
      />
      <Row>
        <div className="d-flex justify-content-between">
          <h6 className="py-3 px-2">
            {t('educationalProgram.tabAreaOfStudy.title')}
          </h6>
          <div className="d-flex align-items-center">
            <button className="clear_btn" onClick={handleClearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
              {t("commonSubHeadingClearAll")}
            </button>
            <div className="vertical-line"></div>
            <div className="d-flex gap-1">
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                  <Tooltip {...props} className="tooltip-justify">
                    <b>{t("disclaimer")}: </b> {t("informationTextAreaOfStudy").split('\\n').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && (
                          <div style={{ height: '7px' }} />)}
                      </React.Fragment>
                    ))}
                  </Tooltip>
                )}
                placement="bottom"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#f0f0f0",
                    cursor: 'pointer',
                    // marginRight: "10px"
                  }}
                >
                  <FaInfoCircle style={{ fontSize: '20px' }} />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </Row>
      {!showPaginationComponent && (
        <div className="row gx-0 areaOfStudy-dropdown">
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProvince"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedProvince}
                onChange={(e) => handleProvinceChange(e)}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {provinces &&
                  provinces.map((item) => (
                    <option key={item._id} value={item._id}>
                      {capitalizeString(t(item.languageKey))}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="levelOfInstructionSelect" className="text-black">
                {t(
                  "educationalProgram.tabAreaOfStudy.inputTitle.selectLevelOfInstruction"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedLevelOfInstruction}
                onChange={(e) =>{handleLevelOfInstructionChange(e)}}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {levelOfInstructions &&
                  levelOfInstructions.map((item) => (
                    <option value={item}>{capitalizeString(t(item))}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="durationSelect" className="text-black">
                {t(
                  "educationalProgram.tabAreaOfStudy.inputTitle.apprentice"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={apprenticeOffered}
                onChange={handleApprenticeOfferedChange }
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.all"
                  )}
                </option>
                <option value={true}>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.offered"
                  )}
                </option>
                <option value={false}>
                  {t(
                    "educationalProgram.tabAreaOfStudy.inputValue.notOffered"
                  )}
                </option>
              </select>
            </div>
          </div>
        </div>
      )}

      {showPaginationComponent ?
        (
          <>
            <button
              onClick={() => setShowPaginationComponent(!showPaginationComponent)}
              className="btn btn-link text-theme font-small ps-2 m-0"
            >
              {t("educationalProgram.tabAreaOfStudy.paginationComponent.backButtonChart")}

            </button>
            <PaginationComponent
              item={studyField}
              apprenticeship={apprenticeOffered}
              levelOfInstruction={selectedLevelOfInstruction}
              province={selectedProvince ||'all'}
              clearAllRef={clearAllRef}
              mainTabs={'areaByStudy'}
            />
          </>
        )
        :
        <div style={{ marginTop: "20px" }} className="chart-container" id="chart-container">
          <HighchartsReact highcharts={Highcharts} constructorType={'chart'} options={options} />
        </div>
      }

    </>
  );
};

export default HorizontalChartEducationalProgram;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";
import API_URLS from "../constants/apiUrls.ts";
import httpClient from "../services/httpClient.ts";
import { useUser } from '../context/UserContext.js';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const { updateUser } = useUser();

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') ?? "en");
  
  
  const handleLanguageChange = async (e) => {

    const selectedLanguage = e.target.value;

    setLanguage(selectedLanguage)
    localStorage.setItem('selectedLanguage', selectedLanguage);

    await handleUpdateProfile(selectedLanguage);
    await loadTranslations(selectedLanguage);
  };

  const fetchTranslations = async (lang) => {
    let url = API_URLS.getTranslationFile.replace("{language}", lang)
    const response = httpClient({
      method: "get",
      url,
    })
    .then(({ result, error }) => {
      if (error) {
   
        return // Call with error if the request fails
      }
      return result
    })
    
    return response;
};
  const loadTranslations = async (lang) => {
    const translations = await fetchTranslations(lang);
    if(translations) {
      i18n.addResourceBundle(lang, 'translation', translations);
      i18n.changeLanguage(lang);
    } 
}

  useEffect(() => {
    loadTranslations(language)
  }, []);

  const handleUpdateProfile = async (selectedLanguage) => {
    if (!user) {
      throw new Error("user not found");
      return;
    }

    const updatedData = {
      userId: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization,
      jobTitle: user.jobTitle,
      phone: user.phone,
      ext: user.ext,
      mailingAddress: user.mailingAddress,
      preferredLanguage: selectedLanguage, // Updated language state
    };
    try {
      const { result, error } = await httpClient({
        method: "post",
        url: API_URLS.userUpdateProfile,
        data: updatedData,
      });

    
      if (error) {
        console.error("API Error:", error);
        return;
      }

      if (result.status) {
        updateUser(result.user); // Store updated user in context

      }
    } catch (err) {
      console.error("Request failed:", err);
    }
  }


  return (
    <div className="language-select" >
      <IoLanguage style={{ fontSize: "1.5rem" }} />
      <select onChange={handleLanguageChange} style={{ width: "100%" }} value={language}>
        <option value='en'>English</option>
        <option value='fr'>Français</option>
      </select>
    </div>
  );
}

export default LanguageSelector;

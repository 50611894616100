import React, { useEffect, useState, useRef } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { capitalizeString } from "../../services/commonFn";
import { Loader } from "../../components/Loader.tsx";
import PaginationComponent from "./PaginationComponent.js";
import HelpTour from "../../components/HelpTour.tsx";
import ReactPlayer from "react-player";
import { BiSolidVideos } from 'react-icons/bi'
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";

const TableByInstitution = ({ steps, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation();
    const { defaultSettings, updateSettings ,setCurrentTab } = useUserSettings();
    const mainTab = "educationalPrograms";
    const subTab = "levelOfInstruction";
      // Initialize filter values from global context or use defaults
      const userSettings = defaultSettings?.find(setting => setting?.mainTab === mainTab)?.subTabs?.find(sub => sub?.subTab === subTab)?.settings;
 
  const clearAllRef = useRef(null)
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const [dataTitle, setDataTitle] = useState([]);
  const [colData, setColData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [columnSortOrder, setColumnSortOrder] = useState(
    Array(dataTitle.length + 1).fill(true)
  );
  const [showLoader, setShowLoader] = useState(false);
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)
  const [countData, setCountData] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [programs, setPrograms] = useState([])
  const [studyField, setStudyField] = useState('')
  const [selectedLevelOfInstruction, setLevelOfInstruction] = useState('')
  const [selectedProvince, setSelectedProvince] = useState(userSettings?.selectedProvince ?? "")
  const [selectedCity, setSelectedCity] = useState(userSettings?.selectedCity ?? "")
  const [selectedLanguage, setSelectedLanguage] = useState(userSettings?.selectedLanguage ?? "")
  const [languages, setLanguages] = useState([])
  const [helpRun, setHelpRun] = useState(false)
  const [joyrideSteps, setSteps] = useState([])

  const [orderBy, setOrderBy] = useState(0);
  const [order, setOrder] = useState("asc");

  const sortTableData = (data, index, asc) => {
    const sortedData = [...data.slice(0, -1)]; // Exclude the last two rows
    sortedData.sort((a, b) => {
      if (index === 0) {
        const valueA = a[index]; // Assuming column 1 is index 1
        const valueB = b[index];
        if (asc) {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      } else {
        const valueA = a[index]; // Assuming column 1 is index 1
        const valueB = b[index];
        if (asc) {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }
    });
    return [...sortedData, data[data.length - 1]];
  };

  // const handleProvinceChange = (e) => {
  //   const { value } = e.target
  //   setSelectedProvince(value);
  //   setSelectedCity("");
  //   // setSelectedProgram("");
  //   // setDuration("");
  //   // setCurrentPage(1);
  // };

  const handleTdClick = (rowIndex, columnIndex) => {
    const data = countData[rowIndex]
    if (data) {
      setStudyField(data)
    }
    const levelOfInstruction = dataTitle[columnIndex - 1]
    if (levelOfInstruction) {
      setLevelOfInstruction(levelOfInstruction)
    }
    setShowPaginationComponent(true)
  };

  const handleSorting = (index) => {
    const newColumnSortOrder = [...columnSortOrder];
    newColumnSortOrder[index] = !newColumnSortOrder[index]; // Toggle sorting order for the clicked column
    setColumnSortOrder(newColumnSortOrder);

    const sortedData = sortTableData(tableData, index, newColumnSortOrder[index]); // Pass the new sorting order
    setTableData(sortedData);
  };

  const clearAll = () => {
    setSelectedProvince("")
    setSelectedCity("")
    setSelectedLanguage("");
    updateSettings(mainTab, subTab, "selectedProvince", "");
    updateSettings(mainTab, subTab, "selectedCity", "");
    updateSettings(mainTab, subTab, "selectedLanguage", "");
    clearAllRef.current?.();

    
  }

  const handleProvinceChange = (value) => {
  
    setSelectedProvince(value);
    setSelectedCity('');
    updateSettings('educationalPrograms', subTab, "selectedProvince", value);
    updateSettings('educationalPrograms', subTab, "selectedCity", '');

  };

  const handleCityChange =(value) =>{
    setSelectedCity(value);
    updateSettings('educationalPrograms', subTab, "selectedCity", value);
  }

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    updateSettings('educationalPrograms', subTab, "selectedLanguage", value);
  }

  const resetStepStates = () => {
    setStepStates(steps?.map(() => ({ showVideo: false })) || [])
  }

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      resetStepStates()
    }
  }

  useEffect(() => {
    if (joyrideStatus) {
      let joyrideSteps; // Initialize a variable to hold steps
      if (showPaginationComponent) {
        // Use slice to avoid mutating joyrideSteps
        joyrideSteps = steps?.slice(-2)?.reverse() // Get steps from index 2 onward
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
         
          const translatedContent = (<>
            {t(step.content).split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            // Ensure to set the proper buttons
            disableCloseOnEsc: true, // Disable close on Esc if needed
            disableBeacon: true,
            showSkipButton: false, // Optionally hide the skip button
            showNextButton: true, // Show next button
            closeButtonText: "Close", // Change button text if needed
          }
        });
      } else {
        joyrideSteps = steps?.slice(0, 2); // Get the first 3 steps
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content)?.split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            // placement: window.innerWidth <= 768 ? 'top' : step.placement
          };
        })
      }
      setSteps(joyrideSteps); // Set the steps state
      setHelpRun(true); // Set help run state
    }
  }, [joyrideStatus, stepStates]);

  useEffect(() => {
    const fetch = async () => {
      setShowLoader(true)
      try {
        const urls = {
          geographies: API_URLS.listGeographies,
          countAreaOfStudy: API_URLS.countAreaOfStudy.replace('{province}', '').replace('{levelOfInstruction}', '').replace('{apprentice}', ''),
          studyProgramsLanguage: API_URLS.listStudyProgramsLanguage,
          eductaionalProgramByInstitution: API_URLS.eductaionalProgramByInstitution.replace('{province}', selectedProvince).replace('{city}', selectedCity).replace('{language}', selectedLanguage)
        };


        // Execute all API calls concurrently
        const [geographiesResponse, studyProgramsLanguageResponse, educationProgramResponse] = await Promise.all([
          httpClient({ method: "get", url: urls.geographies }),
          httpClient({ method: "get", url: urls.studyProgramsLanguage }),
          httpClient({ method: "get", url: urls.eductaionalProgramByInstitution })
        ]);

        // Handle geographies response
        if (geographiesResponse?.result) {
          setProvinces(geographiesResponse?.result?.geographies.filter(item => item.name !== 'canada' && item.name !== 'geography').sort((a, b) => a.name.localeCompare(b.name)))
        }
        if (studyProgramsLanguageResponse.result) {
          setLanguages(studyProgramsLanguageResponse.result.results)
        }
        if (educationProgramResponse.result) {
          const result = educationProgramResponse.result
          setCities(result?.cities)
          setDataTitle(result?.levelOfInstructionsLanguageKey);
          setCountData(result?.counts)
          if (result.counts.length > 0) {
            const resArr = [];

            for (let c in result.counts) {
              let temparr = new Array(result.levelOfInstructions.length + 1).fill(
                0
              );
              let sum = 0;
              temparr[0] = capitalizeFirstLetter(
                t(result.counts[c].fieldOfLanguageKey)
              );
              for (
                let i = 0;
                i < result.counts[c].levelOfInstructions.length;
                i++
              ) {
                let name =
                  result.counts[c].levelOfInstructions[i].levelOfInstruction;
                let findMyIndex = result.levelOfInstructions.findIndex(
                  (x) => x === name
                );
                if (findMyIndex !== -1)
                  temparr[findMyIndex + 1] =
                    result.counts[c].levelOfInstructions[i]?.count;
                sum += result.counts[c].levelOfInstructions[i]?.count;
              }
              temparr[result.levelOfInstructions.length + 1] = sum;
              resArr[c] = temparr;
            }

            const grandTotalArr = new Array(resArr[0].length).fill(0);
            grandTotalArr[0] = "Grand Total";
            resArr.forEach((row) => {
              for (let i = 1; i < row.length; i++) {
                grandTotalArr[i] += row[i];
              }
            });
            resArr.push(grandTotalArr);
            setTableData(resArr);
            setColData(resArr);
          } else {
            setTableData([]);
            setColData([]);
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setShowLoader(false)
      }
    }
    fetch()
  }, [selectedProvince, selectedCity, selectedLanguage, t])

  
 useEffect(() =>{
    setCurrentTab('educationalPrograms' ,'levelOfInstruction')
  },[])

  return (
    <>
      <Loader show={showLoader} />
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      // setStepIndex={handlePaginationHelpTour}
      />
      <div className="d-flex justify-content-between">
        <h6 className="py-3 px-2">
          {t("educationalProgram.tabLevelOfInstruction.title")}
        </h6>
        <button className="clear_btn" onClick={clearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
          {t("commonSubHeadingClearAll")}
        </button>
      </div>
      {!showPaginationComponent && (
        <div className="row gx-0 levelOfInstruction-dropdown">
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectProvince"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedProvince}
                onChange={(e) => handleProvinceChange(e.target.value)}
              >
                <option value="" selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {provinces &&
                  provinces.map((item) => (
                    <option key={item._id} value={item._id}>
                      {capitalizeFirstLetter(t(item.languageKey))}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="citySelect" className="text-black">
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectCity"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedCity}
                onChange={(e) => handleCityChange(e.target.value)}
              >
                <option value={""} selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {cities && cities.map((item) => (
                  <option key={item._id} value={item?._id}>
                    {capitalizeFirstLetter(t(item?.languageKey))}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="languageSelect" className="text-black">
                {t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectLanguage"
                )}
              </label>
              <select
                className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                value={selectedLanguage}
                onChange={(e) => handleLanguageChange(e.target.value)}
              >
                <option value={""} selected>
                  {t(
                    "educationalProgram.tabProvinceAndTerritory.paginationComponent.inputValue.all"
                  )}
                </option>
                {languages.map((item) => (
                  <option key={item} value={item}>
                    {capitalizeFirstLetter(t(`language.${item}`))}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {showPaginationComponent ?
        (
          <>
            <button
              onClick={() => setShowPaginationComponent(!showPaginationComponent)}
              className="btn btn-link text-theme font-small ps-2 m-0"
            >
              {t("educationalProgram.tabLevelOfInstruction.paginationComponent.backButtonTable")}

            </button>
            <PaginationComponent
              item={studyField}
              levelOfInstruction={selectedLevelOfInstruction}
              language={selectedLanguage ||''}
              province={selectedProvince ||'all'}
              city={selectedCity}
              clearAllRef={clearAllRef}
              mainTabs={'levelOfInstruction'}
            />
          </>
        )
        :
        <Table responsive bordered className="institution-table m-0">

          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "#e41b2c",
                  borderRadius: "5px 0 0 0",
                  verticalAlign: "middle",
                }}
                onClick={() => handleSorting(0)}
              >
                {t("educationalProgram.tabLevelOfInstruction.tableColumnHeading")}
                {columnSortOrder[0] ? (
                  <FaArrowDown
                    style={{ float: "right", fontSize: "10px", marginLeft: "10px" }}
                  />
                ) : (
                  <FaArrowUp
                    style={{ float: "right", fontSize: "10px", marginLeft: "10px" }}
                  />
                )}
              </th>
              {dataTitle &&
                dataTitle.map((_, index) => (
                  <th
                    style={{ backgroundColor: "#e41b2c", verticalAlign: "middle" }}
                    onClick={() => handleSorting(index + 1)}
                  >
                    {capitalizeFirstLetter(t(_))}
                    {columnSortOrder[index + 1] ? (
                      <FaArrowUp
                        style={{
                          float: "right",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    ) : (
                      <FaArrowDown
                        style={{
                          float: "right",
                          fontSize: "10px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </th>
                ))}
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#e41b2c",
                  borderRadius: "0 5px 0 0",
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData && tableData.length > 0
              ?
              tableData?.map((i, index1) => {
                return (
                  <tr key={index1}>
                    {i?.map((item, index) => {
                      return (
                        <>
                          {index === 0 ? (
                            <OverlayTrigger
                              key={`${index1}-${index}`}
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {item?.languageKey
                                    ? capitalizeFirstLetter(t(item.languageKey))
                                    : item}
                                </Tooltip>
                              )}
                              placement="bottom"
                            >
                              <td
                                key={index}
                                style={{
                                  textAlign: index !== 0 ? "center" : "left",
                                  fontWeight:
                                    index === dataTitle.length + 1 ||
                                      index1 === colData.length - 1
                                      ? "bold"
                                      : "",
                                  fontSize:
                                    index === dataTitle.length + 1 ||
                                      index1 === colData.length - 1
                                      ? "14px"
                                      : "12px",
                                  cursor: item > 0 && index !== i.length - 1 ? "pointer" : ""
                                }}
                                onClick={() => {
                                  if (item > 0) {
                                    handleTdClick(index1, index);
                                  }
                                }}
                              >
                                {item}
                              </td>
                            </OverlayTrigger>
                          ) : (item > 0 && index1 !== tableData.length - 1 && index !== i.length - 1) ? (
                            <OverlayTrigger
                              key={`${index1}-${index}`}
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {t("clickForProgramDetails")}
                                </Tooltip>
                              )}
                              placement="bottom"
                            >
                              <td
                                key={index}
                                style={{
                                  textAlign: index !== 0 ? "center" : "left",
                                  fontWeight:
                                    index === dataTitle.length + 1 ||
                                      index1 === colData.length - 1
                                      ? "bold"
                                      : "",
                                  fontSize:
                                    index === dataTitle.length + 1 ||
                                      index1 === colData.length - 1
                                      ? "14px"
                                      : "12px",
                                  cursor: item > 0 && index !== i.length - 1 ? "pointer" : "default"
                                }}
                                onClick={() => {
                                  if (item > 0 && index1 !== tableData.length - 1 && index !== i.length - 1) {
                                    handleTdClick(index1, index);
                                  }
                                }}
                              >
                                {item}
                              </td>
                            </OverlayTrigger>
                          ) : <td
                            key={index}
                            style={{
                              textAlign: index !== 0 ? "center" : "left",
                              fontWeight:
                                index === dataTitle.length + 1 ||
                                  index1 === colData.length - 1
                                  ? "bold"
                                  : "",
                              fontSize:
                                index === dataTitle.length + 1 ||
                                  index1 === colData.length - 1
                                  ? "14px"
                                  : "12px",
                              cursor: item > 0 && index1 !== tableData.length - 1 && index !== i.length - 1 ? "pointer" : "default"
                            }}
                            onClick={() => {
                              if (item > 0 && index1 !== tableData.length - 1 && index !== i.length - 1) {
                                handleTdClick(index1, index);
                              }
                            }}
                          >
                            {item}
                          </td>}
                        </>
                      )
                    })}
                  </tr>
                )
              })
              :
              "No data found"
            }
          </tbody>
        </Table>
      }
    </>
  );
};

export default TableByInstitution;

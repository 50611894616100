// import React, { useState, useEffect, useRef } from "react";
// import { Tabs, Tab } from "react-bootstrap";
// import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
// import PyramidChart from "./EmploymentDataComponents/PyramidChart";
// import LineChart from "./charts/LineChart";
// import Comparison from "../pages/dashboard/Comparison.tsx"
// import { useTranslation } from "react-i18next";
// import { useUser } from "../context/UserContext.js"

// const TabForEmployment = ({ getTabData, getTab, joyrideStatus, setJoyrideStatus }) => {
//   const { t } = useTranslation()
//   const containerRef = useRef(null);
//   const { permissions, joyrideSteps, user, userPlan? } = useUser()
//   const isSuperAdmin = user.role === "SUPER_ADMIN"
//   const [loading, setLoading] = useState(true)
//   const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.tab); // State to manage active tab
//   const [overflowStyle, setOverflowStyle] = useState("initial");

//   const handleTab = (k) => {
//     setKey(k)
//     getTabData('tabcomponent', k)
//     getTab(k)
//   }

//   useEffect(() => {
//       setKey("tab1");
//   }, [userPlan]);

//   // Monitor overflow in tab container
//   useEffect(() => {
//     const container = containerRef.current;
//     if (container && container.scrollHeight > container.clientHeight) {
//       setOverflowStyle("auto");
//     } else {
//       setOverflowStyle("initial");
//     }
//   }, [key]);

//   return (
//     <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
//       {/* Fixed Tabs */}
//       <div className="tab-scroll-container">
//       <Tabs
//         id="controlled-tab"
//         activeKey={key}
//         onSelect={handleTab}
//         className="custom_tab"
//       >
//         {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabProvince'))) && ( */}
//           <Tab eventKey="tab1" title={t('employmentInsights.tabTitle.provinceAndTerritory')}
//             className="tab_container mt-2"
//           />
//         {/* )} */}
//         {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabTotalEmployment'))) && ( */}
//           <Tab eventKey="tab2" title={t('employmentInsights.tabTitle.totalEmploymentOverTime')}
//             className="tab_container mt-2"
//           />
//         {/* )} */}
//         {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabDemographicBreakdown'))) && ( */}
//           <Tab eventKey="tab3" title={t('employmentInsights.tabTitle.breakdown')}
//             className="tab_container mt-2"
//           />
//         {/* )} */}
//         {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabComparison'))) && ( */}
//         {(isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.workforceInsights?.dataComparison == "accessible")) && (
//           <Tab eventKey="tab4" title={t('commonTitleComparison')}
//             className="tab_container mt-2"
//           />
//         )}
//       </Tabs>
//       </div>

//       <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>

//         {key === "tab1" && (
//           <TableViewEmploymentData tabSettings={getTabData} steps={joyrideSteps?.workforceInsights?.tabProvinceTerritory} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
//         )}
//         {key === "tab2" && <LineChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
//         {key === "tab3" && <PyramidChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
//         {key === "tab4" && (isSuperAdmin || (userPlan?.subscriptionPlanId?.features?.workforceInsights?.dataComparison == "accessible")) && <Comparison field={"employmentInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
//       </div>
//     </div>
//   );
// };

// export default TabForEmployment;

import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip, Overlay, Modal, Button } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";
import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext.js"
import { useLocation, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../pages/utils/authUtils.js";

const TabForEmployment = ({ getTabData, getTab, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate(); // Hook for navigation
  const { permissions, joyrideSteps, user, userPlan ,defaultPlan} = useUser()
  const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.tab); // State to manage active tab
  const [overflowStyle, setOverflowStyle] = useState("initial");
  const [restrictedTab, setRestrictedTab] = useState(null); // Track which tab is restricted

  const location = useLocation();
  const initialTab = location?.state?.tab;

  // const handleTab = (k) => {
  //   setKey(k)
  //   getTabData('tabcomponent', k)
  //   getTab(k)
  // }

  const tabImages = isLoggedIn() ? {
    tab1: user?.preferredLanguage==='fr' ? "/tabImages/langFre/workforce/province.png" :"/tabImages/langEng/workforce/province.png",
    tab2:  user?.preferredLanguage==='fr'? "/tabImages/langFre/workforce/totalEmployment.png" : "/tabImages/langEng/workforce/totalEmployment.png",
    tab3: user?.preferredLanguage==='fr'? "/tabImages/langFre/workforce/demographicBreakDown.png" : "/tabImages/langEng/workforce/demographicBreakDown.png",
    tab4: user?.preferredLanguage==='fr'? "/tabImages/langFre/workforce/comparison.png" :"/tabImages/langEng/workforce/comparison.png"
  } :  {
    tab1: user?.preferredLanguage==='fr' ? "/nonLoggedInTabImages/langFre/workforce/province.png" :"/nonLoggedInTabImages/langEng/workforce/province.png",
    tab2:  user?.preferredLanguage==='fr'? "/nonLoggedInTabImages/langFre/workforce/totalEmployment.png" : "/nonLoggedInTabImages/langEng/workforce/totalEmployment.png",
    tab3: user?.preferredLanguage==='fr'? "/nonLoggedInTabImages/langFre/workforce/demographicBreakDown.png" : "/nonLoggedInTabImages/langEng/workforce/demographicBreakDown.png",
    tab4: user?.preferredLanguage==='fr'? "/nonLoggedInTabImages/langFre/workforce/comparison.png" :"/nonLoggedInTabImages/langEng/workforce/comparison.png"
  };


  useEffect(() => {
    setKey(initialTab || "tab1");
  }, [userPlan]);

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);

  // useEffect(() => {
  //   if (isSuperAdmin) {
  //     setKey(initialTab || "tab1");
  //     return;
  //   }
  //   if (userPlan?.subscriptionPlanId?.features?.workforceInsights?.defaultWorkforceAccessibility === 'accessible') {

  //     const { workforceInsights } = userPlan.subscriptionPlanId.features;
  //     const accessibleTabs = [
  //       workforceInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
  //       workforceInsights?.totalEmployment?.defaultTotalEmploymentAccessibility === "accessible" && "tab2",
  //       workforceInsights?.demographicBreakdown?.defaultDemographicBreakdownAccessibility === "accessible" && "tab3",
  //       workforceInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab4",

  //     ].filter(Boolean); // Remove false/null values
  //     if (accessibleTabs?.length <= 0) {
  //       setKey('tab1');
  //     }
  //     if (accessibleTabs?.length > 0) {

  //       setKey(initialTab || accessibleTabs[0] ); // Set first accessible tab
  //     }
  //   } else {

  //     setKey(initialTab); // Default if no plan or no accessible tabs
  //     setRestrictedTab('tab1'); // Set which tab is restricted
  //     setShowModal(true);
  //   }
  // }, [userPlan, initialTab]);

    useEffect(() => {
      if(isSuperAdmin){
        setKey(initialTab || "tab1"); 
        return;
      }
     if(!isLoggedIn()){
 
      if ( defaultPlan?.features?.workforceInsights?.defaultWorkforceAccessibility === 'accessible') {
  
        const { workforceInsights } = defaultPlan?.features;
        const accessibleTabs = [
          workforceInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
          workforceInsights?.totalEmployment?.defaultTotalEmploymentAccessibility === "accessible" && "tab2",
          workforceInsights?.demographicBreakdown?.defaultDemographicBreakdownAccessibility === "accessible" && "tab3",
          workforceInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab4",
  
        ].filter(Boolean); 
        if(accessibleTabs?.length <= 0){
          setKey('tab1');
        }
        if (accessibleTabs?.length > 0) {
    
          setKey( initialTab || accessibleTabs[0]);
        }
      } else {
  
        // setKey(initialTab || "tab1");
        setRestrictedTab('tab1'); // Set which tab is restricted
        setShowModal(true);
      }
     }else{
   
      if (userPlan?.subscriptionPlanId?.features?.workforceInsights?.defaultWorkforceAccessibility === 'accessible') {

        const { workforceInsights } = userPlan?.subscriptionPlanId.features;
        const accessibleTabs = [
          workforceInsights?.provinceAndTerritory?.defaultProvinceAndTerritoryAccessibility === "accessible" && "tab1",
          workforceInsights?.totalEmployment?.defaultTotalEmploymentAccessibility === "accessible" && "tab2",
          workforceInsights?.demographicBreakdown?.defaultDemographicBreakdownAccessibility === "accessible" && "tab3",
          workforceInsights?.comparison?.defaultComparisonAccessibility === "accessible" && "tab4",
  
        ].filter(Boolean); // Remove false/null values
        if (accessibleTabs?.length <= 0) {
          setKey('tab1');
        }
        if (accessibleTabs?.length > 0) {
  
          setKey(initialTab || accessibleTabs[0] ); // Set first accessible tab
        }
      }  else {
  
        // setKey(initialTab || "tab1");
        setRestrictedTab('tab1'); // Set which tab is restricted
        setShowModal(true);
      }
     }
    }, [userPlan, initialTab ,isLoggedIn ,defaultPlan]);



  const isTabAccessible = (subkey, featureKey) => {
    if(isLoggedIn()){
      return (
      user?.role === "SUPER_ADMIN" || user?.role === "ADMIN" ||
      (userPlan &&
        userPlan?.subscriptionPlanId &&
        userPlan?.subscriptionPlanId?.features &&
        userPlan?.subscriptionPlanId?.features?.workforceInsights?.[subkey]?.[featureKey] === "accessible")
    );}else{
      

    return (
      (defaultPlan &&
        defaultPlan?.features &&
        defaultPlan?.features?.workforceInsights?.[subkey]?.[featureKey] === "accessible")
    );
    }
  };

  const handleTab = (k, event) => {
    if (!isTabAccessible('provinceAndTerritory', 'defaultProvinceAndTerritoryAccessibility') && k === "tab1" || !isTabAccessible('totalEmployment', 'defaultTotalEmploymentAccessibility') && k === "tab2" || !isTabAccessible('demographicBreakdown', 'defaultDemographicBreakdownAccessibility') && k === "tab3" || !isTabAccessible('comparison', 'defaultComparisonAccessibility') && k === "tab4") {
      console.log('not access')
      setRestrictedTab(k); // Set which tab is restricted
      event.preventDefault(); // Block tab switch
      setShowModal(true);
    } else {
      setKey(k);
      getTabData("tabcomponent", k);
    }
  };


  const handleUpgrade = () => {
    setShowModal(false); // Close the modal
    setRestrictedTab(null);
       isLoggedIn() ? navigate("/subscription-plans", { state: { upgradePlan: true } }) : navigate("/login")  ; // Navigate to subscription plans
  };

  const handleCancel = () => {
    setShowModal(false); // Close the modal
    setRestrictedTab(null);
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
        <Tabs
          id="controlled-tab"
          activeKey={key}
          onSelect={(k, e) => handleTab(k, e)}
          className="custom_tab"
        >

          <Tab eventKey="tab1"
            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('provinceAndTerritory', 'defaultProvinceAndTerritoryAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "22px",
                      height: "22px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontWeight: "bolder", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("employmentInsights.tabTitle.provinceAndTerritory")}</span> {/* Some gap between icon and text */}
              </div>
            }
            className="tab_container mt-2"
          />

          <Tab eventKey="tab2"

            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('totalEmployment', 'defaultTotalEmploymentAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("employmentInsights.tabTitle.totalEmploymentOverTime")}</span> {/* Some gap between icon and text */}
              </div>
            }
            className="tab_container mt-2"
          />

          <Tab eventKey="tab3"
            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('demographicBreakdown', 'defaultDemographicBreakdownAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "21px",
                      height: "21px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("employmentInsights.tabTitle.breakdown")}</span> {/* Some gap between icon and text */}
              </div>
            }
            className="tab_container mt-2"
          />
          {/* )} */}



          <Tab
            eventKey="tab4"

            title={
              <div className="d-flex align-items-center">
                {!isTabAccessible('comparison', 'defaultComparisonAccessibility') && (
                  <span className="d-flex align-items-center justify-center me-1"
                    style={{
                      width: "22px",
                      height: "22px",
                      backgroundColor: "#e61e28",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <FaDollarSign style={{ color: "white", fontSize: "15px" }} />
                  </span>
                )}
                <span className="">{t("commonTitleComparison")}</span> {/* Some gap between icon and text */}
              </div>
            }
            className="tab_container mt-2"
          //disabled={!isTabAccessible("workForceDataComparison")} // Disable the tab if not accessible
          />
        </Tabs>

      </div>

      <Modal
        show={showModal}
        onHide={handleCancel}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('plan.premiumUpgradeTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('plan.premiumUpgradeHeader')}
          </p>
          <p> {t('plan.premiumUpgradeBody')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleUpgrade}
            className="w-25 me-2"
          >
            {t('plan.upgrade')}
          </Button>
          <Button
            variant="secondary"
            onClick={handleCancel}
            className="w-25"
          >
            {t('settings.tabUsers.modalConfirmation.buttonTitle.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show preview image if tab is not accessible */}
      {restrictedTab && (
        <>

          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)',
            zIndex: 1000
          }}>
            <img
              src={tabImages[restrictedTab]}
              alt={`${restrictedTab} preview`}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </div>
        </>
      )}

      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>

        {key === "tab1" && (
          <TableViewEmploymentData tabSettings={getTabData} steps={joyrideSteps?.workforceInsights?.tabProvinceTerritory} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
        )}
        {key === "tab2" && <LineChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
        {key === "tab3" && <PyramidChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
        {key === "tab4" && <Comparison field={"employmentInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
      </div>
    </div>
  );
};

export default TabForEmployment;

import React, { useState, useEffect, useTransition, useRef } from 'react'
import { useUser } from '../../context/UserContext'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { capitalizeFirstLetter, capitalizeString } from '../../services/commonFn.js'
import httpClient from '../../services/httpClient.ts'
import API_URLS from '../../constants/apiUrls.ts'
import moment from 'moment'
import visaLogo from "../../assets/visa.png";
import mastercardLogo from "../../assets/mastercard.svg";
// import amexLogo from "./assets/amex.png";
import defaultLogo from "../../assets/default-card.png";
import { Loader } from '../../components/Loader.tsx'
import { FaExclamationTriangle } from 'react-icons/fa'

const SubscriptionDetails = () => {
    const navigate = useNavigate()
    const { user, userPlan, updateUserPlan } = useUser()
    const { t } = useTranslation()
    const dropdownRef = useRef(null);
    const stripe = useStripe()
    const elements = useElements()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [showCardModal, setShowCardModal] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
    const [showCardConfirmationModal, setShowCardConfirmationModal] = useState<boolean>(false)
    const [showDefaultCardDeleteMessageModal, setShowDefaultCardDeleteMessageModal] = useState<boolean>(false)
    const [cancelPlanId, setCancelPlanId] = useState('')
    const [upcomingPlans, setUpcomingPlans] = useState<any>([])
    const [selectedPaymentId, setPaymentId] = useState(null)
    const [cardHolderName, setCardHolderName] = useState<string>("")
    const [cardType, setCardType] = useState("default")
    const [cards, setCards] = useState<[] | null>(null)
    const [localUserPlan, setLocalUserPlan] = useState<any>(null);
    const [dropdownIndex, setDropdownIndex] = useState<number | null>(null);
    const [cardComplete, setCardComplete] = useState(false)
    const [expiryComplete, setExpiryComplete] = useState(false)
    const [cvcComplete, setCvcComplete] = useState(false)
    const [cardErrors, setCardErrors] = useState({
        cardHolderName: "",
        cardNumber: "",
        expiry: "",
        cvc: "",

    });

    const getCardLogo = (cardType: any) => {
        switch (cardType) {
            case "visa":
                return visaLogo;
            case "mastercard":
                return mastercardLogo;
            //   case "amex":
            //     return amexLogo;
            default:
                return defaultLogo;
        }
    };

    const validateFields = () => {
        let errors: any = {};

        if (!cardHolderName.trim()) {
            errors.cardHolderName = "Card Holder Name is required.";
        }
        if (cardErrors.cardNumber) {
            errors.cardNumber = cardErrors.cardNumber; // Use error from cardErrors if present
        } else if (!cardComplete) {
            errors.cardNumber = "Card Number is incomplete."; // Default error if cardComplete is false
        }
        if (cardErrors.expiry) {
            errors.expiry = cardErrors.expiry; // Use error from cardErrors if present
        } else if (!expiryComplete) {
            errors.expiry = "Expiry is incomplete.";
        }

        if (cardErrors.expiry) {
            errors.cvc = cardErrors.cvc; // Use error from cardErrors if present
        } else if (!cvcComplete) {
            errors.cvc = "Cvc is incomplete.";
        }

        setCardErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChangeCardHolderName = (e) => {
        const value = e.target.value;
        setCardHolderName(value);

        // Remove error when the user starts typing
        setCardErrors((prev) => ({
            ...prev,
            cardHolderName: value.trim() ? "" : "Card Holder Name is required.",
        }));
    };

    const handleCardNumber = (e) => {
        setCardType(e.brand)
        setCardComplete(e.complete);
        setCardErrors((prev) => ({
            ...prev,
            cardNumber: e.error ? e.error.message : e.complete ? "" : "Incomplete card number",
        }));
    };

    const handleExpiry = (e) => {
        setExpiryComplete(e.complete);
        setCardErrors((prev) => ({
            ...prev,
            expiry: e.error ? e.error.message : e.complete ? "" : "Incomplete expiry date",
        }));
    };

    const handleCvc = (e) => {
        setCvcComplete(e.complete);
        setCardErrors((prev) => ({
            ...prev,
            cvc: e.error ? e.error.message : e.complete ? "" : "Incomplete CVC",

        }));
    };

    const handleClose = () => {
        setCardErrors({}); // Clear errors
        setCardHolderName(""); // Optionally reset input fields
        setCardComplete(false)
        setExpiryComplete(false)
        setCvcComplete(false)
        setShowCardModal(false)
    };

    const toggleDropdown = (index) => {
        setDropdownIndex(dropdownIndex === index ? null : index);
    };

    const handleSetDefault = (paymentMethodId) => {

        const data = {
            userId: user._id,
            paymentMethodId
        }
        httpClient({
            method: "post",
            url: API_URLS.setDefaultCard,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error.message);
                    return
                }
                if (result.status) {
                    setCards(result.cards)
                    toast.success("Card made default successfully");
                } else {
                    setCards([])
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    };

    const handleDeleteCard = (paymentMethodId) => {

        setShowCardConfirmationModal(false)
        setIsLoading(true)
        const data = {
            userId: user._id,
            paymentMethodId
        }
        httpClient({
            method: "post",
            url: API_URLS.detachPaymentMethod,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error.message);
                    setIsLoading(false)
                    return
                }
                if (result.status) {
                    setCards(result.cards)
                    setPaymentId(null)
                    setIsLoading(false)
                    toast.success("Card deleted successfully");
                } else {
                    setCards([])
                    setPaymentId(null)
                    setIsLoading(false)
                    toast.error(result.message)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    };

    const handleCancelSubscription = () => {

        if (!userPlan) {
            toast.error("Subscription plan not found")
            setShowConfirmationModal(false)
            return
        }
        const data = {
            userId: user?._id,
            userSubscriptionId: cancelPlanId
        }
        httpClient({
            method: "post",
            url: API_URLS.cancelUserSubscription,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    setCancelPlanId('')
                    setShowConfirmationModal(false);
                    toast.error(error.message);
                    return
                }
                if (result.status) {
                    updateUserPlan(result.plan);
                    setCancelPlanId('');
                    setShowConfirmationModal(false);
                    fetchUserPlan()
                    fetchUpcomingActivePlans();
                    toast.success(result.message);
                } else {
                    setCancelPlanId('');
                    setShowConfirmationModal(false);
                    toast.error(result.message)
                }
            })
            .catch((error) => {
                setCancelPlanId('')
                setShowConfirmationModal(false);
                console.error("Error:", error);
                toast.error(error.message)
            })
    }

    const handleCardSubmit = async (e) => {
        e.preventDefault();
        if (validateFields()) {
            if (!stripe || !elements) {
                toast.error('Stripe is not loaded yet!');
                return;
            }
            setIsProcessing(true)
            // const name = `${user?.firstName} ${user?.lastName ?? ""}`
            const cardNumberElement = elements.getElement(CardNumberElement)
            try {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        email: user?.email || "",
                        name: cardHolderName || "",
                    },
                });

                if (error) {
                    console.error('Error creating payment method:', error.message);
                    setIsProcessing(false)
                    toast.error(error.message)
                } else {

                    // Send paymentMethod.id to your backend
                    const data = {
                        userId: user._id,
                        paymentMethodId: paymentMethod.id
                    }
                    httpClient({
                        method: "post",
                        url: API_URLS.addCardToStripe,
                        data,
                    })
                        .then(({ result, error }) => {
                            if (result.status) {
                                setShowCardModal(false);
                                setIsProcessing(false)
                                setCardHolderName("")
                                setCardType('default')
                                setCards(result.cards)
                                setCardHolderName(""); // Optionally reset input fields
                                setCardComplete(false)
                                setExpiryComplete(false)
                                setCvcComplete(false)
                                // setIsLoading(false)
                                toast.success("Card Added successfully");
                            } else {
                                setIsProcessing(false)
                                toast.error(error);
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        })
                }
            } catch (err) {
                console.error('Error:', err.message);
            }
        }
    };

    const fetchCustomerCards = async () => {
        if (user) {
            setIsLoading(true)
            const data = {
                userId: user?._id
            }
            httpClient({
                method: "post",
                url: API_URLS.fetchCustomerCards,
                data,
            })
                .then(({ result, error }) => {

                    if (error) {
                        setIsLoading(false)
                        toast.error(error.message);
                        return
                    }
                    if (result.status) {
                        setCards(result.cards)
                        setIsLoading(false)
                        // toast.success("Card added successfully");
                    } else {
                        setCards([])
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                })
        }
    }

    const fetchUpcomingActivePlans = async () => {
        if (user) {
            setIsLoading(true)

            httpClient({
                method: "get",
                url: `${API_URLS.getUpcomingPlans}?userId=${user?._id}`,

            })
                .then(({ result, error }) => {

                    if (error) {
                        setIsLoading(false)
                        toast.error(error.message);
                        return
                    }
                    if (result.success) {
                        setUpcomingPlans(result.upcomingPlans)
                        setIsLoading(false)
                        // toast.success("Card added successfully");
                    } else {
                        setUpcomingPlans([])
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                })
        }
    };

    const fetchUserPlan = async () => {
        try {
            const data = {
                userId: user._id
            };

            const response = await httpClient({
                method: "post",
                url: API_URLS.getUserPlan,
                data
            });

            const { result, error } = response;

            if (error) {
                toast.error(error?.message)
                return;
            }

            if (result && result.status) {
                setLocalUserPlan(result.plan); // Store the plan in local state
                updateUserPlan(result.plan); // Also update the context if needed
            } else {
                setLocalUserPlan(null); // Set local state to null when no active subscription
                updateUserPlan(null); // Update context as well
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    // useEffect(() => {
    //     fetchCustomerCards()
    //     fetchUpcomingActivePlans()
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                setIsLoading(true);
                try {
                    await Promise.all([
                        fetchCustomerCards(),
                        fetchUpcomingActivePlans(),
                        fetchUserPlan()
                    ]);
                } catch (error) {
                    console.error("Error:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [user]);




    // Close the dropdown if a click happens outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                toggleDropdown(null); // Close the dropdown
            }
        };

        // Add event listener for outside click
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts or changes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleDropdown]);

    const handleCancelPlan = (userSubId: string) => {
        if (!userSubId) {
            toast.error('No plan Found')
            return;
        }
        setCancelPlanId(userSubId);
        setShowConfirmationModal(true);
    }

    return (
        <>
          
            <Loader show={isLoading} />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        {(localUserPlan || upcomingPlans?.length > 0) &&
                            (!upcomingPlans?.some(plan => plan.subscriptionPlanId?.price === 0) && localUserPlan?.subscriptionPlanId?.price !== 0) && (
                                (!(localUserPlan?.isCancel && (upcomingPlans?.[0]?.isCancel ?? true))) &&
                                <div className={`alert bg-light text-muted d-flex align-items-center p-3 mb-3 rounded shadow-sm`}>
                                    <FaExclamationTriangle className="me-2" style={{ color: "#FFA500", fontSize: "20px" }} />
                                    <span className="fw-bold">
                                        {t('accounts.tabSubscriptionDetails.cardSubscription.dueMessageText1')}{" "}
                                        {capitalizeFirstLetter(
                                            upcomingPlans?.length > 0 && !upcomingPlans[0]?.isCancel
                                                ? upcomingPlans[0]?.plan
                                                : localUserPlan?.subscriptionPlanId?.plan || ""
                                        )}{" "}
                                        {t('accounts.tabSubscriptionDetails.cardSubscription.dueMessageText2')}{" "}
                                        {upcomingPlans?.length > 0 && !upcomingPlans[0]?.isCancel
                                            ? new Date(upcomingPlans[0].validUpTo).toLocaleDateString('en-GB')
                                            : localUserPlan?.validUpTo
                                                ? new Date(localUserPlan.validUpTo).toLocaleDateString('en-GB')
                                                : ""}
                                    </span>
                                </div>
                            )
                        }


                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div>
                                    <h5>{t('accounts.tabSubscriptionDetails.cardSubscription.heading')}</h5>
                                    <div className="container p-4 border rounded shadow-sm">
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <p className="text-muted">{!localUserPlan ? "No active plan" : `${capitalizeFirstLetter(localUserPlan?.subscriptionPlanId?.plan || '')} Plan`} </p>
                                            <p className="h5 font-weight-bold">{!localUserPlan ? "" : localUserPlan?.subscriptionPlanId?.price === 0 ? '$0' : `$${localUserPlan?.recurrence == 'monthly' ? localUserPlan?.subscriptionPlanId?.price : (localUserPlan?.subscriptionPlanId?.price) * 12}`} {!localUserPlan ? "" : localUserPlan?.subscriptionPlanId?.price === 0 ? `/ ${localUserPlan?.recurrence}` : `/ ${localUserPlan?.recurrence}`}</p>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center border-top pt-4">
                                            {/* <div className="d-flex gap-4"> */}
                                            <div>
                                                <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.price')}</p>
                                                <p className="h5 font-weight-bold">{!localUserPlan ? "" : localUserPlan?.subscriptionPlanId?.price === 0 ? '$ 0' : `$${localUserPlan?.recurrence == 'monthly' ? localUserPlan?.subscriptionPlanId?.price : (localUserPlan?.subscriptionPlanId?.price) * 12}`}</p>
                                            </div>
                                            <div>
                                                <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.planExpiry')}</p>
                                                <p className="h5 font-weight-bold">{localUserPlan && localUserPlan?.startFrom ? moment(localUserPlan?.startFrom).format('MMMM DD, YYYY') : ""} - {localUserPlan && localUserPlan?.validUpTo ? moment(localUserPlan?.validUpTo).format('MMMM DD, YYYY') : ""}</p>
                                            </div>
                                            <div>
                                                <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.status')}</p>
                                                <p className="h5 font-weight-bold">

                                                    <span className={`badge ${(localUserPlan && localUserPlan?.status) === 'expired' ? 'bg-danger' : 'bg-success'} text-white px-3 py-1 rounded`}>{localUserPlan && localUserPlan?.status ? capitalizeFirstLetter(localUserPlan?.status) : capitalizeFirstLetter(localUserPlan?.status)}</span>
                                                </p>
                                            </div>

                                            {/* </div> */}
                                            {/* <button className="btn btn-outline-primary px-4 py-2 rounded shadow-sm hover:bg-light">
                                            View Subscription
                                        </button> */}
                                        </div>

                                    </div>
                                    <div className='row justify-content-end mt-4'>
                                        <div className='d-flex col-md-3 justify-content-end gap-2 w-100 flex-wrap'>
                                            <button type='button' className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => {
                                                if (localUserPlan && !localUserPlan.isCancel) {
                                                    navigate('/subscription-plans', { state: { upgradePlan: true } });
                                                } else {
                                                    navigate('/subscription-plans'); // No state needed for "Choose Plan"
                                                }
                                            }}>{(!localUserPlan || localUserPlan.isCancel === true) ? t('accounts.tabSubscriptionDetails.cardSubscription.button.choosePlan') : t('accounts.tabSubscriptionDetails.cardSubscription.button.upgradeSubscription')}</button>
                                            {localUserPlan?.subscriptionPlanId?.price !== 0 && (
                                                <button type='button' className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px" }} onClick={() => handleCancelPlan(localUserPlan?._id)} disabled={!localUserPlan || localUserPlan?.isCancel}>{t('accounts.tabSubscriptionDetails.cardSubscription.button.cancelSubscription')}</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* upcominmg  plan listing */}

                            {upcomingPlans?.length > 0 && <div className="card-body">
                                <div>
                                    <h5>{t('accounts.tabSubscriptionDetails.cardSubscription.upComingPlanHeading')}</h5>

                                    {upcomingPlans.length > 0 &&
                                        upcomingPlans.map((plan: any) => (
                                            <>
                                                <div className="container p-4 border rounded shadow-sm">
                                                    <div className="d-flex justify-content-between align-items-center ">
                                                        <p className="text-muted">
                                                            {plan.subscriptionPlanId?.plan
                                                                ? `${capitalizeFirstLetter(plan.subscriptionPlanId.plan)} Plan`
                                                                : "No plan"}
                                                        </p>
                                                        <p className="h5 font-weight-bold">
                                                            {plan.subscriptionPlanId?.price === 0 ? "$0" : `$${plan.subscriptionPlanId?.price ?? ""}`}
                                                            {` / ${plan.recurrence}`}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center border-top pt-4">
                                                        {/* <div className="d-flex gap-4"> */}
                                                        <div>
                                                            <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.price')}</p>
                                                            <p className="h5 font-weight-bold">
                                                                {plan.subscriptionPlanId?.price === 0 ? "$0" : `$${plan.subscriptionPlanId?.price ?? ""}`}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.planExpiry')}</p>
                                                            <p className="h5 font-weight-bold">
                                                                {moment(plan.startFrom).format('MMMM DD, YYYY')} - {moment(plan.validUpTo).format('MMMM DD, YYYY')}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-muted">{t('accounts.tabSubscriptionDetails.cardSubscription.label.status')}</p>
                                                            <p className="h5 font-weight-bold">
                                                                <span className={`badge ${(plan && plan?.status) === 'expired' ? 'bg-danger' : 'bg-success'} text-white px-3 py-1 rounded`}>{plan && plan?.status ? capitalizeFirstLetter(plan?.status) : ""}</span>
                                                            </p>
                                                        </div>

                                                        {/* </div> */}
                                                        {/* <button className="btn btn-outline-primary px-4 py-2 rounded shadow-sm hover:bg-light">
                                            View Subscription
                                        </button> */}
                                                    </div>

                                                </div>


                                                <div className='row justify-content-end mt-4'>
                                                    <div className='d-flex col-md-3 justify-content-end gap-2 w-100 flex-wrap'>

                                                        <button type='button' className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px" }} onClick={() => { handleCancelPlan(plan._id) }} disabled={plan?.isCancel}>{t('accounts.tabSubscriptionDetails.cardSubscription.button.cancelSubscription')}</button>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                            </div>}
                        </div>
                        {/* <div className="card shadow-sm">
                    <div className="card-body">
                        <h5 className="font-semibold mb-4">SUBSCRIPTION DETAILS</h5> */}
                        {/* <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <div className="d-flex justify-content-between">
                                            <strong>Subscription Plan:</strong>
                                            <span className="text-muted">Premium Plan</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <div className="d-flex justify-content-between">
                                            <strong>Start Date:</strong>
                                            <span className="text-muted">January 1, 2025</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <div className="d-flex justify-content-between">
                                            <strong>Expiry Date:</strong>
                                            <span className="text-muted">December 31, 2025</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mb-3">
                                        <div className="d-flex justify-content-between">
                                            <strong>Status:</strong>
                                            <span className="badge bg-success text-white">Active</span>
                                        </div>
                                    </div>
                                </div> */}


                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <div>
                                    <h5>{t('accounts.tabSubscriptionDetails.cardPaymentCard.heading')}</h5>
                                    <div className='card-container'>

                                        {
                                            (!isLoading && (!cards || cards.length === 0)) &&
                                            (
                                                <div className='card'>
                                                    <div className='card-body d-flex align-items-center justify-content-between'>
                                                        <p className="mb-0">{t('accounts.tabSubscriptionDetails.cardPaymentCard.text.noCardAdded')}</p>
                                                        <button type="button" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => setShowCardModal(true)}>{t('accounts.tabSubscriptionDetails.cardPaymentCard.button.addCard')}</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            cards && cards.length > 0 &&
                                            (
                                                <div>
                                                    <ul>
                                                        {
                                                            cards && cards.length > 0 ?
                                                                cards.map((card: any, index) => (
                                                                    <li key={index} style={styles.cardItem}>
                                                                        <div style={styles.cardDetails}>

                                                                            <img
                                                                                src={getCardLogo(card?.card?.brand)}
                                                                                alt="Card Type"
                                                                                style={styles.cardListLogo}
                                                                            />

                                                                            <div style={styles.cardInfo}>
                                                                                <div style={styles.cardNumber}>
                                                                                    **** **** **** {card?.card?.last4}
                                                                                </div>
                                                                                <div style={styles.cardName}>
                                                                                    {capitalizeFirstLetter(card?.billingDetails?.name) || ""}
                                                                                </div>
                                                                                <div style={styles.cardExpiry}>
                                                                                    <div>Expires: {card?.card?.exp_month}/{card?.card?.exp_year}</div>
                                                                                    {card?.isDefault && (
                                                                                        <span style={styles.defaultChip}>Default</span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div style={styles.menuWrapper}>
                                                                                <button style={styles.menuButton} onClick={() => toggleDropdown(index)}>
                                                                                    &#x22EE;
                                                                                </button>
                                                                                {dropdownIndex === index && (
                                                                                    <div ref={dropdownRef} style={styles.dropdown}>
                                                                                        {!card?.isDefault &&
                                                                                            <div style={styles.dropdownItem} onClick={() => { handleSetDefault(card.paymentMethodId); toggleDropdown(index) }}>
                                                                                                {t('accounts.tabSubscriptionDetails.cardPaymentCard.cardOptions.setDefault')}
                                                                                            </div>
                                                                                        }

                                                                                        <div style={styles.dropdownItem}
                                                                                            // onClick={() => { setShowCardConfirmationModal(true); setPaymentId(card.paymentMethodId); toggleDropdown(index) }}

                                                                                            // >
                                                                                            onClick={() => {
                                                                                                if (card.isDefault) {
                                                                                                    // Show the modal or different message when card.isDefault is true
                                                                                                    setShowDefaultCardDeleteMessageModal(true);  // You can create a state for the modal for this case
                                                                                                } else {
                                                                                                    // Run the normal onClick functions if card.isDefault is false
                                                                                                    setShowCardConfirmationModal(true);
                                                                                                    setPaymentId(card.paymentMethodId);
                                                                                                    toggleDropdown(index);
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {t('accounts.tabSubscriptionDetails.cardPaymentCard.cardOptions.deleteCard')}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </li>

                                                                ))
                                                                :
                                                                <li>
                                                                    <button type="button" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => setShowCardModal(true)}>{t('accounts.tabSubscriptionDetails.cardPaymentCard.button.addCard')}</button>
                                                                </li>
                                                        }
                                                        <li>
                                                            <button type="button" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ float: "inline-end", minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => setShowCardModal(true)}>{t('accounts.tabSubscriptionDetails.cardPaymentCard.button.addCard')}</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showCardModal}
                onHide={handleClose}
                // size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('accounts.tabSubscriptionDetails.modalAddCard.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                        <div style={styles.container}>
                            <div style={styles.inputGroup}>
                                <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cardHolderName')}</label>
                                <div style={{ backgroundColor: "#f7f7f7", width: "400px", padding: "8px", border: "1px solid #ddd", borderRadius: "5px" }}>
                                    <input
                                        type="text"
                                        placeholder="Enter Card Holder Name"
                                        value={cardHolderName}
                                        onChange={handleChangeCardHolderName}
                                        // maxLength={19} // Maximum length for most card numbers
                                        style={styles.input}
                                    />

                                </div>
                                {cardErrors.cardHolderName && <span style={{ color: "red" }}>{cardErrors.cardHolderName}</span>}
                            </div>
                            <div className="mt-3" style={styles.inputGroup}>
                                <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cardNumber')}</label>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#f7f7f7", width: "400px", border: "1px solid #ddd", borderRadius: "5px" }}>
                                    <div style={{ width: "330px", paddingLeft: "10px" }}>
                                        <CardNumberElement options={{ style }} onChange={(e) => handleCardNumber(e)} />
                                    </div>
                                    <img
                                        src={getCardLogo(cardType)}
                                        alt="Card Type"
                                        style={styles.cardLogo}
                                    />
                                </div>
                                {cardErrors.cardNumber && <span style={{ color: "red" }}>{cardErrors.cardNumber}</span>}
                            </div>
                            <div className="mt-3" style={styles.inputGroup}>
                                <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.expiry')}</label>
                                <div style={{ padding: "15px", backgroundColor: "#f7f7f7", width: "400px", border: "1px solid #ddd", borderRadius: "5px" }}>
                                    <CardExpiryElement options={{ style }} onChange={(e) => handleExpiry(e)} />
                                </div>
                                {cardErrors.expiry && <span style={{ color: "red" }}>{cardErrors.expiry}</span>}
                            </div>
                            <div className="mt-3" style={styles.inputGroup}>
                                <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cvc')}</label>
                                <div style={{ padding: "15px", backgroundColor: "#f7f7f7", width: "400px", border: "1px solid #ddd", borderRadius: "5px" }}>
                                    <CardCvcElement options={{ style }} onChange={(e) => handleCvc(e)} />
                                </div>
                                {cardErrors.cvc && <span style={{ color: "red" }}>{cardErrors.cvc}</span>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn-primary py-2 px-4 rounded" style={{ backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={handleCardSubmit} disabled={isProcessing}>{isProcessing ? t('accounts.tabSubscriptionDetails.modalAddCard.button.processing') : t('accounts.tabSubscriptionDetails.modalAddCard.button.addCard')}</button>
                    <button type="submit" className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={handleClose} disabled={isProcessing}>{t('accounts.tabSubscriptionDetails.modalAddCard.button.close')}</button>
                    {/* <Button className="button button-secondary" variant={null} onClick={handleSubmitForm2(handleResetPassword)}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.save')}</Button>
                    <Button className="button" variant={null} onClick={() => { setShowResetPasswordModal(false); resetForm2() }}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.close')}</Button> */}
                </Modal.Footer>
            </Modal>
            <Modal
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
                backdrop='static'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accounts.tabSubscriptionDetails.modalConfirmation.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('accounts.tabSubscriptionDetails.modalConfirmation.textBody')}
                </Modal.Body>
                <Modal.Footer >

                    <Button type="button" className="w-25 btn btn-primary me-2" onClick={() => handleCancelSubscription()}>
                        {t('accounts.tabSubscriptionDetails.modalConfirmation.button.yes')}
                    </Button>
                    <Button type="button" className="w-25 btn btn-primary" onClick={() => setShowConfirmationModal(false)}>
                        {t('accounts.tabSubscriptionDetails.modalConfirmation.button.cancel')}
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal
                show={showCardConfirmationModal}
                onHide={() => setShowCardConfirmationModal(false)}
                backdrop='static'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accounts.tabSubscriptionDetails.modalConfirmation.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('accounts.tabSubscriptionDetails.modalCardConfirmation.textBody')}
                </Modal.Body>
                <Modal.Footer >

                    <Button type="button" className="w-25 btn btn-primary me-2" onClick={() => handleDeleteCard(selectedPaymentId)}>
                        {t('accounts.tabSubscriptionDetails.modalConfirmation.button.yes')}
                    </Button>
                    <Button type="button" className="w-25 btn btn-primary" onClick={() => setShowCardConfirmationModal(false)}>
                        {t('accounts.tabSubscriptionDetails.modalConfirmation.button.cancel')}
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                show={showDefaultCardDeleteMessageModal}
                onHide={() => setShowDefaultCardDeleteMessageModal(false)}
                backdrop='static'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('accounts.tabSubscriptionDetails.modalCardDeleteMessage.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('accounts.tabSubscriptionDetails.modalCardDeleteMessage.textBody')}
                </Modal.Body>
                <Modal.Footer >

                    <Button type="button" className="w-25 btn btn-primary me-2" onClick={() => setShowDefaultCardDeleteMessageModal(false)}>
                        {t('accounts.tabSubscriptionDetails.modalCardDeleteMessage.button.ok')}
                    </Button>
                    {/* <Button type="button" className="w-25 btn btn-primary" onClick={() => setShowCardConfirmationModal(false)}>
                        {t('accounts.tabSubscriptionDetails.modalConfirmation.button.cancel')}
                    </Button> */}

                </Modal.Footer>
            </Modal>
        </>
        //     </div>
        // </div>
    )

}

export default SubscriptionDetails

const style = {
    base: {
        // Style to make it look like a standard input field
        color: "#495057", // Text color
        fontFamily: "'Arial', sans-serif", // Font family
        fontSize: "16px", // Font size
        fontWeight: "normal", // Font weight
        // padding: "10px", // Padding inside the input
        // borderRadius: "4px", // Rounded corners
        // border: "1px solid #ced4da", // Light border
        backgroundColor: "#f7f7f7", // White background
        // boxSizing: "border-box", // Ensure padding doesn't affect overall size
        // width: "350px",
        // Placeholder styling
        "::placeholder": {
            color: "#6c757d", // Placeholder text color
            opacity: 1, // Ensure placeholder is fully opaque
        },

        // Focus styling
        // ":focus": {
        //     borderColor: "#80bdff", // Border color on focus
        //     outline: "none", // Remove outline on focus
        //     boxShadow: "0 0 0 0.2rem rgba(38, 143, 255, 0.25)", // Blue shadow on focus
        // },
    },
    invalid: {
        // Styling for invalid input
        color: "#fa755a", // Red text color for invalid input
        borderColor: "#fa755a", // Red border on invalid input

    },
};

const styles: Record<string, React.CSSProperties> = {
    container: {

    },
    inputGroup: {
        display: "flex",
        // gap: "10px",
        flexDirection: "column"
    },
    cardLogo: {
        width: "50px",
        height: "50px",
        paddingRight: "10px",
        objectFit: "contain", // Ensure the aspect ratio is maintained
        display: "block", // Ensures the image is treated as a block element
    },
    input: {
        width: "300px",
        padding: "5px",
        fontSize: "16px",
        border: "none",
        outline: "none",
        backgroundColor: "#f7f7f7"
        // borderRadius: "5px",
    },
    cardNumberGroup: {
        backgroundColor: "#fff",
        width: "400px",
        padding: "5px 10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-between",
    },
    // cardItem: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     padding: '10px 15px',
    //     marginBottom: '10px',
    //     borderRadius: '8px',
    //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    //     backgroundColor: '#fff',
    //     border: '1px solid #ddd',
    // },
    cardDetails: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    cardListLogo: {
        width: '40px',
        height: 'auto',
        marginRight: '15px',
    },
    cardInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    cardNumber: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#333',
    },
    cardName: {
        fontSize: '14px',
        color: '#555',
        marginBottom: '5px',
    },
    cardExpiry: {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        // flexDirection: 'column', // Arrange items vertically
        alignItems: 'center', // Align text and chip to the left
        justifyContent: 'space-between',
        gap: '45px', // Add space between "Expires" and "Default" chip
    },
    defaultChip: {
        backgroundColor: '#cbf5fd',
        color: '#1467d4',
        fontSize: '12px',
        padding: '2px 8px',
        borderRadius: '12px',
        fontWeight: 'bold',
    },
    cardItem: {
        position: 'relative', // Needed for dropdown positioning
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        marginBottom: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
    },
    menuWrapper: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    menuButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
    },
    dropdown: {
        position: 'absolute',
        top: '30px',
        right: '0',
        width: '150px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        zIndex: 1000,
    },
    dropdownItem: {
        padding: '10px 15px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee',
        color: '#333',
    },
    dropdownItemLast: {
        borderBottom: 'none',
    },
};
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/esm/Form";
import logo from "../assets/Logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import httpClient from "../services/httpClient.ts";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../constants/apiUrls.ts";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa"; 

const hidden = {
  display: "none",
};

const SetPassword = () => {
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({ mode: "all" });

  const { t, i18n } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [showExpiredModal, setShowExpiredModal] = useState(false)
  const token = searchParams.get('token')
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const storedLanguage = localStorage.getItem("loginLanguage") || "en";
  const [language, setLanguage] = useState(storedLanguage);

  const newPassword = watch("newPassword", "");
  const confirmPassword = watch("confirmPassword", "");

  const validatePasswordMatch = (value) => {
    return value === newPassword || `${t('validation.passwordMatchMessage')}`;
  };

  const fetchTranslations = async (lang) => {
    let url = API_URLS.getTranslationFile.replace("{language}", lang)
    const response = httpClient({
      method: "get",
      url: `${url}&isLoggedIn=${false}`,
    })
      .then(({ result, error }) => {
        if (error) {
          return
        }
        return result
      })

    return response;
  };

  const loadTranslations = async (lang) => {
    const translations = await fetchTranslations(lang);
    if (translations) {
      i18n.addResourceBundle(lang, 'translation', translations);
      i18n.changeLanguage(lang);
      localStorage.setItem("loginLanguage", lang);
    }
  }

  useEffect(() => {
    loadTranslations(language)
  }, [language]);

  useEffect(() =>{
    handleCheckIfTokenExpired()
  },[])

  const languages = [
    { code: "en", label: "English" },
    { code: "fr", label: "Français" },
  ];

  const handleSetPassword = (data) => {
    let url = API_URLS.setPassword;
    httpClient({
      method: "post",
      url,
      data:{password: data.newPassword, token},
    })
      .then(({ result, error }) => {
        if (result) {
          toast.success(result?.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
          reset();
        } else {
            toast.error(error?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleCheckIfTokenExpired= () => {
    let url = API_URLS.checkTokenExpired;
    httpClient({
      method: "post",
      url,
      data:{ token},
    })
      .then(({ result, error }) => {
        if(error){
          setShowExpiredModal(true)
        }

        if (result) {
        } else {
          setShowExpiredModal(true)
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const existingToken = localStorage.getItem("token");
    if (existingToken) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  }, []);

  return (
    <div className="container">
  
      <div className="row no-gutters main-height">
        <div className="col-md-6 left-container">
          <img src={logo} alt="logo" width="150px" />
          <div className="w-500 mt-5">
            <p style={{ fontSize: "22px", fontWeight: "bold" }}>
              {t('setPassword.headerText1')}<br />{t('setPassword.headerText2')}
            </p>
          </div>
        </div>

        <div className="align-items-center bg-white col-md-6 d-flex flex-column p-5">
          <div style={{ marginTop: "-24px" }} className="d-flex justify-content-end w-100">
            <select
              className="form-select language-dropdown mt-[-34px]"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              style={{ width: "98px" }}
            >
              {languages.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          <div className="brand form-style d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "100%", width: "100%" }}>
            {showExpiredModal ? (
              <div className="text-center">
                <h3 className="d-flex align-items-center justify-content-center">
                  <FaExclamationTriangle className="text-danger me-2" size={30} />
                  {t('forgotPassword.linkExpired')}
                </h3>
                <p className="text-danger fw-semibold">
                {t('forgotPassword.expiredMessage')}
                </p>
              </div>
            ) : (
              <>
                <h1 className="pb-3">{t('setPassword.title')}</h1>

                <Form className="mb-5" style={successMessage ? hidden : { width: "100%", maxWidth: "400px" }}>
                  <div className="form-group pb-3">
                    <label>{t('setPassword.formLabel.newPassword')} *</label>
                    <div className="input-group">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        placeholder={t('setPassword.formLabel.newPassword')}
                        className="form-control mt-2"
                        id="InputNewPassword"
                        {...register("newPassword", {
                          required: `${t("login.formLabel.password")} ${t('validation.requiredMessage')}`,
                          minLength: { value: 8, message:  `${t('validation.passwordLengthMessage')}` }
                        })}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={toggleNewPasswordVisibility}
                        style={{ margin: "8px 0 0" }}
                      >
                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {errors.newPassword && <div className="invalid-feedback">{errors.newPassword.message}</div>}
                  </div>

                  <div className="form-group pb-3">
                    <label>{t('setPassword.formLabel.confirmPassword')} *</label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder={t('setPassword.formLabel.confirmPassword')}
                        className="form-control mt-2"
                        id="InputConfirmPassword"
                        {...register("confirmPassword", {
                          required: `${t("login.formLabel.password")} ${t('validation.requiredMessage')}`,
                          validate: validatePasswordMatch,
                        })}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={toggleConfirmPasswordVisibility}
                        style={{ margin: "8px 0 0" }}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword.message}</div>}
                  </div>

                  <button type="submit" className="btn btn-primary mt-4" onClick={handleSubmit(handleSetPassword)}>
                    {t('setPassword.formLabel.submit')}
                  </button>
                </Form>

                <div id="success-message" style={successMessage ? {} : hidden}>
                  <p style={{ color: "rgba(69, 230, 69, 0.836)" }} id="success">
                    {successMessage}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
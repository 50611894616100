import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import logoblack from "../../../assets/fpsc-logo.png";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/GeoIcon.svg";
import { ReactComponent as SectorIcon } from "../../../assets/icons/SectorIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/icons/EducationIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/DocumentIcon.svg";
import { ReactComponent as GearIcon } from "../../../assets/icons/GearIcon.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/LogoutIcon.svg";
import { Link } from "react-router-dom";
// import { useIntercom } from "react-use-intercom";
import { useUser } from '../../../context/UserContext';
import { FiMenu } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RiAccountCircleLine } from "react-icons/ri";
import { Button, Modal } from "react-bootstrap";
import { FaDollarSign } from "react-icons/fa";
import TabEstablishment from "../../../components/TabEstablishment";
import { isLoggedIn } from "../../../pages/utils/authUtils";

const Sidebar = () => {
  const { permissions, logout, user, userPlan, defaultPlan } = useUser();


  const navigate = useNavigate();
  const location = useLocation();
  // const { shutdown } = useIntercom();
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Open by default on desktop
  const [decodedToken, setDecodedToken] = useState({})
  const [plan, setPlan] = useState();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [restrictedTab, setRestrictedTab] = useState(null);

  const handleLogout = () => {
    localStorage.clear();
    logout();
    navigate("/establishment-data");
    // shutdown();
  };


  const tabImages = {
    establishmentInsights: user?.preferredLanguage === 'fr' ? "/tabImages/langFre/establishment/province.png" : "/tabImages/langEng/establishment/province.png",
    educationalProgramInsights: user?.preferredLanguage === 'fr' ? "/tabImages/langFre/educational/province.png" : "/tabImages/langEng/educational/province.png",
    workforceInsights: user?.preferredLanguage === 'fr' ? "/tabImages/langFre/workforce/province.png" : "/tabImages/langEng/workforce/province.png",
  };

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      setDecodedToken(jwtDecode(token))
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true); // Ensure sidebar is open on desktop view
      } else {
        setIsSidebarOpen(false); // Close sidebar initially on mobile
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // This will ensure the component updates if userPlan changes
    if (userPlan) {
      setPlan(userPlan);
    }
  }, [userPlan]);

  const isTabAccessible = (mainkey, featureKey) => {
    if (user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") return true;

    if (isLoggedIn()) {
      return userPlan?.subscriptionPlanId?.features?.[mainkey]?.[featureKey] === "accessible";
    }

    return defaultPlan?.features?.[mainkey]?.[featureKey] === "accessible";
  };

  const handleTab = (event, mainkey, featureKey) => {

    if (!isTabAccessible(mainkey, featureKey) && mainkey && featureKey) {
    
      event.preventDefault(); // Block tab switch
      setRestrictedTab(mainkey);
      setShowModal(true);
    } else {
      setIsSidebarOpen(!isSidebarOpen)
    }
  };


  const handleUpgrade = () => {

    setShowModal(false); // Close the modal
    setRestrictedTab(null);


    isLoggedIn() ? navigate("/subscription-plans", { state: { upgradePlan: true } }) : navigate('/login');

  };

  const handleCancel = () => {
    setShowModal(false); // Close the modal
    setRestrictedTab(null);

  };


  return (
    <>
      <div
        className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <a href="/" className="text-decoration-none sidebar_logo">
          <img src={logoblack} alt="logo" style={{ width: "154px" }} />
        </a>

        {/* Hamburger icon for mobile view */}
        {isMobile && (
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="menu-toggle-button"
            aria-label="Toggle sidebar"
            style={{ background: "none", border: "none", cursor: "pointer", margin: "10px" }}
          >
            {isSidebarOpen ? <IoCloseCircleOutline size={24} /> : <FiMenu size={24} />}
          </button>
        )}

        {/* Sidebar links */}
        {(!isMobile || isSidebarOpen) && (
          <div className={`nav-bar ${isMobile ? 'show' : ''}`} style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

            <div>
              <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item"><LanguageSelector /></li>
                {(user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") &&
                  (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/establishment-data"
                          className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <GlobeIcon className="icon" />
                          {t("sidebarEstablishmentInsights")}
                        </Link>

                      </li>
                      <li>
                        <Link
                          to="/educational-program"
                          className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <EducationIcon className="icon" />
                          {t('sidebarEducationalProgramsInsights')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/employment-data"
                          className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <SectorIcon className="icon" />
                          {t("sidebarWorkforceInsights")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/documents"
                          className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <DocumentIcon className="icon" />
                          {t('sidebarDocuments')}
                        </Link>
                      </li>
                    </>
                  )
                }
                {(user && (user?.role !== "SUPER_ADMIN" || user?.role !== "ADMIN") && userPlan) ||
                  ( !isLoggedIn() && defaultPlan) ? (
                  <>
                    <li className="nav-item d-flex align-items-center">
                      <Link
                        to="/establishment-data"
                        className={`nav-link d-flex align-items-center flex-nowrap ${location.pathname === "/establishment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={(e) => handleTab(e, 'establishmentInsights', 'defaultEstablishmentAccessibility')}
                        style={{ gap: "8px" }} // Ensures proper spacing
                      >
                        <GlobeIcon className="icon" />
                        <span>{t("sidebarEstablishmentInsights")}</span>
                      </Link>

                      {!isTabAccessible('establishmentInsights', 'defaultEstablishmentAccessibility') && (
                        <span
                          className="d-flex align-items-center justify-center ms-auto me-2"
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: "#e61e28",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FaDollarSign style={{ color: "white", fontSize: "12px" }} />
                        </span>
                      )}
                    </li>

                    <li className="nav-item d-flex align-items-center"

                    >
                      <Link
                        to="/educational-program"
                        className={`nav-link d-flex align-items-center flex-nowrap ${location.pathname === "/educational-program" ? "active" : ""}`}
                        aria-current="page"
                        onClick={(e) => handleTab(e, 'educationalProgramInsights', 'defaultEducationalAccessibility')}
                        style={{ gap: "8px" }}
                      >
                        <EducationIcon className="icon" />
                        {t('sidebarEducationalProgramsInsights')}
                      </Link>
                      {!isTabAccessible('educationalProgramInsights', 'defaultEducationalAccessibility') && (
                        <span className="d-flex align-items-center justify-center ms-auto me-2"
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: "#e61e28",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <FaDollarSign style={{ color: "white", fontSize: "12px" }} />
                        </span>
                      )}
                    </li>
                    <li className="nav-item d-flex align-items-center"
                      style={{

                      }}
                    >
                      <Link
                        to="/employment-data"
                        className={`nav-link d-flex align-items-center flex-nowrap ${location.pathname === "/employment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={(e) => handleTab(e, 'workforceInsights', 'defaultWorkforceAccessibility')}
                        style={{ gap: "8px" }}
                      >
                        <SectorIcon className="icon" />
                        {t("sidebarWorkforceInsights")}
                      </Link>
                      {!isTabAccessible('workforceInsights', 'defaultWorkforceAccessibility') && (
                        <span className="d-flex align-items-center justify-center ms-auto me-2"
                          style={{
                            width: "18px",
                            height: "18px",
                            backgroundColor: "#e61e28",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <FaDollarSign style={{ color: "white", fontSize: "12px" }} />
                        </span>
                      )}
                    </li>
                  </>) : null
                }
                {/* {(permissions?.includes('establishmentInsights') || permissions?.includes('full')) && (
                <li className="nav-item">
                  <Link
                    to="/establishment-data"
                    className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <GlobeIcon className="icon" />
                    {t("sidebarEstablishmentInsights")}
                  </Link>
                </li>
              )}
              {(permissions?.includes('educationalProgramInsights') || permissions?.includes('full')) && (
                <li>
                  <Link
                    to="/educational-program"
                    className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <EducationIcon className="icon" />
                    {t('sidebarEducationalProgramsInsights')}
                  </Link>
                </li>
              )}
              {(permissions?.includes('workforceInsights') || permissions?.includes('full')) && (
                <li>
                  <Link
                    to="/employment-data"
                    className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <SectorIcon className="icon" />
                    {t("sidebarWorkforceInsights")}
                  </Link>
                </li>
              )}
              {decodedToken && (decodedToken as any)?.role?.role === "SUPER_ADMIN" && (
                <li>
                  <Link
                    to="/documents"
                    className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <DocumentIcon className="icon" />
                    {t('sidebarDocuments')}
                  </Link>
                </li>
              )} */}
              </ul>
            </div>

            <div className="sidebar-footer" style={{ marginBottom: "30px" }}>
              <ul className="nav nav-pills flex-column mb-0">
                {/* <li className="nav-item">
                <Link
                  to="/subscription-plans"
                  className={`nav-link ${location.pathname === "/subscription-plans" ? "active" : "link-dark"}`}
                  aria-current="page"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <GearIcon className="icon" />
                  {'Subscription Plans'}
                </Link>
              </li> */}
                {(user?.role === "SUPER_ADMIN" || user?.role === "ADMIN") ?
                  (
                    <>
                      {/* < li className="nav-item">
                      <Link
                        to="/account-info"
                        className={`nav-link`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <RiAccountCircleLine
                          fontSize={20}
                          // className={`nav-link ${location.pathname === "/account-info" ? "active" : "link-dark"}`}
                          fill={location.pathname === "/account-info" ? "red" : "gray"}
                        // style={{
                        //   color: location.pathname === "/account-info" ? "red" : "gray", // Change color dynamically
                        //   // transition: "color 0.3s ease", // Smooth transition
                        // }}
                        />
                        {'Account'}
                      </Link>
                    </li> */}
                      <li className="nav-item">
                        <Link
                          to="/settings"
                          className={`nav-link ${location.pathname === "/settings" ? "active" : "link-dark"}`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <GearIcon className="icon" />
                          {t('sidebarSettings')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          role="button"
                          tabIndex={0}
                          onClick={handleLogout}
                        >
                          <LogoutIcon className="icon" />
                          {t('sidebarLogout')}
                        </a>
                      </li>
                    </>
                  )
                  :
                  (isLoggedIn() &&
                    <>
                      < li className="nav-item">
                        <Link
                          to="/account-details"
                          className={`nav-link`}
                          aria-current="page"
                          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        >
                          <RiAccountCircleLine
                            fontSize={20}
                            // className={`nav-link ${location.pathname === "/account-info" ? "active" : "link-dark"}`}
                            fill={location.pathname === "/account-details" ? "red" : "gray"}
                          // style={{
                          //   color: location.pathname === "/account-info" ? "red" : "gray", // Change color dynamically
                          //   // transition: "color 0.3s ease", // Smooth transition
                          // }}
                          />
                          {'Account'}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          role="button"
                          tabIndex={0}
                          onClick={handleLogout}
                        >
                          <LogoutIcon className="icon" />
                          {t('sidebarLogout')}
                        </a>
                      </li>
                    </>
                  )
                }
              </ul>
            </div>
          </div>
        )
        }

        <Modal
          show={showModal}
          onHide={handleCancel}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('plan.premiumUpgradeTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('plan.premiumUpgradeHeader')}
            </p>
            <p> {t('plan.premiumUpgradeBody')}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleUpgrade}
              className="w-25 me-2"
            >
              {t('plan.upgrade')}
            </Button>
            <Button
              variant="secondary"
              onClick={handleCancel}
              className="w-25"
            >
              {t('settings.tabUsers.modalConfirmation.buttonTitle.cancel')}
            </Button>
          </Modal.Footer>
        </Modal>


      </div >


      {/* Show preview image if tab is not accessible */}
      {restrictedTab && (
        <div style={{
          position: 'fixed', // Changed from 'absolute' to 'fixed'
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255,255,255,0.9)', // Slightly more opaque
          zIndex: 1050, // Higher than sidebar and other elements
          pointerEvents: 'auto' // Ensure it captures clicks
        }}>
          <img
            src={tabImages[restrictedTab]}
            alt={`${restrictedTab} preview`}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              boxShadow: '0 0 20px rgba(0,0,0,0.3)' // Optional: adds a nice shadow
            }}
          />
        </div>
      )}
    </>
  );
};

export default Sidebar;
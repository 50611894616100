import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import httpClient from "../services/httpClient.ts";
import API_URLS from "../constants/apiUrls.ts";
import { ToastContainer, toast } from "react-toastify";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { helpStepOptions } from "../constants/commonData.js";
import { Loader } from "./Loader.tsx";
import { useForm } from "react-hook-form";
import { useUser } from "../context/UserContext.js";
import ReactPlayer from "react-player";
import Upload from '../assets/Upload.svg'
import { FaPlayCircle } from "react-icons/fa";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";

const StepHelpUpload = () => {
  const { t } = useTranslation();
  const { fetchJoyrideSteps, joyrideSteps } = useUser();
  const fileInputRef = useRef(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [tabOptions, setTabOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewVideo, setIsNewVideo] = useState(false);
  const [step, setStep] = useState<any>(null);
  const [stepVideoData, setStepVideoData] = useState<[]>([]);
  const [showUploadVideo, setShowUploadVideo] = useState<boolean>(false);
  const [showStepTable, setShowStepTable] = useState<boolean>(true);
  const [hasVideoUrl, setHasVideoUrl] = useState<boolean | null>(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const selectedInsights = watch("selectedInsights");
  const selectedTab = watch("selectedTab");
  const selectedTarget = watch("selectedTarget");

  const onFieldChange = (fieldValue: any) => {
    const selectedFieldObject: any = helpStepOptions.find(
      (field) => field.value === fieldValue
    );
    setTabOptions(selectedFieldObject ? selectedFieldObject?.tab : []);

    // Clear selectedTab and selectedTarget when selectedInsights changes
    setValue("selectedInsights", fieldValue); // Update the selected field
    clearErrors("selectedInsights");
    setValue("selectedTab", ""); // Clear selected tab if no tabs
    setValue("selectedTarget", ""); // Clear selected target if no tabs
    setShowStepTable(false);
  };

  const onTabChange = (tabValue: any) => {
 
    const selectedTabObject: any = tabOptions?.find(
      (tab: any) => tab.value === tabValue
    );
    const targetOptions = selectedTabObject ? selectedTabObject.target : [];
    setTargetOptions(targetOptions);
    setValue("selectedTab", tabValue);
    clearErrors("selectedTab");
    setValue("selectedTarget", "");
  };

  const onTargetChange = (targetValue: any) => {
    setValue("selectedTarget", targetValue);
  };

  // const handleShowTable = (insight, tab, step) => {
  //   console.log(insight, tab, step)
  //   const selectedFieldObject: any = helpStepOptions.find(
  //     (field) => field.value === insight
  //   );
  //   const tabFields = selectedFieldObject ? selectedFieldObject?.tab : []
  //   setTabOptions(tabFields);
  //   setValue("selectedInsights", insight);
  //   if(tabOptions.length > 0) {
  //     setValue("selectedTab", tab);
  //     const selectedTabObject: any = tabFields?.find((item: any) => item.value.toString() === tab);
  //     const targetOptions = selectedTabObject ? selectedTabObject.target : [];
  //     setTargetOptions(targetOptions);

  //     setValue("selectedTarget", step);
  //     setShowStepTable(false)
  //   }
  // }

  const handleShowUploadFile = (insight: string, tab: string, step: string) => {


    const selectedFieldObject: any = helpStepOptions.find(
      (field) => field.value === insight
    );

    const tabFields = selectedFieldObject ? selectedFieldObject?.tab : [];
    setTabOptions(tabFields); // State updates asynchronously

    setValue("selectedInsights", insight);
    setTimeout(() => {
      if (tabFields.length > 0) {
        setValue("selectedTab", tab);
      }
    }, 200);
    const selectedTabObject: any = tabFields?.find(
      (item: any) => item.value.toString() === tab
    );

    const targetOptions = selectedTabObject ? selectedTabObject.target : [];

    setTargetOptions(targetOptions);
    setTimeout(() => {
      if (targetOptions.length > 0) {
        setValue("selectedTarget", step);
      }
    }, 200);

    setShowUploadVideo(true);
  };

  const uploadFile = (data) => {
    try {
      setIsLoading(true);
      const url = API_URLS.uploadStepHelpVideo;
      let formData = new FormData();
      formData.append("file", data.file[0]);
      formData.append("insights", data.selectedInsights);
      formData.append("tab", data.selectedTab);
      formData.append("target", data.selectedTarget);

      httpClient({
        method: "post",
        url,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(({ result, error }) => {
        if (result) {
          fetchJoyrideSteps();
          // reset()
          setIsLoading(false);
          setIsNewVideo(false);
          setValue("file", "");
          toast.success("Video uploaded successfully");
        }

        if (error) {
          setIsLoading(false);
          toast.error(error.error || error.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateVideo = async (videoUrl) => {
    try {
      setIsLoading(true);
      const url = API_URLS.updateStepHelpVideo;
      const formData = getValues();
      const bodyData = {
        insights: formData.selectedInsights,
        tab: formData.selectedTab,
        target: formData.selectedTarget,
        videoUrl,
      };

      httpClient({
        method: "post",
        url,
        data: bodyData,
        // headers: { "Content-Type": "multipart/form-data" },
      }).then(({ result, error }) => {
        if (result) {
          fetchJoyrideSteps();
          // reset()
          setIsLoading(false);
          toast.success("Video changed successfully");
        }

        if (error) {
          setIsLoading(false);
          toast.error("Video not changed");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVideo = async () => {
    try {
      // Trigger validation for all fields
      // const isValid = await trigger(); // This will validate all the fields

      // if (!isValid) {
      //   // If validation fails, you can show a message or handle it
      //   toast.error("Fields not valid");
      //   return; // Stop the function if validation fails
      // }
      setIsLoading(true);
      const url = API_URLS.deleteStepHelpVideo;
      const formData = getValues();
      const bodyData = {
        insights: formData.selectedInsights,
        tab: formData.selectedTab,
        target: formData.selectedTarget,
      };

      httpClient({
        method: "post",
        url,
        data: bodyData,
        // headers: { "Content-Type": "multipart/form-data" },
      }).then(({ result, error }) => {
        if (result) {
          fetchJoyrideSteps();
          // reset()
          setIsLoading(false);
          toast.success("Video deleted successfully");
        }

        if (error) {
          setIsLoading(false);
          toast.error("Video not deleted");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const combineData = () => {
    const combinedData: any = [];
    const json = joyrideSteps; // Assuming joyrideSteps is defined elsewhere

    Object.keys(json).forEach((key) => {
      const insight = helpStepOptions.find((option) => option.value === key); // Match insight name
      if (insight) {
        insight.tab.forEach((tab) => {
          const tabContent = json[key][tab.value]; // Access the tab array for the specific key

          if (Array.isArray(tabContent)) {
            tab.target.forEach((target) => {
              // Try to find matching content for the target
              const content = tabContent.find(
                (item) => item.target === `.${target.value}`
              );

              // Push data into combinedData
              combinedData.push({
                insightName: insight.name,
                insightValue: insight.value,
                insightTranslationKey: insight.translationKey,
                tabName: tab.name,
                tabValue: tab.value,
                tabTranslationKey: tab.translationKey,
                targetName: target.name,
                targetValue: target.value,
                targetTranslationKey: target.translationKey,
                videoUrl: content?.videoUrl ? content.videoUrl : "", // Ensure videoUrl is used correctly
                videoUrls: content?.videoUrls ? content.videoUrls : [],
                content: content ? content.content : "No content available",
              });
            });
          } else {
            // Handle cases where tabContent is not an array
            console.warn(
              `Expected an array for ${tab.value}, but got`,
              tabContent
            );
          }

          if (tab.value === "pagination" && json[key].tabProvinceTerritory) {
            const paginationData = json[key].tabProvinceTerritory;

            const content = paginationData.find(
              (item) => item.target === `.${tab.target?.[0].value}`
            );
            combinedData.push({
              insightName: insight.name,
              insightValue: insight.value,
              insightTranslationKey: insight.translationKey,
              tabName: tab.name,
              tabValue: tab.value,
              tabTranslationKey: tab.translationKey,
              targetName: tab.target[0].name,
              targetValue: tab.target[0].value,
              targetTranslationKey: tab.target[0].translationKey,
              videoUrl: content.videoUrl ? content.videoUrl : "",
              videoUrls: content?.videoUrls ? content.videoUrls : [],
              content: content.content || "No content available",
            });
          }

          if (tab.value === "comparison" && json[key]) {
            const tabContent = json[key]
           
            tab.target.forEach((target) => {
              // Try to find matching content for the target
              const content = tabContent.find(
                (item) => item.target === `.${target.value}`
              );

            combinedData.push({
              insightName: insight.name,
              insightValue: insight.value,
              insightTranslationKey: insight.translationKey,
              tabName: tab.name,
              tabValue: tab.value,
              tabTranslationKey: tab.translationKey,
              targetName: target.name,
              targetValue: target.value,
              targetTranslationKey: target.translationKey,
              videoUrl: content?.videoUrl ? content.videoUrl : "", // Ensure videoUrl is used correctly
              videoUrls: content?.videoUrls ? content.videoUrls : [],
              content: content ? content.content : "No content available",
            });
          })
        }
          // Handling the case where the tab has "parentSteps" or "pieChartSteps"
          if (tab.value === "tabSector" && json[key].tabSector) {
            const sectorData = json[key].tabSector;

            // Handling "parentSteps"
            if (sectorData.parentSteps) {
              sectorData.parentSteps.forEach((step) => {
                const content = tab.target.find((item) =>
                  item.value.includes(step.target.replace(/^\./, ""))
                );
                combinedData.push({
                  insightName: insight.name,
                  insightValue: insight.value,
                  insightTranslationKey: insight.translationKey,
                  tabName: tab.name,
                  tabValue: tab.value,
                  tabTranslationKey: tab.translationKey,
                  targetName: content.name,
                  targetValue: content.value,
                  targetTranslationKey: content?.translationKey,
                  videoUrl: step.videoUrl ? step.videoUrl : "",
                  videoUrls: step?.videoUrls ? step.videoUrls : [],
                  content: step.content || "No content available",
                });
              });
            }

            // Handling "pieChartSteps"
            if (sectorData.pieChartSteps) {
              sectorData.pieChartSteps.forEach((pieChartStep) => {
                const content = tab.target.find((item) =>
                  item.value.includes(pieChartStep.target.replace(/^\./, ""))
                );
                combinedData.push({
                  insightName: insight.name,
                  insightValue: insight.value,
                  insightTranslationKey: insight.translationKey,
                  tabName: tab.name,
                  tabValue: tab.value,
                  tabTranslationKey: tab.translationKey,
                  targetName: content.name,
                  targetValue: `${pieChartStep.target.replace(/^\./, "")}_pie`,
                  targetTranslationKey: content?.translationKey,
                  videoUrl: pieChartStep.videoUrl ? pieChartStep.videoUrl : "",
                  videoUrls: pieChartStep?.videoUrls
                    ? pieChartStep.videoUrls
                    : [],
                  imageSrc: pieChartStep.imageSrc || "No Image", // Assuming imageSrc is optional
                  content: pieChartStep.text || "No text available",
                  placement: pieChartStep.placement || "No placement",
                });
              });
            }
          }
        });
      }
    });

    const filteredData = combinedData.filter((el) => el.videoUrl !== "");

    const groupedData = filteredData.reduce((acc, item) => {
      let existingGroup = acc.find((i) => i.insightName === item.insightName);

      if (existingGroup) {
        existingGroup.stepData.push(item);
      } else {
        acc.push({
          insightName: item.insightName,
          stepData: [item], // Store all matching items here
        });
      }

      return acc;
    }, []);

    return groupedData;
  };

  useEffect(() => {
    let stepData: any = null;
    let videoUrl: boolean = false;

    if (selectedInsights === "comparison") {
      const stepArray = joyrideSteps?.[`${selectedInsights}`];
      stepData = stepArray?.find(
        (step) => step.target === `.${selectedTarget}`
      );
      videoUrl = stepData?.videoUrl;
    } else if (
      selectedInsights === "establishmentInsights" &&
      selectedTab === "tabSector"
    ) {
      const stepArray =
        joyrideSteps?.[`${selectedInsights}`]?.[`${selectedTab}`];
      const splitValues = selectedTarget?.split("_");

      if (splitValues?.[1] === "pie" && stepArray?.pieChartSteps) {
        stepData = stepArray?.pieChartSteps?.find(
          (step) => step.target === `.${splitValues[0]}`
        );
        videoUrl = stepData?.videoUrl;
      } else {
        stepData = stepArray?.parentSteps?.find(
          (step) => step.target === `.${selectedTarget}`
        );
        videoUrl = stepData?.videoUrl;
      }
    } else if (
      selectedTarget === "pagination-filter-dropdown" &&
      selectedInsights === "educationalProgramInsights"
    ) {
      const stepArray =
        joyrideSteps?.[`${selectedInsights}`]?.[`${"tabProvinceTerritory"}`];
      stepData = stepArray?.find(
        (step) => step.target === `.${selectedTarget}`
      );
      videoUrl = stepData?.videoUrl;
    } else if (
      selectedTarget === "url-column" &&
      selectedInsights === "educationalProgramInsights"
    ) {
      const stepArray =
        joyrideSteps?.[`${selectedInsights}`]?.[`${"tabProvinceTerritory"}`];
      stepData = stepArray?.find(
        (step) => step.target === `.${selectedTarget}`
      );
      videoUrl = stepData?.videoUrl;
    } else {
      const stepArray =
        joyrideSteps?.[`${selectedInsights}`]?.[`${selectedTab}`];
      stepData = stepArray?.find(
        (step) => step.target === `.${selectedTarget}`
      );
      videoUrl = stepData?.videoUrl;
    }

    // Update state with step and videoUrl
    setStep(stepData);
    setHasVideoUrl(videoUrl);
    const combinedData = combineData();

    setStepVideoData(combinedData);
  }, [selectedInsights, selectedTab, selectedTarget, joyrideSteps]);

  return (
    <>
    
      <Loader show={isLoading} />
      {!showUploadVideo ? (
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Row className="p-2 border-bottom">
            <div className="col-md-6 d-flex align-items-center">
              <h5 className="">{t('settings.tabVideoUpload.title.videos')}</h5>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              {/* <img src={Upload} alt="" /> */}
              

              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#e61e28",
                  outline: "none",
                  color: "#fff",
                }}
                onClick={() => setShowUploadVideo(true)}
                // onClick={() =>
                //   handleShowTable(
                //     item.insightValue,
                //     item.tabValue,
                //     item.targetValue
                //   )
                // }
              >
                <img src={Upload} className="inline-block align-middle me-2 icon-margin" alt="icon" />{t('settings.tabVideoUpload.button.uploadNewVideo')}
              </button>
            </div>
          </Row>
          <div className="mt-3">
            {stepVideoData.length > 0 ?
              stepVideoData.map((insight: any, index: number) => (
                <React.Fragment key={index}>
                  <h6 className="mb-2">{insight.insightName}</h6>
                  <table className="table table-hover document-table">
                    <thead>
                      <tr>
                        {/* <th
                              scope="col"
                              style={{ borderRadius: "5px 0 0 0" }}
                            >
                              {t(
                                "settings.tabVideoUpload.tableHeaderName.insights"
                              )}
                            </th> */}
                        <th
                          scope="col"
                          style={{
                            width: "40%",
                            whiteSpace: "nowrap",
                            borderRadius: "5px 0 0 0",
                          }}
                        >
                          {t("settings.tabVideoUpload.tableHeaderName.tab")}
                        </th>
                        <th>
                          {t("settings.tabVideoUpload.tableHeaderName.step")}
                        </th>
                        <th>{t("settings.tabVideoUpload.tableHeaderName.totalVideos")}</th>
                        <th>{t("settings.tabVideoUpload.tableHeaderName.defaultVideo")}</th>
                        <th
                          scope="col"
                          style={{ borderRadius: "0 5px 0 0" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {insight.stepData?.length > 0 ? (
                        insight.stepData.map((item: any, index1: number) => (
                          <tr key={index1} className="align-middle">
                            <td
                              style={{
                                width: "40%",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {t(item.tabTranslationKey)}
                            </td>
                            <td style={{ width: "15%" }}>
                              {t(item.targetTranslationKey)}
                            </td>
                            <td style={{ width: "15%" }}>{`${
                              item.videoUrls.length
                            } ${
                              item.videoUrls.length > 1 ? "Videos" : "Video"
                            }`}</td>
                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <button
                                type="button"
                                className="btn"
                                // style={{
                                //   backgroundColor: "#e61e28",
                                //   outline: "none",
                                //   color: "#fff",
                                // }}
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "#007bff", // Bootstrap primary link color
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  padding: 0,
                                }}
                                onClick={() => {
                                  // e.preventDefault(); // Prevents the page from jumping to the top
                                  setSelectedVideoUrl(item.videoUrl);
                                  setShowVideoModal(true);
                                }}
                              >
                                {t(
                                  "settings.tabVideoUpload.tableActionButton.defaultVideo"
                                )}
                              </button>
                            </td>
                            <td
                              style={{
                                width: "15%",
                              }}
                            >
                              <button
                                type="button"
                                className="btn"
                                // style={{
                                //   backgroundColor: "#e61e28",
                                //   outline: "none",
                                //   color: "#fff",
                                // }}
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "#007bff", // Bootstrap primary link color
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  padding: 0,
                                }}
                                onClick={() =>
                                  handleShowUploadFile(
                                    item.insightValue,
                                    item.tabValue,
                                    item.targetValue
                                  )
                                }
                              >
                                {t(
                                  "settings.tabVideoUpload.tableActionButton.view"
                                )}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={4}
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </React.Fragment>
              ))
            :
            <div>
              {t('settings.tabVideoUpload.text.noVideoUploaded')}
            </div>
            }
          </div>

          {/* <button
                    type="button"
                    className="button"
                    onClick={() => {setShowStepTable(true);reset()}}
                  >
                    Back
                  </button> */}
          {/* </div>
            </div>
          )} */}
        </div>
      ) : (
        <>
          <div className="row">
            {/* 60% Column */}
            <div className="col-md-7">
              <div className="row justify-content-start mx-2 mb-3">
                <div className="col-md-4 d-flex align-items-center gap-2 text-start">
                  <FaArrowCircleLeft
                    fontSize={25}
                    onClick={() => {setShowUploadVideo(false);reset()}}
                    // onClick={() => {
                    //   setShowStepTable(true);
                    //   setIsNewVideo(false);
                    //   reset();
                    // }}
                  />
                  <h5 className="mb-0">{t('settings.tabVideoUpload.title.addNewVideo')}</h5>
                </div>
                {/* <div className="col-md-4 text-start">
              
            </div> */}
              </div>
              <form>
                <div className="row g-3 mx-3">
                  {/* Select Field */}
                  <div className="col-md-6 col-12">
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="field" className="text-black">
                        {t("settings.tabVideoUpload.selectField.inputTitle")}
                      </label>
                      <select
                        {...register("selectedInsights", {
                          required: "Field selection is required",
                        })}
                        onChange={(e) => onFieldChange(e.target.value)}
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                      >
                        <option value="">
                          {t("settings.tabVideoUpload.selectField.inputTitle")}
                        </option>
                        {helpStepOptions.map((field: any) => (
                          <option key={field.value} value={field.value}>
                            {t(field.translationKey)}
                          </option>
                        ))}
                      </select>
                      {errors.selectedInsights && (
                        <span className="text-danger">
                          {errors.selectedInsights.message?.toString()}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Select Tab */}
                  <div className="col-md-6 col-12">
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="tab" className="text-black">
                        {t("settings.tabVideoUpload.selectTab.inputTitle")}
                      </label>
                      <select
                        {...register("selectedTab", {
                          required: "Tab selection is required",
                        })}
                        onChange={(e) => onTabChange(e.target.value)}
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                      >
                        <option value="">
                          {t("settings.tabVideoUpload.selectTab.inputTitle")}
                        </option>
                        {tabOptions?.map((field: any) => (
                          <option key={field.value} value={field.value}>
                            {t(field.translationKey)}
                          </option>
                        ))}
                      </select>
                      {errors.selectedTab && (
                        <span className="text-danger">
                          {errors.selectedTab.message?.toString()}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Select Step */}
                  <div className="col-md-6 col-12">
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="step" className="text-black">
                        {t("settings.tabVideoUpload.selectStep.inputTitle")}
                      </label>
                      <select
                        {...register("selectedTarget", {
                          required: "Step selection is required",
                        })}
                        onChange={(e) => onTargetChange(e.target.value)}
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                      >
                        <option value="">
                          {t("settings.tabVideoUpload.selectStep.inputTitle")}
                        </option>
                        {targetOptions.map((field: any) => (
                          <option key={field.value} value={field.value}>
                            {t(field.translationKey)}
                          </option>
                        ))}
                      </select>
                      {errors.selectedTarget && (
                        <span className="text-danger">
                          {errors.selectedTarget.message?.toString()}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="custom-form-group custom-form-group-md">
                      <label htmlFor="file" className="text-black">
                        {t("settings.tabVideoUpload.selectFile.inputTitle")}
                      </label>
                      <Form.Control
                        type="file"
                        {...register("file", {
                          required: "File selection is required",
                          validate: {
                            fileSize: (value) => {
                              // Validate file size is <= 30 MB
                              if (value[0]?.size <= 30 * 1024 * 1024) {
                                return true;
                              }
                              return "File size should not exceed 30 MB";
                            },
                            fileExtension: (value) => {
                              const allowedExtensions = [
                                "mp4",
                                "webm",
                                "mpeg",
                                "mkv",
                              ];
                              const fileName = value[0]?.name || "";
                              const fileExtension = fileName
                                .split(".")
                                .pop()
                                .toLowerCase();
                              if (allowedExtensions.includes(fileExtension)) {
                                return true;
                              }
                              return `Only ${allowedExtensions.join(
                                ", "
                              )} files are allowed`;
                            },
                          },
                        })}
                        className="form-control"
                      />
                      {errors.file && (
                        <span className="text-danger">
                          {errors.file.message?.toString()}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-start gap-2">
                      <Button
                        variant="primary"
                        onClick={handleSubmit(uploadFile)}
                      >
                        {t("translationButtonTitleUploadFile")}
                      </Button>
                      <Button variant="secondary" onClick={() => reset()}>
                        {t("translationButtonTitleReset")}
                      </Button>
                      {/* <Button variant="secondary" onClick={() => setIsNewVideo(false)}>
                                            {'close'}
                                        </Button> */}
                    </div>
                  </div>

                  {/* {showStepTable && (
              <>
              {stepVideoData.length > 0 && stepVideoData.map((insight, index) => (
                <>
                <h1>{insight}</h1>
                <table className="table table-hover document-table">
                  <thead> 
                    <tr>
                      <th scope="col" style={{ borderRadius: "5px 0 0 0" }}>
                        {t('settings.tabVideoUpload.tableHeaderName.insights')}
                      </th>
                      <th>{t('settings.tabVideoUpload.tableHeaderName.tab')}</th>
                      <th>{t('settings.tabVideoUpload.tableHeaderName.step')}</th>
                      <th
                        scope="col"
                        style={{ borderRadius: "0 5px 0 0" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {insight.stepData.length > 0 ? (
                      insight.stepData.map((item: any, index1: number) => (
                        <tr className="align-middle">
                          <td>{t(item.insightTranslationKey)}</td>
                          <td>{t(item.tabTranslationKey)}</td>
                          <td>{t(item.targetTranslationKey)}</td>
                          <td style={{ textAlign: "center" }}>
                            <button
                              type="button"
                              className="btn"
                              style={{backgroundColor: "#e61e28", outline: "none", color: "#fff"}}
                              onClick={() =>
                                handleShowTable(
                                  item.insightValue,
                                  item.tabValue,
                                  item.targetValue
                                )
                              }
                            >
                              {t('settings.tabVideoUpload.tableActionButton.view')}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                </>
              ))}
                
              </>
            )} */}

                  {/* {showStepTable && (
               
              )} */}
                </div>
              </form>
            </div>
            <div className="col-md-5 d-flex flex-column">
              <div className="flex-grow-1">
                {selectedInsights && selectedTab && selectedTarget ? (
                  step && (
                    <div className="">
                    {/* <div className="card-body"> */}
                        {step?.videoUrls && step?.videoUrls?.length > 0 ? (
                          <div className="row">
                            {/* Video Player Section */}
                            <div className="col-md-12 col-12 d-flex flex-column gap-2">
                              {hasVideoUrl ? (
                                <>
                                  <h5>
                                    {t(
                                      "settings.tabVideoUpload.text.defaultVideo"
                                    )}
                                  </h5>
                                  <div
                                    className="w-xs-100 w-sm-75 w-100"
                                    style={{
                                      maxWidth: "100%",
                                      position: "relative",
                                      height: 0,
                                      paddingBottom: "56.25%",
                                    }}
                                  >
                                    <ReactPlayer
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                            style: {
                                              objectFit: "cover",
                                              width: "100%",
                                              height: "100%",
                                            },
                                          },
                                        },
                                      }}
                                      style={{
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                        backgroundColor: "#020202",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                      }}
                                      url={hasVideoUrl}
                                      controls={true}
                                      className="w-100"
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>

                                  {/* Remove Video Button */}
                                  <div className="d-flex justify-content-end align-items-center gap-2">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={deleteVideo}
                                    >
                                      {t("delete")}
                                    </button>
                                    {/* <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => setIsNewVideo(true)}
                                    >
                                      {t(
                                        "settings.tabVideoUpload.button.newVideo"
                                      )}
                                    </button> */}
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>
                                    {t(
                                      "settings.tabVideoUpload.text.noActiveVideo"
                                    )}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                    {/* </div> */}
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="flex-grow-1 p-3">
                {step?.videoUrls?.length > 0 && (
                  <div className="col-md-12 col-12">
                    <h5>{t("settings.tabVideoUpload.text.allVideos")}</h5>
                    <div className="video-list">
                      {step.videoUrls.map((video, index) => (
                        <div key={index} className="card mb-2">
                          <div className="card-body d-flex align-items-center justify-content-between">
                            {/* Video Thumbnail & Name */}
                            <div
                              className="d-flex align-items-center gap-2"
                              style={{ flex: "1 1 auto" }}
                            >
                              <MdOutlineVideoLibrary fontSize={25} />
                              {/* <div style={{ width: '100px', height: '60px', flexShrink: 0 }}>
                                <ReactPlayer
                                    url={video?.url}
                                    light={true}
                                    playing={false}
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    style={{ borderRadius: '10px', overflow: 'hidden', backgroundColor: "#020202" }}
                                    onClick={() => {
                                        setSelectedVideoUrl(video?.url);
                                        setShowVideoModal(true);
                                    }}
                                />
                            </div> */}
                              {/* <span style={{maxWidth: "200px", cursor: "pointer"}} onClick={() => {
                                        setSelectedVideoUrl(video?.url);
                                        setShowVideoModal(true);
                                    }}>
                                {video?.name || 'Unnamed Video'}
                            </span> */}
                              <a
                                href="#"
                                style={{
                                  maxWidth: "200px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "inline-block",
                                }}
                                onClick={(e) => {
                                  e.preventDefault(); // Prevents the page from jumping to the top
                                  setSelectedVideoUrl(video?.url);
                                  setShowVideoModal(true);
                                }}
                              >
                                {video?.name || "Unnamed Video"}
                              </a>
                            </div>

                            {/* Action Button (Only if Video is Inactive) */}
                            {video?.status === "inactive" && (
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn"
                                  style={{
                                    backgroundColor: "#FFDDDF",
                                    outline: "none",
                                    color: "#EC4E55",
                                  }}
                                  onClick={() => updateVideo(video?.url)}
                                >
                                  {t(
                                    "settings.tabVideoUpload.button.setDefault"
                                  )}
                                </button>
                              </div>
                            )}
                            {video?.status === "active" && (
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="btn"
                                  style={{
                                    color: "#31A63A",
                                    border: "1px solid #CAF1DD",
                                    outline: "none",
                                    backgroundColor: "#fff",
                                  }}
                                  // onClick={() => updateVideo(video?.url)}
                                >
                                  {t(
                                    "settings.tabVideoUpload.button.default"
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title>{t('settings.tabVideoUpload.text.videoPlayer')}</Modal.Title>
                </Modal.Header> */}
        <Modal.Body style={{ padding: "0", position: "relative" }}>
          {/* Video Player */}
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  style: { objectFit: "cover", width: "100%", height: "100%" },
                },
              },
            }}
            style={{
              borderRadius: "7px",
              overflow: "hidden",
              backgroundColor: "#020202",
            }}
            url={selectedVideoUrl ?? ""}
            controls={true}
            className="w-100"
            height="300px"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StepHelpUpload;
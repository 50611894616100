import React, { useState } from 'react'
import Payment from './StripeForms'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';


//const stripePromise = loadStripe('pk_test_51QoO6T019k3qhSrmDrA7w2CG739HLmLlLq4toDos5bJbJQ6scFGHjaFq3XFyxnZ0x0AJyT4Kjy9VomvlxFkOAFBG00TbI0wDck');
const stripePromise = loadStripe('pk_test_51QfgosAC6MN0hm6W81Omk3Db1GX5X6M1SCJ8l9CKkqmADFjkiSJnSKlmFxNy6Qj9EtbZEfRX88pJvjm5ZfX8f1Rh00wuADDXmt');
const Checkout = () => {

  const location = useLocation();
  const { clientSecret } = location.state || {};
  const { plan, isUpgrading } = location.state || {};  // Get the plan data passed from the previous page




  const options = {

    clientSecret

  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <Payment clientSecret={clientSecret} plan={plan} isUpgrading={isUpgrading} />
    </Elements>
  )
}

export default Checkout
import React, { useState, useEffect } from 'react';
import { FaCreditCard, FaInfoCircle, FaPlus } from "react-icons/fa";
import { Modal, Button, Form } from 'react-bootstrap';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import visaLogo from "../assets/visa.png";
import mastercardLogo from "../assets/mastercard.svg";
import defaultLogo from "../assets/default-card.png";
import { useNavigate } from "react-router-dom";
import { useUser } from '../context/UserContext';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from 'react-toastify';
import httpClient from '../services/httpClient.ts';
import { v4 as uuidv4 } from 'uuid';
import { STRIPE_ERROR_CODES, STRIPE_ERROR_MESSAGES } from '../../src/constants/errorConstants.ts';
import { keyTextMapping } from '../constants/commonData.js';
import { Loader } from '../components/Loader.tsx';
import { FaCheckCircle } from 'react-icons/fa';
import { capitalizeFirstLetter } from '../services/commonFn.js';
import CommonHeader from './Header.tsx';

export default function Payment({ clientSecret, plan, isUpgrading }) {

  const [paymentIntent, setPaymentIntent] = useState({});
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const { userPlan, updateUserPlan } = useUser();
  const { t } = useTranslation()
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements();
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState(null);
  const [showCardModal, setShowCardModal] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("")
  const [cardType, setCardType] = useState("default");
  const [modalError, setModalError] = useState("");
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default: Monthly
  const [displayPrice, setDisplayPrice] = useState(plan?.price); // Displayed Price
  const [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal] = useState(false);
  const [showUpgradeConfirmationModal, setShowUpgradeConfirmationModal] = useState(false);
  const { user } = useUser();
  // Determine the user's current billing cycle from userPlan
  const currentBillingCycle = userPlan?.recurrence || "monthly"; 


  useEffect(() => {
    if (currentBillingCycle === "yearly") {
      setBillingCycle("yearly");
    } else {
      setBillingCycle("monthly");
    }
  }, [currentBillingCycle]);
  const getTextForKey = ((key) => {
    return keyTextMapping[key] || key
  });

  
    // Calculate the adjusted price when upgrading
    useEffect(() => {
      let basePrice;
    
      if (isUpgrading && plan?.price > userPlan?.subscriptionPlanId?.price) {
        // Calculate the price difference for upgrading
        if (billingCycle === "yearly") {
          // If upgrading to yearly, calculate the yearly price of the new plan
          const yearlyPriceOfNewPlan = plan?.price * 12;
          // Subtract the remaining value of the current plan
          basePrice = yearlyPriceOfNewPlan - userPlan?.subscriptionPlanId?.price;
        } else {
          // If upgrading to monthly, calculate the monthly difference
          basePrice = plan?.price - userPlan?.subscriptionPlanId?.price;
        }
      } else {
        // If not upgrading, use the new plan's price directly
        basePrice = billingCycle === "yearly" ? plan?.price * 12 : plan?.price;
      }
    
      // Set the display price
      setDisplayPrice(basePrice);
    }, [isUpgrading, plan?.price, userPlan?.subscriptionPlanId?.price, billingCycle]);

  // Call the API in a useEffect if the user exists
  useEffect(() => {
    if (user) {
      fetchCustomerCards();
    }
  }, []);

 // Function to handle price toggle
 const handleBillingChange = (cycle) => {
  setBillingCycle(cycle);
 
 
  
  const basePrice = (isUpgrading && plan?.price > userPlan?.subscriptionPlanId?.price ) ? (cycle === "yearly" ? ((plan?.price * 12 )- userPlan?.subscriptionPlanId?.price) : plan?.price - userPlan?.subscriptionPlanId?.price) : plan.price;

  setDisplayPrice(cycle === "yearly" && !isUpgrading ? basePrice * 12 : basePrice);
};


  async function confirmation() {
    if (!stripe || !clientSecret) {
      // Stripe hasn't loaded or clientSecret is not available yet
      return;
    }

    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);

      if (error) {
        console.error('Payment confirmation error:', error.message);
        setStatus(`Payment failed: ${error.message}`);
      } else if (paymentIntent) {
        setPaymentIntent(paymentIntent);
        setStatus(paymentIntent.status);
      }
    } catch (err) {
      console.error('Unexpected error during payment confirmation:', err);
    }

  };

  // Validation for the card :
  const validateCardDetails = () => {
    if (!cardHolderName || cardHolderName.trim() === "") {
      setModalError("Card holder name is required .");
      return false;
    }
    // Additional validations (Card Number, Expiry, CVC)
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardNumberElement._complete) {
      setModalError("Invalid card number .");
      return false;
    }
    if (!cardExpiryElement || !cardExpiryElement._complete) {
      setModalError("Invalid expiry date .");
      return false;
    }
    if (!cardCvcElement || !cardCvcElement._complete) {
      setModalError("CVC is required and must be 3 or more digits .");
      return false;
    }
    setModalError(""); // Reset error if all fields are valid
    return true;
  };

  // Fetch the user cards :
  const fetchCustomerCards = async () => {
    try {


      const response = await httpClient({
        method: "post",
        url: API_URLS.fetchCustomerCards,
        data: { userId: user._id },
      });

      const { result, error } = response;

      if (error) {
        throw new Error(error); // Throw error to be caught below
      }

      if (result?.status) {
        setCards(result.cards);
      } else {
        setCards([]);
      }
    } catch (err) {
      console.error("Error fetching customer cards:", err);
      toast.error(err.message || "An unexpected error occurred.");
    }
  };



  // Set the default card if available
  useEffect(() => {
    if (cards && cards.length > 0) {
      const defaultCard = cards.find((card) => card.isDefault === true);
      if (defaultCard) {
        setSelectedCard(defaultCard.paymentMethodId); // Set default card's paymentMethodId
      } else {
        setSelectedCard(cards[0].paymentMethodId); // Fallback to the first card
      }
    }
  }, [cards]);

  // Handle card selection by paymentMethodId
  const handleCardSelect = (paymentMethodId) => {
    setSelectedCard((prev) => (prev === paymentMethodId ? null : paymentMethodId));
  };


  const getCardLogo = (cardType) => {
    switch (cardType) {
      case "visa":
        return visaLogo;
      case "mastercard":
        return mastercardLogo;
      //   case "amex":
      //     return amexLogo;
      default:
        return defaultLogo;
    }
  };

  const handleCardNumber = (e) => {
    setCardType(e.brand)
  };

  const handleCardSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      alert('Stripe is not loaded yet!');
      return;
    }

    if (!validateCardDetails()) {
      return; // Stop if validation fails
    }

    setIsProcessing(true); // Indicate that the submission is in progress
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          email: user?.email || "",
          name: cardHolderName, // Use the entered cardholder name
        },
      });

      if (error) {
        console.error('Error creating payment method:', error.message);
        setModalError(`Error: ${error.message}`);
        setIsProcessing(false); // Stop processing
      } else {

        // Send paymentMethod.id to your backend
        const data = {
          userId: user._id,
          paymentMethodId: paymentMethod.id
        }
        httpClient({
          method: "post",
          url: API_URLS.addCardToStripe,
          data,
        })
          .then(({ result, error }) => {
            setIsProcessing(false); // Stop processing
            if (result.status) {
              setShowCardModal(false);
              setCardHolderName("");
              setCardType('default');
              setCards(result.cards);
              toast.success("Card Added successfully");
            } else {
              toast.error(error || "An error occurred while adding the card.");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setIsProcessing(false); // Stop processing
            setModalError("Failed to add card. Please try again.");
          });
      }
    } catch (err) {
      console.error('Error:', err.message);
      setIsProcessing(false); // Stop processing
      setModalError("An unexpected error occurred.");
    }
  };

  // Helper function to filter and format accessible and limited features
  const formatFeatures = (features) => {
    const featureCategories = [];

    // Iterate over each category (Establishment Insights, etc.)
    Object.keys(features).forEach((category) => {
      const categoryFeatures = features[category];
      const accessibleFeatures = [];

      if (category === 'educationalProgramInsights') {
        // For educationalProgramInsights, only get the subcategory names
        Object.keys(categoryFeatures).forEach((subCategory) => {
          // Skip fields starting with 'default'
          if (subCategory.startsWith('default')) {
            return;
          }

          // Add the subcategory name (not the value)
          accessibleFeatures.push(t(getTextForKey(subCategory)));
        });
      } else {
        // For other categories, dive into the subcategory and get feature names
        const seenFeatures = new Set(); // Track seen features to avoid repetition

        Object.keys(categoryFeatures).forEach((subCategory) => {
          // Skip fields starting with 'default'
          if (subCategory.startsWith('default')) {
            return;
          }

          const subCategoryFeatures = categoryFeatures[subCategory];
          if (typeof subCategoryFeatures === 'object' && subCategoryFeatures !== null) {
            // Dive into the subcategory and get feature names
            Object.keys(subCategoryFeatures).forEach((feature) => {
              // Skip fields starting with 'default'
              if (feature.startsWith('default') || feature==='dataYearRange') {
                return;
              }

              // Skip if the feature has already been seen
              if (seenFeatures.has(feature)) {
                return;
              }

              // Add the feature name (not the value)
              accessibleFeatures.push(t(getTextForKey(feature)));
              seenFeatures.add(feature); // Mark the feature as seen
            });
          }
        });
      }

      // Group features into chunks of 2 per line
      const groupedFeatures = [];
      for (let i = 0; i < accessibleFeatures.length; i += 2) {
        groupedFeatures.push(accessibleFeatures.slice(i, i + 2).join(', '));
      }

      // Only add the category if it has accessible or limited features
      if (groupedFeatures.length > 0) {
        featureCategories.push({
          category,
          features: groupedFeatures, // Store grouped features
        });
      }
    });

    return featureCategories;
  };

  const formattedFeatures = formatFeatures(plan?.features);

 // const formattedFeatures = formatFeatures(plan?.features);

 
  const handlePayment = async () => {
    try {
      if (!user || !user._id) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!selectedCard) {
        toast.error("Please select a payment method.");
        return;
      }

      if (plan?.price <= 0 || displayPrice <= 0) {
        toast.error("Invalid payment amount.");
        return;
      }



      setIsLoading(true);
      setPaymentIsProcessing(true);

      const idempotencyKey = uuidv4();

      const response = await httpClient({
        method: "post",
        url: isUpgrading ? API_URLS.upgradePlan : API_URLS.createPaymentIntent,
        headers: {
          'Idempotency-Key': idempotencyKey,
        },
        data: {
          userId: user._id,
          amount: displayPrice,
          currency: plan?.currency,
          paymentMethodId: selectedCard,
          planId: plan._id,
          billingCycle: billingCycle
        },
      });

      if (!response || response.error) {
        toast.error(response?.error?.message || "Failed to initiate payment. Please try again.");
        console.error("Payment Intent API Error:", response?.error);
        setIsLoading(false);
        return;
      }

      const { result } = response;

      if (result.requiresAction) {
        const { clientSecret } = result;

        if (!stripe || !elements) {
          toast.error("Stripe is not initialized.");
          return;
        }

        const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(clientSecret);

        if (stripeError) {
          console.error("Stripe Payment Error:", stripeError);
          const errorCode = stripeError.code || STRIPE_ERROR_CODES.UNKNOWN_ERROR;
          const errorMessage = STRIPE_ERROR_MESSAGES[errorCode] || stripeError.message;
          setIsLoading(false); // Stop the loader before navigation
          toast.error(errorMessage || "Payment failed. Please try again.");
          return;
        }

        if (paymentIntent?.status === "succeeded") {
          toast.success("Payment successful! ✅");
          setIsLoading(false); // Stop the loader before navigation
          navigate("/establishment-data");
        } else {
          setIsLoading(false); // Stop the loader before navigation
          toast.error("Payment failed. Please try again later.");
        }
      } else if (result.status === true) {

        const data = {
          userId: user._id
        };

        const response = await httpClient({
          method: "post",
          url: API_URLS.getUserPlan,
          data
        });

        const { result, error } = response;

        if (result.status) {
          setShowSuccessSubscriptionModal(true);
          updateUserPlan(result.plan);
          // Stop the loader before showing modal and navi

          // Show toast first, then navigate after a delay
          //toast.success("Subscription added successfully! ✅");

          setTimeout(() => {
            setIsLoading(false)
            navigate("/establishment-data");
          }, 1500); // Delay navigation to allow toast to be visible
        }


      }

    } catch (err) {
      setIsLoading(false); // Stop the loader before showing modal and navi
      toast.error(err.message || "An unexpected error occurred.");
    } finally {
      setIsLoading(false); // Stop the loader before showing modal and navi
      setPaymentIsProcessing(false);
    }

  };

  useEffect(() => {
    if (showSuccessSubcriptionModal) {
      const timer = setTimeout(() => {
        setShowSuccessSubscriptionModal(false);
        navigate('/establishment-data')
      }, 2000); // 2 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal]);

  return (
    <>
  <CommonHeader
            title={t('settings.tabCheckout.checkoutDetails')}
            subtitle={`Source: ${t('dataSourceEducation')}`}
         
          />
      <Loader show={isLoading} />
      <div className="checkout-payment-wrapper">
        {/* Heading */}
        {/* <div className="checkout-heading">
          <h1>{t('settings.tabCheckout.checkoutDetails')}</h1>
        </div> */}

        {/* Content: Left and Right Layout */}
        <div className="checkout-content">
          {/* Left Side: Plan Details */}
          <div className="plan-details">
            <h2>{t('settings.tabCheckout.planDetail.planDetail')}</h2>
            <ul>
              <li>
                <strong>{t('settings.tabCheckout.planDetail.planName')} :</strong> {capitalizeFirstLetter(plan?.plan)}
              </li>
              <li style={{ display: "flex", alignItems: "center", gap: "13px" }}>
                <strong>{t('settings.tabCheckout.planDetail.prize')} :</strong> 
                
                  ${displayPrice || 0} {plan?.currency.toUpperCase() || 'CAD'} 
                  {/* {isUpgrading && plan?.price > userPlan?.subscriptionPlanId?.price&&<span className="feature-tooltip-container" ><FaInfoCircle className="feature-info-icon" /><span className="feature-tooltip-text">{isUpgrading && plan?.price > userPlan?.subscriptionPlanId?.price ? t('settings.tabCheckout.planDetail.upgradePriceExplanation') : ''}</span></span>} */}

                {/* Radio Buttons for Monthly / Yearly */}
                <label style={{ color: billingCycle === "monthly" ? "red" : "black" }}>
                  <input
                    type="radio"
                    value="monthly"
                    checked={billingCycle === "monthly"}
                    onChange={() => handleBillingChange("monthly")}
                    style={{ marginRight: "5px" }}
                  />
                  {t('settings.tabCheckout.planDetail.buttonOptions.monthly')}
                </label>

                <label style={{ color: billingCycle === "yearly" ? "red" : "black" }}>
                  <input
                    type="radio"
                    value="yearly"
                    checked={billingCycle === "yearly"}
                    onChange={() => handleBillingChange("yearly")}
                    style={{ marginRight: "5px" }}
                  />
                  {t('settings.tabCheckout.planDetail.buttonOptions.yearly')}
                </label>
              </li>

              {formattedFeatures.length > 0 && (
                <li>
                  <strong>{t('settings.tabCheckout.planDetail.features')} :</strong>
                  <ul className="feature-list">
                    {formattedFeatures.map((category, index) => (
                      <li key={index}>
                        <strong>
                          {category.category === 'workforceInsights'
                            ? t('sidebarWorkforceInsights')
                            : t(category.category)}
                          :
                        </strong>
                        {category.features.map((featureGroup, idx) => (
                          <div key={idx} className="feature-group">
                            {featureGroup}
                          </div>
                        ))}
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>


          </div>

          {/* Right Side: Card Details */}
          <div className="card-details">
            <h2>  {t('settings.tabCheckout.cardDetails.savedCards')}</h2>

            <div className="add-card">
              <div className="card-info">
                <div className="card-icon"> <FaCreditCard size={24} /></div>
                <div>
                  <p style={{ marginBottom: "0rem" }}>  {t('settings.tabCheckout.cardDetails.creditCard')}</p>
                </div>
              </div>
              <div className="add-icon" onClick={() => setShowCardModal(true)}>+</div>
            </div>

            {cards && cards.length > 0 && cards.map((card, index) => {



              return (
                <div
                  key={index}
                  className={`saved-card ${selectedCard === card.paymentMethodId ? "selected" : ""}`}
                  onClick={() => handleCardSelect(card.paymentMethodId)}
                >
                  <div className="card-info">
                    <div className="card-icon">
                      <img
                        src={getCardLogo(card?.card?.brand)}
                        alt="Card Type"
                        style={styles.cardListLogo}
                      />
                    </div>
                    <div>
                      <p style={{ fontWeight: "bolder", fontSize: "16px" }}>**** **** **** {card?.card?.last4}</p>
                      <p>{t('settings.tabCheckout.cardForm.labels.expiry ')}: {card?.card?.exp_month}/{card?.card?.exp_year}</p>
                    </div>
                  </div>
                  <div className="checkbox-custom">
                    {selectedCard === card.paymentMethodId && <div className="checkbox-dot"></div>}
                  </div>
                </div>
              );
            })}

            <button type="submit"
              onClick={() => {
                if (isUpgrading && plan?.price > userPlan?.subscriptionPlanId?.price) {
                  setShowUpgradeConfirmationModal(true);
                } else {
                  handlePayment();
                }
              }}
              disabled={isProcessing}
              className="checkout-paymentButton">
              {paymentIsProcessing ? "Payment Processing..." : "Pay Now"}
            </button>

            <Modal
              show={showCardModal}
              onHide={() => setShowCardModal(false)}
              // size="lg"
              dialogClassName="modal-90w"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t('accounts.tabSubscriptionDetails.modalAddCard.title')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                {modalError && (
                  <div style={{ color: "red" }}>
                    {modalError} {/* Display the error message */}
                  </div>
                )}
                <div className='d-flex align-items-center justify-content-center mt-3'>

                  <div style={styles.inputGroup}>
                    <div style={styles.inputGroup}>
                      <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cardHolderName')}</label>
                      <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                        <input
                          type="text"
                          placeholder="Enter Card Holder Name"
                          value={cardHolderName}
                          onChange={(e) => setCardHolderName(e.target.value)}
                          // maxLength={19} // Maximum length for most card numbers
                          style={styles.input}
                        />

                      </div>
                    </div>
                    <div style={styles.inputGroup}>
                      <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cardNumber')}</label>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#f7f7f7", width: "400px", marginRight: "5px" }}>
                        <div style={{ padding: "10px", width: "350px" }}>
                          <CardNumberElement options={{ style }} onChange={(e) => handleCardNumber(e)} />
                        </div>
                        <img
                          src={getCardLogo(cardType)}
                          alt="Card Type"
                          style={styles.cardLogo}
                        />
                      </div>
                    </div>
                    <div style={styles.inputGroup}>
                      <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.expiry')}</label>
                      <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                        <CardExpiryElement />
                      </div>
                    </div>
                    <div style={styles.inputGroup}>
                      <label>{t('accounts.tabSubscriptionDetails.modalAddCard.inputTitle.cvc')}</label>
                      <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                        <CardCvcElement />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button disabled={isProcessing} className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={handleCardSubmit}> {isProcessing ? "Processing..." : t('accounts.tabSubscriptionDetails.modalAddCard.button.addCard')}</button>
                <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={() => setShowCardModal(false)}>{t('accounts.tabSubscriptionDetails.modalAddCard.button.close')}</button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <Modal
          show={showSuccessSubcriptionModal}
          onHide={() => { setShowSuccessSubscriptionModal(false); navigate('/establishment-data') }}
          backdrop="static" // Prevent closing on outside click
          keyboard={false} // Prevent closing with "Escape" key
          centered>
          <Modal.Header closeButton>
            {/* // <Modal.Title>{t('subscription.toast.success')}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

            {/* Icon and Success Text */}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px" }}>
              <FaCheckCircle style={{ fontSize: "50px", color: "green" }} />
              <p style={{ fontSize: "18px", fontWeight: "bold", margin: "10px 0 0 0" }}>{t('subscription.toast.success')}</p>
            </div>

            {/* Subscription Message */}
            {isUpgrading ? (
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                You have successfully upgraded to <strong>{capitalizeFirstLetter(plan?.plan)}</strong> Plan
              </p>
            ) : (
              <p style={{ fontSize: "16px", fontWeight: "500" }}>
                {t('subscription.toast.message')} <strong>{capitalizeFirstLetter(plan?.plan)}</strong> {t('subscription.toast.plan')}.
              </p>
            )}

          </Modal.Body>
        </Modal>

        <Modal
  show={showUpgradeConfirmationModal}
  onHide={() => setShowUpgradeConfirmationModal(false)}
  dialogClassName="modal-90w"
  aria-labelledby="contained-modal-title-vcenter"
  centered
>
  <Modal.Header closeButton>
    <Modal.Title id="contained-modal-title-vcenter">
      {t('settings.tabCheckout.confirmUpgradeModal.title')}
    </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>
    {t('settings.tabCheckout.confirmUpgradeModal.body.upgradingPlan')} <strong>{capitalizeFirstLetter(plan?.plan)}</strong> {t('settings.tabCheckout.confirmUpgradeModal.body.plan')}.
    </p>
    <ul>
      <li>
      {t('settings.tabCheckout.confirmUpgradeModal.body.upgradingPlan')} <strong>${plan?.price}</strong>.
      </li>
      <li>
      {t('settings.tabCheckout.confirmUpgradeModal.body.messageToUser')} (
        <strong>{capitalizeFirstLetter(userPlan?.subscriptionPlanId?.plan)}</strong>), {t('settings.tabCheckout.confirmUpgradeModal.body.priceChargeMessage')}{" "}
        <strong>${displayPrice}</strong>.
      </li>
      
    </ul>
    <p>
    {t('settings.tabCheckout.confirmUpgradeModal.body.confirmMessage')}
    </p>
  </Modal.Body>
  <Modal.Footer>
    <button
      className="bg-gray-200 text-gray-600 py-2 px-4 rounded"
      onClick={() => setShowUpgradeConfirmationModal(false)}
    >
      {t('settings.tabSubscription.buttonTitle.cancel')}
    </button>
    <button style={{padding :"9px 24px"}}
      className="checkout-paymentButton"
      onClick={async () => {
        setShowUpgradeConfirmationModal(false); // Close the modal
         handlePayment(); // Proceed with payment
      }}
    >
       {t('settings.tabCheckout.confirmUpgradeModal.body.button.upgrade')}
    </button>
  </Modal.Footer>
</Modal>
      </div>
    </>
  );
}

const style = {
  base: {
    // Style to make it look like a standard input field
    color: "#495057", // Text color
    fontFamily: "'Arial', sans-serif", // Font family
    fontSize: "16px", // Font size
    fontWeight: "normal", // Font weight
    backgroundColor: "#f7f7f7", // White background
    "::placeholder": {
      color: "#6c757d", // Placeholder text color
      opacity: 1, // Ensure placeholder is fully opaque
    },
  },
  invalid: {
    // Styling for invalid input
    color: "#fa755a", // Red text color for invalid input
    borderColor: "#fa755a", // Red border on invalid input
  },
};

const styles = {
  inputGroup: {
    display: "flex",
    // alignItems: "center",
    gap: "10px",
    flexDirection: "column"
  },
  cardLogo: {
    width: "50px",
    height: "22px",
    objectFit: "contain", // Ensure the aspect ratio is maintained
    display: "block", // Ensures the image is treated as a block element
  },
  input: {
    width: "300px",
    padding: "5px",
    fontSize: "16px",
    border: "none",
    outline: "none",
    backgroundColor: "#f7f7f7"
    // borderRadius: "5px",
  },
  cardNumberGroup: {
    backgroundColor: "#fff",
    width: "400px",
    padding: "5px 10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
  },
  cardDetails: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  cardListLogo: {
    width: '40px',
    height: 'auto',
  },
  cardInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardNumber: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#333',
  },
  cardName: {
    fontSize: '14px',
    color: '#555',
    marginBottom: '5px',
  },
  cardExpiry: {
    fontSize: '14px',
    color: '#666',
    display: 'flex',
    // flexDirection: 'column', // Arrange items vertically
    alignItems: 'flex-start', // Align text and chip to the left
    gap: '45px', // Add space between "Expires" and "Default" chip
  },
  defaultChip: {
    backgroundColor: '#cbf5fd',
    color: '#1467d4',
    fontSize: '12px',
    padding: '2px 8px',
    borderRadius: '12px',
    fontWeight: 'bold',
  },
  cardItem: {
    position: 'relative', // Needed for dropdown positioning
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    marginBottom: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
  },
  menuWrapper: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  menuButton: {
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  dropdown: {
    position: 'absolute',
    top: '30px',
    right: '0',
    width: '150px',
    backgroundColor: '#fff',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    zIndex: 1000,
  },
  dropdownItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee',
    color: '#333',
  },
  dropdownItemLast: {
    borderBottom: 'none',
  },
};

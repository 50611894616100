// import React, { useState, useEffect } from "react";
// import httpClient from "../../services/httpClient.ts";
// import API_URLS from "../../constants/apiUrls.ts";
// import { useTranslation } from "react-i18next";
// import { Modal, Button, Form, Card } from "react-bootstrap";
// import { capitalizeFirstLetter } from "../../services/commonFn.js";
// import { features, helperText, keyTextMapping } from "../../constants/commonData.js";
// import { ToastContainer, toast } from "react-toastify";
// import { FaInfoCircle } from "react-icons/fa";

// const Feature = () => {
//   const { t } = useTranslation();

//   const [subscriptionPlan, setSubscriptionPlan] = useState(null);
//   const [planFeatures, setPlanFeatures] = useState<any>(null);
//   const [subscriptionPlans, setSubscriptionPlans] = useState([]);
//   const [checkedFeatures, setCheckedFeatures] = useState({});
//   const [errors, setErrors] = useState({}); // To store validation errors

//   const getTextForKey = ((key: string) =>{ 

//    return keyTextMapping[key] || key
//   });
//   const getHelperTextForKey = (key: string) => helperText[key] || key;



//   useEffect(() => {
//     getSubscriptionPlans();
//   }, []);

//   const getSubscriptionPlans = async () => {
//     try {
//       const { result } = await httpClient({ method: "get", url: API_URLS.getSubscriptionPlans });
//       setSubscriptionPlans(result.status ? result.subscriptions : []);
//     } catch (error) {
//       console.error("Error fetching subscription plans:", error);
//     }
//   };

//   const getSubscriptionPlanDetail = async (planId) => {
//     try {
//       const { result } = await httpClient({ method: "get", url: `${API_URLS.getSubscriptionPlanDetail}?planId=${planId}` });
//       if (result.status) {
//         setPlanFeatures(result.subscriptions.features);
//         setCheckedFeatures(result.subscriptions.features);
//       }
//     } catch (error) {
//       console.error("Error fetching subscription plan details:", error);
//     }
//   };

//   const handleSubscriptionChange = (e) => {
//     const selectedPlan = JSON?.parse(e.target.value) || "";
//     setSubscriptionPlan(selectedPlan);
//     getSubscriptionPlanDetail(selectedPlan._id);
//   };

//   const updateFeature = (category, field, newValue) => {
//     setPlanFeatures((prevFeatures: any) => ({
//       ...prevFeatures,
//       [category]: {
//         ...prevFeatures[category],
//         [field]: newValue,
//       },
//     }));
//   };

//   const handleCheckboxChange = (category, field, checked) => {
//     const newValue =
//       field === "businessSize" ||
//         field === "dataYears" ||
//         field === "demographicSectorDropdown" ||
//         (category === "workforceInsights" && field === "sectors")
//         ? checked
//           ? "full"
//           : "limited"
//         : checked
//           ? "accessible"
//           : "inaccessible";

//     updateFeature(category, field, newValue);

//     // Trigger backend update immediately after the toggle change
//     handleUpdateSubscriptionFeatures({
//       ...planFeatures,
//       [category]: {
//         ...planFeatures[category],
//         [field]: newValue,
//       },
//     });
//   };

//   const handleUpdateSubscriptionFeatures = (updatedFeatures) => {
//     const data = {
//       planId: subscriptionPlan,
//       features: updatedFeatures,
//     };

//     httpClient({
//       method: "post",
//       url: API_URLS.updateSubscriptionFeatures,
//       data,
//     })
//       .then(({ result, error }) => {
//         if (result.status) {
//           toast.success(result.message);
//         }
//         if (error) {
//           toast.error(error.message);
//           return;
//         }
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//         toast.error(error.message || "something went wrong ,please try after some time");
//       });
//   };

//     // Function to get the default accessibility key for a category
//     const getDefaultAccessibilityKey = (category) => {
//       switch (category) {
//         case "establishmentInsights":
//           return "defaultEstablishmentAccessibility";
//         case "educationalProgramInsights":
//           return "defaultEducationalAccessibility";
//         case "workforceInsights":
//           return "defaultWorkforceAccessibility";
//         default:
//           return "";
//       }
//     };

//     const handleYearChange = (category, field, newYearRange) => {
//       const currentYear = new Date().getFullYear();
//       const { startYear, endYear } = newYearRange;
    
//       // Validation
//       const newErrors = { ...errors };
//       if (startYear > currentYear) {
//         newErrors[`${category}-startYear`] = "Start year cannot be greater than the current year.";
//       } else if (endYear < startYear) {
//         newErrors[`${category}-endYear`] = "End year must be greater than start year.";
//       } else {
//         delete newErrors[`${category}-startYear`];
//         delete newErrors[`${category}-endYear`];
//       }
//       setErrors(newErrors);
    
//       // Update feature only if there are no errors
//       if (!newErrors[`${category}-startYear`] && !newErrors[`${category}-endYear`]) {
//         updateFeature(category, "establishmentDataYearRange", { yearStart: startYear, yearEnd: endYear });
//         handleUpdateSubscriptionFeatures({
//           ...planFeatures,
//           [category]: {
//             ...planFeatures[category],
//             establishmentDataYearRange: { yearStart: startYear, yearEnd: endYear },
//           },
//         });
//       }
//     };

//       // Generate years from 2000 to current year
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years :any = [];
//     for (let year = 2000; year <= currentYear; year++) {
//       years.push(year);
//     }
//     return years;
//   };


//   return (
//     <div className="container mt-4">
//       <ToastContainer />
//       <h1 className="m-0">{t("settings.tabTitle.features")}</h1>

//       <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
//         <Form.Group controlId="formSubscriptionPlan" className="mb-3">
//           <Form.Label>{t('settings.tabSubscription.selectSubscription')}</Form.Label>
//           <Form.Control
//             as="select"
//             value={subscriptionPlan ? JSON.stringify(subscriptionPlan) : ""}
//             onChange={handleSubscriptionChange}
//             className="form-select c-form-group"
//             style={{ width: "350px" }}
//           >
//             <option value="">Select Plan</option>
//             {subscriptionPlans.filter((plan: any) => plan.status !== "inactive").map((item: any) => (
//               <option key={item._id} value={item && JSON.stringify(item) || ""}>
//                 {capitalizeFirstLetter(item?.plan)}
//               </option>
//             ))}
//           </Form.Control>
//         </Form.Group>
//       </div>

//       <div className="feature-cards">
//         {planFeatures &&
//           Object.entries(planFeatures).map(([category, fields]) => {
//             const defaultAccessibilityKey = getDefaultAccessibilityKey(category);
//             return (
//             <Card key={category} className="mb-3">
//               <Card.Body>
//               <div className="d-flex align-items-center mb-4">
//                   <Card.Title className="m-0">
//                     {category === 'workforceInsights' ? t('sidebarWorkforceInsights') : t(category)}
//                   </Card.Title>
//                  {/* Toggle for default accessibility */}
//                  <label className="toggle-switch ms-3">
//                       <input
//                         type="checkbox"
//                         id={`${category}-${defaultAccessibilityKey}`}
//                         checked={fields[defaultAccessibilityKey] === "accessible"}
//                         onChange={(e) =>
//                           handleCheckboxChange(category, defaultAccessibilityKey, e.target.checked)
//                         }
//                       />
//                       <span className="slider"></span>
//                     </label>
//                   </div>
//                   {Object.entries(fields as any)?.map(([field, value]) => {
//                     // Skip rendering the default accessibility toggle again
//                     if (field === defaultAccessibilityKey) return null;

//                     // Render year range inputs for dataYears in establishmentInsights
//                     if (category === "establishmentInsights" && field === "dataYears") {
//                       const startYear = planFeatures[category]?.establishmentDataYearRange?.yearStart || new Date().getFullYear() - 1;
//                       const endYear = planFeatures[category]?.establishmentDataYearRange?.yearEnd || new Date().getFullYear();
//                       console.log(value)
//                       return (
//                         <div className="toggle-container mb-3 d-flex align-items-center" key={`${category}-${field}`}>
//                           <label className="toggle-switch">
//                             <input
//                               type="checkbox"
//                               id={`${category}-${field}`}
//                               checked={ value === "full" || value === "accessible"}
//                               onChange={(e) =>
//                                 handleCheckboxChange(category, field, e.target.checked)
//                               }
                             
//                             />
//                             <span className="slider"></span>
//                           </label>
//                           <span className="toggle-label" style={{ marginLeft: "20px" }}>
//                           {t(getTextForKey(field))}
//                         </span>
                        
//                          { value === 'full' && <div className="d-flex align-items-center gap-2 ms-3">
//                             <Form.Control
//                               as="select"
//                               value={startYear}
//                               onChange={(e) => {
//                                 const newStartYear = parseInt(e.target.value);
//                                 handleYearChange(category, "establishmentDataYearRange", { startYear: newStartYear, endYear });
//                               }}
//                               //disabled={fields[defaultAccessibilityKey] === "inaccessible"}
//                               style={{ width: "100px" }}
//                             >
//                               {generateYears().map((year) => (
//                                 <option key={year} value={year}>
//                                   {year}
//                                 </option>
//                               ))}
//                             </Form.Control>
//                             <span>-</span>
//                             <Form.Control
//                               as="select"
//                               value={endYear}
//                               onChange={(e) => {
//                                 const newEndYear = parseInt(e.target.value);
//                                 handleYearChange(category, "establishmentDataYearRange", { startYear, endYear: newEndYear });
//                               }}
//                               //disabled={fields[defaultAccessibilityKey] === "inaccessible"}
//                               style={{ width: "100px" }}
//                             >
//                               {generateYears().map((year) => (
//                                 <option key={year} value={year}>
//                                   {year}
//                                 </option>
//                               ))}
//                             </Form.Control>
//                           </div>}
//                           <span className="feature-tooltip-container">
//                           <FaInfoCircle className="feature-info-icon" />
//                           <span className="feature-tooltip-text">{t(getHelperTextForKey(field))}</span>
//                         </span>
//                           {errors[`${category}-startYear`] && (
//                             <div className="text-danger">{errors[`${category}-startYear`]}</div>
//                           )}
//                           {errors[`${category}-endYear`] && (
//                             <div className="text-danger">{errors[`${category}-endYear`]}</div>
//                           )}
//                         </div>
//                       );
//                     }
//                     return (
//                       <div className="toggle-container mb-3" key={`${category}-${field}`}>
//                         <label className="toggle-switch">
//                           <input
//                             type="checkbox"
//                             id={`${category}-${field}`}
//                             checked={value === "full" || value === "accessible"}
//                             onChange={(e) =>
//                               handleCheckboxChange(category, field, e.target.checked)
//                             }
//                             //disabled={fields[defaultAccessibilityKey] === "inaccessible"}
//                           />
//                           <span className="slider"></span>
//                         </label>
//                         <span className="toggle-label" style={{ marginLeft: "20px" }}>
//                           {t(getTextForKey(field))}
//                         </span>
//                         <span className="feature-tooltip-container">
//                           <FaInfoCircle className="feature-info-icon" />
//                           <span className="feature-tooltip-text">{t(getHelperTextForKey(field))}</span>
//                         </span>
//                       </div>
//                     );
//                   })}
//               </Card.Body>
//             </Card>
// )})}
//       </div>


//     </div>
//   );
// };

// export default Feature;

import React, { useState, useEffect } from "react";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "../../constants/apiUrls.ts";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn.js";
import { features, helperText, keyTextMapping } from "../../constants/commonData.js";
import { ToastContainer, toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";

const Feature = () => {
  const { t } = useTranslation();

  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [planFeatures, setPlanFeatures] = useState<any>(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [checkedFeatures, setCheckedFeatures] = useState({});
  const [errors, setErrors] = useState({}); // To store validation errors

  const getTextForKey = ((key: string) => { 
    return keyTextMapping[key] || key;
  });
  const getHelperTextForKey = (key: string) => helperText[key] || key;

  useEffect(() => {
    getSubscriptionPlans();
  }, []);
  

  const getSubscriptionPlans = async () => {
    try {
      const { result } = await httpClient({ method: "get", url: API_URLS.getSubscriptionPlans });
      setSubscriptionPlans(result.status ? result.subscriptions : []);
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  };

  const getSubscriptionPlanDetail = async (planId) => {
    try {
      const { result } = await httpClient({ method: "get", url: `${API_URLS.getSubscriptionPlanDetail}?planId=${planId}` });
      if (result.status) {
        setPlanFeatures(result.subscriptions.features);
        setCheckedFeatures(result.subscriptions.features);
      }
    } catch (error) {
      console.error("Error fetching subscription plan details:", error);
    }
  };

  const handleSubscriptionChange = (e) => {
    const selectedPlan = JSON?.parse(e.target.value) || "";
    setSubscriptionPlan(selectedPlan);
    getSubscriptionPlanDetail(selectedPlan._id);
  };

  const updateFeature = (category, subCategory='', field, newValue) => {
    if (!subCategory) {
      // Update top-level feature
      setPlanFeatures((prevFeatures) => ({
        ...prevFeatures,
        [category]: {
          ...prevFeatures[category],
          [field]: newValue,
        },
      }));
    } else {
      // Update nested feature
      setPlanFeatures((prevFeatures) => ({
        ...prevFeatures,
        [category]: {
          ...prevFeatures[category],
          [subCategory]: {
            ...prevFeatures[category][subCategory],
            [field]: newValue,
          },
        },
      }));
    }
  };

  const handleCheckboxChange = (category, subCategory = '', field, checked) => {
   
  
    let newValue = checked ? "accessible" : "inaccessible";
  
    // Fields that follow "full" ↔ "limited" toggling
    if (
      field === "businessSize" || 
      field === "dataYears" || 
      field === "demographicSectorDropdown" || 
      (category === "workforceInsights" && field === "sectors")
    ) {
      newValue = checked ? "full" : "limited";
    }
  
    let updatedFeatures = { ...planFeatures };
  
    if (!subCategory) {
      // Updating a main category
      updatedFeatures[category] = {
        ...updatedFeatures[category],
        [field]: newValue,
      };


         // If turning ON main category, ensure at least first subcategory is ON
    if (checked && field === getDefaultAccessibilityKey(category)) {
      const subCategories = Object.keys(updatedFeatures[category]).filter(
        key => typeof updatedFeatures[category][key] === "object"
      );
      
      if (subCategories.length > 0) {
        const firstSubCategory = subCategories[0];
        const subCategoryDefaultKey = `default${capitalizeFirstLetter(firstSubCategory)}Accessibility`;
        
        updatedFeatures[category][firstSubCategory] = {
          ...updatedFeatures[category][firstSubCategory],
          [subCategoryDefaultKey]: "accessible"
        };
        
        
      }
    }


  
      // If turning OFF main category, also turn OFF all subcategories and their features
      if (!checked && field === getDefaultAccessibilityKey(category)) {
        Object.keys(updatedFeatures[category])?.forEach((sub) => {
          if (typeof updatedFeatures[category][sub] === "object") {
            Object.keys(updatedFeatures[category][sub])?.forEach((feature) => {
              // Skip `dataYearRange` to avoid overwriting it with a string
              if (feature === "dataYearRange") {
                updatedFeatures[category][sub][feature] = {
                  yearStart: null,
                  yearEnd: null,
                };
              } else {
                updatedFeatures[category][sub][feature] = 
                feature === "dataYears" || feature === "businessSize" ||  feature === "demographicSectorDropdown" ? "limited" : "inaccessible";
              }
            });
          }
        });
      }
    } else {
      // Updating a subcategory
     
      updatedFeatures[category][subCategory] = {
        ...updatedFeatures[category][subCategory],
        [field]: newValue,
      };
  
      // If turning OFF subcategory, also turn OFF all its features
      if (!checked && field === `default${capitalizeFirstLetter(subCategory)}Accessibility`) {
        Object.keys(updatedFeatures[category][subCategory])?.forEach((feature) => {
          // Skip `dataYearRange` to avoid overwriting it with a string
          if (feature === "dataYearRange") {
            updatedFeatures[category][subCategory][feature] = {
              yearStart: null,
              yearEnd: null,
            };
          } else {
            updatedFeatures[category][subCategory][feature] = 
              feature === "dataYears" || feature === "businessSize" ||  feature === "demographicSectorDropdown"? "limited" : "inaccessible";
          }
        });

         // NEW: Check if this is the last active subcategory
      const otherSubCategories = Object.keys(updatedFeatures[category])
      .filter(key => typeof updatedFeatures[category][key] === "object" && key !== subCategory);
    
    const hasOtherActiveSubCategory = otherSubCategories.some(subCat => {
      const subCatDefaultKey = `default${capitalizeFirstLetter(subCat)}Accessibility`;
      return updatedFeatures[category][subCat][subCatDefaultKey] === "accessible";
    });

    // If main category is ON and this is the last active subcategory, prevent turning OFF
    if (updatedFeatures[category][getDefaultAccessibilityKey(category)] === "accessible" && !hasOtherActiveSubCategory) {
      updatedFeatures[category][getDefaultAccessibilityKey(category)] = "inaccessible";
        
      // Also turn OFF all other subcategories' features (just to be thorough)
      Object.keys(updatedFeatures[category])?.forEach((sub) => {
        if (typeof updatedFeatures[category][sub] === "object") {
          Object.keys(updatedFeatures[category][sub])?.forEach((feature) => {
            if (feature === "dataYearRange") {
              updatedFeatures[category][sub][feature] = {
                yearStart: null,
                yearEnd: null,
              };
            } else {
              updatedFeatures[category][sub][feature] = 
                feature === "dataYears" || feature === "businessSize" || feature === "demographicSectorDropdown"? "limited" : "inaccessible";
            }
          });
        }
      });
    }

    
  }
      

      
    }
  
    // Ensure `dataYearRange` is updated when toggling `dataYears` directly
    if (field === "dataYears" && !checked) {
      if (subCategory) {
        updatedFeatures[category][subCategory]["dataYearRange"] = {
          yearStart: null,
          yearEnd: null,
        };
      } else {
        updatedFeatures[category]["dataYearRange"] = {
          yearStart: null,
          yearEnd: null,
        };
      }
    }
  
    // Update the state and send to backend
    setPlanFeatures(updatedFeatures);
    handleUpdateSubscriptionFeatures(updatedFeatures);
  };
  
  
  
  const handleUpdateSubscriptionFeatures = (updatedFeatures) => {
    const data = {
      planId: subscriptionPlan,
      features: updatedFeatures,
    };

    httpClient({
      method: "post",
      url: API_URLS.updateSubscriptionFeatures,
      data,
    })
      .then(({ result, error }) => {
        if (result.status) {
          toast.success(result.message);
        }
        if (error) {
          toast.error(error.message);
          return;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message || "something went wrong ,please try after some time");
      });
  };

  const getDefaultAccessibilityKey = (category) => {
    switch (category) {
      case "establishmentInsights":
        return "defaultEstablishmentAccessibility";
      case "educationalProgramInsights":
        return "defaultEducationalAccessibility";
      case "workforceInsights":
        return "defaultWorkforceAccessibility";
      default:
        return "";
    }
  };

  const handleYearChange = (category, subCategory, startYear, endYear) => {
    const currentYear = new Date().getFullYear();

    // Validation
    const newErrors = { ...errors };
    if (startYear > currentYear) {
      newErrors[`${category}-${subCategory}-startYear`] = "Start year cannot be greater than the current year.";
    } else if (endYear < startYear) {
      newErrors[`${category}-${subCategory}-endYear`] = "End year must be greater than start year.";
    } else {
      delete newErrors[`${category}-${subCategory}-startYear`];
      delete newErrors[`${category}-${subCategory}-endYear`];
    }
    setErrors(newErrors);

    // Update feature only if there are no errors
    if (!newErrors[`${category}-${subCategory}-startYear`] && !newErrors[`${category}-${subCategory}-endYear`]) {
      updateFeature(category, subCategory, "dataYearRange", { yearStart: startYear, yearEnd: endYear });
      handleUpdateSubscriptionFeatures({
        ...planFeatures,
        [category]: {
          ...planFeatures[category],
          [subCategory]: {
            ...planFeatures[category][subCategory],
            dataYearRange: { yearStart: startYear, yearEnd: endYear },
          },
        },
      });
    }
  };

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years: any = [];
    for (let year = 2000; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h1 className="m-0">{t("settings.tabTitle.features")}</h1>

      <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
        <Form.Group controlId="formSubscriptionPlan" className="mb-3">
          <Form.Label>{t('settings.tabSubscription.selectSubscription')}</Form.Label>
          <Form.Control
            as="select"
            value={subscriptionPlan ? JSON.stringify(subscriptionPlan) : ""}
            onChange={handleSubscriptionChange}
            className="form-select c-form-group"
            style={{ width: "350px" }}
          >
            <option value="">Select Plan</option>
            {subscriptionPlans.filter((plan: any) => plan.status !== "inactive").map((item: any) => (
              <option key={item._id} value={item && JSON.stringify(item) || ""}>
                {capitalizeFirstLetter(item?.plan)}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      <div className="feature-cards">
  {planFeatures &&
    Object.entries(planFeatures).map(([category, categoryFields]) => {
      const defaultAccessibilityKey = getDefaultAccessibilityKey(category);

      return (
        <Card key={category} className="mb-3">
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  id={`${category}-${defaultAccessibilityKey}`}
                  checked={categoryFields[defaultAccessibilityKey] === "accessible"}
                  onChange={(e) =>
                    handleCheckboxChange(category, '', defaultAccessibilityKey, e.target.checked)
                  }
                />
                <span className="slider"></span>
              </label>
              <Card.Title className="m-2">
                {category === 'workforceInsights' ? t('sidebarWorkforceInsights') : t(category)}
              </Card.Title>
              <span className="feature-tooltip-container">
                <FaInfoCircle className="feature-info-icon" />
                <span className="feature-tooltip-text">{t(getHelperTextForKey(category))}</span>
              </span>
            </div>
            {Object.entries(categoryFields as any).map(([subCategory, subCategoryFields]) => {
              if (subCategory === defaultAccessibilityKey) return null;

              const subCategoryDefaultAccessibilityKey = `default${capitalizeFirstLetter(subCategory)}Accessibility`;
              return (
                <div key={`${category}-${subCategory}`} className="mb-3 pl-3"> {/* Added pl-4 for padding-left */}
                  <div className="d-flex align-items-center mb-3">
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        id={`${category}-${subCategory}-${subCategoryDefaultAccessibilityKey}`}
                        checked={subCategoryFields[subCategoryDefaultAccessibilityKey] === "accessible"}
                        onChange={(e) =>
                          handleCheckboxChange(category, subCategory, subCategoryDefaultAccessibilityKey, e.target.checked)
                        }
                        disabled={categoryFields[defaultAccessibilityKey] === "inaccessible"}
                      />
                      <span className="slider"></span>
                    </label>
                    <Card.Subtitle className="m-2">
                      {t(getTextForKey(subCategory))}
                    </Card.Subtitle>
                    <span className="feature-tooltip-container">
                      <FaInfoCircle className="feature-info-icon" />
                      <span className="feature-tooltip-text">{t(getHelperTextForKey(category))}</span>
                    </span>
                  </div>
                  {Object.entries(subCategoryFields as any).map(([field, value]) => {
                    if (field === subCategoryDefaultAccessibilityKey) return null;
                    if (field === 'dataYearRange') return null;

                    if (field === "dataYears" && value === "full") {
                      const startYear = subCategoryFields.dataYearRange?.yearStart || new Date().getFullYear() - 1;
                      const endYear = subCategoryFields.dataYearRange?.yearEnd || new Date().getFullYear();
                      return (
                        <div className="toggle-container mb-3 d-flex align-items-center pl-4" key={`${category}-${subCategory}-${field}`}> {/* Added pl-4 for padding-left */}
                          <label className="toggle-switch">
                            <input
                              type="checkbox"
                              id={`${category}-${subCategory}-${field}`}
                              checked={value === "full"}
                              onChange={(e) =>
                                handleCheckboxChange(category, subCategory, field, e.target.checked)
                              }
                              disabled={subCategoryFields[subCategoryDefaultAccessibilityKey] === "inaccessible"}
                            />
                            <span className="slider"></span>
                          </label>
                          <span className="toggle-label" style={{ marginLeft: "20px" }}>
                            {t(getTextForKey(field))}
                          </span>
                          <div className="d-flex align-items-center gap-2 ms-3">
                            <Form.Control
                              as="select"
                              value={startYear}
                              onChange={(e) => {
                                const newStartYear = parseInt(e.target.value);
                                handleYearChange(category, subCategory, newStartYear, endYear);
                              }}
                              style={{ width: "100px" }}
                            >
                              {generateYears().map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Form.Control>
                            <span>-</span>
                            <Form.Control
                              as="select"
                              value={endYear}
                              onChange={(e) => {
                                const newEndYear = parseInt(e.target.value);
                                handleYearChange(category, subCategory, startYear, newEndYear);
                              }}
                              style={{ width: "100px" }}
                            >
                              {generateYears().map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                          <span className="feature-tooltip-container">
                            <FaInfoCircle className="feature-info-icon" />
                            <span className="feature-tooltip-text">{t(getHelperTextForKey(field))}</span>
                          </span>
                          {errors[`${category}-${subCategory}-startYear`] && (
                            <div className="text-danger">{errors[`${category}-${subCategory}-startYear`]}</div>
                          )}
                          {errors[`${category}-${subCategory}-endYear`] && (
                            <div className="text-danger">{errors[`${category}-${subCategory}-endYear`]}</div>
                          )}
                        </div>
                      );
                    }
                    return (
                      <div className="toggle-container mb-3 pl-4" key={`${category}-${subCategory}-${field}`}> {/* Added pl-4 for padding-left */}
                        <label className="toggle-switch">
                          <input
                            type="checkbox"
                            id={`${category}-${subCategory}-${field}`}
                            checked={value === "full" || value === "accessible"}
                            onChange={(e) =>
                              handleCheckboxChange(category, subCategory, field, e.target.checked)
                            }
                            disabled={subCategoryFields[subCategoryDefaultAccessibilityKey] === "inaccessible"}
                          />
                          <span className="slider"></span>
                        </label>
                        <span className="toggle-label" style={{ marginLeft: "20px" }}>
                          {t(getTextForKey(field))}
                        </span>
                        <span className="feature-tooltip-container">
                          <FaInfoCircle className="feature-info-icon" />
                          <span className="feature-tooltip-text">{t(getHelperTextForKey(field))}</span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Card.Body>
        </Card>
      );
    })}
</div>
    </div>
  );
};

export default Feature;
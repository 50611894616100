// stripeErrorCodes.ts

export const STRIPE_ERROR_CODES = {
    // Payment Intent Errors
    PAYMENT_INTENT_ACTION_REQUIRED: "payment_intent_action_required",
    PAYMENT_INTENT_AUTHENTICATION_FAILURE: "payment_intent_authentication_failure",
    PAYMENT_INTENT_INCOMPATIBLE_PAYMENT_METHOD: "payment_intent_incompatible_payment_method",
    PAYMENT_INTENT_INVALID_PARAMETER: "payment_intent_invalid_parameter",
    PAYMENT_INTENT_KONBINI_REJECTED_CONFIRMATION_NUMBER: "payment_intent_konbini_rejected_confirmation_number",
    PAYMENT_INTENT_MANDATE_INVALID: "payment_intent_mandate_invalid",
    PAYMENT_INTENT_PAYMENT_ATTEMPT_EXPIRED: "payment_intent_payment_attempt_expired",
    PAYMENT_INTENT_PAYMENT_ATTEMPT_FAILED: "payment_intent_payment_attempt_failed",
    PAYMENT_INTENT_UNEXPECTED_STATE: "payment_intent_unexpected_state",
  
    // Payment Method Errors
    PAYMENT_METHOD_BANK_ACCOUNT_ALREADY_VERIFIED: "payment_method_bank_account_already_verified",
    PAYMENT_METHOD_BANK_ACCOUNT_BLOCKED: "payment_method_bank_account_blocked",
    PAYMENT_METHOD_BILLING_DETAILS_ADDRESS_MISSING: "payment_method_billing_details_address_missing",
    PAYMENT_METHOD_CONFIGURATION_FAILURES: "payment_method_configuration_failures",
    PAYMENT_METHOD_CURRENCY_MISMATCH: "payment_method_currency_mismatch",
    PAYMENT_METHOD_CUSTOMER_DECLINE: "payment_method_customer_decline",
    PAYMENT_METHOD_INVALID_PARAMETER: "payment_method_invalid_parameter",
    PAYMENT_METHOD_INVALID_PARAMETER_TESTMODE: "payment_method_invalid_parameter_testmode",
    PAYMENT_METHOD_MICRODEPOSIT_FAILED: "payment_method_microdeposit_failed",
    PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_AMOUNTS_INVALID: "payment_method_microdeposit_verification_amounts_invalid",
    PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_AMOUNTS_MISMATCH: "payment_method_microdeposit_verification_amounts_mismatch",
    PAYMENT_METHOD_MICRODEPOSIT_VERIFICATION_ATTEMPTS_EXCEEDED: "payment_method_microdeposit_verification_attempts_exceeded",
  
    // General Errors
    NETWORK_ERROR: "network_error",
    RATE_LIMIT_EXCEEDED: "rate_limit_exceeded",
    UNKNOWN_ERROR: "unknown_error",
  };
  
  export const STRIPE_ERROR_MESSAGES: Record<string, string> = {
    // Payment Intent Errors
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_ACTION_REQUIRED]: "Additional authentication required. Please complete the security challenge.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_AUTHENTICATION_FAILURE]: "Authentication failed. Please try a different payment method.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_INCOMPATIBLE_PAYMENT_METHOD]: "The payment method provided is not compatible.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_INVALID_PARAMETER]: "Invalid parameters provided for the payment intent.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_PAYMENT_ATTEMPT_EXPIRED]: "Payment attempt expired. Please try again.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_PAYMENT_ATTEMPT_FAILED]: "Payment attempt failed. Please try a different payment method.",
    [STRIPE_ERROR_CODES.PAYMENT_INTENT_UNEXPECTED_STATE]: "Unexpected payment state. Please try again later.",
  
    // Payment Method Errors
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_BANK_ACCOUNT_ALREADY_VERIFIED]: "This bank account is already verified.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_BANK_ACCOUNT_BLOCKED]: "This bank account is blocked and cannot be used.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_BILLING_DETAILS_ADDRESS_MISSING]: "Billing address details are missing. Please update and try again.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_CONFIGURATION_FAILURES]: "Payment method configuration failed. Please check your settings.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_CURRENCY_MISMATCH]: "Currency mismatch. Please use a matching currency payment method.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_CUSTOMER_DECLINE]: "The customer declined the payment. Please provide a new payment method.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_INVALID_PARAMETER]: "Invalid payment method parameters. Please verify your input.",
    [STRIPE_ERROR_CODES.PAYMENT_METHOD_MICRODEPOSIT_FAILED]: "Microdeposits failed. Please verify your bank account details.",
  
    // General Errors
    [STRIPE_ERROR_CODES.NETWORK_ERROR]: "Network error. Please check your connection and try again.",
    [STRIPE_ERROR_CODES.RATE_LIMIT_EXCEEDED]: "Too many requests. Please try again later.",
    [STRIPE_ERROR_CODES.UNKNOWN_ERROR]: "An unexpected error occurred. Please try again later or contact support.",
  };
  
import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import SemiPieChart from "./SemiPieChart";
import { Loader } from "../Loader.tsx";
import { FaInfoCircle } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { useUser } from "../../context/UserContext.js";
import { useUserSettings } from "../../context/GlobalSettingsProvider.js";
import { isLoggedIn } from "../../pages/utils/authUtils.js";

const rangeOptions = ["all", "under30", "30to54", "55above"];

const PyramidChart = ({ tabSettings }) => {
  const { t } = useTranslation();
  const { user, userPlan ,defaultPlan} = useUser();

  const { defaultSettings, updateSettings ,setCurrentTab ,resetSettings } = useUserSettings();
  const mainTab = "workForce";
  const subTab = "demograpghicBreakDown";

  // Initialize filter values from global context or use defaults
  const userSettings = defaultSettings?.find(setting => setting?.mainTab === mainTab)?.subTabs?.find(sub => sub?.subTab === subTab)?.settings;


  const isSuperAdmin = user?.role === "SUPER_ADMIN" || user?.role === "ADMIN";
  const chartRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false)
  const [seriesData, setSeriesData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [establishment, setEstablistment] = useState([]);
  const [selectedEstablishment, setSelectedEstablistment] = useState(userSettings?.selectedSector ?? "");
  const [genderData, setGenderData] = useState(null)
  const [immigrantsData, setImmigrantsData] = useState(null)
  const [establishmentDataLoad, setEstablishmentDataLoad] = useState(false)
  const [selectedRange, setSelectedRange] = useState(userSettings?.ageRange|| "all");
  const [genderAgeOptions, setGenderAgeOptions] = useState({
    chart: {
      type: "bar",
      height: 400
    },
    title: {
      text: t("employmentInsights.tabAgeRange.chartTitle"),
      style: {
        color: "#E61E28"
      }
    },
    subtitle: {
      text: "Gender Breakdown by Age",
      style: {
        fontSize: "12px",
        fontWeight: 800,
        padding: '10px',
        color: '#000'
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: [
      {
        categories: null,
      },
      {
        opposite: true,
        reversed: false,
        categories: null,
        linkedTo: 0,
      },
    ],
    tooltip: {
      formatter: function () {
        const language = i18n.language
        let value = Math.abs(this.y)
        let percent
        if (language == "en") {
          percent = value
        } else if (language == "fr") {
          percent = value.toString().replace(".", ",")
        }
        return (
          "<b>" +
          this.series.name +
          " " +
          this.x +
          "</b><br/>" +
          `${t("percent")}: ` +
          percent +
          "%"
        );
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value) + "%";
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600, // For screens <= 600px
          },
          chartOptions: {
            chart: {
              height: 300, // Adjust chart height for smaller screens
            },
            title: {
              style: {
                fontSize: "14px", // Smaller font size for the title
              },
            },
            subtitle: {
              style: {
                fontSize: "10px", // Smaller font size for the subtitle
              },
            },
            xAxis: [
              {
                labels: {
                  style: {
                    fontSize: "10px", // Smaller font size for x-axis labels
                  },
                },
              },
              {
                labels: {
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            ],
            yAxis: {
              labels: {
                style: {
                  fontSize: "10px", // Smaller font size for y-axis labels
                },
              },
            },
            legend: {
              itemStyle: {
                fontSize: "10px", // Smaller font size for legend items
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 400, // For screens <= 400px
          },
          chartOptions: {
            chart: {
              height: 250, // Reduce height further for smaller screens
            },
            xAxis: [
              {
                labels: {
                  style: {
                    fontSize: "8px", // Even smaller font for very small screens
                  },
                },
              },
              {
                labels: {
                  style: {
                    fontSize: "8px",
                  },
                },
              },
            ],
            yAxis: {
              labels: {
                style: {
                  fontSize: "8px",
                },
              },
            },
            title: {
              style: {
                fontSize: "12px",
              },
            },
            subtitle: {
              style: {
                fontSize: "8px",
              },
            },
          },
        },
      ],
    },
  });
  const [genderOptions, setGenderOptions] = useState({
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      spacing: [10, 0, 10, 0],
      height: 400,
    },
    title: {
      text: "Gender Breakdown",
      style: {
        fontSize: "12px",
        fontWeight: 800,
      },
    },
    credits: { enabled: false },
    legend: { enabled: false },
    tooltip: {
      formatter: function () {
        const language = i18n.language;
        const value = this.point.percentage.toFixed(1);
        const percent = language === "fr" ? value.replace(".", ",") : value;
        return `<b>${this.point.name}</b><br/>${t("percent")}: ${percent}%`;
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: 10,
          style: { fontWeight: "bold", color: "black" },
          formatter: function () {
            const language = i18n.language;
            const value = this.point.percentage.toFixed(1);
            const percent = language === "fr" ? value.replace(".", ",") : value;
            return `${this.point.name}:<br/>${percent}%`;
          },
        },
        center: ["50%", "60%"],
        size: "250px",
        colors: ["#fbb518", "#ef4b32"],
      },
    },
    series: [
      {
        type: "pie",
        name: t("gender"),
        innerSize: "50%",
        data: [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: { maxWidth: 400 },
          chartOptions: {
            chart: { height: 300 },
            title: { style: { fontSize: "10px" } },
            plotOptions: {
              pie: {
                size: "150px",
                dataLabels: {
                  distance: 10,
                  style: { fontSize: "8px" },
                },
              },
            },
          },
        },
      ],
    },
  });

  
  useEffect(()=>{
    resetSettings()
  },[])

  useEffect(() =>{
    setCurrentTab('workForce' ,'demograpghicBreakDown')
    //resetSettings()
  },[]);

  const [immigrantOptions, setImmigrantOptions] = useState({
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      height: 400,
      paddingTop: '20px',
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container-immigrant';
          const tooltipId = 'icon-tooltip-immigrant';

          const addIconWithTooltip = () => {
            // Remove the existing icon and tooltip if they exist
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            let existingTooltip = document.getElementById(tooltipId);
            if (existingTooltip) existingTooltip.remove();

            // Create a div for the icon
            let iconDiv = document.createElement('div');
            iconDiv.id = iconContainerId;
            iconDiv.className = 'custom-icon';

            // Ensure the chart container exists before appending elements
            if (chart.container) {
              // Set chart container position to relative
              chart.container.style.position = 'relative';
              chart.container.appendChild(iconDiv);
              // chart.container.appendChild(tooltipDiv);
            }

            // Render the React icon inside the div
            try {
              ReactDOM.render(
                <CustomIconWithTooltip
                  iconContainerId={iconContainerId}
                  tooltipId={tooltipId}
                />,
                iconDiv
              );
            } catch (error) {
              console.error("Error rendering the icon:", error);
            }

            // Set the icon's position in the top-right corner
            const updateIconPosition = () => {
              if (chart.container) {
                iconDiv.style.right = '25px'; // 10px padding from right
                iconDiv.style.top = '10px'; // 10px padding from top
              }
            };

            updateIconPosition();
            Highcharts.addEvent(chart, 'redraw', updateIconPosition);
            window.addEventListener('resize', updateIconPosition);
          };

          addIconWithTooltip();
        }
      }
    },
    title: {
      text: t("Immigrant Breakdown"),
      style: {
        fontSize: "12px",
        fontWeight: 800,
        padding: '10px',
      },
      useHTML: true
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false, // Disable the legend
    },
    tooltip: {
      formatter: function () {
        const language = i18n.language
        let value = this.point.percentage.toFixed(1)
        let percent
        if (language == "en") {
          percent = value
        } else if (language == "fr") {
          percent = value.toString().replace(".", ",")
        }
        return (
          `<b>${this.point.name}</b><br/>${t("percent")}: ${percent}%`
        );
      },
    },
    plotOptions: {
      series: {
        tooltip: {
          valueSuffix: '%'
        }
      },
      pie: {
        dataLabels: {
          enabled: true,
          distance: 10,
          style: {
            fontWeight: "bold",
            color: "black",
          },
          formatter: function () {
            const language = i18n.language
            let value = this.point.options.y
            let percent
            if (language == "en") {
              percent = value
            } else if (language == "fr") {
              percent = value.toString().replace(".", ",")
            }
            return this.point.name + ":<br/> " + percent + '%';
          }
        },

        startAngle: -90,
        endAngle: 90,
        center: ["47%", "70%"],
        size: "250px",
        colors: ["#ef4b32", "#fbb518", "#119448"], //"#d880b7","#FFC94A","#1ea2dc"
      },
    },
    series: [
      {
        type: "pie",
        name: t("Immigrants"),
        innerSize: "50%",
        data: [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: { maxWidth: 400 },
          chartOptions: {
            chart: { height: 300 },
            title: { style: { fontSize: "10px" } },
            plotOptions: {
              pie: {
                size: "150px",
                dataLabels: {
                  distance: 10,
                  style: { fontSize: "8px" },
                },
              },
            },
          },
        },
      ],
    },
  });



  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      
      try {
        const urls = {
          genderAgeData: API_URLS.employmentByGenderRange.replace(
            "{establishment}",
            selectedEstablishment ? selectedEstablishment?._id : ""
          ),
          genderData: API_URLS.employmentByGender.replace(
            "{establishment}",
            selectedEstablishment ? selectedEstablishment?._id : ""
          ).replace(
            "{range}",
            selectedRange == "all" ? "" : selectedRange
          ),
          immigrantData: API_URLS.employmentByImmigrants.replace(
            "{establishment}",
            selectedEstablishment ? selectedEstablishment?._id : ""
          )
        };

        const [genderAgeResponse, genderResponse, immigrantResponse] = await Promise.all([
          httpClient({ method: "get", url: urls.genderAgeData }),
          httpClient({ method: "get", url: urls.genderData }),
          httpClient({ method: "get", url: urls.immigrantData }),
        ]);

        if (genderAgeResponse?.result) {

          const genderSort = genderAgeResponse?.result.count.sort((a, b) => (a.gender === "men" ? 1 : -1))
          const result = { ...genderAgeResponse?.result, count: genderSort }

          let category = [];
          let seriesData = [];

          for (let index in result.count) {
            let dataToPopulate = [];

            result.count[index].ranges.forEach(function (item, i) {
              category[i] = item.range;

              dataToPopulate[i] = {
                y: index == 0
                  ? -Number(item.percentage.toFixed(2))
                  : Number(item.percentage.toFixed(2)),
                value: item.totalCount
              }
            });

            seriesData[index] = {
              name: capitalizeFirstLetter(t(result.count[index].gender)),
              data: dataToPopulate,
              color: result.count[index].gender === "men" ? "#fbb518" : "#ef4b32", //"#fbb518", "#ef4b32" //"#1ea2dc" : "#d880b7"
            };
          }

          setSeriesData(seriesData);
          setGenderAgeOptions(prevOptions => ({
            ...prevOptions,
            title: { text: selectedEstablishment ? t(selectedEstablishment?.languageKey) : t("employmentInsights.tabAgeRange.inputValue.overallFoodIndustry") },
            xAxis: [{ categories: category }, { categories: category, opposite: true }],
            series: seriesData,
          }));
          setCatData(category);
        }

        if (genderResponse?.result) {
          const genderSort = genderResponse?.result?.count[0]?.establishmentCounts.sort((a, b) => (a.gender === 'men' ? -1 : 1))
          const result = { ...genderResponse?.result?.count[0], establishmentCounts: genderSort }
          const data = [
            [
              capitalizeFirstLetter(
                t(result.establishmentCounts[0].gender)
              ),
              Number(
                result.establishmentCounts[0].percentage.toFixed(2)
              ),
            ],
            [
              capitalizeFirstLetter(
                t(result.establishmentCounts[1].gender)
              ),
              Number(
                result.establishmentCounts[1].percentage.toFixed(2)
              ),
            ],
          ]

          setGenderData(data)
        }

        if (immigrantResponse?.result) {
          setImmigrantsData(immigrantResponse?.result.data)
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setShowLoader(false)
        setEstablishmentDataLoad(true)
      }

    }
    Highcharts.setOptions({
      lang: {
        thousandsSep: ","
      }
    })
    fetchData()

  }, [selectedEstablishment, t]);

  useEffect(() => {
    const initialize = () => {
      try {
        let url = API_URLS.listEstablishments.replace(false, true);

        httpClient({
          method: "get",
          url,
        }).then(({ result, error }) => {
          if (result) {
            if (result.establishments) {
              const filteredEstablishment = result.establishments.filter(item => item.name !== 'Total, all industries' && item?.name !== 'Food manufacturing')
              setEstablistment(filteredEstablishment);
            }
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
    initialize()
  }, []);

  const handleEstChange = (event) => {
    const { value } = event.target;


    setSelectedEstablistment(value !== "" ? JSON.parse(value) : value);
    updateSettings(mainTab, subTab, "selectedSector", JSON.parse(value));
    // tabSettings('ageRange', { establishment: JSON.parse(value), range: selectedRange })
  };

  const handleRangeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRange(selectedValue);
    updateSettings(mainTab, subTab, "ageRange", selectedValue);
    tabSettings('ageRange', { establishment: selectedEstablishment, range: selectedValue })
  }

  const clearAll = () => {
    setSelectedEstablistment("")
    setSelectedRange("all")
    updateSettings(mainTab, subTab, "selectedSector", '');
    updateSettings(mainTab, subTab, "ageRange", 'all');
  }

  useEffect(() => {
    if (establishmentDataLoad) {
      let url = API_URLS.employmentByGender.replace(
        "{establishment}",
        selectedEstablishment ? selectedEstablishment?._id : ""
      ).replace(
        "{range}",
        selectedRange == "all" ? "" : selectedRange
      )

      httpClient({
        method: "get",
        url,
      }).then(({ result, error }) => {
        if (result) {
          const genderSort = result?.count[0]?.establishmentCounts.sort((a, b) => (a.gender === 'men' ? -1 : 1))
          const newResult = { ...result?.count[0], establishmentCounts: genderSort }

          const data = [
            [
              capitalizeFirstLetter(
                t(newResult.establishmentCounts[0].gender)
              ),
              Number(
                newResult.establishmentCounts[0].percentage.toFixed(2)
              ),
            ],
            [
              capitalizeFirstLetter(
                t(newResult.establishmentCounts[1].gender)
              ),
              Number(
                newResult.establishmentCounts[1].percentage.toFixed(2)
              ),
            ],
          ]
          setGenderData(data)
        }
      })
    }
  }, [selectedRange])

  useEffect(() => {
    let filteredData = [];
    let filteredCatData = []
    let subTitle

    setGenderOptions((prevState) => {
      return {
        ...prevState,
        title: { text: t("employmentInsights.tabAgeRange.chartGenderBreakdownSubTitle") },
        series: [{ ...prevState.series[0], data: genderData }],

      };
    });
    if (selectedRange === "under30") {
      filteredData = [...seriesData].map((series) => ({
        ...series,
        data: series.data.slice(0, 3).map((item) => ({
          y: item.y,
          value: item.value,
        })),
      }));
      subTitle = t('employmentInsights.tabAgeRange.chartGenderAgeUnder30SubTitle')
      filteredCatData = [...catData].slice(0, 3);

      const data = [
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.Immigrants')
          ),
          immigrantsData?.immigrantsPercentageData?.["under_24"] ?? 0,
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonImmigrants')
          ),
          immigrantsData?.nonImmigrantsPercentageData?.["under_24"] ?? 0,
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonPermamnentResidents')
          ),
          immigrantsData?.nonPRPercentageData?.["under_24"] ?? 0,
        ],
      ]

      setImmigrantOptions((prevState) => {
        return {
          ...prevState,
          // title: { text: t("employmentInsights.tabAgeRange.chartImmigrantSubTitle") },
          series: [{ ...prevState.series[0], name: t("Immigrants"), data: data }],
        };
      });

    } else if (selectedRange === "30to54") {

      filteredData = [...seriesData].map((series) => ({
        ...series,
        data: series.data.slice(3, 6).map((item) => ({
          y: item.y,
          value: item.value,
        })),
      }));
      subTitle = t('employmentInsights.tabAgeRange.chartGenderAgeBetween30to54SubTitle')
      filteredCatData = [...catData].slice(3, 6);
      const data = [
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.Immigrants')
          ),
          immigrantsData?.immigrantsPercentageData["24_55"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonImmigrants')
          ),
          immigrantsData?.nonImmigrantsPercentageData["24_55"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonPermamnentResidents')
          ),
          immigrantsData?.nonPRPercentageData["24_55"],
        ],
      ]

      setImmigrantOptions((prevState) => {
        return {
          ...prevState,
          // title: { text: t("employmentInsights.tabAgeRange.chartImmigrantSubTitle") },
          series: [{ ...prevState.series[0], name: t("Immigrants"), data: data }],
        };
      });
    } else if (selectedRange === "55above") {

      filteredData = [...seriesData].map((series) => ({
        ...series,
        data: series.data.slice(6).map((item) => ({
          y: item.y,
          value: item.value,
        })),
      }));
      subTitle = t('employmentInsights.tabAgeRange.chartGenderAgeAbove55SubTitle')
      filteredCatData = [...catData].slice(6);
      const data = [
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.Immigrants')
          ),
          immigrantsData?.immigrantsPercentageData["55_plus"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonImmigrants')
          ),
          immigrantsData?.nonImmigrantsPercentageData["55_plus"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonPermamnentResidents')
          ),
          immigrantsData?.nonPRPercentageData["55_plus"],
        ],
      ]

      setImmigrantOptions((prevState) => {
        return {
          ...prevState,
          // title: { text: t("employmentInsights.tabAgeRange.chartImmigrantSubTitle") },
          series: [{ ...prevState.series[0], name: t("Immigrants"), data: data }],
        };
      });
    } else if (selectedRange == "all") {
      filteredData = seriesData.map((series) => ({
        ...series,
        data: series.data.map((item) => ({
          y: item.y,
          value: item.value,
        })),
      }));
      filteredCatData = catData;
      subTitle = t('employmentInsights.tabAgeRange.chartGenderAgeSubTitle')
      const data = [
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.Immigrants')
          ),
          immigrantsData?.immigrantsPercentageData["totalPercentage"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonImmigrants')
          ),
          immigrantsData?.nonImmigrantsPercentageData["totalPercentage"],
        ],
        [
          capitalizeFirstLetter(
            t('employmentInsights.tabImmigrant.pieDataTitle.NonPermamnentResidents')
          ),
          immigrantsData?.nonPRPercentageData["totalPercentage"],
        ],
      ]

      setImmigrantOptions((prevState) => {
        return {
          ...prevState,
          title: { text: t("employmentInsights.tabAgeRange.chartImmigrantSubTitle") },
          series: [{ ...prevState.series[0], name: t("Immigrants"), data: data }],
        };

      });
    }
    setGenderAgeOptions(prevOptions => ({
      ...prevOptions,
      subtitle: { ...prevOptions.subtitle, text: subTitle },
      xAxis: [{ categories: filteredCatData }, { categories: filteredCatData, opposite: true }],
      series: filteredData,
    }));
  }, [selectedRange, seriesData, catData, genderData]);

  return (
    <>
      <Loader show={showLoader} />
      <div className="container-fluid">
        {/* Clear Button Row */}
        <div className="row gx-0 mb-3">
          <div className="col d-flex justify-content-end align-items-center">
            <button
              className="clear_btn"
              onClick={clearAll}
              style={{ fontSize: "15px", marginRight: "10px" }}
            >
              {t("commonSubHeadingClearAll")}
            </button>
          </div>
        </div>

        {/* Filters Row */}
        <div className="row gx-3 gy-3">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t("employmentInsights.tabAgeRange.inputTitle.selectSector")}
              </label>
              {((user?.role !== "SUPER_ADMIN" || user?.role !== "ADMIN") && ( isLoggedIn() ? userPlan?.subscriptionPlanId?.features?.workforceInsights?.demographicBreakdown?.demographicSectorDropdown == "limited" : defaultPlan?.features?.workforceInsights?.demographicBreakdown?.demographicSectorDropdown == "limited")) &&
                <select
                  className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                  value={
                    selectedEstablishment
                      ? JSON.stringify(selectedEstablishment)
                      : selectedEstablishment
                  }
                  onChange={handleEstChange}
                >

                  <option value="">
                    {t(
                      "employmentInsights.tabAgeRange.inputValue.overallFoodIndustry"
                    )}
                  </option>
                </select>
              }
              {(isSuperAdmin || ( isLoggedIn() ?  userPlan?.subscriptionPlanId?.features?.workforceInsights?.demographicBreakdown?.demographicSectorDropdown == "full" : defaultPlan?.features?.workforceInsights?.demographicBreakdown?.demographicSectorDropdown == "full")) &&
                <select
                  className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                  value={
                    selectedEstablishment
                      ? JSON.stringify(selectedEstablishment)
                      : selectedEstablishment
                  }
                  onChange={handleEstChange}
                >

                  <option value="">
                    {t(
                      "employmentInsights.tabAgeRange.inputValue.overallFoodIndustry"
                    )}
                  </option>
                  {establishment.map((item) => (
                    <option key={item._id} value={JSON.stringify(item)}>
                      {t(item.languageKey)}
                    </option>
                  ))}
                </select>
              }
              {/* {establishment.map((item) => (
                  <option key={item._id} value={JSON.stringify(item)}>
                    {t(item.languageKey)}
                  </option>
                ))}
              </select> */}
            </div>
          </div>

          {(isSuperAdmin ||( isLoggedIn() ?  userPlan?.subscriptionPlanId?.features?.workforceInsights?.demographicBreakdown?.demographicAgeRangeDropdown == "accessible" : defaultPlan?.features?.workforceInsights?.demographicBreakdown?.demographicAgeRangeDropdown == "accessible")) &&
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="custom-form-group custom-form-group-sm ps-2">
                <label htmlFor="yearSelect" className="text-black">
                  {t("employmentInsights.tabAgeRange.inputTitle.byAgeRange")}
                </label>
                <select
                  className="form-select c-form-control c-form-control-bg c-form-control-sm c-form-control-sm-height fw-semibold"
                  value={selectedRange}
                  onChange={handleRangeChange}
                >
                  {rangeOptions.map((item) => (
                    <option value={item}>
                      {t("employmentInsights.tabAgeRange.inputValue." + item)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          }
        </div>

        {/* Charts Section */}
        <div className="row gx-3 gy-3 mt-3">
          {/* Full-Width Chart */}
          <div className="col-12">
            <div
              className="mt-3"
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 0px 10px 0px",
              }}
            >
              <HighchartsReact highcharts={Highcharts} options={genderAgeOptions} />
            </div>
          </div>

          {/* Two Half-Width Charts */}
          <div className="col-sm-12 col-md-12">
            <div
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 0px 10px 0px",
              }}
            >
              <HighchartsReact highcharts={Highcharts} options={genderOptions} />
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 0px 10px 0px",
              }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={immigrantOptions}
                ref={chartRef}
              />
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default PyramidChart;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId }) => {
  const { t } = useTranslation()
  return (
    <>
      {/* First Icon with Tooltip */}
      <div id={`${iconContainerId}-1`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              <b>{t("disclaimer")}: </b> {t("informationImmigrants").split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaInfoCircle style={{ fontSize: '20px', color: "#212529" }} />
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
};
